import { request } from "app/utils/request";
import { Dispatch } from "react";
import { action, ActionType } from "typesafe-actions";
import config from "config/environment";

const pending = () => action("@destinations.fetch.pending");

const error = (err: string) => action("@destinations.fetch.error", err);

const success = (success: any) =>
  action("@destinations.fetch.success", success);

const data = (data: any[]) => action("@destinations.fetch.data", data);

export const selectedDestinations = (data: any[]) =>
  action("@destinations.fetch.selectedDestinations", data);
export const allowMultipleSelect = (allowMultipleSelect: boolean) =>
  action("@destinations.fetch.allowMultipleSelect", allowMultipleSelect);

export const clearDestinations = () => action("@destinations.clear");

const thisActions = {
  pending,
  error,
  success,
  data,
  selectedDestinations,
  allowMultipleSelect,
  clearDestinations,
};

export type destinationsActions = ActionType<typeof thisActions>;

export function fetchDestinations() {
  return (dispatch: Dispatch<destinationsActions>) => {
    dispatch(pending());

    const url = config.API_SERVER + `/api/v1/posts/destinations`;
    const promise = request(url, "GET", {}, { version: 1 });
    promise.then((destinationsData) => dispatch(success(destinationsData)));
    promise.catch((err) => dispatch(error(err)));

    return promise;
  };
}
