/** @module reducers */
import { ImportModel, BatchResult, TeamGroupMemberImportActions } from 'app/actions/team-group-member-import';

export type TeamGroupMemberImportState = {
  importPending: boolean;
  importStatus: ImportModel;
  importDone: BatchResult;
};

const initialState: TeamGroupMemberImportState = {
  importPending: false,
  importStatus: null,
  importDone: null,
};

export default function teamGroupMemberImportReducer(state = initialState, action: TeamGroupMemberImportActions): TeamGroupMemberImportState {
  switch (action.type) {
    case '@team-group-member-import.post.pending':
      return { ...state, importStatus: null, importDone: null, importPending: true };
    case '@team-group-member-import.post.status':
      return { ...state, importStatus: action.payload };
    case '@team-group-member-import.post.done':
      return { ...state, importDone: action.payload, importPending: false };

    default:
      return state;
  }
}
