import React from "react";
import BullseyeArrow from "../../../../public/images/bullseye-arrow.svg";
import styles from "./styles.module.scss";
import { fetchSelf } from "app/actions/user";
import { useStoreActions } from "app/utils/hooks";
import { fetchOnboarding } from "app/actions/onboarding";
import ProgressBar from "app/components/Layouts/ProgressBar";
import { cls } from "app/utils";

const GettingStartedProgress = () => {
  const { user } = useStoreActions({ user: fetchSelf });
  const { onboarding } = useStoreActions({ onboarding: fetchOnboarding });

  return (
    user.data &&
    user.data.needsOnboarding &&
    user.data.kind === "coach" && (
      <div className={cls(styles.container)}>
        <div className={styles.progress_bar_container}>
          <div className={styles.title_wrapper}>
            <img src={BullseyeArrow} alt="bullseye" />
            <h1 className={styles.title}>Getting started</h1>
          </div>
          <div className={styles.progress_bar_wrapper}>
            {onboarding.data && (
              <ProgressBar
                percentage={
                  onboarding.data && onboarding.data.percentsCompleted
                }
              />
            )}
          </div>
        </div>
        <a href="/getting-started">
          <p className={styles.continue_btn}>Continue</p>
        </a>
      </div>
    )
  );
};

export default GettingStartedProgress;
