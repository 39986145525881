import { useEffect, useRef, useState } from "react";

import { useActions, useSelector } from "app/utils/hooks";
import { clearMediaState, fetchMedia } from "app/actions/media-library-data";
import { RootState } from "typedefs";
import { useDispatch } from "react-redux";
import { selectedMedia } from "app/actions/new-post-data";

const useUploadsPicker = () => {
  const actions = useActions({ fetchMedia, clearMediaState });
  const [libraryData, setLibraryData] = useState<[] | null>(null);
  const containerRef = useRef<any>(null);

  const [currentPage, setCurrentPage] = useState<number>(1);

  const { pending, hasMore, attachment } = useSelector(
    (state: RootState) => state.mediaLibraryData
  );

  const dispatch = useDispatch();

  const handleSelectMedia = (selectedItem: any) => {
    dispatch(selectedMedia(selectedItem));
  };

  useEffect(() => {
    setLibraryData(attachment);

    return () => {
      setLibraryData([]);
    };
  }, [attachment]);

  useEffect(() => {
    actions.fetchMedia(currentPage);
    setCurrentPage(currentPage + 1);

    return () => {
      actions.clearMediaState();
    };
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver((entries: any) => {
      if (!hasMore || pending) {
        return;
      }

      const entry = entries[0];
      if (entry.isIntersecting) {
        actions.fetchMedia(currentPage);
        setLibraryData(...libraryData, attachment);
        setCurrentPage(currentPage + 1);
      }
    });

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, [containerRef, currentPage, hasMore]);

  const dropDownData: Array<any> = [
    {
      title: "Filter By",
      data: [
        { optionTitle: "Video", option: "", isChecked: true },
        { optionTitle: "Audio", option: "", isChecked: true },
        { optionTitle: "Images", option: "", isChecked: true },
        { optionTitle: "Documents", option: "", isChecked: true },
      ],
    },
  ];

  return {
    pending,
    libraryData,
    dropDownData,
    handleSelectMedia,
  };
};

export default useUploadsPicker;
