/** @module components/Wrappers */
import React from "react";
import { cls } from "app/utils";
import { Dots, Spinner } from "app/components/Loading";
import styles from "./styles.module.scss";

type LoadTypes = "dots" | "spinner" | undefined;

/**
 * @typedef Props
 * @property {string} className - container class name
 * @property {string} loadType - value can be any one of the following: [ 'dots', 'spinner' ]
 * @property {boolean} isLoading - true shows loading, false shows child content
 */
type PropsDef = React.PropsWithChildren<{
  className?: string;
  loadType?: LoadTypes;
  isLoading?: boolean;
}>;

/**
 * Loading Wrapper shows loading or child content based on isLoading boolean
 *
 * @name Loading
 * @param {Props} props
 * @return {Element}
 */
export default function Loading(props: PropsDef) {
  let children = props.children;
  if (props.isLoading) {
    children = getLoader(props.loadType);
  }

  return (
    <div
      className={cls(styles.loadingWrapper, props.className, {
        [styles.loading]: props.isLoading,
      })}
    >
      {children}
    </div>
  );
}

const getLoader = (type: LoadTypes): JSX.Element => {
  switch (type) {
    case "dots":
      return <Dots active={true} />;
    case "spinner":
      return <Spinner />;
    default:
      return <Dots active={true} />;
  }
};
