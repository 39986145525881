import { MoveAction } from "app/actions/move";
import { RequestError } from "typedefs";

export type MoveState = {
  data: any | null;
  error: RequestError | null;
  pending: boolean;

  pendingMove: boolean;
  errorMove: RequestError | null;
  dataMove: any | null;
  moveLocation: string | null;
  moveObjectLocation: string | null;
};

const initialState: MoveState = {
  data: null,
  error: null,
  pending: false,

  dataMove: null,
  errorMove: null,
  pendingMove: false,
  moveLocation: null,
  moveObjectLocation: null,
};

export default function moveReducer(state = initialState, action: MoveAction) {
  switch (action.type) {
    case "@move.fetch.pending":
      return { ...state, pending: true, data: null };
    case "@move.fetch.error":
      return { ...state, pending: false, data: null, error: action.payload };
    case "@move.fetch.success":
      return { ...state, pending: false, data: action.payload, error: null };
    case "@move.create.pending":
      return { ...state, dataMove: null, pendingmove: true };
    case "@move.create.error":
      return {
        ...state,
        dataMove: null,
        pendingmove: false,
        errorMove: action.payload,
      };
    case "@move.create.success":
      return { ...state, dataMove: action.payload, pendingmove: false };
    case "@move.fetch.moveLocation":
      return { ...state, moveLocation: action.payload, pending: false };
    case "@move.fetch.moveObjectLocation":
      return { ...state, moveObjectLocation: action.payload, pending: false };
    case "@move.clear":
      return {
        ...state,
        dataMove: null,
        data: null,
        pending: null,
        moveLocation: null,
        moveObjectLocation: null,
        errorMove: null,
      };
    default:
      return state;
  }
}
