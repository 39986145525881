import pdf_ico from "../../../../../public/images/ico_pdf.png";
import xlxx_ico from "../../../../../public/images/ico_xls.png";
import doc_ico from "../../../../../public/images/ico_doc.png";
import ppt_ico from "../../../../../public/images/ico_ppt.png";
import audio_ico from "../../../../../public/images/ico_audio.png";

const useDatafileIcons = () => {
  const icons: { [key: string]: any } = {
    xlxx: xlxx_ico,
    xlsx: xlxx_ico,
    doc: doc_ico,
    docx: doc_ico,
    ppt: ppt_ico,
    pptx: ppt_ico,
    pdf: pdf_ico,
    audio: audio_ico,
  };

  const dataFileIconHandler = (datafile: string): any => {
    if (datafile === undefined) {
      return;
    }

    return icons[datafile] || undefined;
  };

  return {
    dataFileIconHandler,
  };
};

export default useDatafileIcons;
