/**
 * @module Components.Routes.Signin
 *
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  no_code: { id: 'app.resend_code.no_code', defaultMessage: "Didn't get the code?" },
  resend: { id: 'app.resend_code.resend', defaultMessage: 'Resend' },
  retry: { id: 'app.resend_code.retry', defaultMessage: 'Retry in {counter} seconds.' },
  error: { id: 'app.resend_code.error', defaultMessage: 'Something went wrong.' }
});
