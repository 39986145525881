/**
 * @module Components.Inputs.Checkbox
 *
 */
import CheckBox from './Base';
export default CheckBox;

export { default as AgeConsent } from './AgeConsent';
export { default as Image } from './Image';
export { default as TermsOfService } from './TermsOfService';

