/**
 * @module Components.Input
 *
 */
import React, { useState, useEffect, useRef } from 'react';
import { defineMessages } from 'react-intl';
import { useLoc } from 'app/utils/hooks';
import { cls } from 'app/utils';
import Styles from './styles.module.scss';

/***/
type PropsDef = React.PropsWithChildren<{
  clearInput?: boolean;
  className?: string;
  placeholder?: string;
  onChange?: (value: string, key: string) => void;
  onClick?: (value: string, key: string) => void;
}>;

/**
 * @class SearchBar
 *
 */
export default function SearchBar(props: PropsDef) {
  const { formatMessage } = useLoc();
  const ref = useRef(null);
  const placeholder = props.placeholder || formatMessage(LOCALES.placeholder);
  const [showClearIcon, setShowClearIcon] = useState(false);

  useEffect(() => {
    if (props.clearInput) {
      clearInput();
    }
  }, [props.clearInput]);

  const handleChange = (evt: React.KeyboardEvent<HTMLInputElement>) => {
    setShowClearIcon(ref.current.value !== null && ref.current.value !== '');

    // Handle Enter press
    if (evt.which === 13) {
      handleClick();
    }

    // Handle Esc press
    if (evt.which === 27) {
      clear();

      return;
    }

    if (props.onChange != null) {
      props.onChange(ref.current.value, evt.key);
    }
  };

  const handleClick = () => {
    if (props.onClick != null) {
      props.onClick(ref.current.value, 'Enter');
    }
  };

  const clearInput = () => {
    ref.current.value = null;
    setShowClearIcon(false);
  };

  const clear = () => {
    clearInput();

    if (props.onClick != null) {
      props.onClick(ref.current.value, 'Enter');
    }

    if (props.onChange != null) {
      props.onChange(ref.current.value, 'Enter');
    }
  };

  return (
    <div className={cls(Styles.searchBar, props.className)}>
      <input
        ref={ref}
        type="text"
        placeholder={placeholder}
        onKeyUp={handleChange}
      />
      {
        showClearIcon && (
          <i
            className={cls(Styles.iconClear, "ico ico-x")}
            onClick={clear}
          />
        )
      }
      <i
        className={cls(Styles.iconSearch, "ico ico-search")}
        onClick={handleClick}
      />
    </div>
  );
}

const LOCALES = defineMessages({
  placeholder: { id: 'search.placeholder.value', defaultMessage: 'Search' }
});
