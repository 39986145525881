/** @module utils/analytics-helpers */
import { AnalyticsService } from 'app/services';

/**
 * Paywall track wrapper
 *
 */
function _optionSelected(feature: string, option: string) {
  AnalyticsService.track('Purchase Paywall Option Selected', {
    Feature: feature,
    Source: 'Web',
    Option: option,
  });
}

export function trialSelected(feature: string) {
  _optionSelected(feature, 'Trial');
}

export function upgradeSelected(feature: string) {
  _optionSelected(feature, 'Purchase');
}

export function chatSelected(feature: string) {
  _optionSelected(feature, 'Intercom Chat');
}

export function demoSelected(feature: string) {
  _optionSelected(feature, 'Schedule Demo');
}

export function paywallShown(feature: string) {
  AnalyticsService.track('Purchase Paywall Shown', {
    Feature: feature,
    Source: 'Web'
  });
}
