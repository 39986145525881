import React from "react";
import styles from "./styles.module.scss";
import ModalLayout from "../ModalLayout/ModalLayout";
import NoResults from "../NoResults";
import { ApplyTemplateListProps } from "app/components/Button/ApplyTemplateDropdown/index.types";
import SearchBar from "app/components/inputs/SearchBar";
import { Dots } from "app/components/Loading";
import { cls } from "app/utils";

const ApplyTemplateListsModal = ({
  isOpen,
  onClose,
  hasNoResult,
  noResultImage,
  noResultTitle,
  noResultSubText,
  modalSubTitle,
  searchPlaceholder,
  onSearchChange,
  pending,
  children,
  cancelHandler,
  applyHandler,
}: ApplyTemplateListProps) => {
  return (
    <ModalLayout isOpen={isOpen} onClose={onClose}>
      <div className={styles.container}>
        {/* <div className={classNames(styles.overlay)} onClick={onClose} /> */}
        <div className={styles.headingWrapper}>
          <h1>Apply Template</h1>
          {modalSubTitle && <p>{modalSubTitle}</p>}
          <SearchBar
            placeholder={searchPlaceholder}
            onChange={onSearchChange}
            className={styles.searchInput}
          />
        </div>
        {pending && !hasNoResult ? (
          <div className={styles.space_list_container}>
            <Dots active={true} />
          </div>
        ) : hasNoResult ? (
          <NoResults
            image={noResultImage}
            text={noResultTitle}
            subtext={noResultSubText}
          />
        ) : (
          children
        )}
        <div className={styles.buttons}>
          <button
            className={cls("btn btn-outline-secondary", styles.button)}
            onClick={cancelHandler}
          >
            Cancel
          </button>
          <button
            disabled={hasNoResult}
            className={cls("btn btn-primary", styles.button, {
              ["disabled"]: hasNoResult,
            })}
            onClick={applyHandler}
          >
            Apply
          </button>
        </div>
      </div>
    </ModalLayout>
  );
};

export default ApplyTemplateListsModal;
