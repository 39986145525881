import React, { useCallback, useEffect, useState } from "react";
import styles from "./styles.module.scss";
import GettingStartedProgress from "app/components/GettingStarted/GettingStartedProgress";
import { cls } from "app/utils";
import { useActions, useSelector } from "app/utils/hooks";
import { fetchTags, deleteTag, clearTags } from "../../../actions/tag";
import { RootState } from "typedefs";
import { TagsProps } from "../Tags.types";

const DeleteTag = ({ changeToTagsUrl, tagId }: TagsProps) => {
  const tagAction = useActions({ fetchTags, deleteTag, clearTags });
  useEffect(() => {
    tagAction.fetchTags(tagId).catch((err) => {
      console.error(`Something went wrong: ${err}`);
    });
    return () => {
      tagAction.clearTags();
    };
  }, [tagId]);

  const tagState = useSelector((state: RootState) => state.tag);
  const [deleteallUsages, setDeleteallUsages] = useState(false);

  const submitHandler = useCallback(() => {
    tagAction
      .deleteTag(tagState.data, deleteallUsages)
      .then(() => {
        changeToTagsUrl();
      })
      .catch((err) => {
        alert(err ? err.errors[0] : "Something went wrong");
      });
  }, [tagState, deleteallUsages]);

  const checkboxHandler = () => {
    setDeleteallUsages((prev) => !prev);
  };

  return (
    <div className={styles.container}>
      <GettingStartedProgress />
      <div className={styles.add_tag_container}>
        <h1>
          Delete Tag &quot;{tagState.data ? tagState.data.name : "undefined"}
          &quot;
        </h1>

        <form className={styles.add_new_wrapper}>
          <div className={cls(styles.update_all_usages_wrapper)}>
            <div className={cls("checkbox")}>
              <input
                type="checkbox"
                name="usages"
                id="usages"
                defaultChecked={deleteallUsages}
                onChange={checkboxHandler}
              />
              <label
                htmlFor="usages"
                id="usages"
                className="tag_delete_all"
              ></label>
            </div>
            <label htmlFor="usages">
              delete all occurences of this tag in my posts
            </label>
          </div>
          <span className={styles.buttons_container}>
            <button
              className="btn btn-primary"
              onClick={submitHandler}
              disabled={tagState.pending}
              type="submit"
            >
              <span>{tagState.pending ? "Please Wait..." : "delete"}</span>
            </button>
            <button
              className={cls("btn btn-outline-secondary")}
              onClick={changeToTagsUrl}
              ng-disabled="processing"
            >
              Cancel
            </button>
          </span>
        </form>
      </div>
    </div>
  );
};

export default DeleteTag;
