import React, { useCallback, useEffect, useState } from "react";
import styles from "./styles.module.scss";
import PagePanel from "app/components/PagePanel";
import GettingStartedProgress from "app/components/GettingStarted/GettingStartedProgress";
import PostingForm from "app/components/PostingForm";
import { useSelector } from "react-redux";
import { fetchMediaById } from "app/actions/media-library-data";
import { RootState } from "typedefs";
import { useActions } from "app/utils/hooks";
import TagModel from "app/models/TagModel";
import { createPost } from "../../../actions/postingForm";
import { PostingModel } from "app/models/PostModel";
import { route } from "app/utils/router";

interface Props {
  resourceId: string;
}

const CreatePostWithMedia = ({ resourceId }: Props) => {
  const resourceActions = useActions({
    fetchMediaById,
    createPost,
  });
  const user = useSelector((state: RootState) => state.user.self);

  const attachment = useSelector(
    (state: RootState) => state.mediaLibraryData.attachment,
  );

  const mediaData = useSelector(
    (state: RootState) => state.mediaLibraryData.attachment,
  );

  const selectedLocations = useSelector(
    (state: RootState) => state.destinations.selectedDestinations,
  );

  const [isProcessed, setIsProcessed] = useState(
    (attachment && attachment.processed) || false,
  );

  useEffect(() => {
    if (resourceId) {
      resourceActions.fetchMediaById(resourceId);
    }
  }, [resourceId]);

  const [textArea, setTextArea] = useState<string | null>(null);
  const [tags, selectedTags] = useState<TagModel[]>([]);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [postLocations, setPostLocations] = useState(null);
  const [scheduledAt, setScheduledAt] = useState(null);

  const canUploadMedia = () => {
    if (!user) {
      return null;
    }
    return (
      user.planPermissions.includes("upload_to_library") &&
      user.planPermissions.includes("post_media")
    );
  };

  useEffect(() => {
    if (attachment) {
      setIsProcessed(attachment.processed);
    }
    const pollingTimer = setInterval(() => {
      if (attachment && attachment.processed) {
        clearInterval(pollingTimer);
        setIsProcessed(true);
      }
      if (attachment && attachment.type === "video" && !isProcessed) {
        resourceActions.fetchMediaById(attachment.id);
      }
    }, 3000);

    return () => {
      clearInterval(pollingTimer);
    };
  }, [attachment, isProcessed]);

  const onPostHandler = () => {
    if (!selectedLocations || selectedLocations.length < 1) {
      setErrorMessage("Please select location");
      return;
    }

    setErrorMessage(null);
    if (!errorMessage || canUploadMedia) {
      const postModel: PostingModel = {
        details: textArea,
        ...postLocations,
        tags,
      };

      if (mediaData) {
        postModel.resource_id = mediaData.id;
        postModel.resource_type = mediaData.type;
      }

      if (scheduledAt) {
        postModel.scheduled_at = scheduledAt;
      }

      resourceActions
        .createPost(postModel)
        // .then(() => (window.location.pathname = "/resources"));
        .finally(() => route("/resources"));
    }
  };

  useEffect(() => {
    if (errorMessage) {
      if (selectedLocations && selectedLocations.length > 0) {
        setErrorMessage(null);
      }
    }

    if (selectedLocations && selectedLocations.length > 0) {
      selectedLocations.forEach((obj: { id: string; object: string }) => {
        const idx =
          obj.object === "smart_list"
            ? "smart_list_ids"
            : obj.object === "athlete_space"
            ? "space_id"
            : obj.object === "team"
            ? "team_ids"
            : obj.object === "segment"
            ? "segment_ids"
            : "team_group_ids";

        setPostLocations((prevState: any) => ({
          ...prevState,
          [idx]: [...prevState[idx], obj.id],
        }));
      });
    }

    return () => {
      setPostLocations({
        smart_list_ids: [],
        space_id: [],
        team_ids: [],
        segment_ids: [],
        team_group_ids: [],
      });
    };
  }, [selectedLocations]);

  const textAreaHandler = useCallback(
    (e: string) => {
      setTextArea(e);
    },
    [textArea],
  );

  const onTagsChange = useCallback(
    (tags: any) => {
      const tagNames = tags.map((tag: TagModel) => tag.name);

      selectedTags(tagNames);
    },
    [tags],
  );

  const onScheduleChange = (data: number) => {
    if (data) {
      setScheduledAt(new Date(data).toISOString());
    }
  };

  return (
    <>
      <GettingStartedProgress />
      <div className={styles.container}>
        <PagePanel title="Create post with media" />
        <PostingForm
          canUploadMedia={canUploadMedia()}
          resourceId={resourceId}
          expanded
          attachment={attachment && attachment}
          onPost={onPostHandler}
          textAreaHandler={textAreaHandler}
          onTagsChange={onTagsChange}
          onScheduleChange={onScheduleChange}
          errorMessage={errorMessage}
        />
      </div>
    </>
  );
};

export default CreatePostWithMedia;
