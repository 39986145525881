import ModalLayout from "../Layouts/ModalLayout/ModalLayout";
import React from 'react';
import styles from './styles.module.scss';
import classNames from "app/utils/classNames";
import Button from "../Button/ButtonVariant";
import NoSubscriptionImg from '../../../public/images/no-subscription-image.png';
import CloseIcon from '../../../public/images/close-icon.svg';
import paywallModalPro from "app/utils/paywall";

interface PostNoSubscriptionModalProps {
  onClose: () => void;
  isOpen: boolean;
}

const PostNoSubscriptionModal = (props: PostNoSubscriptionModalProps) => {

  const {
    isOpen,
    onClose
  } = props;


  const onUpgrade = () => {
    onClose();
    paywallModalPro();
  };

  const onLearnMode = () => {
    window.location.replace('https://coachnow.io/pro');
  };


  return (
    <ModalLayout
      isOpen={isOpen}
      onClose={onClose}
    >
      <div className={classNames(styles.content_wrapper)}>
        <button className={classNames(styles.close_btn)} onClick={onClose}>
          <img src={CloseIcon} alt="icon" />
        </button>
        <div className={classNames(styles.info_block)}>
          <h2>Post Views - Available for CoachNow PRO</h2>
          {/* <p>While Smart Lists help you gain insight into activity within SPACES, Views Tracking gives you insight into the engagement within your POSTS.</p> */}
          <p>Views Tracking gives you insight into the engagement within your POSTS.</p>
          <p>
            Views Tracking will help you understand WHO has viewed your post and WHEN they viewed it</p>
          <div className={classNames(styles.info_block_actions)}>
            <Button
              title="Learn more"
              onClickFunc={onLearnMode}
            />
            <Button
              title="Upgrade Now"
              primary
              onClickFunc={onUpgrade}
            />
          </div>
        </div>
        <div className={classNames(styles.no_sub_image)}>
          <img src={NoSubscriptionImg} alt="img" />
        </div>
      </div>
    </ModalLayout>
  );
};

export default PostNoSubscriptionModal;
