/**
 * @module Utils
 *
 */
import { AnyObject } from 'typedefs';
import isEmpty from './isEmpty';
import { get } from './object';

export function isArray(value: any): boolean {
  if (value != null && typeof value === 'object' && Array.isArray(value)) {
    return true;
  }
  return false;
}

export function uniq<T>(list: T[], fn?: (item: T) => any) {
  const result: T[] = [];
  list.forEach(item => {
    if (fn != null) {
      if (result.find(test => (fn(test) === fn(item)) == null)) {
        result.push(item);
      }
    } else {
      if (result.indexOf(item) === -1) {
        result.push(item);
      }
    }
  });
  return result;
}

export function removeItem<T extends any>(list: T[], item: T) {
  const idx = list.indexOf(item);
  if (idx !== -1) {
    return [ ...list.slice(0, idx), ...list.slice(idx+1) ];
  }
  return list;
}

export function addItem<T extends any>(list: T[], item: T) {
  return [ ...list, item ];
}

export function clean<T>(list: T[]): T[] {
  return Array.prototype.filter.call(list, (value: T) => !isEmpty(value));
}

type BasicTypes = string | number;

type ComparatorReturn = 0 | 1 | -1;
type Comparator = (a: any, b: any) => ComparatorReturn;

const defaultComparator = (valueA: BasicTypes, valueB: BasicTypes) => {
  if (valueA < valueB) {
    return -1;
  } else if (valueA > valueB) {
    return 1;
  } else {
    return 0;
  }
};

export function sortObjectsFunc<T extends AnyObject>(itemA: T, itemB: T, key: keyof T, comparator?: Comparator) {
  if (typeof comparator !== 'function') { comparator = defaultComparator; }

  const valueA: any = get(itemA, (key as string));
  const valueB: any = get(itemB, (key as string));
  return comparator(valueA, valueB);
}

export function sortObjectsBy<T extends AnyObject>(list: T[], keys: Array<keyof T>, comparators?: Comparator[]) {
  return list.sort((a: T, b: T) => {
    const vals = keys.map((k: keyof T, idx: number) =>
      sortObjectsFunc(a, b, k, (comparators || [])[idx])
    );
    const result = vals.find((val: number) => val !== 0);
    return result != null ? result : 0;
  });
}

export function replace<T extends any>(list: T[], index: number, item: T) {
  return [].concat(list.slice(0, index), item, list.slice(index + 1));
}
