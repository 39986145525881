import { useEffect, useRef, useState } from "react";
import WaveSurfer from "../../../vendor/wavesurfer";
import { AudioPlayerProps } from "./index.types";
import moment from "moment";

const useAudioPlayerModel = ({ data }: AudioPlayerProps) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [remaining, setRemaining] = useState(data.duration);
  const wavesurferRef = useRef<WaveSurfer | null>(null);
  const audioDuration = moment.utc(data.duration).format("mm:ss");

  useEffect(() => {
    wavesurferRef.current = WaveSurfer.create({
      container: `#waveform-${data.id}`,
      waveColor: "#dddddd",
      progressColor: "#e54500",
      barWidth: 1.6,
      barGap: 3,
      loopSelection: false,
      barHeight: 4,
      cursorWidth: 0,
      height: 32,
      responsive: true,
    });
    wavesurferRef.current.load(data.url);

    return () => {
      wavesurferRef.current.destroy();
    };
  }, [data.url]);

  useEffect(() => {
    wavesurferRef.current.on("audioprocess", () => {
      if (wavesurferRef.current && isPlaying) {
        const currentTime = wavesurferRef.current.getCurrentTime();
        const duration = wavesurferRef.current.getDuration();
        setRemaining(duration - currentTime);
      }
    });

    wavesurferRef.current.on("ready", () => {
      const duration = wavesurferRef.current.getDuration();

      setRemaining(duration);
    });
  }, [isPlaying]);

  const handlePlayPause = () => {
    if (!wavesurferRef.current) {
      return;
    }
    setIsPlaying(!isPlaying);
    wavesurferRef.current.playPause();
  };

  const remainingDuration = moment
    .utc(Math.max(remaining, 0) * 1000)
    .format("mm:ss");

  useEffect(() => {
    if (remainingDuration === "00:00") {
      setIsPlaying(false);
    }
  }, [remainingDuration]);

  return { handlePlayPause, isPlaying, audioDuration, remainingDuration };
};

export default useAudioPlayerModel;
