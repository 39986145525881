/** @module components/modals */
import React from 'react';
import { defineMessages } from 'react-intl';
import { cls } from 'app/utils';
import { useAuth, useLoc, useCalendly } from 'app/utils/hooks';
import ModalWrapper from 'app/components/modals/ModalWrapper';
import { Loc } from 'app/components/helpers';
import config from 'config/environment';
import styles from './styles.module.scss';

type PropsDef = {
  isOpen: boolean;
  onClose: () => void;
};

/**
 * @class CalendlyModal
 *
 */
export default function CalendlyModal({ isOpen, onClose }: PropsDef) {
  const { formatMessage } = useLoc();
  const [ user ] = useAuth();
  const [ showDone, setShowDone ] = React.useState(false);
  const calendly = useCalendly();

  const closeModal = () => {
    if (onClose) {
      onClose();
    }
  };

  const onScheduled = () => {
    setShowDone(true);
  };

  React.useEffect(() => {
    if (calendly != null) {
      calendly.onEventScheduled(onScheduled);
      return () => calendly.clearEventScheduled(onScheduled);
    }
    return () => {};
  }, [ calendly ]);

  const setupCalendar = (parentElement: HTMLDivElement) => {
    if (calendly != null) {
      const prefill: { [key: string]: string } = {};
      if (user.data != null) {
        prefill.name = `${user.data.firstName} ${user.data.lastName}`;
        prefill.email = user.data.email;
      }

      if (parentElement != null) {
        calendly.initInlineWidget({
          url: `https://calendly.com/${config.CALENDLY_LINK}?background_color=transparent`,
          parentElement,
          prefill
        });
      }
    }
  };

  const setRef = (ref: HTMLDivElement) => {
    setupCalendar(ref);
  };

  return (
    <ModalWrapper
      className={styles.modal}
      containerClassName={styles.modalBody}
      headerClassName={styles.header}
      overlayClassName={styles.overlay}
      size="auto"
      title={formatMessage(locales.title)}
      isOpen={isOpen}
      onRequestClose={closeModal}
    >
      <div className={styles.calendlyModal}>
        <div className={styles.wrapper}>
          <div className={styles.container}>
            <div
              ref={setRef}
              className="calendly-inline-widget"
              // style={{ width: '100%'}} // , height: '100%' }}
              data-auto-load={false}
            ></div>
          </div>
        </div>

        <div className={cls(styles.buttons, showDone ? styles.done : styles.close)}>
          <button className={cls("btn btn-primary", styles.primary)} onClick={closeModal}>
            <Loc value={locales.done_button} />
          </button>

          <button className={cls("btn btn-outline-secondary", styles.secondary)} onClick={closeModal}>
            <Loc value={locales.close_button} />
          </button>
        </div>
      </div>
    </ModalWrapper>
  );
}


const locales = defineMessages({
  title: { id: 'calendly.modal.title', defaultMessage: 'Schedule A Demo' },
  done_button: { id: 'app.title.done', defaultMessage: 'Done' },
  close_button: { id: 'app.title.close', defaultMessage: 'Close' },
});
