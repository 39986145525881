/** @module components/paywalls */
import React, { useState } from 'react';
import { defineMessages } from 'react-intl';
import { cls } from 'app/utils';
import { openIntercom, closeIntercom } from 'app/utils/intercom';
import { chatSelected, demoSelected } from 'app/utils/analytics-helpers';
import CalendlyModal from 'app/components/modals/CalendlyModal';
import { Loc } from 'app/components/helpers';
import styles from './styles.module.scss';

type PropsDef = {
  className?: string;
  disabled?: boolean;
  feature?: string;
  onChat?: (isOpen: boolean) => void;
  onSchedule?: (isOpen: boolean) => void;
};

/**
 * @class Support
 *
 */
export default function Support(props: PropsDef) {
  const [ showCalendly, setShowCalendly ] = useState<boolean>(false);
  const [ intercomIsOpen, setIntercomState ] = useState<boolean>(false);

  const handleChat = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    if (intercomIsOpen) {
      closeIntercom();
    } else {
      openIntercom();
      chatSelected(props.feature || 'Unknown');
    }

    if (props.onChat) {
      props.onChat(!intercomIsOpen);
    }
    setIntercomState(!intercomIsOpen);
  };

  const handleSchedule = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setShowCalendly(true);
    demoSelected(props.feature || 'Unknown');
    if (props.onSchedule) {
      props.onSchedule(true);
    }
  };

  const closeCalendly = () => {
    setShowCalendly(false);
    if (props.onSchedule) {
      props.onSchedule(false);
    }
  };

  return (
    <div className={cls(styles.support, props.className)}>
      <strong className="text-secondary">
        <Loc value={locale.questions_title} />
      </strong>

      <div className="text-muted">
        <Loc value={locale.questions_body} />
      </div>

      <button className={cls(styles.button, "btn btn-outline-secondary")} onClick={handleChat} disabled={props.disabled}>
        <Loc value={locale.questions_button} />
      </button>

      <button className={cls(styles.button, "btn btn-outline-secondary")} onClick={handleSchedule} disabled={props.disabled}>
        <Loc value={locale.demo_button} />
      </button>

      <CalendlyModal isOpen={showCalendly} onClose={closeCalendly} />
    </div>
  );
}

const locale = defineMessages({
  questions_title: { id: 'spaces_limit.questions.title', defaultMessage: 'Have Questions?' },
  questions_body: { id: 'spaces_limit.questions.body', defaultMessage: 'Want to see how it works, extend your trial time, or have another question?' },
  questions_button: { id: 'spaces_limit.questions.button', defaultMessage: 'Start Chat' },
  demo_button: { id: 'spaces_limit.demo.button', defaultMessage: 'Schedule Call' },
});
