
import { defineMessages } from 'react-intl';

export default defineMessages({
  status: { id: 'app.title.status', defaultMessage: "Status" },
  status_plan: { id: 'app.title.plan', defaultMessage: "Plan" },
  status_plan_name: { id: 'app.subscription.plan-monthly', defaultMessage: "CoachNow+ Monthly" },
  status_active_message: { id: 'subscription.active-member-status', defaultMessage: "You're a {planName} Member" },

  cant_upgrade: { id: 'subscription.cant-upgrade', defaultMessage: "Please contact support to upgrade to CoachNow PRO" },
  contact_support: { id: 'subscription.contact-support', defaultMessage: "Contact Support" },

  upgrade_to_pro_title: {id: 'subscription.status.upgrade_to_pro_title', defaultMessage: 'Upgrade to PRO'},
  upgrade_to_pro_subtitle: {id: 'subscription.status.upgrade_to_pro_subtitle', defaultMessage: 'CoachNow Pro unlocks all of our powerful features. For more information on the PRO feature set, <a href="https://coachnow.io/pro">click here</a>.'},

  normal_price: { id: 'subscription.status.normal_price', defaultMessage: 'Normal price' },
  your_discount: { id: 'subscription.status.your_discount', defaultMessage: 'Your discount' },
  your_price: { id: 'subscription.status.your_price', defaultMessage: 'Your price' },
  coupon_not_allowed: { id: 'subscription.status.coupon_not_allowed', defaultMessage: 'Discount can not be applied to the selected plan.' },

  upgrade_button: { id: 'subscription.status.upgrade_button', defaultMessage: 'Upgrade Now' },
  back_button: { id: 'subscription.status.back_button', defaultMessage: 'Go to Dashboard' },
});
