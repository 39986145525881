
import { defineMessages } from 'react-intl';

export const PRODUCT_NAME = "CoachNow";

export default defineMessages({
  payment_type_title: { id: 'upgrade.price.payment_type.title', defaultMessage: 'Select a Payment Plan' },
  promocode_title: { id: 'upgrade.price.promocode.title', defaultMessage: 'Promo Code (Optional)' },

  /* upgrade form strings */
  formatted_title: { id: 'upgrade.product.formatted-title', defaultMessage: 'Choose Your Plan' },
  monthly_title: { id: 'app.title.monthly', defaultMessage: "Monthly" },
  yearly_save: { id: 'upgrade.price.title.yearly', defaultMessage: 'Yearly (Save 37%)' },
  upgrade_summary: {
    id: 'upgrade.product-summary',
    defaultMessage: "CoachNow+ to Uplevel Communication With Your Athletes. CoachNow PRO to Automate Your Business, Analyze Athlete Data, and Access Exclusive Online Courses."
  },
  payment_form_label: { id: 'upgrade.payment_form_label', defaultMessage: "Credit / Debit Card" },
  subtotal: { id: 'app.title.subtotal', defaultMessage: "Subtotal" },
  total_label: { id: 'app.title.total', defaultMessage: "Total Due Now" },
  coupon_code_placeholder: { id: 'app.title.promocode', defaultMessage: "Enter Code" },
  coupon_code_discount: { id: 'app.title.promocode_discount', defaultMessage: "Promo Code" },
  apply_button: { id: 'app.title.apply', defaultMessage: "Apply" },
  clear_button: { id: 'app.title.clear', defaultMessage: "Clear" },
  purchase_button: { id: 'app.title.purchase', defaultMessage: "Purchase" },
  cancel_button: { id: 'app.button.cancel', defaultMessage: 'Cancel' },

  alert_plan_change_yearly: { id: 'upgrade.alert.plan_change.yearly', defaultMessage: 'The promo code has changed the payment plan to monthly' },
  alert_plan_change_monthly: { id: 'upgrade.alert.plan_change.monthly', defaultMessage: 'The promo code has changed the payment plan to yearly' },
  alert_promo_code_removed: { id: 'upgrade.alert.promo_code.removed', defaultMessage: 'The promo code is not valid for the chosen plan' },

  total_enterprise_label: { id: 'upgrade.price.enterprise_total', defaultMessage: 'Are you a business, organization, or academy?' },
  contact_us: { id: 'upgrade.price.contact_us', defaultMessage: 'Contact Us' },

  new_card: { id: 'upgrade.payment-type.new-card', defaultMessage: 'Use a different card' },
  payment_expires: { id: 'upgrade.payment-type.expires', defaultMessage: 'Expires: {expires}' },


  /* success page strings */
  success_title: { id: 'upgrade-subscription-success.title', defaultMessage: 'Welcome to CoachNow+ {first_name}!' },
  success_title_pro: { id: 'upgrade-subscription-success.title-pro', defaultMessage: 'Welcome to CoachNow PRO {first_name}!' },
  success_button: { id: 'upgrade-subscription-success.button', defaultMessage: 'Get started!' },
  success_info_one: {
    id: 'upgrade-subscription-success.info-one',
    defaultMessage: 'Your card was successfully charged and we sent you a confirmation receipt email.'
  },
  success_info_two: {
    id: 'upgrade-subscription-success.info-two',
    defaultMessage: 'You now have access to unlimited Spaces and Teams, media posting and video/image analysis in Teams, 150GB of storage, Library, Lists and Templates Tools.'
  },
  success_info_two_pro: {
    id: 'upgrade-subscription-success.info-two-pro',
    defaultMessage: 'You now have access to all CoachNow PRO features, including Post Scheduling, Advanced Group Controls, and the ConnectedCoach Blueprint.'
  },
  success_info_three: {
    id: 'upgrade-subscription-success.info-three',
    defaultMessage: 'Transform your coaching and make the most of your time with athletes starting now!'
  },
  success_info_three_pro: {
    id: 'upgrade-subscription-success.info-three-pro',
    defaultMessage: 'More exciting updates are on the horizon, and you’ll be hearing from us as soon as new features are available. We are always here to answer any questions at <a mailto="support@coachnow.io">support@coachnow.io</a>.'
  },

  /* payment type strings */
  pro_month_title: { id: 'upgrade.price.title.pro_monthly', defaultMessage: "CoachNow PRO Monthly" },
  pro_month_subtitle: { id: 'upgrade.price.subtitle.pro_monthly', defaultMessage: "{cost} / month" },
  pro_year_title: { id: 'upgrade.price.title.pro_yearly', defaultMessage: 'CoachNow PRO Yearly' },
  pro_year_subtitle: { id: 'upgrade.price.subtitle.pro_yearly', defaultMessage: '{cost} / year' },
  month_title: { id: 'upgrade.price.title.monthly', defaultMessage: "CoachNow+ Monthly" },
  month_subtitle: { id: 'upgrade.price.subtitle.monthly', defaultMessage: "{cost} / month" },
  year_title: { id: 'upgrade.price.title.yearly', defaultMessage: 'CoachNow+ Yearly' },
  year_subtitle: { id: 'upgrade.price.subtitle.yearly', defaultMessage: '{cost} / year' },
  enterprise_title: { id: 'upgrade.price.title.enterprise', defaultMessage: 'CoachNow+ Business' },
  enterprise_subtitle: { id: 'upgrade.price.subtitle.enterprise', defaultMessage: 'Contact us for pricing' },
});
