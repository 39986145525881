/**
  @module services
*/
import { Service, createService } from 'app/utils/service';
import { fetchPut } from 'app/utils/request';
import { reject } from 'rsvp';

const ACCOUNT_FAILURE_REASONS = {
  unprocessable_entity: 'account_unprocessable_entity',
  unknown: 'account_unknown'
};

/**
 * @class AccountService
 *
 */
class AccountService extends Service {
  static _name = "AccountService";

  /**
   * Request a phone update using the verification token.
   *
   * @param {string} verification_token
   * @returns {Promise}
   */
  updatePhone(verificationToken) {
    return fetchPut('account/phone', { verificationToken }, { version: 1 })
      .catch(e => handleErrors(ACCOUNT_FAILURE_REASONS, e));
  }
}

const handleErrors = (messageTypes, e) => {
  if (e.errors && e.errors.length) {
    return reject(messageTypes[e.originalErrors.status] || messageTypes['unknown']);
  }
  return reject('unknown');
};

export default createService(AccountService);
