import CheckBox from "app/components/inputs/CheckBox";
import React from "react";
import styles from "../styles.module.scss";
import UserAvatar from "app/components/UserAvatar";
import { cls } from "app/utils";
import { Loading } from "app/components/Wrappers";
import SpaceModel from "app/models/SpaceModel";

interface Props {
  toggleListClick: (item: {}) => void;
  selectedLists: any;
  searchTerm?: string | null;
  spacesData?: SpaceModel[];
  pending?: boolean;
  loading?: boolean;
  containerRef?: any;
}

const AthleteSpaceLocation = ({
  toggleListClick,
  selectedLists,
  // searchTerm,
  pending,
  loading,
  spacesData,
  containerRef,
}: Props) => {
  const selectedSpaceArray = Array.from(selectedLists);

  const isSpaceSelected = (spaceId: string) => {
    return selectedSpaceArray.some((space: any) => space.id === spaceId);
  };

  if(spacesData && spacesData.length < 1 && (!pending || !loading)) {
    return null;
  }

  return (
    <div>
      <label className={styles.list_title}>Spaces</label>
      {spacesData &&
        spacesData.map((space: any) => (
          <div
            ref={containerRef}
            key={space.id}
            onClick={() =>
              space.planPermissions.includes("post") && toggleListClick(space)
            }
          >
            <div className={styles.dropdownItem}>
              <UserAvatar
                altName="space_icon"
                sizeWidth={37.5}
                sizeHeight={37.5}
                userName={space.ownerName}
                srcName={space.avatar}
                className="avatar"
              />
              <div
                className={cls(styles.name, {
                  [styles.disabled]: !space.planPermissions.includes("post"),
                })}
              >
                {space.athleteName}
                <div className={styles.sport}>{space.sport}</div>
              </div>
              <CheckBox
                className={styles.checkbox}
                name={space.athleteName}
                checked={isSpaceSelected(space.id)}
              />
            </div>
          </div>
        ))}
        {(pending || loading) &&  <Loading isLoading loadType="spinner" className={styles.pending} />}
    </div>
  );
};

export default AthleteSpaceLocation;
