/**
 * @module Components.StripeForm
 *
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  error_unknown: { id: "error.unknown", defaultMessage: "An unknown error occurred. Please try again." },
  error_expired_card: { id: "error.expired.card-expiry", defaultMessage: "The expiration date is in the past." },

  error_invalid_postal_code: { id: "error.invalid.postal-code", defaultMessage: "A postal code entered is invalid." },
  error_invalid_security_code: { id: "error.invalid.card-security-code", defaultMessage: "The number entered is not a valid security code." },
  error_invalid_card_number: { id: "error.invalid.card-number", defaultMessage: "The number entered is not a valid card number." },
  error_invalid_card_expiry: { id: "error.invalid.card-expiry", defaultMessage: "The expiration date entered is invalid." },
  error_invalid_card: { id: "error.invalid.card", defaultMessage: "The information provided cannot be verfified. Please check your information and try again." },

  error_required_postal_code: { id: "error.required.postal-code", defaultMessage: "A postal code is required." },
  error_required_security_code: { id: "error.required.card-security-code", defaultMessage: "Please enter the security code that appears on your card." },
  error_required_card_expiry: { id: "error.required.card-expiry", defaultMessage: "Please enter your cards expiration date." },
  error_required_card_name: { id: "error.required.card-name", defaultMessage: "Please enter the name that appears on your credit card." },
  error_required_card_number: { id: "error.required.card-number", defaultMessage: "Please enter your credit card number." },

  hint_security_code: {
    id: "hint.form.security-code",
    defaultMessage: "A CVC, or Card Verifcation Code, is a three or four-digit number printed on your card separate from the 16-digit card number."
  },

  security_code: { id: "placeholder.form.security-code", defaultMessage: "Security Code" },
  card_expiry: { id: "placeholder.form.card-expiry", defaultMessage: "Expiration Date (MO/YR)" },
  card_name: { id: "placeholder.form.card-name", defaultMessage: "Name on Card" },
  card_number: { id: "placeholder.form.card-number", defaultMessage: "Card Number" },
  postal_code: { id: "placeholder.form.postal-code", defaultMessage: "Postal Code" },

  cancel_button: { id: "app.button.cancel", defaultMessage: "Cancel" },
});

