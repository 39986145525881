/** @module utils/request */
export {
  fetchGet,
  fetchPost,
  fetchPut,
  fetchDelete,
  request,
} from './fetch';

export {
  serialize,
  serializeObject,
  serializeArray,
  deserialize,
  deserializeObject,
  deserializeArray,
  parseChanges,
} from './model';

export {
  build as buildUrlFromPath,
  template as templateUrl,
  buildUrl,
  buildQuery,
} from './url';

export { default as syncRequest } from './sync';

interface ResourceContext<T> {
  deserialize: (resp: { data: T }) => { data: T };
}

export function bindAngularResource<T>(data: T, context: ResourceContext<T>): T {
  // @ts-ignore
  if (data && data.data) {
    throw new Error("data.data found in bindAngularResource, data should just be a model object");
  }

  if (!(context && context.deserialize)) {
    return data;
  }

  const res = context.deserialize({ data });
  return res.data;
}
