/**
 * @module Routes.Reset
 *
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  title: { id: 'reset-password.title', defaultMessage: 'Reset Password' },
  new_pass_placeholder: { id: 'reset-password.new-pass-placeholder', defaultMessage: 'New Password' },
  confirm_placeholder: { id: 'reset-password.confirm-placeholder', defaultMessage: 'Confirm New Password' },
  reset_button: { id: 'reset-password.button', defaultMessage: 'Reset password' },
  back_link: { id: 'reset-password.back-link', defaultMessage: 'I know my password' },

  password_mismath: { id: 'reset-password.mismatch', defaultMessage: 'Passwords do not match' },
  password_failure: { id: 'reset-password.failure', defaultMessage: 'Password must between 6 and 128 characters long.' },
  unknown_error: { id: 'forgot-password.unknown-error', defaultMessage: 'An unknown error occurred. Please try again.' },

  reset_success_status: { id: 'reset-password.success.subtitle', defaultMessage: 'Your password has been reset!' },
  reset_success_info: { id: 'reset-password.success.info', defaultMessage: 'Redirecting to the Sign In page in <b>{time}</b> seconds' },
  reset_success_link: { id: 'reset-password.success.link', defaultMessage: 'Take me to Sign In now' }
});
