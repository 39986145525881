/**
 * @module Components.Routes.Signin
 *
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  main_title: { id: 'app.title.main', defaultMessage: 'Sign in' },
  signin: { id: 'app.button.signin', defaultMessage: 'Sign In' },
  signup: { id: 'app.button.signup', defaultMessage: 'Sign Up' },
  email: { id: 'app.form-label.email', defaultMessage: 'E-mail' },
  phone: { id: 'app.form-label.phone', defaultMessage: 'Phone' },
  code: { id: 'app.form-label.code', defaultMessage: 'Verification Code' },
  password: { id: 'app.form-label.password', defaultMessage: 'Password' },
  remember: { id: 'app.form-label.remember-me', defaultMessage: 'Remember Me' },
  forgot: { id: 'app.form-label.forgot-password', defaultMessage: 'Forgot password?' },
  or_opt: { id: 'signin.form-label.or-option', defaultMessage: 'or sign in with' },
  next: { id: 'app.button.next', defaultMessage: 'Next' },
  back: { id: 'app.button.back', defaultMessage: 'Back' },
  confirmation_sent: { id: 'app.confirmation-sent', defaultMessage: 'We sent confirmation code to<br/> <strong>{phoneNumber}</strong>' },

  bad_login: { id: 'error.signin.bad_login', defaultMessage: 'The email address or password you entered is incorrect.' },
  standard_invalid_grant: { id: 'error.signin.invalid_grant', defaultMessage: "The email address or password you entered is incorrect." },

  facebook_grant_rejected: {
    id: 'error.signin.facebook_grant_rejected',
    defaultMessage: "No existing CoachNow account found. Please Sign Up with Facebook to get started."
  },

  unknown: { id: 'error.signin.unknown', defaultMessage: "An unknown error occurred. Please try again." },
  standard_unknown: { id: 'error.signin.unknown', defaultMessage: "An unknown error occurred. Please try again." },
  facebook_unknown: { id: 'error.signin.unknown', defaultMessage: "An unknown error occurred. Please try again." },

  code_unknown: { id: 'error.signin-code.unknown', defaultMessage: "Signin as failed. Error Unknown." },
  code_expired: { id: 'error.signin-code.expired', defaultMessage: "Signin as failed. Code Expired." },
  code_invalid_grant: { id: 'error.signin-code.invalid_grant', defaultMessage: "Signin as failed. The code is invalid." },

  phone_verification_unprocessable_entity: { id: 'error.signin-phone-verification.unprocessable_entity', defaultMessage: "Signin failed. Please provide a valid phone number"},
  phone_verification_invalid_code: { id: 'error.signin-verification-token.invalid_verification', defaultMessage: "Signin failed. Verification code is invalid." },
  phone_verification_unknown: { id: 'error.signin-phone-verification.unknown', defaultMessage: "Signin failed. Error unknown"},

  verification_token_unknown: { id: 'error.signin-verification-token.unknown', defaultMessage: "Signin failed. Error unknown." },
  verification_token_invalid_grant: { id: 'error.signin-verification-token.invalid_grant', defaultMessage: "Signin failed. User not found." },
  verification_token_invalid_verification: { id: 'error.signin-verification-token.invalid_verification', defaultMessage: "Signin failed. Verification code is invalid." },
});
