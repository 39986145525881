import config from "config/environment";
import { action, ActionType } from "typesafe-actions";
import { request } from "app/utils/request";
import { Dispatch } from "react";

const pendingAddToRecruitPost = () =>
  action("@addToRecruitPost.create.pending");
const errorAddToRecruitPost = (err: string) =>
  action("@addToRecruitPost.create.error", err);
const successAddToRecruitPost = (value: boolean) =>
  action("@addToRecruitPost.create.success", value);

export const location = (postId: string) =>
  action("@addToRecruitPost.fetch.locations", postId);

export const clearAddToRecruitPost = () => action("@addToRecruitPost.clear");

export function addToRecruitPost(postId: string) {
  return (dispatch: Dispatch<AddToRecruitPostAction>) => {
    dispatch(pendingAddToRecruitPost());

    const url = config.API_SERVER + `/api/v1/recruit/milestones`;
    const promise = request(url, "POST", { postId }, { version: 1 });
    promise.then(() => dispatch(successAddToRecruitPost(true)));
    promise.catch((err) => dispatch(errorAddToRecruitPost(err)));

    return promise;
  };
}

const thisActions = {
  pendingAddToRecruitPost,
  errorAddToRecruitPost,
  successAddToRecruitPost,
  clearAddToRecruitPost,
};

export type AddToRecruitPostAction = ActionType<typeof thisActions>;
