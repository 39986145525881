import { useSelector } from "app/utils/hooks";
import { RootState } from "typedefs";

const usePostingForm = () => {
  const mediaData = useSelector(
    (state: RootState) => state.mediaLibraryData.attachment,
  );

  const pending = useSelector(
    (state: RootState) => state.mediaLibraryData.pending,
  );

  return {
    mediaData,
    pending,
  };
};

export default usePostingForm;
