/**
 * @module Components.Links
 *
 */
import React from 'react';
import { FormattedMessage } from 'react-intl';

/**
 * @class Cookies
 *
 */
export default function Cookies() {
  return (
    <a href="https://coachnow.io/cookies">
      <FormattedMessage
        id="app.links.cookies"
        defaultMessage="Cookie Policy"
        description="Cookie policy link text"
      />
    </a>
  );
}

