/**
 * @module Utils.Request
 *
 */
import { AnyObject } from 'typedefs';
import { serialize } from './model';

export default function successHandler(data: AnyObject | AnyObject[] | null): { data?: AnyObject } {
  if (data == null) {
    return {};
  }
  return { data: serialize(data) };
}
