/** @module components/subscription/upgrade */
import { RequestError } from 'typedefs';
import React, { useRef, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { FormattedNumber, MessageDescriptor } from 'react-intl';
import { useLoc, useActions } from 'app/utils/hooks';
import { RootState } from 'app/reducers';
import { fetchPaymentInfo } from 'app/actions/payment-info';
import { applyCoupon, clearCoupon } from 'app/actions/coupon';
import { purchaseSubscription } from 'app/actions/stripe';
import { UserState } from 'app/reducers/user';
import { SubscriptionPlanModel } from 'app/models';
import { cls } from 'app/utils';
import { openIntercom } from 'app/utils/intercom';
import { decodeQuery } from 'app/utils/object';
import PlanTypes from './PlanTypes';
import PaymentForm from './PaymentForm';
import { Loc, Toast } from 'app/components/helpers';
import styles from './styles.module.scss';
import LOCALES, { PRODUCT_NAME } from './locale';

interface PropsDef {
  onSuccess: (activePlanId: string) => void;
  onCancel: () => void;
  user: UserState;
}

const COACH_MONTHLY = 'coach+monthly';
const COACH_YEARLY = 'coach+yearly';
export const COACH_PRO_MONTHLY = 'coachpro+monthly';
export const COACH_PRO_YEARLY = 'coachpro+yearly';
const COACH_BUSINESS = 'coach+enterprise';

const INIT_PLAN = COACH_YEARLY;

type PlanNameTypes = typeof COACH_PRO_YEARLY | typeof COACH_PRO_MONTHLY | typeof COACH_YEARLY | typeof COACH_MONTHLY | typeof COACH_BUSINESS;

/**
 * @class PlanForm
 *
 */
export default function PlanForm(props: PropsDef) {
  const search = decodeQuery(window.location.search);
  const promocodeRef = useRef<HTMLInputElement>(null);
  const [ activePlanName, setActivePlanName ] = useState<string>(INIT_PLAN);
  const [ activePlan, setActivePlan ] = useState<SubscriptionPlanModel>(null);
  const [ toastAlert, setToastAlert ] = useState<string>(null);
  const { formatMessage, formatNumber } = useLoc();

  const actions = useActions({ fetchPaymentInfo, applyCoupon, clearCoupon, purchaseSubscription });
  const actionState = useSelector((state: RootState) => ({
    coupon: state.coupon,
    paymentInfo: state.paymentInfo,
    stripe: state.stripe,
  }));

  useEffect(() => (actions.fetchPaymentInfo(), undefined), []);

  const normalizeMessage = (value: RequestError | null) => {
    if (value == null) {
      return null;
    } else if (typeof value === 'string') {
      return value;
    } else {
      return formatMessage(value as MessageDescriptor);
    }
  };

  const checkedMonthly = (couponCode: string) => {
    return actions.applyCoupon({ couponCode, productKind: 'CoachPlus', planCode: COACH_MONTHLY });
  };

  const checkedYearly = (couponCode: string) => {
    return actions.applyCoupon({ couponCode, productKind: 'CoachPlus', planCode: COACH_YEARLY });
  };

  const checkedProMonthly = (couponCode: string) => {
    return actions.applyCoupon({ couponCode, productKind: 'CoachPlus', planCode: COACH_PRO_MONTHLY });
  };

  const checkedProYearly = (couponCode: string) => {
    return actions.applyCoupon({ couponCode, productKind: 'CoachPlus', planCode: COACH_PRO_YEARLY });
  };

  const setPlanByCoupon = (couponCode: string, notifySwitch?: boolean) => {
    const _setPlan = (type: typeof COACH_PRO_MONTHLY | typeof COACH_PRO_YEARLY | typeof COACH_MONTHLY | typeof COACH_YEARLY) => {
      setActivePlanName(type);
      if (notifySwitch) {
        setToastAlert(formatMessage(LOCALES.alert_plan_change_yearly));
      }
    };

    if (activePlanName === COACH_YEARLY) {
      // yearly is already set so do nothing if yearly is successful
      // check yearly and if not successful then check monthly
      checkedYearly(couponCode);
    } else if (activePlanName === COACH_MONTHLY) {
      // monthly is already set so do nothing if successful
      // check monthly and if not successful then check yearly
      checkedMonthly(couponCode);
    } else if (activePlanName === COACH_PRO_YEARLY) {
      // monthly is already set so do nothing if successful
      // check monthly and if not successful then check yearly
      checkedProYearly(couponCode);
    } else if (activePlanName === COACH_PRO_MONTHLY) {
      // monthly is already set so do nothing if successful
      // check monthly and if not successful then check yearly
      checkedProMonthly(couponCode);
    } else {
      // enterprise is set so set monthly or yearly if either is successful
      // check monthly and if not successful then check yearly
      checkedMonthly(couponCode);
    }
  };

  const removeCouponIfInvalid = (planType: PlanNameTypes) => {
    if (actionState.coupon.data != null) {
      const removeFunc = () => {
        setToastAlert(formatMessage(LOCALES.alert_promo_code_removed));
        actions.clearCoupon();
      };

      if (planType === COACH_YEARLY) {
        checkedYearly(actionState.coupon.data.couponCode).catch(removeFunc);
      } else if (planType === COACH_MONTHLY) {
        checkedMonthly(actionState.coupon.data.couponCode).catch(removeFunc);
      } else if (planType === COACH_PRO_YEARLY) {
        checkedProYearly(actionState.coupon.data.couponCode).catch(removeFunc);
      } else if (planType === COACH_PRO_MONTHLY) {
        checkedProMonthly(actionState.coupon.data.couponCode).catch(removeFunc);
      } else {
        removeFunc();
      }
    }
  };

  useEffect(() => {
    if (search.coupon) {
      setPlanByCoupon(search.coupon);
    }

    if (activePlan) {
      const affilateCoupon = activePlan.coupon;

      if (affilateCoupon) {
        setPlanByCoupon(affilateCoupon);
      }
    }
  }, [ search.coupon, activePlan ]);

  useEffect(() => {
    if (search.plan === COACH_PRO_YEARLY || search.plan === COACH_PRO_MONTHLY || search.plan === COACH_YEARLY || search.plan === COACH_MONTHLY || search.plan === COACH_BUSINESS) {
      setActivePlanName(search.plan);
    }
  }, [ search.plan ]);

  let total = '0';
  let totalPrice = '0';

  if (activePlan != null) {
    let amt = activePlan.amount/100;
    total = formatNumber(amt, { style: 'currency', currency: activePlan.currency.toUpperCase() });

    if (actionState.coupon.data != null && actionState.coupon.error == null) {
      amt = (activePlan.amount - actionState.coupon.data.amountOff)/100;
    }
    totalPrice = formatNumber(amt, { style: 'currency', currency: activePlan.currency.toUpperCase() });
  }

  let priceSection = (
    <React.Fragment>
      <h6><Loc value={LOCALES.total_label} /></h6>
      <h1>{totalPrice}</h1>
    </React.Fragment>
  );

  if (activePlanName === COACH_BUSINESS) {
    const handleEnterprise = () => {
      // open intercom
      openIntercom();
    };

    priceSection = (
      <React.Fragment>
        <h6><Loc value={LOCALES.total_enterprise_label} /></h6>
        <button className="btn btn-primary" onClick={handleEnterprise}>
          <Loc value={LOCALES.contact_us} />
          </button>
      </React.Fragment>
    );
  } else if (actionState.coupon.data != null && actionState.coupon.error === null) {
    priceSection = (
      <React.Fragment>
        <h5>
          <span><Loc value={LOCALES.subtotal} /></span>
          <span>{total}</span>
        </h5>
        <h5 className={styles.red}>
          <span><Loc value={LOCALES.coupon_code_discount} /></span>
          <span>(<FormattedNumber value={(actionState.coupon.data.amountOff/100)} style="currency" currency="USD" />)</span>
        </h5>
        <h6><Loc value={LOCALES.total_label} /></h6>
        <h1>{totalPrice}</h1>
      </React.Fragment>
    );
  }

  const clearPromo = () => {
    promocodeRef.current.value = '';
    actions.clearCoupon();
  };

  const applyPromo = () => {
    const promocode = promocodeRef.current.value.trim();
    setPlanByCoupon(promocode, true);
  };

  const submitPromo = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.which === 13) { applyPromo(); }
  };

  const purchase = (token: string) => {
    // get coupon code if existst
    const coupon = actionState.coupon.data;
    const couponCode = coupon && coupon.couponCode ? coupon.couponCode : undefined;

    // send purchase to api
    actions.purchaseSubscription({
      token, couponCode,
      productKind: activePlan.productKind,
      planCode: activePlan.id
    }).then((result: any) => {
      if (result.type === '@stripe.post.success') {
        props.onSuccess(activePlan.id);
      }
    });
  };

  const completePurchase = (cardId: string) => purchase(cardId);
  const cancelUpgrade = () => props.onCancel();

  const CouponButton = () => {
    if (actionState.coupon.data != null) {
      return (
        <button
          className="btn btn-outline-secondary"
          onClick={clearPromo}
        >
          <Loc value={LOCALES.clear_button} />
        </button>
      );
    } else {
      return (
        <button
          className="btn btn-outline-secondary"
          onClick={applyPromo}
          disabled={actionState.coupon.pending}
        >
          <Loc value={LOCALES.apply_button} />
        </button>
      );
    }
  };

  const CouponError = () => {
    if (actionState.coupon.error != null) {
      return (<Loc value={(actionState.coupon.error as MessageDescriptor)} />);
    } else {
      return (<div />);
    }
  };

  const changePlanType = (v: SubscriptionPlanModel) => {
    setActivePlan(v);

    if (v != null) {
      if (activePlanName !== v.id) {
        setActivePlanName(v.id);

        if (!search.coupon) {
          removeCouponIfInvalid(v.id);
        }
      }
    } else {
      if (activePlanName !== INIT_PLAN) {
        setActivePlanName(INIT_PLAN);
      }
    }
  };

  return (
    <div className={styles.upgrade}>
      <div className={styles.container}>

        <div className={styles.infoBox}>
          <div className={styles.title}>
            <Loc value={{ ...LOCALES.formatted_title, values: { product: PRODUCT_NAME } }} />
          </div>

          <div className={styles.desc}>
            <Loc value={LOCALES.upgrade_summary} />
          </div>

          <div className={styles.pricing}>
            <h5 className={styles.sectionLabel}><Loc value={LOCALES.payment_type_title} /></h5>
            <PlanTypes selectPlan={activePlanName} onChange={changePlanType} />
          </div>

          <div className={cls(styles.promocode, actionState.coupon.error != null ? styles.invalid : '')}>
            <h5 className={styles.sectionLabel}><Loc value={LOCALES.promocode_title} /></h5>

            <input
              ref={promocodeRef}
              type="text"
              placeholder={formatMessage(LOCALES.coupon_code_placeholder)}
              onKeyUp={submitPromo}
              defaultValue={search.coupon || (activePlan ? activePlan.coupon : null) || ''}
              disabled={!!(actionState.coupon.pending || actionState.coupon.data)}
            />

            <CouponButton />

            <div className={styles.clearFloat}></div>

            <div className={styles.error}>
              <CouponError />
            </div>
          </div>

          <div className={styles.totalPrice}>{priceSection}</div>
        </div>

        <div className={cls(styles.paymentBox, activePlanName === COACH_BUSINESS ? styles.hide : '')}>
          <h5 className={styles.sectionLabel}><Loc value={LOCALES.payment_form_label} /></h5>

          <PaymentForm
            paymentInfo={actionState.paymentInfo.data}
            pending={actionState.stripe.pending}
            error={normalizeMessage(actionState.stripe.error)}
            onSubmit={completePurchase}
            onCancel={cancelUpgrade}
          />
        </div>
      </div>

      <Toast info={toastAlert} onDone={() => setToastAlert(null)} />
    </div>
  );
}
