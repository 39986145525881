/**
 * @module Utils.File
 *
 */
import { Promise, reject } from 'rsvp';

/**
 * Reads a file as ArrayBuffer.
 *
 * @param {File} file
 * @returns {Promise<Buffer>}
 */
export default function readAsArrayBuffer(file: File | Blob): Promise<string> {
  if ('FileReader' in window) {
    const reader = new FileReader();

    return new Promise((resolve) => {
      reader.addEventListener('load', () => {
        resolve((reader.result as string));
      });
      reader.readAsArrayBuffer(file);
    });
  }

  return reject(new Error('FileReader is not supported.'));
}
