/* eslint-disable */
import { request } from "app/utils/request";
import { Dispatch } from "react";
import { action, ActionType } from "typesafe-actions";
import config from "config/environment";
import { MediaTypes } from "app/routes/Resources/ResourceCard/index.types";

const pending = () => action("@media.fetch.pending");

const error = (err: string) => action("@media.fetch.error", err);

const success = (success: any) => action("@media.fetch.success", success);

const data = (data: []) => action("@media.fetch.data", data);

export const attachedData = (data: any) =>
  action("@media.fetch.attachment", data);

export const mediaLibraryData = (data: any) =>
  action("@media.fetch.mediaLibraryData", data);

const hasMore = (hasMore: boolean) => action("@media.fetch.hasMore", hasMore);

export const page = (page: number) => action("@media.fetch.currentPage", page);

export const selectedMedia = (selectedMedia: MediaTypes) =>
  action("@media.fetch.selectedMedia", selectedMedia);

export const clearMediaState = () => action("@media.clear");

const thisActions = {
  pending,
  error,
  success,
  data,
  attachedData,
  page,
  mediaLibraryData,
  hasMore,
  selectedMedia,
  clearMediaState,
};

export type mediaAction = ActionType<typeof thisActions>;

export function fetchMedia(
  currentPage?: number,
  perPage?: number,
  filters?: {
    orderby?: string;
    to?: string;
    from?: string;
    tags?: string[];
    types?: {
      image?: boolean;
      video?: boolean;
      audio?: boolean;
      datafile?: boolean;
    };
    userIds?: string[];
  },
) {
  return (dispatch: Dispatch<mediaAction>) => {
    dispatch(pending());

    const baseUrl = config.API_SERVER + "/api/v2/resources";
    const queryParams = [];

    queryParams.push("order=desc");

    if (filters) {
      if (filters.orderby) {
        queryParams.push(`order_by=${encodeURIComponent(filters.orderby)}`);
      }

      if (filters.to) {
        queryParams.push(`to=${encodeURIComponent(filters.to)}`);
      }

      if (filters.from) {
        queryParams.push(`from=${encodeURIComponent(filters.from)}`);
      }

      if (filters.tags && filters.tags.length > 0) {
        filters.tags.forEach((tag) => {
          queryParams.push(`tags[]=${encodeURIComponent(tag)}`);
        });
      }

      if (filters.types) {
        if (filters.types.image) {
          queryParams.push("type[]=image");
        }
        if (filters.types.video) {
          queryParams.push("type[]=video");
        }
        if (filters.types.audio) {
          queryParams.push("type[]=audio");
        }
        if (filters.types.datafile) {
          queryParams.push("type[]=datafile");
        }
      }
    }

    if (currentPage) {
      queryParams.push(`page=${currentPage}`);
    }

    if (perPage) {
      queryParams.push(`per_page=${perPage}`);
    }

    const url = `${baseUrl}?${queryParams.join("&")}`;

    const promise = request(url, "GET", {}, { version: 1 });

    promise
      .then((mediaLibraryData) => {
        dispatch(success(mediaLibraryData));

        if (mediaLibraryData.length < 20) {
          dispatch(hasMore(false));
        }
      })
      .catch((err) => dispatch(error(err)));

    return promise;
  };
}

export const fetchMediaById = (id: string) => {
  return (dispatch: Dispatch<mediaAction>) => {
    dispatch(pending());

    const url = config.API_SERVER + `/api/v1/resources/${id}`;
    const promise = request(url, "GET", {}, { version: 1 });
    promise.then((res) => {
      dispatch(attachedData(res));
    });
    promise.catch((err) => dispatch(error(err)));

    return promise;
  };
};

export const deleteMedia = (id: string) => {
  return (dispatch: Dispatch<mediaAction>) => {
    dispatch(pending());

    const url = config.API_SERVER + `/api/v1/resources/${id}`;
    const promise = request(url, "DELETE", {}, { version: 1 });
    promise.then((mediaLibraryData) => {
      dispatch(success(mediaLibraryData));
    });
    promise.catch((err) => dispatch(error(err)));

    return promise;
  };
};

export const createMedia = (resource: { id: string; filename: string }) => {
  return (dispatch: Dispatch<mediaAction>) => {
    dispatch(pending());
    const url = config.API_SERVER + `/api/v2/resources`;
    const promise = request(url, "POST", resource, { version: 1 });
    promise.then((res: MediaTypes) => {
      let parsedRes: MediaTypes;
      try {
        parsedRes = typeof res === "string" ? JSON.parse(res) : res;
        dispatch(attachedData(parsedRes));
      } catch (error) {
        console.error("Error parsing JSON:", error);
        dispatch(error(error));
        return;
      }
    });
    promise.catch((err) => {
      console.error("API Error:", err);
      dispatch(error(err));
    });
    promise.catch((err) => dispatch(error(err)));

    return promise;
  };
};

export const editMedia = (id: string, resource: any) => {
  return (dispatch: Dispatch<mediaAction>) => {
    dispatch(pending());

    const url = config.API_SERVER + `/api/v1/resources/${id}`;
    const promise = request(url, "PUT", resource, { version: 1 });
    promise.then((data) => dispatch(success(data)));
    promise.catch((err) => dispatch(error(err)));

    return promise;
  };
};
