/**
 * @module Routes
 *
 */
import React from 'react';
import { NavLink } from 'react-router-dom';
import { defineMessages } from 'react-intl';
import { cls } from 'app/utils';
import { Loc } from 'app/components/helpers';
import upgrade_trial_jpg from 'app/images/upgrade-trial.jpg';
import Styles from './styles.module.scss';

/**
 * @class StartTrial
 *
 */
export default function StartTrial() {
  return (
    <div className={Styles.successMain}>
      <div className={cls(Styles.panel, Styles.panelOne)}>
        <div className={Styles.successImage} style={{ backgroundImage: `url(${upgrade_trial_jpg})` }} />
      </div>

      <div className={cls(Styles.panel, Styles.panelTwo)}>
        <div className={Styles.panelContainer}>
          <div className={Styles.panelHeader}>
            <Loc value={locales.trial_began} />
          </div>

          <div className={Styles.panelBody}>
            <p><Loc value={locales.information} /></p>
            <p><Loc value={locales.summary} /></p>
            <div className="clear-float"></div>
          </div>

          <div className={Styles.panelFooter}>
            <NavLink className={cls('btn', 'btn-primary', 'btn-success', Styles.doneButton)} to="/">
              <Loc value={locales.button} />
            </NavLink>
          </div>

          <div className="clear-float"></div>
        </div>
      </div>
    </div>
  );
}

const locales = defineMessages({
  trial_began: { id: 'trial.start-trial.began', defaultMessage: 'Your 7-day Trial Has Begun!' },
  information: {
    id: 'trial.start-trial.information',
    defaultMessage: 'You now have access to unlimited Spaces and Teams, media posting and video/image analysis in Teams, 150GB of storage, Library, Segements, and Templates Tools.'
  },
  summary: {
    id: 'trial.start-trial.summary',
    defaultMessage: 'Transform your coaching and make the most of your time with athletes starting now!'
  },
  button: {
    id: 'trial.start-trial.button',
    defaultMessage: 'Get started',
  }
});
