/**
 * @module Utils.Hooks
 *
 */
import { GenericObject } from 'typedefs';
import { useReducer } from 'react';
import mergeTypes from 'app/utils/mergeTypes';


function addErrorsAction<T = string>(errorState: T) {
  return { type: 'ADD_ERRORS', errors: errorState };
}

function clearErrorsAction<T = string>(emptyState: T) {
  return { type: 'CLEAR_ERRORS', errors: emptyState };
}

type FormErrorAction = ReturnType<typeof addErrorsAction> | ReturnType<typeof clearErrorsAction>;

/**
 *
 * @public
 * @method errorReducer
 */
function errorReducer<T = string>(errors: T, action: FormErrorAction) {
  switch (action.type) {
    case 'ADD_ERRORS':
      return mergeTypes(errors, action.errors);
    case 'CLEAR_ERRORS':
      return action.errors;

    default:
      return errors;
  }
}

/**
 * Error object state handler
 *
 * @method useError
 * @return {[ ErrorState, addErrors(), clearErorrs() ]}
 */
export default function useError<T = GenericObject>(initErrors: T): [ T, (v: T) => void, () => void ] {
  const [ errors, dispatch ] = useReducer(errorReducer, initErrors);

  function add(value: T) {
    dispatch(addErrorsAction<T>(value));
  }

  function clear() {
    dispatch(clearErrorsAction<T>(initErrors));
  }

  return [ errors, add, clear ];
}
