/**
 * @module Routes
 *
 */
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useActions } from 'app/utils/hooks';
import { RootState } from 'app/reducers';
import { fetchSelf } from 'app/actions/user';
import { startTrial } from 'app/actions/subscription';
import { UpgradePlus } from 'app/components/Wrappers';
import StartTrial from './StartTrial';
import ExpiredTrial from './ExpiredTrial';
import InTrial from './InTrial';

/**
 * @class Trial
 *
 */
export default function Trial() {
  const actions = useActions({ fetchSelf, startTrial });
  const { user, subscription } = useSelector((state: RootState) => ({ user: state.user, subscription: state.subscription }));
  useEffect(() => (actions.fetchSelf(), undefined), [actions]);

  let showing = (<div />);
  if (subscription.data != null) {
    showing = (<StartTrial />);
  } else if (user.self && user.self.hasTrialSubscription) {
    showing = (<InTrial daysLeft={(user.self.plan && user.self.plan.trialDaysLeft) || 0} />);
  } else if (user.self && user.self.hasExpiredTrialSubscription) {
    showing = (<ExpiredTrial />);
  } else if (user.self && !subscription.pending) {
    if (!subscription.error && !subscription.data && subscription.trialStarted === false) {
      actions.startTrial();
    }
  }

  return (
    <UpgradePlus loading={user.pending || subscription.pending}>{showing}</UpgradePlus>
  );
}
