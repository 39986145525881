import {
  ColumnBox,
  GridItem,
  NoFilteredResults,
  NoResults,
} from "app/components/Layouts";
import React from "react";
import styles from "./styles.module.scss";
import { SpaceModel } from "app/models";
import UserModel from "app/models/UserModel";
import { cls } from "app/utils";
import no_space_png from "app/images/null-training-space.png";
import { Loading } from "app/components/Wrappers";
import { useLoc } from "app/utils/hooks";
import ContentCard from "app/components/ContentCard";
import { defineMessages } from "react-intl";
import useSpacesListModel from "./indexModel";
import null_list from "../../../../public/images/null-lists.png";

export interface SpacesListProps {
  user?: UserModel;
  pending?: boolean;
  searchTerm?: string | null;
}

const SpacesList = ({ user, searchTerm }: SpacesListProps) => {
  const {
    spacesData,
    spaceClicked,
    pending,
    loading,
    containerRef,
    selectedSmartList,
  } = useSpacesListModel({ searchTerm });
  const { formatMessage } = useLoc();

  const renderSpaceList = () => {
    if (spacesData && spacesData.length > 0) {
      return (
        <>
          <ColumnBox columns={2}>
            {spacesData.map((item: SpaceModel) => (
              <GridItem
                containerRef={containerRef}
                key={item.id}
                className={styles.grid}
                onClick={() =>
                  spaceClicked(item.timelineUrl ? item.timelineUrl : item.id)
                }
              >
                <ContentCard
                  img={item.athleteAvatar}
                  title={item.athleteName}
                  subtitle={item.sport}
                />
              </GridItem>
            ))}
          </ColumnBox>
          {(pending || loading) && (
            <Loading isLoading loadType="spinner" className={styles.loading} />
          )}
        </>
      );
    }

    if ((pending || loading) && (!spacesData || spacesData && spacesData.length < 1)) {
      return <Loading isLoading loadType="spinner" />;
    }

    if (
      searchTerm.length >= 3 &&
      !loading &&
      !pending &&
      spacesData &&
      !spacesData.length
    ) {
      return <NoFilteredResults missingTerm={searchTerm} />;
    }

    if (
      searchTerm.length === 0 &&
      !pending &&
      !loading &&
      spacesData &&
      !spacesData.length
    ) {
      if (selectedSmartList.object === "segment") {
        return (
          <NoResults
            image={null_list}
            text={formatMessage(locales.no_results_list_text)}
            subtext={formatMessage(locales.no_results_list_subtext)}
            className={styles.noResults}
          />
        );
      } else {
        return (
          <NoResults
            image={no_space_png}
            text={formatMessage(locales.no_results_text)}
            subtext={formatMessage(locales.no_results_subtext)}
            className={styles.noResults}
          />
        );
      }
    }

    return null;
  };

  return (
    <>
      <div
        className={cls(styles.spaceList, {
          [styles.needs_only_onboarding]:
            user &&
            user.needsOnboarding &&
            selectedSmartList.object !== "smart_list",
          [styles.selected_smart_list]:
            selectedSmartList.object === "smart_list" &&
            user &&
            !user.needsOnboarding,
          [styles.selected_smart_list_and_onboarding]:
            selectedSmartList.object === "smart_list" &&
            user &&
            user.needsOnboarding,
        })}
      >
        {renderSpaceList()}
        <div ref={containerRef} />
      </div>
    </>
  );
};

export default SpacesList;

const locales = defineMessages({
  no_results_text: {
    id: "spaces.no_results.text",
    defaultMessage: "Welcome to Spaces",
  },
  no_results_subtext: {
    id: "spaces.no_results.subtext",
    defaultMessage: "Work one-on-one with a coach or player.",
  },
  no_results_list_text: {
    id: "spaces.no_results.text",
    defaultMessage: "No Spaces in this list yet",
  },
  no_results_list_subtext: {
    id: "spaces.no_results.subtext",
    defaultMessage: "All spaces that you create in this list will appear here.",
  },
  no_results_button: {
    id: "spaces.no_results.button",
    defaultMessage: "+ Add Space",
  },
});
