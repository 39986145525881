/**
 * @module Utils
 *
 */
import isEmpty from 'app/utils/isEmpty';

interface IMimeTypeObject {
  [key: string]: RegExp;
}

/**
 * returns a mime type for a extension type
 *
 * @method mimeType
 * @param filename {string} filename with extension
 * @return {string} MimeType or ContentType string
 */
export default function mimeType(filename: string): string {
  let ext = '.' + filename.split('.').slice(-1).join('');
  ext = ext.toLowerCase();

  let type = Object.keys(MIME_TYPES)
    .map(key => MIME_TYPES[key].test(ext) ? key : null)
    .find(v => v != null);

  if (isEmpty(type)) {
    type = 'text/plain';
  }
  return (type as string);
}


/**
 * MIME TYPES list
 *
 */
const MIME_TYPES: IMimeTypeObject = {
  'image/jpeg': /\.jpe?g$/,                                                                 // JPEG Images
  'image/gif': /\.gif$/,                                                                    // Graphics Interchange Format
  'image/png': /\.png$/,                                                                    // Portable Network Graphics
  'image/svg+xml': /\.svg/,                                                                 // Scaleable Vector Graphics
  'image/tiff': /\.tfff?$/,                                                                 // Tagged Images File Format
  'image/bmp': /\.bmp$/,                                                                    // Windows OS/2 Bitmap Graphics
  'image/webp': /\.webp$/,                                                                  // WEBP image

  'audio/wav': /\.wav$/,                                                                    // Waveform Audio Format
  'audio/aac': /\.aac$/,                                                                    // AAC audio
  'audio/mpeg': /\.mp3$/,                                                                   // MP3 audio
  'audio/ogg': /\.oga$/,                                                                    // OGG audio
  'audio/webm': /\.weba$/,                                                                  // WEBM audio

  'video/quicktime': /\.mov$/,                                                              // Quicktime movie
  'video/x-msvideo': /\.avi$/,                                                              // AVI: Audio Video Interleave
  'video/mp4': /\.mp4$/,                                                                    // MPEG-4
  'video/x-m4v': /\.m4v$/,                                                                  // m4v video
  'video/x-matroska': /\.mkv$/,                                                             // Matroska video
  'video/x-ms-wmv': /\.wmv$/,                                                               // windows media
  'video/mpeg': /\.mpe?g$/,                                                                 // MPEG video
  'video/webm': /\.webm$/,                                                                  // WEBM video
  'video/ogg': /\.ogv$/,                                                                    // OGG video
  'video/3gpp': /\.3gpp?$/,                                                                 // 3GPP audio/video container
  'video/3gpp2': /\.3gp{0,2}2$/,                                                            // 3GPP2 audio/video container


  'audio/3gpp': /\.3gpp?$/,                                                                 // 3GPP audio/video container (no video)
  'audio/3gpp2': /\.3gp{0,2}2$/,                                                            // 3GPP2 audio/video container (no video)

  'font/woff': /\.woff$/,                                                                   // Web Open Font Format (WOFF)
  'font/woff2': /\.woff2$/,                                                                 // Web Open Font Format (WOFF)
  'font/ttf': /\.ttf$/,                                                                     // TrueType Font
  'font/otf': /\.otf$/,                                                                     // OpenType Font

  'application/ogg': /\.og(x|g)$/,                                                          // OGG
  'application/pdf': /\.pdf$/,                                                              // Adobe Portable Document Format (PDF)

  'application/xml': /\.xml$/,                                                              // XML
  'application/xhtml+xml': /\.xhtml$/,                                                      // XHTML
  'application/rtf': /\.rtf$/,                                                              // Rich Text Format


  'application/vnd.ms-excel': /\.xls$/,                                                     // Microsoft Excel
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': /\.xlsx$/,           // Microsoft Excel (OpenXML)
  'application/vnd.ms-powerpoint': /\.ppt$/,                                                // Microsoft Powerpoint
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': /\.pptx$/,   // Microsoft Powerpoint (OpenXML)

  'text/csv': /\.csv$/,                                                                     // Comma-seperated values (CSV)
  'text/plain': /\.txt$/,                                                                   // Text file
};
