/** @module containers/subscription */
import React from 'react';
import { useStoreActions } from 'app/utils/hooks';
import { fetchSelf } from 'app/actions/user';
import Upgrade from 'app/components/subscription/Upgrade';
import Status from 'app/components/subscription/Status';
import { Spinner } from 'app/components/Loading';

/**
 * @class UpgradeContainer
 *
 */
export default function UpgradeContainer() {
  const { user } = useStoreActions({ user: fetchSelf });

  if (user.data == null || user.data.plan == null || user.pending) {
    return (<Spinner />);
  }

  if (user.data.plan.status === 'active' || user.data.plan.upgradable) {
    // Show status page with upgrade (from PLUS to PRO) options
    return (<Status self={user} />);
  } else {
    // Show regular purchase page
    return (<Upgrade user={user} />);
  }
}
