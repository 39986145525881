/** @module components/subscription/upgrade */
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useLoc, usePending } from 'app/utils/hooks';
import { cls } from 'app/utils';
import { PaymentInfoModel } from 'app/models';
import { Token } from 'app/utils/stripe';
import FormItem from 'app/components/inputs/FormItem';
import StripeForm from 'app/components/StripeForm';
import CheckBox from 'app/components/inputs/CheckBox';
import { Loc } from 'app/components/helpers';
import { Spinner } from 'app/components/Loading';
import styles from './styles.module.scss';
import LOCALES from './locale';

interface PropsDef {
  onCancel: () => void;
  onSubmit: (cardId: string, cb: () => void) => void;
  error: string;
  pending: boolean;
  paymentInfo: PaymentInfoModel;
}

/**
 * @class PaymentForm
 *
 */
export default function PaymentForm({ paymentInfo, error, pending, onCancel, onSubmit }: PropsDef) {
  const { formatMessage } = useLoc();
  const [ useCard, setUseCard ] = useState<boolean>(true);
  const [ pendingState, startPending, stopPending ] = usePending();

  useEffect(() => {
    if (pending === false) {
      stopPending();
    }
  }, [ pending ]);

  const cancelButton = (evt: React.MouseEvent<HTMLButtonElement>) => {
    evt.preventDefault();
    onCancel();
  };

  const submitButton = (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    startPending();
    onSubmit(paymentInfo.cardId, () => {
      stopPending();
    });
  };

  const submitToken = (token: Token) => {
    startPending();
    onSubmit(token.id, () => {
      stopPending();
    });
  };

  if ((paymentInfo == null && error == null) && pending) {
    return (<Spinner />);
  }

  if (paymentInfo == null || !useCard) {
    return (
      <div className={styles.formWrapper}>
        {renderEachCard({ paymentInfo, pending, useCard, setUseCard })}

        <FormItem error={error}>
          <StripeForm
            disabled={pending}
            submitTitle={formatMessage(LOCALES.purchase_button)}
            onToken={submitToken}
            onCancel={onCancel}
          />
        </FormItem>
      </div>
    );
  } else {
    return (
      <form onSubmit={submitButton} target="_self">
        <FormItem className={styles.formWrapper} error={error}>
          {renderEachCard({ paymentInfo, pending, useCard, setUseCard })}

          <div className={styles.paymentTypesButtons}>
            <button
              className="btn btn-outline-secondary"
              onClick={cancelButton}
              disabled={pendingState.status || pending}
            >
              <Loc value={LOCALES.cancel_button} />
            </button>

            <button
              className="btn btn-primary"
              disabled={pendingState.status || pending}
            >
              <Loc value={LOCALES.purchase_button} />
            </button>

            <div className={styles.clearFloat}></div>
          </div>
        </FormItem>
      </form>
    );
  }
}

interface CardPropsDef {
  paymentInfo: PaymentInfoModel | null;
  pending: boolean;
  useCard: boolean;
  setUseCard: (v: boolean) => void;
}

const renderEachCard = (props: CardPropsDef) => {
  // this can be converted to work with multiple payment types
  // in the future. currently it only supports one.
  const updateRadio = () => {
    props.setUseCard(!props.useCard);
  };

  if (props.paymentInfo == null) {
    return <div />;
  }

  const cardLabel = (
    <div className={styles.labelContainer}>
      <span>{`•••• •••• •••• ${props.paymentInfo.cardLast4}`}</span>
      <span>
        <Loc value={{ ...LOCALES.payment_expires, values: { expires: moment(props.paymentInfo.cardExpiry).format('MM/YY') } }} />
      </span>
    </div>
  );

  return (
    <div className={styles.paymentTypes}>
      <div className={cls(styles.radio, props.useCard ? styles.selected : '')}>
        <CheckBox
          className={styles.checkbox}
          name="payment_method"
          label={cardLabel}
          checked={props.useCard}
          onChange={() => updateRadio()}
          disabled={props.pending}
        />
        <div className={styles.clearFloat}></div>
      </div>

      <div className={cls(styles.radio, props.useCard ? '' : styles.selected)}>
        <CheckBox
          className={styles.checkbox}
          name="payment_method"
          label={(<div className={styles.labelContainer}><Loc value={LOCALES.new_card} /></div>)}
          checked={!props.useCard}
          onChange={() => updateRadio()}
          disabled={props.pending}
        />
        <div className={styles.clearFloat}></div>
      </div>
    </div>
  );
};

