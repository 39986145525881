import React from "react";
import styles from "./styles.module.scss";
import { cls } from "app/utils";

interface TemplateAppliedToProps {
  templateId: string;
  activeTab: string;
  tabHandler: (e: string) => void;
}

const TemplateAppliedTo = ({
  activeTab,
  tabHandler,
}: TemplateAppliedToProps) => {
  const tabs = [
    {
      title: "Posts",
      icon: "ico ico-feed-o",
    },
    {
      title: "History",
      icon: "ico ico-templates",
    },
  ];
  return (
    <>
      <nav className={styles.navbar}>
        <ul className="panel-tabs tabs">
          {tabs &&
            tabs.map(
              (
                tab: {
                  title: string;
                  icon: string;
                },
                index: number
              ) => {
                return (
                  <li
                    key={index}
                    className={cls({ ["active"]: activeTab === tab.title })}
                    onClick={(e: React.MouseEvent<HTMLLIElement>) =>
                      tabHandler(e.currentTarget.textContent.trim())
                    }
                  >
                    <i className={tab.icon}></i>
                    <span className="desktop-only">&nbsp;{tab.title}</span>
                  </li>
                );
              }
            )}
        </ul>
      </nav>
    </>
  );
};

export default TemplateAppliedTo;
