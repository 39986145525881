/* disable-eslint */
import React, { memo, useEffect, useMemo } from "react";
import styles from "./styles.module.scss";
import useDatafileIcons from "app/components/inputs/SelectBox/helpers/DatafileIcons";
import mediaSprite from "../../../../public/images/media-sprite-vid.png";
import { fetchMediaById, attachedData } from "app/actions/media-library-data";
import { useDispatch, useSelector } from "react-redux";
import { useActions } from "app/utils/hooks";
import { Spinner } from "app/components/Loading";
import { MediaTypes } from "app/routes/Resources/ResourceCard/index.types";
import { PostingFormProps } from "../index.types";
import { RootState } from "typedefs";

const AttachmentPreview = ({ attachment }: PostingFormProps) => {
  const dispatch = useDispatch();
  const { dataFileIconHandler } = useDatafileIcons();
  const mediaActions = useActions({ fetchMediaById });

  const parsedAttachment: MediaTypes = useMemo(() => {
    return typeof attachment === "string" ? JSON.parse(attachment) : attachment;
  }, [attachment]);
  const uploadError = useSelector(
    (state: RootState) => state.mediaLibraryData.error,
  );

  const fetchMedia = () => {
    if (parsedAttachment && parsedAttachment.id) {
      mediaActions.fetchMediaById(parsedAttachment.id);
    }
  };

  useEffect(() => {
    let pollingInterval: any;

    if (!parsedAttachment || (parsedAttachment && parsedAttachment.processed)) {
      return;
    }

    pollingInterval = setInterval(() => {
      fetchMedia();

      if (parsedAttachment && parsedAttachment.processed) {
        clearInterval(pollingInterval);
      }
    }, 2000);

    return () => {
      clearInterval(pollingInterval);
    };
  }, [parsedAttachment]);

  const onClearClick = () => {
    dispatch(attachedData(null));
  };

  const Loading = () => {
    return (
      <span className={styles.loading_preview}>
        <Spinner />
      </span>
    );
  };

  const RenderImage = () => {
    if (parsedAttachment.object === "video") {
      return (
        <div className={styles.video_icon_container}>
          <img src={mediaSprite} alt="video" className={styles.media_vid} />
        </div>
      );
    } else if (
      parsedAttachment.object === "image" &&
      (parsedAttachment.processed || parsedAttachment.originalUrl)
    ) {
      return (
        <img
          src={parsedAttachment.originalUrl}
          alt="image"
          className={styles.preview}
        />
      );
    } else {
      return (
        <img
          src={dataFileIconHandler(
            parsedAttachment.extension || parsedAttachment.object,
          )}
          alt="media"
          className={styles.preview}
        />
      );
    }
  };

  return (
    <div className={styles.preview_container}>
      {!parsedAttachment && !uploadError ? (
        parsedAttachment.object === "image" &&
        !parsedAttachment.originalUrl && <Loading />
      ) : (
        <RenderImage />
      )}
      <button className={styles.delete_preview_btn} onClick={onClearClick}>
        ✕
      </button>
    </div>
  );
};

export default memo(AttachmentPreview);
