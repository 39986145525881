import React, { useCallback, useState } from "react";
import { cls } from "app/utils";
import styles from "./styles.module.scss";
import AddToRecruitPostModal from "app/components/Layouts/AddToRecruitPostModal";
import { AddToRecruitProfileProps } from "./index.types";
import AddToRecruitPostSuccessModal from "app/components/Layouts/AddToRecruitPostSuccessModal";
import recruitLogo from "../../../../public/images/recruit-logo.svg";
import { useActions } from "app/utils/hooks";
import { addToRecruitPost } from "app/actions/addToRecruitPost";

const AddToRecruitProfile = ({ postId }: AddToRecruitProfileProps) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState<boolean>(false);

  const onClickFunc = useCallback(() => {
    setIsModalOpen((prev) => !prev);
  }, []);

  const successModalHandler = useCallback(() => {
    setIsSuccessModalOpen((prev) => !prev);
  }, []);

  const onModalClose = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const addToRecruitActions = useActions({
    addToRecruitPost,
  });

  const onClickHandler = useCallback(() => {
    if (postId) {
      addToRecruitActions.addToRecruitPost(postId);
      onModalClose();
      setIsSuccessModalOpen(true);
    }
  }, [postId, addToRecruitActions, onModalClose]);

  return (
    <>
      <button
        className={cls("button inline-flex", styles.container)}
        onClick={onClickFunc}
      >
        <img src={recruitLogo} alt="recruit" />
        <p>Add to my Recruit profile</p>
      </button>
      {isModalOpen && (
        <AddToRecruitPostModal
          modalOpen={isModalOpen}
          onModalClose={onModalClose}
          postId={postId}
          yesClickHandler={onClickHandler}
        />
      )}
      {isSuccessModalOpen && (
        <AddToRecruitPostSuccessModal
          isSuccessModalOpen={isSuccessModalOpen}
          onSuccessModalClose={successModalHandler}
        />
      )}
    </>
  );
};

export default AddToRecruitProfile;
