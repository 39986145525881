import React from "react";
import styles from "./styles.module.scss";
import PagePanel from "app/components/PagePanel";
import { useSelector } from "react-redux";
import { RootState } from "typedefs";
import paywallModalPro from "app/utils/paywall";
import ResourcesList from "./ResourcesList";
import FilterDropdown from "../../components/Filter";
import SegmentsLayout from "app/components/Layouts/SegmentsLayout";

const Resources = () => {
  const user = useSelector((state: RootState) => state.user.data);
  const canAddMedia =
    user && user.planPermissions.includes("upload_to_library");

  return (
    <SegmentsLayout displayNoResult={!user}>
      <div className={styles.container}>
        <PagePanel
          title="Library"
          titleStyles={styles.title}
          buttonTitle="+ Add Media"
          buttonStyles={canAddMedia ? "btn-primary" : "btn-upgrade"}
          onButtonClick={() => paywallModalPro({ displayModal: "library" })}
          url={canAddMedia && "/resources/new"}
        />
        <div className={styles.dropdown_container}>
          <hr />
          <FilterDropdown />
        </div>
        <ResourcesList />
      </div>
    </SegmentsLayout>
  );
};

export default Resources;
