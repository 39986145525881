import {
  clearMediaState,
  createMedia,
  fetchMediaById,
} from "app/actions/media-library-data";
import { MediaTypes } from "app/routes/Resources/ResourceCard/index.types";
import { useActions } from "app/utils/hooks";
import upload from "app/utils/uploader";
import { useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "typedefs";

interface Props {
  onClose: () => void;
}

const useFileUpload = ({ onClose }: Props) => {
  const mediaActions = useActions({
    createMedia,
    clearMediaState,
    fetchMediaById,
  });
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [uploadError, setUploadError] = useState(null);
  const [progress, setProgress] = useState(0);
  const [selectedTab, setSelectedTab] = useState<tabName>("upload");
  const [choosenMedia, setChoosenMedia] = useState<MediaTypes>(null);
  type tabName = "upload" | "library";
  const isMediaSelected = useSelector(
    (state: RootState) => state.mediaLibraryData.selectedMedia,
  );

  const mediaSelectHandler = () => {
    setChoosenMedia(isMediaSelected);
    modalHandler();
    mediaActions.fetchMediaById(isMediaSelected && isMediaSelected.id);
  };

  const onTabSelect = (tabName: tabName) => {
    setSelectedTab(tabName);
  };

  const uploadRef = useRef(null);

  const onDrop = async (acceptedFiles: File[]) => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];

      try {
        setIsLoading(true);
        dispatch(clearMediaState());
        const abortableUpload = upload(file, file.name, file.type, undefined);
        uploadRef.current = abortableUpload;
        const response = await abortableUpload.promise;

        abortableUpload.subscribe(
          (progressData) => {
            const percentage = Math.floor(
              (progressData.loaded / progressData.total) * 100,
            );
            setProgress(percentage);
          },
          (error) => {
            setUploadError(error);
            setProgress(0);
          },
          () => {
            const { id, filename } = response as {
              id: string;
              filename: string;
            };
            mediaActions.createMedia({ id, filename }).finally(() => {
              setProgress(100);
              modalHandler();
            });
          },
        );
      } catch (error) {
        console.error("Error during file upload:", error);
        setUploadError(error);
      }
    }
  };

  const modalHandler = () => {
    onClose();
    setIsLoading(false);
    setUploadError(null);
    setSelectedTab("upload");
    setProgress(0);
    if (uploadRef.current) {
      uploadRef.current.abort();
      uploadRef.current = null;
      setUploadError(null);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    noClick: true,
    accept: {
      "audio/*": [],
      "document/doc": [
        ".doc",
        ".docx",
        ".ppt",
        ".pptx",
        ".pdf",
        ".xls",
        ".xlxx",
        ".xlsx",
      ],
      "image/*": [],
      "video/*": [],
    },
  });

  return {
    isLoading,
    uploadError,
    progress,
    selectedTab,
    onTabSelect,
    getRootProps,
    getInputProps,
    isDragActive,
    modalHandler,
    isMediaSelected,
    mediaSelectHandler,
    choosenMedia,
  };
};

export default useFileUpload;
