/**
 * @module Components.SignUp
 *
 */
import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import { Token } from 'app/utils/stripe';
import { useLoc } from 'app/utils/hooks';
import { AuthService } from 'app/services';
import { Loc } from 'app/components/helpers';
import StripeForm from 'app/components/StripeForm';
import LOCALES from './locale';
import styles from './styles.module.scss';

interface PropsDef {
  onDone: (id: string) => void;
  onCancel: () => void;
}

export default function BillingInfo(props: PropsDef) {
  const { formatMessage } = useLoc();
  const btnTitle = formatMessage(LOCALES.billing_info_done);

  const createConsentToken = (token?: Token | null) => {
    if (token != null) {
      (AuthService as any)
        .createConsentToken(token.id)
        .then((data: { chargeId: string }) => props.onDone(data.chargeId));
    }
  };

  return (
    <div className={styles.billingInfo}>
      <h1><Loc value={LOCALES.billing_info_title} /></h1>
      <p><FormattedHTMLMessage {...LOCALES.billing_info_subtitle} /></p>

      <StripeForm
        onToken={createConsentToken}
        submitTitle={btnTitle}
        onCancel={() => props.onCancel()}
      />
    </div>
  );
}
