/**
 * @module Components.SignUp
 *
 */
import { defineMessages } from 'react-intl';

/**
 * SignUp locale strings
 */
export default defineMessages({
  first_name: { id: 'app.form-label.first-name', defaultMessage: 'First Name' },
  last_name: { id: 'app.form-label.last-name', defaultMessage: 'Last Name' },
  email: { id: 'app.form-label.email', defaultMessage: 'Email' },
  password: { id: 'app.form-label.password', defaultMessage: 'Password' },
  password_verify: { id: 'app.form-label.password-verify', defaultMessage: 'Re-type password' },
  code: { id: 'app.form-label.code', defaultMessage: 'Verification Code' },
  next: { id: 'app.button.next', defaultMessage: 'Next' },
  send_confirmation_title: { id: 'app.button.send_confirmation_title', defaultMessage: 'Please enter your phone number to continue' },
  continue: { id: 'app.button.continue', defaultMessage: 'Continue' },
  send_code: { id: 'app.button.send_code', defaultMessage: 'Send Confirmation Code' },
  confirmation_sent: { id: 'app.confirmation-sent', defaultMessage: 'We sent confirmation code to<br/> <strong>{phoneNumber}</strong>' },

  phone_verification_unprocessable_entity: { id: 'error.signin-phone-verification.unprocessable_entity', defaultMessage: "Signin failed. Please provide a valid phone number"},
  phone_verification_invalid_code: { id: 'error.signin-verification-token.invalid_verification', defaultMessage: "Signin failed. Verification code is invalid." },
  phone_verification_unknown: { id: 'error.signin-phone-verification.unknown', defaultMessage: "Signin failed. Error unknown"},

  error_required_first_name: { id: 'error.required.first-name', defaultMessage: 'Please enter your first name.' },
  error_required_last_name: { id: 'error.required.last-name', defaultMessage: 'Please enter your last name.' },

  error_required_kind: { id: 'error.invalid.kind', defaultMessage: 'Please select your role.' },

  error_undefined_email: { id: 'error.undefined.email', defaultMessage: "Email doesn't match any accounts of file." },
  error_unavailable_email: { id: 'error.unavailable.email', defaultMessage: 'This email is already in use, please sign in or reset password.' },
  error_unavailable_phone: { id: 'error.unavailable.phone', defaultMessage: 'This phone is already in use, please sign in.' },
  error_required_email: { id: 'error.required.email', defaultMessage: 'Please enter your email address.' },
  error_invalid_email: { id: 'error.invalid.email', defaultMessage: 'The email address entered is not valid.' },
  error_no_connection: { id: 'error.signup.no_connection', defaultMessage: 'The account failed to create. No internet connection.' },

  error_mismatch_password: { id: 'error.mismatch.password', defaultMessage: 'Passwords do not match' },
  error_required_password: { id: 'error.required.password', defaultMessage: 'Please enter your password.' },
  error_invalid_password: { id: 'error.invalid.password', defaultMessage: 'Password must between 6 and 128 characters long.' },

  error_required_tos_consent: {
    id: 'error.required.tos-consent',
    defaultMessage: "Please agree to CoachNow's Privacy Policy, Terms of Service, and Cookie Policy to sign up."
  },
  error_signup_unknown: { id: 'error.signup.unknown', defaultMessage: 'The account failed to create.' },

  signup_consent: { id: 'app.button.signup-consent', defaultMessage: 'Provide Billing Information' },

  signup: { id: 'app.button.signup', defaultMessage: 'Sign Up' },
  coach_signup: { id: 'app.button.coach-signup', defaultMessage: 'Begin My Free Trial' },
  signin: { id: 'app.button.signin', defaultMessage: 'Sign In' },
  coach_signin: { id: 'app.button.coach-signin', defaultMessage: 'Already Have an Account? Sign In Here.' },

  coach_signup_header_copy: { id: 'app.coach-signup-header-copy', defaultMessage: 'Submit your information below to create your CoachNow account and begin your <strong>Free 7-day Trial</strong><br/>(no credit card required)' },
  coach_signup_copy: { id: 'app.coach-signup-copy', defaultMessage: 'By creating an account, you consent to receiving emails from us (don’t worry - we hate spam just like you!). You can change your subscription preferences at any time in the footer of our emails.' },

  or_opt: { id: 'signup.form-label.or-option', defaultMessage: 'or sign in with' },

  billing_info_title: { id: 'signup.billing-info.title', defaultMessage: 'Billing Information' },
  billing_info_subtitle: {
    id: 'signup.billing-info.subtitle',
    defaultMessage: "Please provide your parent/guardians's credit or debit card to confirm their consent. <strong>It will be charged $1 and then refunded.</strong>"
  },
  billing_info_done: { id: 'app.button.done', defaultMessage: 'Done' },

  about_you_title: { id: 'signup.about-you.title', defaultMessage: 'About You' },
  about_you_subtitle: { id: 'signup.about-you.subtitle', defaultMessage: 'Tell us a little bit about yourself so we can customize your experience.' },
  about_you_describe: { id: 'signup.about-you.describe-your-role', defaultMessage: 'What role best describes you?' },
  about_you_uses: { id: 'signup.about-you.describe-your-uses', defaultMessage: 'What are you using CoachNow for?' },
  about_you_name: { id: 'signup.about-you.describe-your-alias', defaultMessage: 'I like to be called...' },
  about_you_button: { id: 'signup.about-you.get-started', defaultMessage: 'Get Started' },

  coach_selection: { id: 'app.title.coach', defaultMessage: 'Coach' },
  athlete_selection: { id: 'app.title.athlete', defaultMessage: 'Athlete' },
  parent_selection: { id: 'app.title.parent', defaultMessage: 'Parent / Fan' },


  error_facebook_email_exists: {
    id: 'error.signup.facebook.email_exists',
    defaultMessage: 'The email associated with this Facebook account is already in use.'
  },
  error_facebook_email_taken: {
    id: 'error.signup.facebook.email_taken',
    defaultMessage: 'The email associated with this Facebook account is already in use.'
  },
  error_facebook_email_unavailable: {
    id: 'error.signup.facebook.email_unavailable',
    defaultMessage: 'The Facebook profile provided either does not have an email or security settings prevents sharing it. Please check your profile and try again.'
  },
  error_facebook_unknown: {
    id: 'error.signup.facebook.unknown',
    defaultMessage: 'Unable to sign up with Facebook. Please try again.'
  },

  join_team: {
    id: 'join.signup.join-team',
    defaultMessage: 'Sign up and join the <b>{name}</b> Group'
  },
  join_space: {
    id: 'join.signup.join-space',
    defaultMessage: 'Sign up and join the <b>{name}</b> TrainingSpace'
  },
  join_created_by: {
    id: 'join.signup.join-created-by',
    defaultMessage: 'created by {createdBy}'
  },
  join_welcome: {
    id: 'join.signup.welcome',
    defaultMessage: 'Welcome'
  },

  invalid_invite_header: {
    id: 'join.signup.invalid-header',
    defaultMessage: 'Invitation is Invalid'
  },
  invalid_invite_message: {
    id: 'join.signup.invalid-message',
    defaultMessage: 'This invitation has expired or it has already been accepted.'
  },
  invalid_invite_help: {
    id: 'join.signup.invalid-help',
    defaultMessage: 'For help, email us at <a href="mailto:{email}">{text}</a>'
  }
});
