/**
 * @module Components
 *
 */
import React, { FormEvent, ReactNode } from 'react';
import { LocaleString } from 'typedefs';
import { cls } from 'app/utils';
import { uuid } from 'app/utils/uuid';
import styles from './styles.module.scss';

export type PropsDef = {
  className?: string;
  name?: string;
  label?: LocaleString | ReactNode;
  disabled?: boolean;
  checked?: boolean;
  onChange?: (checked: boolean) => void;
  getRef?: (ref: any) => void;
};

export default function CheckBox(props: PropsDef) {
  const notifyParent = (e: FormEvent<HTMLInputElement>): void => {
    if (props.onChange) {
      props.onChange(e.currentTarget.checked);
    }
  };

  const handleRef = (ref: any) => {
    if (props.getRef) {
      props.getRef(ref);
    }
  };

  const id = uuid();
  const checked = props.checked ? true : false;
  return (
    <div className={cls(props.className, styles.index, props.disabled ? styles.disabled : '')}>
      <input
        id={id}
        name={props.name}
        type="checkbox"
        checked={checked}
        disabled={props.disabled}
        onChange={(e) => notifyParent(e)}
      />
      <label ref={handleRef} htmlFor={id}>
        <i className="fas fa-check-circle" aria-hidden="true"></i>
        <i className="far fa-circle" aria-hidden="true"></i>
        {props.label}
      </label>
      <div className={styles.clear}></div>
    </div>
  );
}

