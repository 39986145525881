import { request } from "app/utils/request";
import { Dispatch } from "react";
import { action, ActionType } from "typesafe-actions";
import config from 'config/environment';


const pending = () => action('@views.fetch.pending');
const error = (err: string) => action('@views.fetch.error', err);
const success = (views: any) => action('@views.fetch.success', views);

const pendingNotSeenUsers = () => action('@views.fetch.notSeenUsers.pending');
const errorNotSeenUsers = (err: string) => action('@views.fetch.notSeenUsers.error', err);
const successNotSeenUsers = (members: any[]) => action('@views.fetch.notSeenUsers.success', members);
export const clearViewsState = () => action('@views.clear');

const pendingReceipts = () => action('@views.fetch.receipts.pending');
const errorReceipts = (err: string) => action('@views.fetch.receipts.error', err);
const successReceipts = (receipts: any[]) => action('@views.fetch.receipts.success', receipts);


const thisActions = { pending, error, success, pendingNotSeenUsers, errorNotSeenUsers, successNotSeenUsers, clearViewsState, pendingReceipts, errorReceipts, successReceipts};

export type ViewsAction = ActionType<typeof thisActions>;

export function fetchViews(post_id: string) {
  return (dispatch: Dispatch<ViewsAction>) => {
    dispatch(pending());
    dispatch(pendingNotSeenUsers());

    const url = config.API_SERVER + `/api/v2/post_views?post_id=${post_id}`;
    const promise = request(url, 'GET', {}, { version: 1 });
          promise.then((views) => dispatch(success(views)));
          promise.catch(err => dispatch(error(err)));

    const notSeenUsers = fetchNotSeenUsers(post_id)
                        .then((members: any[]) => dispatch(successNotSeenUsers(members)))
                        .catch((err) => dispatch(errorNotSeenUsers(err)));
    const receipts = fetchReceipts(post_id)
                        .then((receipts: any[]) => dispatch(successReceipts(receipts)))
                        .catch((err) => dispatch(errorReceipts(err)));

    return Promise.all([promise, notSeenUsers, receipts]);
  };
}

export function fetchNotSeenUsers(postId: string) {
    const url = config.API_SERVER + `/api/v2/posts/${postId}/not_seen`;
    const promise = request(url, 'GET', {}, { version: 1 });
    return promise;
}


export function fetchReceipts(postId: string) {
  const url = config.API_SERVER + `/api/v1/read_receipts?post_id=${postId}`;
  const promise = request(url, 'GET', {}, { version: 1 });
  return promise;
}

export function activateReceipt(userId: string, postId: string) {
  const url = config.API_SERVER + `/api/v1/read_receipts`;
  const promise = request(url, 'POST', {
    recipient_id: userId,
    post_id: postId
  }, { version: 1 });

  return promise;
}


export function deactivateReceipt(receiptId: string) {
  const url = config.API_SERVER + `/api/v1/read_receipts/${receiptId}`;
  const promise = request(url, 'DELETE', {}, { version: 1 });

  return promise;
}

