/**
 * @module TabView
 *
 */
import React, { useState } from "react";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import { cls } from "app/utils";
import Styles from "./styles.module.scss";

type PropsDef = React.PropsWithChildren<{
  className?: string;
  defaultFocus?: boolean;
  defaultIndex?: number;
  styles?: Record<string, string>;
  disabledTabClassName?: string;
  domRef?: (node?: HTMLElement) => void;
  forceRenderTabPanel?: boolean;
  onSelect?: (
    index: number,
    last: number,
    event: Event
  ) => boolean | number | void;
  selectedIndex?: number;
  selectedTabClassName?: string;
  selectedTabPanelClassName?: string;
}>;

export default function TabView({
  className,
  onSelect,
  children,
  defaultIndex,
  styles = Styles,
  ...props
}: PropsDef) {
  const [selectedTab, setTab] = useState(defaultIndex || 0);

  const selectHandler = (index: number, last: number, event: Event) => {
    if (typeof onSelect === "function") {
      if (onSelect(index, last, event) === false) {
        return false;
      }
    }
    setTab(index);
    return true;
  };

  const childElements = Array.isArray(children)
    ? (children as JSX.Element[])
    : [children as JSX.Element];

  const selected = childElements[selectedTab];
  const actions = selected.props.actions || <div />;

  return (
    <div className={cls(styles.tabView, className)}>
      <Tabs defaultIndex={defaultIndex} onSelect={selectHandler} {...props}>
        <div className="panel">
          {/* <div className={styles.headerTitle}> */}
          <div className="panel-header mb-0">
            <h1 className="panel-title">{selected.props.headerTitle}</h1>
            {/* <h5>{selected.props.headerSubTitle}</h5> */}
            <div className={styles.headerAction}>{actions}</div>
          </div>
          {renderHeader(childElements)}
        </div>
        <div className={styles.tabBody}>{renderBody(childElements)}</div>
      </Tabs>
    </div>
  );
}

function renderHeader(children: any) {
  const tabs = children.map((child: any, idx: number) => {
    return (
      <Tab key={idx}>
        {child.props.icon} {child.props.tabTitle}
      </Tab>
    );
  });

  return <TabList>{tabs}</TabList>;
}

function renderBody(children: any) {
  return children.map((child: any, idx: number) => {
    return <TabPanel key={idx}>{child}</TabPanel>;
  });
}
