import React, { useMemo } from "react";
import { ApplyTemplateToProps } from "../ApplyTemplateDropdown/index.types";
import { cls } from "app/utils";
import { useActions } from "app/utils/hooks";
import { fetchSpaces } from "app/actions/space";

const ApplyTemplateToSpace = ({
  className,
  spaceSelectModalHandler,
}: ApplyTemplateToProps) => {
  const spaceQuery = {
    options: {
      include: ["team_info"],
      exclude: ["permissions", "state"],
    },
  };

  const applyTemplateActions = useActions({
    fetchSpaces,
  });

  useMemo(() => {
    applyTemplateActions.fetchSpaces(spaceQuery);
  }, []);

  return (
    <>
      <div className={cls(className)} onClick={spaceSelectModalHandler}>
        <i className="ico ico-tspace" />
        <p>Apply to Space</p>
      </div>
    </>
  );
};

export default ApplyTemplateToSpace;
