/**
 * @module Components
 *
 */
import React from 'react';
import { cls } from 'app/utils';
import styles from './styles.module.scss';

type PropsDef = {
  className?: string,
  contain?: boolean,
  active?: boolean,
};

/**
 * @class LoadingDots
 *
 */
export default function LoadingDots(props: PropsDef) {
  const contain = props.contain ? styles.contain : '';
  const active = props.active ? styles.active : '';

  return (
    <>
    <div className={cls(styles.main, contain, props.className, active)}>
      {/* <div className={cls('loading-dots', styles.dots)}>
        <div className={cls('loading-dot', styles.dot)}></div>
        <div className={cls('loading-dot', styles.dot)}></div>
        <div className={cls('loading-dot', styles.dot)}></div>
      </div> */}
    </div>
    </>
  );
}

