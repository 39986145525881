import React, { useCallback, useEffect } from "react";
import { TagSelect } from "../inputs/SelectBox";
import Dropdown from "app/components/Dropdown";
import styles from "./styles.module.scss";
import CheckBox from "../inputs/CheckBox";
import { useDispatch, useSelector } from "react-redux";
import { useActions } from "app/utils/hooks";
import {
  fetchMedia,
  clearMediaState,
  mediaLibraryData,
} from "app/actions/media-library-data";
import { clearFilter, filterData } from "app/actions/filter";
import { RootState } from "typedefs";
import useFilters from "../helpers/useFilters";
import { filterStateDataType } from "../Filter";

const filterLabels = [
  { value: "image", label: "Images" },
  { value: "video", label: "Videos" },
  { value: "audio", label: "Audio" },
  { value: "datafile", label: "Documents" },
];

const FileUploadFilter = () => {
  const dispatch = useDispatch();
  const mediaActions = useActions({ fetchMedia, clearMediaState });
  const filterActions = useActions({ clearFilter });

  const { filters } = useFilters();
  const filterState = useSelector((state: RootState) => state.filter);
  const pending = useSelector(
    (state: RootState) => state.mediaLibraryData.pending
  );

  const onTagsChange = (tags: any) => {
    const tagNames = tags.map((item: { name: string }) => item.name);
    dispatch(filterData({ ...filterState.data, tags: tagNames }));
  };

  const toggleMediaType = useCallback(
    (dataType: filterStateDataType) => {
      if (!pending) {
        dispatch(
          filterData({
            ...filterState.data,
            type: {
              ...filterState.data.type,
              [dataType]: !filterState.data.type[dataType],
            },
          })
        );
      }
    },
    [filterState.data, pending, dispatch]
  );

  useEffect(() => {
    mediaActions.clearMediaState();
    mediaActions.fetchMedia(1, 20, filters).then((res) => {
      dispatch(mediaLibraryData(res));
    });
  }, [filterState.data]);

  useEffect(() => {
    return () => {
      filterActions.clearFilter();
      mediaActions.clearMediaState();
    };
  }, []);

  const DropdownContent = () => (
    <div className={`${styles.btn_content} btn btn-secondary-outline`}>
      All Types
      <i className="ico ico-chevron" />
    </div>
  );

  return (
    <>
      <TagSelect
        isCreatable={false}
        isClearable
        isMulti
        onChange={onTagsChange}
      />
      <Dropdown
        dropdownWrapperClass={styles.dropdown_content}
        btnClassName={styles.filter_btn}
        btnContent={<DropdownContent />}
      >
        <div className={styles.dropdown_container}>
          <p className={styles.filter_title}>Filter By</p>
          <div className={styles.checkbox_wrapper}>
            {filterLabels.map((item) => (
              <CheckBox
                key={item.value}
                name={item.value}
                label={item.label}
                checked={
                  filterState.data &&
                  filterState.data.type &&
                  filterState.data.type[item.value as filterStateDataType]
                }
                onChange={() =>
                  toggleMediaType(item.value as filterStateDataType)
                }
              />
            ))}
          </div>
        </div>
      </Dropdown>
    </>
  );
};

export default FileUploadFilter;
