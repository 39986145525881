import { request } from "app/utils/request";
import { Dispatch } from "react";
import { action, ActionType } from "typesafe-actions";
import config from "config/environment";
import { CopyPostModel } from "app/models/CopyPostModel";

const pending = () => action("@copy.fetch.pending");
const error = (err: string) => action("@copy.fetch.error", err);
const success = (post: any) => action("@copy.fetch.success", post);

const pendingCopy = () => action("@copy.create.pending");
const errorCopy = (err: string) => action("@copy.create.error", err);
const successCopy = (post: any) => action("@copy.create.success", post);

export const copyLocation = (location: string[]) =>
  action("@copy.fetch.copyLocation", location);

export const clearCopy = () => action("@copy.clear");

const thisActions = {
  pending,
  error,
  success,
  pendingCopy,
  errorCopy,
  successCopy,
  copyLocation,
  clearCopy,
};

export type CopyAction = ActionType<typeof thisActions>;

export function fetchPost(post_id: string) {
  return (dispatch: Dispatch<CopyAction>) => {
    dispatch(pending());

    const url = config.API_SERVER + `/api/v2/posts/${post_id}`;
    const promise = request(url, "GET", {}, { version: 1 });
    promise.then((views) => dispatch(success(views)));
    promise.catch((err) => dispatch(error(err)));

    return promise;
  };
}

export function createCopyPost(post: CopyPostModel) {
  return (dispatch: Dispatch<CopyAction>) => {
    dispatch(pendingCopy());

    const url = config.API_SERVER + `/api/v2/posts`;
    const promise = request(url, "POST", { post }, { version: 1 });
    promise.then((views) => dispatch(successCopy(views)));
    promise.catch((err) => dispatch(errorCopy(err)));

    return promise;
  };
}
