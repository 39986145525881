import React from "react";
import styles from "./styles.module.scss";
import { StepCardProps } from "./index.types";
import nextArrowIcon from "../../../../public/images/next-arrow.svg";
import CheckIcon from "../../../../public/images/blue-check-icon.svg";

const StepCard = ({
  stepNumber,
  isDone,
  children,
  nextArrow,
}: StepCardProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.step_number}>
        {!isDone ? stepNumber : <img src={CheckIcon} className={styles.step_check_icon} alt="check" />}
      </div>
      {children}
      {nextArrow && (
        <img className={styles.next_arrow} src={nextArrowIcon} alt="next" />
      )}
    </div>
  );
};

export default StepCard;
