/** @module Main */
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import ProvideWrapper from 'app/components/ProvideWrapper';
import appInit from 'app/library/application';
import './styles/main.scss';
import App from './App';

/***/

/**
 * @class Main
 *
 */
function Main() {
  // initalize global app info
  useEffect(() => (appInit(), undefined), []);

  return (
    <ProvideWrapper>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ProvideWrapper>
  );
}

ReactDOM.render(
  <Main />,
  document.getElementById('root')
);
