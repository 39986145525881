/**
 * @module Components.StripeForm
 *
 */
import React, { useState, FormEvent } from 'react';
import { injectStripe } from 'react-stripe-elements';
import { MessageDescriptor } from 'react-intl';
import { cls } from 'app/utils';
import { useLoc, useError, usePending } from 'app/utils/hooks';
import { Token, TokenResponse, ElementChangeResponse } from 'app/utils/stripe';
import { Loc } from 'app/components/helpers';
import FormItem from 'app/components/inputs/FormItem';
import { Spinner } from 'app/components/Loading';
import CardName from './CardName';
import CardNumber from './CardNumber';
import CardExpiry from './CardExpiry';
import CardCVC from './CardCVC';
import PostalCode from './PostalCode';
import styles from './styles.module.scss';
import LOCALES from './locale';

export type PropsDef = {
  disabled?: boolean,
  cardName?: string,
  cardNumber?: string,
  cardExpiry?: string,
  cardSecurityCode?: string,
  postalCode?: string,
  submitTitle?: string,
  onToken?: (token: Token) => void,
  onCancel?: () => void,
  stripe?: { [key: string]: any }
};

type ErrorModel = {
  cardNameError?: MessageDescriptor | null,
  cardNumberError?: string | null,
  cardExpiryError?: string | null,
  cardSecurityCodeError?: string | null,
  postalCodeError?: string | null,
  formError?: string | null,
};

type State = ErrorModel & {
  cardName: ElementChangeResponse | null,
  cardNumber: ElementChangeResponse | null,
  cardExpiry: ElementChangeResponse | null,
  cardSecurityCode: ElementChangeResponse | null,
  postalCode: ElementChangeResponse | null,
};

const initError: ErrorModel = {
  cardNameError: null,
  cardNumberError: null,
  cardExpiryError: null,
  cardSecurityCodeError: null,
  postalCodeError: null,
  formError: null,
};

const initState: State = {
  cardName: null,
  cardNumber: null,
  cardExpiry: null,
  cardSecurityCode: null,
  postalCode: null,
};

function StripeForm(props: PropsDef) {
  const { formatMessage } = useLoc();
  const [ isReady, setIsReady ] = useState(false);
  const [ pending, startPending, stopPending ] = usePending();
  const [ errors, addErrors, clearErrors ] = useError(initError);
  const [ state, setState ] = useState(() => mergePropsWithState(props, initState));

  const formClass = cls(styles.stripeCardForm, isReady ? '' : 'hidden');
  const disabled = (props.disabled || pending.status);

  const cancelUpgrade = (event: FormEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (props.onCancel) {
      props.onCancel();
    }
  };

  const onStateChange = (name: string, el: ElementChangeResponse) => {
    clearErrors();
    setState({ ...state, [name]: el });
  };

  const handleCreateToken = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (props.stripe == null) {
      throw new Error("cannont call handleCreateToken with out initializing stripe first!");
    }

    const { cardNumber, cardExpiry, cardSecurityCode } = state;

    // trim cardName postalCode to remove extra spaces
    const cardName = state.cardName && state.cardName.value ? (state.cardName.value as string).trim() : null;
    const postalCode = state.postalCode && state.postalCode.value ? (state.postalCode.value as string).trim() : null;

    // validate input for errors
    const errors = validate(cardName, cardNumber, cardExpiry, cardSecurityCode, postalCode, formatMessage);
    if (Object.keys(errors).length) {
      addErrors(errors);
      return;
    }

    startPending();
    props.stripe.createToken({ name: cardName, address_zip: postalCode })
      .then((token: TokenResponse) => {
        if (token.error) {
          let error: ErrorModel = { formError: token.error.message };
          if (token.error.code === 'incomplete_zip') {
            error = { postalCodeError: token.error.message };
          } else if (token.error.code === 'card_declined') {
            error = { cardNumberError: token.error.message };
          } else if (token.error.code === 'expired_card') {
            error = { cardExpiryError: token.error.message };
          }
          addErrors(error);
          return;
        } else {
          if (props.onToken) {
            props.onToken(token.token);
          }
          return;
        }
      }).finally(() => stopPending());
  };

  const handleKeyboard = (e: React.KeyboardEvent<HTMLFormElement>) => {
    if (e.which === 13) {
      e.preventDefault();
      handleCreateToken(e);
    }
  };

  // console.log('errors', errors);
  return (
    <div className={cls(styles.rcStripeForm, disabled ? 'disable' : '')}>
      {!isReady && <Spinner />}

      <form className={formClass} onKeyDown={handleKeyboard} onSubmit={handleCreateToken} target="_self">
        <CardName
          onChange={(el: ElementChangeResponse) => onStateChange('cardName', el)}
          value={props.cardName}
          error={errors.cardNameError}
          disabled={disabled}
        />
        <CardNumber
          onReady={() => setIsReady(true)}
          onChange={(el: ElementChangeResponse) => onStateChange('cardNumber', el)}
          value={props.cardNumber}
          error={errors.cardNumberError}
          disabled={disabled}
        />
        <CardExpiry
          onChange={(el: ElementChangeResponse) => onStateChange('cardExpiry', el)}
          value={props.cardExpiry}
          error={errors.cardExpiryError}
          disabled={disabled}
        />
        <CardCVC
          onChange={(el: ElementChangeResponse) => onStateChange('cardSecurityCode', el)}
          value={props.cardSecurityCode}
          error={errors.cardSecurityCodeError}
          disabled={disabled}
        />
        <PostalCode
          onChange={(el: ElementChangeResponse) => onStateChange('postalCode', el)}
          value={props.postalCode}
          error={errors.postalCodeError}
          disabled={disabled}
        />

        <FormItem className={styles.formControl} error={errors.formError}>
          <button
            tabIndex={-1}
            className="btn btn-outline-secondary"
            onClick={(e: FormEvent<HTMLButtonElement>) => cancelUpgrade(e)}
            disabled={disabled}
          >
            <Loc value={LOCALES.cancel_button} />
          </button>

          <button
            type="submit"
            className="btn btn-primary"
            disabled={disabled}
          >
            {props.submitTitle}
          </button>
        </FormItem>
      </form>
    </div>
  );
}

export default injectStripe(StripeForm);

const mergePropsWithState = (props: PropsDef, state: State): State => {
  return Object.assign({}, state, {
    cardName: props.cardName,
    cardNumber: props.cardNumber,
    cardExpiry: props.cardExpiry,
    cardSecurityCode: props.cardSecurityCode,
    postalCode: props.postalCode
  });
};

const validate = (
  cardName: string | null,
  cardNumber: ElementChangeResponse | null,
  cardExpiry: ElementChangeResponse | null,
  cardSecurityCode: ElementChangeResponse | null,
  postalCode: string | null,
  formatMessage: (val: any) => string
) => {
  const errors: ErrorModel = {};
  if (!(cardName && cardName.length)) {
    errors.cardNameError = LOCALES.error_required_card_name;
  }

  if (!cardNumber) {
    errors.cardNumberError = formatMessage(LOCALES.error_required_card_number);
  }

  if (!cardExpiry) {
    errors.cardExpiryError = formatMessage(LOCALES.error_required_card_expiry);
  }

  if (!cardSecurityCode) {
    errors.cardSecurityCodeError = formatMessage(LOCALES.error_required_security_code);
  }

  if (!postalCode) {
    errors.postalCodeError = formatMessage(LOCALES.error_required_postal_code);
  }
  return errors;
};

