/**
 * @module Reducers.Sports
 *
 */
import { RequestError } from 'typedefs';
import { SportsAction } from 'app/actions/sports';

export type SportsState = {
  data: string[] | null;
  error: RequestError | null;
  pending: boolean;
};

export const initState: SportsState = {
  data: null,
  error: null,
  pending: false
};

export default function reducer(state = initState, action: SportsAction) {
  switch (action.type) {
    case '@sports.fetch.pending':
      return { ...state, pending: true };
    case '@sports.fetch.success':
      return { ...state, pending: false, data: action.payload  };
    case '@sports.fetch.error':
      return { ...state, pending: false, error: action.payload };
    default:
      return state;
  }
}

