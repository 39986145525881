import React, { useEffect } from "react";
import styles from "./styles.module.scss";
import ModalLayout from "../ModalLayout/ModalLayout";
import checkmark from "../../../../public/images/checkmark-green.png";
import errorIcon from "../../../../public/images/error-x.svg";
import { cls } from "app/utils";
import { useSelector } from "react-redux";
import { RootState } from "typedefs";
import { AddToRecruitProfileProps } from "app/components/Button/AddToRecruitProfile/index.types";
import { Loading } from "app/components/Wrappers";
import { useActions } from "app/utils/hooks";
import { clearAddToRecruitPost } from "app/actions/addToRecruitPost";

const AddToRecruitPostSuccessModal = ({
  isSuccessModalOpen,
  onSuccessModalClose,
}: AddToRecruitProfileProps) => {
  const { errorAddToRecruitPost, pendingAddToRecruitPost } = useSelector(
    (state: RootState) => state.addToRecruitPost
  );

  const addToRecruitActions = useActions({
    clearAddToRecruitPost,
  });

  useEffect(() => {
    return () => {
      addToRecruitActions.clearAddToRecruitPost();
    };
  }, []);

  return (
    <ModalLayout isOpen={isSuccessModalOpen} onClose={onSuccessModalClose}>
      <Loading
        className={cls(
          {
            ["well well-nothing"]: pendingAddToRecruitPost,
          },
          styles.loading_container
        )}
        isLoading={pendingAddToRecruitPost}
        loadType="spinner"
      >
        <div className={styles.container}>
          <img
            src={errorAddToRecruitPost ? errorIcon : checkmark}
            alt="success"
            className={styles.checkMark}
          />
          {!errorAddToRecruitPost && <h1 className={styles.title}>Success!</h1>}
          <p className={styles.info}>
            {!errorAddToRecruitPost
              ? "Milestone successfully added to your Recruit profile"
              : "Something went wrong"}
          </p>

          <button
            className={cls(styles.done_button, "btn btn-primary")}
            onClick={onSuccessModalClose}
          >
            Ok
          </button>
        </div>
      </Loading>
    </ModalLayout>
  );
};

export default AddToRecruitPostSuccessModal;
