/**
 * @module Utils
 *
 */
import { AnyObject } from 'typedefs';
import { ngState } from './bridge';
import { digest } from './runloop';

/**
 * Executes a route change for angular router
 *
 * @method route
 * @param {string} destination uri
 * @param {boolean} optional - true replaces the path history or false adds to it
 */
export function route(destination: string, replace?: boolean, search?: AnyObject) {
  const [ state ] = ngState();
  if (state == null || state.app == null) {
    throw new Error("Change route called before app initialized");
  }

  const $location = state.app.get('$location');
  if (search != null) {
    Object.keys(search).forEach((key: string) => {
      const value = search[key];
      $location.search(key, value);
    });
  }

  if (replace) {
    const loc = $location.replace();
    loc.path(destination);
  } else {
    $location.path(destination);
  }
  digest();
}

type RouteOpts = {
  replace?: boolean;
  reload?: boolean;
};

export function routeNative(destination: string, options: RouteOpts) {
  options = options != null ? options : {};

  if (options.replace && window.location.replace != null) {
    window.location.replace(destination);
  } else if (!options.reload && window.location.assign != null) {
    window.location.assign(destination);
  } else {
    window.location = ((destination as any) as Location);
  }
}
