/**
 * @module Utils
 *
 */
import config from 'config/environment';
import { camelize } from 'app/utils/strings';

/**
 * returns an key value store of all the features
 *
 * @method getFeatures
 * @return {object}
 */
export function getFeatures() {
  return features;
}

/**
 * Gets the features toggles current state true/false
 *
 * the feature is on if true is returned
 * the feature is off if false is returned
 *
 * @method featureIsEnabled
 * @param {string} key - the feature toggle name
 * @param {boolean} defaultState - optional: add default state if toggle does not exist
 * @return {boolean}
 */
export function featureIsEnabled(key: string, defaultState?: boolean): boolean {
  const normailzedKey = camelize(key);
  if (features[normailzedKey] != null) {
    return features[normailzedKey];
  }
  return defaultState === true ? true : false;
}

/**
 * turn a feature flag on
 *
 * @method enableFeature
 * @param {string} key - the feature toggle name
 * @return {void}
 */
export function enableFeature(key: string) {
  toggleFeature(key, true);
}

/**
 * turn a feature flag off
 *
 * @method disableFeature
 * @param {string} key - the feature toggle name
 * @return {void}
 */
export function disableFeature(key: string) {
  toggleFeature(key, false);
}


/**
 * private setup helpers
 */

const ORDER = {
  production: 60,
  staging: 50,
  beta: 40,
  alpha: 30,
  test: 20,
  development: 10,
};

type Levels = keyof typeof ORDER | false | true;

const setFlagForENV = (level: Levels): boolean => {
  if (level === false) {
    return false;
  } else if (level === true) {
    return true;
  } else {
    const isEnabled = ORDER[level] >= ORDER[((config as any).ENVIRONMENT as keyof typeof ORDER)];
    if (isEnabled) {
      return true;
    }
    return false;
  }
};

const features: {[key: string]: boolean} = Object.assign(
  {},
  ...Object.keys(config.FEATURES).map(key => {
    return { [camelize(key)]: setFlagForENV((config.FEATURES as any)[key]) };
  })
);

function toggleFeature(key: string, value: boolean) {
  if (features[key] != null) {
    features[key] = value;
  }
}
