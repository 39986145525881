import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { route } from "app/utils/router";
import { RootState } from "typedefs";
import { useDispatch, useSelector } from "react-redux";
import {
  clearSpace,
  clearSpaceForSearch,
  hasSpacesData,
  spacePage,
} from "app/actions/space";
import { useActions } from "app/utils/hooks";
import { DemoSpaceModel } from "app/actions/DemoSpace";
import useDebounce from "app/utils/hooks/useDebounce";
import SpaceModel from "app/models/SpaceModel";
import useFetchSpaces from "./useFetchSpaces";

interface Props {
  searchTerm?: string | null;
}

const useSpacesListModel = ({ searchTerm }: Props) => {
  const dispatch = useDispatch();
  const demoSpacesData = useSelector(
    (state: RootState) => state.demoSpace.data
  );
  const selectedSmartList = useSelector(
    (state: RootState) => state.smartList.selectedListValue
  );
  const { data, pending, page, hasMore } = useSelector(
    (state: RootState) => state.space
  );

  const containerRef = useRef<HTMLDivElement | null>(null);
  const debouncedSearch = useDebounce(searchTerm, 500);
  const [spacesData, setSpacesData] = useState<SpaceModel[] | null>(null);

  const spaceActions = useActions({
    clearSpaceForSearch,
    clearSpace,
  });
  const {
    loading,
    fetchAllSpaces,
    fetchInitialSpaces,
    fetchSpacesWithPagination,
  } = useFetchSpaces({ debouncedSearch });

  const abortController = useRef<AbortController | null>(null);

  const resetPage = () => {
    dispatch(spacePage(1));
  };

  const resetSpacesData = useCallback(() => {
    setSpacesData(null);
  }, []);

  useEffect(() => {
    abortController.current = new AbortController();
    if (selectedSmartList && selectedSmartList.object !== "demo_space") {
      if (debouncedSearch && debouncedSearch.length >= 3) {
        resetPage();
        resetSpacesData();
        fetchAllSpaces(debouncedSearch).then((res: SpaceModel[]) => {
          setSpacesData(res);
        });
      } else if (
        !debouncedSearch ||
        (debouncedSearch && debouncedSearch.length === 0)
      ) {
        resetPage();
        resetSpacesData();
        fetchInitialSpaces(abortController.current).then(
          (res: SpaceModel[]) => {
            setSpacesData(res);
          }
        );
      }
    }

    if (
      selectedSmartList.object === "demo_space" &&
      demoSpacesData &&
      typeof demoSpacesData._hash === "object"
    ) {
      const demoSpaces = Object.values(demoSpacesData._hash).reduce(
        (acc: DemoSpaceModel[], item: DemoSpaceModel) => {
          if (item.space) {
            acc.push(item.space);
          }
          return acc;
        },
        []
      );
      setSpacesData(demoSpaces as SpaceModel[]);
    }

    return () => {
      spaceActions.clearSpaceForSearch();
      if (abortController.current) {
        abortController.current.abort();
      }
    };
  }, [debouncedSearch, selectedSmartList]);

  useEffect(() => {
    let observer: IntersectionObserver | null = null;

    const handleObserver = (entries: IntersectionObserverEntry[]) => {
      if (
        selectedSmartList.object !== "demo_space" &&
        hasMore &&
        !pending &&
        data &&
        !debouncedSearch
      ) {
        const entry = entries[0];
        if (entry.isIntersecting) {
          fetchSpacesWithPagination(abortController.current).then(
            (res: SpaceModel[]) => {
              setSpacesData((prev: SpaceModel[]) => [...prev, ...res]);
            }
          );
        }
      }
    };

    if (containerRef.current) {
      observer = new IntersectionObserver(handleObserver, {
        threshold: 0.1,
        rootMargin: "100px",
      });
      observer.observe(containerRef.current);
    }

    return () => {
      if (observer && containerRef.current) {
        observer.unobserve(containerRef.current);
        observer.disconnect();
      }
    };
  }, [spacesData, containerRef, page, hasMore, pending, data]);

  useMemo(() => {
    if (spacesData && spacesData.length > 0) {
      dispatch(hasSpacesData(true));
    }

    return () => {
      dispatch(hasSpacesData(false));
    };
  }, [spacesData]);

  useEffect(() => {
    return () => {
      spaceActions.clearSpace();
    };
  }, []);

  const spaceClicked = (value: string) => {
    if (value.includes("demo")) {
      route(value);
    } else {
      route(`/spaces/${value}`);
    }
  };

  return {
    spacesData,
    pending,
    loading,
    selectedSmartList,
    containerRef,
    spaceClicked,
  };
};

export default useSpacesListModel;
