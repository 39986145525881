/**
 * @module Components.Wrappers
 *
 */
import React, { PropsWithChildren } from 'react';
import { Redirect } from 'react-router-dom';
import { checkForAuth, isAuthenticated } from 'app/utils/auth';
import Styles from './styles.module.scss';

type PropsDef = PropsWithChildren<{
  ignoreReturn?: boolean;
}>;

/**
 * @class Authenticated
 *
 */
export default function Authenticated(props: PropsDef) {
  // checks for a token cookie
  // and uses it to set the auth token
  if (!isAuthenticated()) {
    checkForAuth();
  }

  // not authorized, redirect to signin page
  if (!isAuthenticated()) {
    const location = window.location;
    const lastPath = location.pathname + location.search;
    let signinPath = "/signin";
    if (!props.ignoreReturn && lastPath && lastPath.length && lastPath !== '/') {
      signinPath = signinPath + '?return_url=' + encodeURIComponent(lastPath);
    }
    return (<Redirect to={signinPath} />);
  }

  return (
    <div className={Styles.authWrapper}>{props.children}</div>
  );
}
