/**
 * @module Components.Filter
 *
 */
import React, { useState } from "react";

import styles from './styles.module.scss';
import { cls } from 'app/utils';
import { useLoc } from 'app/utils/hooks';
import SpacesSelect from './SpacesSelect';
import GroupsSelect from './GroupsSelect';
import ListsSelect from './ListsSelect';
import Search from './Search';
import LOCALES from './locale';

type PropsDef = {
  selectedCalendarDate: string;
  onSelectedCalendarDateRemove: () => void;
  onSearchSubmit: (value: string) => void;
  onReset: (value: any) => void;
  onSubmit: (value: any, objectTypes?: any) => void;
};

/**
 * @class ScheduleFilter
 *
 */
const ScheduleFilter = (props: PropsDef) => {
  const [ selectedTargets, setSelectedTargets ] = useState([]);
  const [ clearSearchInput, setClearSearchInput ] = useState(false);
  const { formatMessage } = useLoc();

  const onReset = (data: any) => {
    setSelectedTargets([]);
    setClearSearchInput(true);

    props.onReset(data);
  };

  const onSelectedCalendarDateRemove = () => {
    props.onSelectedCalendarDateRemove();
  };

  const onSearchSubmit = (value: string) => {
    setClearSearchInput(false);

    props.onSearchSubmit(value);
  };

  const onSelectedTargetRemove = (target: any) => {
    const remainedTargets = selectedTargets.filter((_target: any) => _target.id !== target.id);

    onSubmit(remainedTargets, null);
  };

  const onSubmit = (data: any, objectTypes?: any) => {
    // Refersh the list of targets for given objectTypes
    if (objectTypes) {
      // Remove all objectTypes items from already selected targets
      const restTargets = selectedTargets.filter((_target: any) => !objectTypes.includes(_target.object));
      // Add new list of targets to selected targets
      data = [...new Set([...restTargets, ...data])];
      // Update selectedTargets state
      setSelectedTargets(data);
    }

    const scheduleTargetIds = Array.from(data, (_target: any) => _target.id);

    setSelectedTargets(data);
    props.onSubmit({ scheduleTargetIds });
  };

  const formatSelecteDate = () => {
    if (!props.selectedCalendarDate) {
      return;
    }

    return moment(props.selectedCalendarDate).format("MMM DD, YYYY");
  };

  const showTargetAvatar = (target: any) => {
    if (!target) {
      return;
    }

    switch (target.object) {
      case "athlete_space":
        return(<img src={target.avatar} className={styles.spaceAvatar}/>);
      case "team":
        return(
          <span className={styles.iconWrapper}>
            <i className={cls("ico", "ico-team", styles.icon)}></i>
          </span>
        );
      case "team_group":
        return(
          <span className={styles.iconWrapper}>
            <i className={cls("ico", "ico-team", styles.icon)}></i>
          </span>
        );
      case "segment":
        return(
          <span className={styles.iconWrapper}>
            <i className={cls("ico", "ico-groups", styles.icon)}></i>
          </span>
        );
    }
  };

  const showTargetName = (target: any) => {
    if (!target) {
      return;
    }

    switch (target.object) {
      case "athlete_space":
        return(
          <span>{target.athleteName}</span>
        );
      case "team":
        return(
          <span>{target.name} ({formatMessage(LOCALES.general_channel)})</span>
        );
      case "team_group":
        return(
          <span>{target.team.name} ({target.name})</span>
        );
      case "segment":
        return(
          <span>{target.name}</span>
        );
    }
  };

  return (
    <div>
      <div className={styles.filterOptionsContainer}>
        <button className={cls(styles.filterButton)} onClick={onReset}>
          <i className={cls("ico", "ico-time", styles.icon)}></i>
          <span className={styles.buttonCaption}>
            {formatMessage(LOCALES.all)}
          </span>
        </button>

        <SpacesSelect selectedSpaces={selectedTargets} onSubmit={onSubmit} />
        <GroupsSelect selectedTeams={selectedTargets} onSubmit={onSubmit} />
        <ListsSelect selectedLists={selectedTargets} onSubmit={onSubmit} />

        <Search clearSearchInput={clearSearchInput} onSearchSubmit={onSearchSubmit}/>
      </div>

      <div className={styles.filterSelectedContainer}>
        <div className={props.selectedCalendarDate ? styles.filterSelectedItem : styles.hidden}>
          <span className={styles.iconTimeWrapper}>
            <i className={cls("ico", "ico-time", styles.icon)}></i>
          </span>
          <span className={cls(styles.timeCaption)}>{formatSelecteDate()}</span>
          <i className={cls("ico", "ico-x", styles.iconClose)} onClick={onSelectedCalendarDateRemove}></i>
        </div>

        {selectedTargets.map(target => (
          <div className={styles.filterSelectedItem} key={target.id}>
            {showTargetAvatar(target)}

            <span className={cls(styles.caption)}>
              {showTargetName(target)}
            </span>

            <i className={cls("ico", "ico-x", styles.iconClose)} onClick={() => onSelectedTargetRemove(target)}></i>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ScheduleFilter;
