/**
 * @module Components.Subscription
 *
 */
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
// import { MessageDescriptor } from 'react-intl';
import { useLoc, useActions } from 'app/utils/hooks';
import { route } from 'app/utils/router';
import { Token } from 'app/utils/stripe';
import { RootState } from 'app/reducers';
import { fetchSelf } from 'app/actions/user';
import { updatePaymentInfo } from 'app/actions/payment-info';
import StripeForm from 'app/components/StripeForm';
import { ErrorMessage } from 'app/components/helpers';
import styles from './styles.module.scss';
import LOCALES from './locale';

function BillingInfoEdit() {
  const { formatMessage } = useLoc();
  const paymentInfo = useSelector((state: RootState) => state.paymentInfo);
  const actions = useActions({ fetchSelf, updatePaymentInfo });

  useEffect(() => (actions.fetchSelf(), undefined), [ actions.fetchSelf ]);

  const _updatePaymentInfo = (token: Token): void => {
    actions.updatePaymentInfo(token.id).then(() => leaveEdit());
  };

  const leaveEdit = () => {
    route('/account/billing_info');
  };

  return (
    <div className={styles.billingInfoEdit}>
      <StripeForm
        disabled={paymentInfo.pending}
        submitTitle={formatMessage(LOCALES.edit_submit_btn)}
        onToken={_updatePaymentInfo}
        onCancel={leaveEdit}
      />

      <ErrorMessage message={paymentInfo.error} />
    </div>
  );
}

export default BillingInfoEdit;
