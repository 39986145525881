/** @module components/paywalls */
import React from 'react';
import { defineMessages } from 'react-intl';
import { cls } from 'app/utils';
import { useLoc } from 'app/utils/hooks';
import { Loc } from 'app/components/helpers';
import ModalWrapper from 'app/components/modals/ModalWrapper';
import Support from 'app/components/paywalls/Support';
import spacelimit_icon_png from 'app/images/paywall-body-icon-space-limit.png';
import styles from './styles.module.scss';

type PropsDef = {
  show?: boolean;
  onClose?: () => void;
};

export default function SpacesLimit(props: PropsDef) {
  const { formatMessage } = useLoc();

  const handleClick = () => {
    (window as any).location = '/upgrade?return_url=/spaces';
  };

  const handleClose = () => {
    if (props.onClose) {
      props.onClose();
    }
  };

  return (
    <ModalWrapper
      isOpen={props.show}
      onRequestClose={handleClose}
      title={formatMessage(locale.header_title)}
    >
      <div className={cls(styles.spacesLimitModal)}>
        <div className={styles.upper}>
          <div className={styles.image}>
            <img src={spacelimit_icon_png} alt="Space Limit Icon" />
          </div>

          <h5>
            <Loc value={locale.title} />
          </h5>

          <p className="text-muted">
            <Loc value={locale.subtitle} html={true} />
          </p>
        </div>

        <div className={styles.lower}>
          <div className={styles.section}>
            <h3>
              <Loc value={locale.footer_title} />
            </h3>

            <p className="text-muted">
              <Loc value={locale.footer_text} />
            </p>

            <button className="btn btn-primary" onClick={handleClick}>
              <Loc value={locale.footer_button} />
            </button>
          </div>

          <div className={styles.section}>
            <Support className={styles.lower} feature="Space Limit" />
          </div>
        </div>

      </div>
    </ModalWrapper>
  );
}

const locale = defineMessages({
  header_title: { id: 'spaces_limit.title.header', defaultMessage: "Upgrade for More Spaces" },

  title: { id: 'spaces_limit.title.main', defaultMessage: "Free Space Limit Reached" },
  subtitle: {
    id: 'spaces_limit.subtitle.main',
    defaultMessage: 'Upgrade to <a href="https://coachnow.io/pricing" target="_blank">CoachNow+</a> for unlimited Spaces!'
  },

  footer_title: { id: 'spaces_limit.title.footer', defaultMessage: "Why Upgrade?" },
  footer_text: {
    id: 'spaces_limit.text.footer',
    defaultMessage: "Transform your coaching with unlimited Spaces, media posting, Library storage, Lists, and Templates."
  },
  footer_button: { id: 'spaces_limit.button.footer', defaultMessage: "Upgrade Now" },

  questions_title: { id: 'spaces_limit.questions.title', defaultMessage: 'Have Questions?' },
  questions_body: { id: 'spaces_limit.questions.body', defaultMessage: 'Want to see how it works, extend your trial time, or have another question?' },
  questions_button: { id: 'spaces_limit.questions.button', defaultMessage: 'Start Chat' },
  demo_button: { id: 'spaces_limit.demo.button', defaultMessage: 'Schedule Call' },
});
