/** @module reducers */
import { RequestError } from 'typedefs';
import { TeamGroupActions, ErrObj } from 'app/actions/team-group';
import { TeamGroupModel, TeamGroupMemberModel } from 'app/models';

export type TeamGroupState = {
  data?: TeamGroupModel[];
  error?: RequestError;
  pending?: boolean;

  addMembersData?: TeamGroupMemberModel[];
  addMembersError?: ErrObj[];
  addMembersPending?: boolean;
};

const initialState: TeamGroupState = {
  data: null,
  error: null,
  pending: false,

  addMembersData: null,
  addMembersError: null,
  addMembersPending: false,
};

export default function teamGroupReducer(state = initialState, action: TeamGroupActions): TeamGroupState {
  switch (action.type) {
    case '@team-group.fetch.success':
      return { ...state, data: action.payload, pending: false };
    case '@team-group.fetch.pending':
      return { ...state, error: null, pending: true };
    case '@team-group.fetch.error':
      return { ...state, error: action.payload, pending: false };

    case '@team-group-member.post.done':
      return { ...state, addMembersData: action.payload.success, addMembersError: action.payload.error, addMembersPending: false };
    case '@team-group-member.post.pending':
      return { ...state, addMembersData: null, addMembersError: null, addMembersPending: true };

    default:
      return state;
  }
}
