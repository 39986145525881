/**
 * @module Components.SignUp
 *
 */
import { isEmpty } from 'app/utils';
import { MessageDescriptor } from 'react-intl';
import { createValidator, IValidator } from 'app/utils/validate';
import { UserModel } from 'app/models';
import LOCALES from './locale';

type FieldMap = {
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  EMAIL: 'email',
  PASSWORD: 'password',
  AGE_CONSENT: 'ageConsent',
  TOS_CONSENT: 'tosConsent',
  KIND: 'kind',
};

export const field_map: FieldMap = {
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  EMAIL: 'email',
  PASSWORD: 'password',
  AGE_CONSENT: 'ageConsent',
  TOS_CONSENT: 'tosConsent',
  KIND: 'kind',
};

type FieldState = {
  firstName: string,
  lastName: string,
  email: string,
  password: string,
  ageConsent: string,
  tosConsent: string,
  kind: string,
};

type FieldResponse = {
  firstName?: IValidator,
  lastName?: IValidator,
  email?: IValidator,
  password?: IValidator,
  ageConsent?: IValidator,
  tosConsent?: IValidator,
  kind?: IValidator,
};

const email_regex = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;

const INVALID_MAP: FieldResponse = {
  [field_map.EMAIL]: createValidator(LOCALES.error_invalid_email, (val: string) => email_regex.test(val)),
  [field_map.PASSWORD]: createValidator(LOCALES.error_invalid_password, (val: string) => /^.{6,128}$/.test(val))
};

const requiredTest = () => ((val: string) => !isEmpty(val));
const REQUIRED_MAP: FieldResponse = {
  [field_map.FIRST_NAME]: createValidator(LOCALES.error_required_first_name, requiredTest()),
  [field_map.LAST_NAME]: createValidator(LOCALES.error_required_last_name, requiredTest()),
  [field_map.EMAIL]: createValidator(LOCALES.error_required_email, requiredTest()),
  [field_map.PASSWORD]: createValidator(LOCALES.error_required_password, requiredTest()),
  [field_map.TOS_CONSENT]: createValidator(LOCALES.error_required_tos_consent, (val: boolean) => val === true),
  [field_map.KIND]: createValidator(LOCALES.error_required_kind, (val: string) => val !== null),
};

const validate = (key: keyof FieldState, value: string | boolean): string | MessageDescriptor | null => {
  // validate required params
  if (REQUIRED_MAP.hasOwnProperty(key)) {
    const context = REQUIRED_MAP[key];

    if (context != null && !context.test(value)) {
      return context.message;
    }
  }

  // validate invalid params
  if (INVALID_MAP.hasOwnProperty(key)) {
    const context = INVALID_MAP[key];
    if (context != null && !context.test(value)) {
      return context.message;
    }
  }
  return null;
};

export const validateProperty = (key: keyof FieldState, value?: string | boolean) => {
  let isValid = true;
  const result = validate(key, value);

  if (!isEmpty(result)) {
    isValid = false;
  }
  return { isValid, error: result };
};

/**
 * Validates a user object params to send to an api
 *
 * @method validateUser
 * @param user {object} key: val pair representing a user to be saved
 * @returns {object}
 */
export const validateUser = (user: Partial<UserModel>, skipEmailValidation: boolean) => {
  // creates an error list of objects like { fieldName: "error string" }
  const list = Object.values(field_map)
    .map(key => ({ [key]: validate(key, user[key]) }))
    .filter(item => Object.values(item)[0] !== undefined);

  const errors = Object.assign({}, ...list);

  if (skipEmailValidation) {
    errors[field_map.EMAIL] = null;
  }

  // add password match error if password does not already have an error
  if (isEmpty(errors[field_map.PASSWORD]) && user.password !== user.passwordVerify) {
    errors[field_map.PASSWORD] = LOCALES.error_mismatch_password;
  }

  const length = Object.keys(errors).filter(key => errors[key] != null).length;
  return { errors, isValid: !(length > 0), length };
};
