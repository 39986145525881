/**
 * @module Components.Button
 *
 */
import React, { useState, useEffect } from "react";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styles from './styles.module.scss';
import { cls } from 'app/utils';
import { useLoc } from 'app/utils/hooks';
import { FormattedHTMLMessage } from 'react-intl';
import LOCALES from './locale';
import moment from 'moment';

type PropsDef = {
  value?: string;
  onClick?: (value: any) => void;
  onChange: (value: any) => void;
};

/**
 * @class ScheduledAtPicker
 *
 */
const ScheduledAtPicker = (props: PropsDef) => {
  const [scheduledAt, setScheduledAt] = useState(null);
  const { formatMessage } = useLoc();

  useEffect(() => {
    if (props.value !== null &&  props.value !== undefined && props.value !== '') {
      setScheduledAt(new Date(props.value));
    }
  }, [props.value]);

  const onScheduledAtChange = (data: string) => {
    setScheduledAt(data);
    props.onChange(data);
  };

  const ScheduledAtInput = React.forwardRef((props: any, ref: any) => {
    if (props.value === '') {
      return (
        <div className={cls("btn btn-outline-secondary", styles.scheduleAtButton)} onClick={props.onClick} ref={ref}>
          <div><i className={cls("ico ico-time", styles.scheduleIcon)}/>&nbsp;&nbsp;{formatMessage(LOCALES.schedule)}&nbsp;</div>
        </div>
      );
    } else {
      const value = moment(Date.parse(props.value));
      const date = value.format("MMM D, YYYY");
      const time = value.format("hh:mm a");

      return (
        <div className={cls("btn btn-outline-secondary", styles.scheduleAtButton)} onClick={props.onClick} ref={ref}>
          <div className={styles.scheduledButtonCaption}>
            <i className={cls("ico ico-time")}/>&nbsp;&nbsp;<FormattedHTMLMessage id={LOCALES.publish_at.id} defaultMessage={formatMessage(LOCALES.publish_at, { date, time })} />
          </div>
        </div>
      );
    }
  });

  return (
    <div>
      <DatePicker
        placeholderText="Schedule"
        selected={scheduledAt}
        onChange={onScheduledAtChange}
        showTimeSelect
        dateFormat="MMM d, yyyy h:mm aa"
        customInput={<ScheduledAtInput/>}
        minDate={moment().toDate()}
      />
    </div>
  );
};

export default ScheduledAtPicker;
