/** @module library */

type CalendlyData = {
  url?: string;
  parentElement?: HTMLDivElement;
  prefill?: {
    name?: string;
    email?: string;
    customAnswers?: { [key: string]: any };
  };
  utm?: {
    utmCampaign?: string;
    utmSource?: string;
    utmMedium?: string;
    utmContent?: string;
    utmTerm?: string;
  }
};

interface CalendlyWidget {
  initBadgeWidget: (data: CalendlyData) => void;
  destroyBadgeWidget: () => void;
  initInlineWidget: (data: CalendlyData) => void;
  initPopupWidget: (data: CalendlyData) => void;
  showPopupWidget: () => void;
  closePopupWidget: () => void;
}

type CallbackFunc = () => void;

/**
 * Calendly Widget Loader
 *
 * @method initialize
 * @return {object} - Calendly
 */
export class Calendly {
  private _calendly: CalendlyWidget = null;
  private _onReady: (() => void) = null;
  private _hasReadyFired: boolean = false;
  private _eventCallbacks: CallbackFunc[] = [];

  onReady(calendly: CalendlyWidget) {
    this._calendly = calendly;
    this._hasReadyFired = true;

    if (typeof this._onReady === 'function') {
      this._onReady();
    }
  }

  get eventCallbacks() {
    return this._eventCallbacks;
  }

  registerOnReady(cb: CallbackFunc) {
    if (this._hasReadyFired) {
      cb();
    } else {
      this._onReady = cb;
    }
  }

  onEventScheduled(cb: CallbackFunc) {
    this._eventCallbacks.push(cb);
  }

  clearEventScheduled(cb: CallbackFunc) {
    const idx = this._eventCallbacks.indexOf(cb);
    if (idx !== -1) {
      this._eventCallbacks = []
        .concat(this._eventCallbacks.slice(0, idx))
        .concat(this._eventCallbacks.slice(idx+1));
    }
  }

  initBadgeWidget(data: CalendlyData) {
    this.throwIfNotReady();
    return this._calendly.initBadgeWidget(data);
  }

  destroyBadgeWidget() {
    this.throwIfNotReady();
    return this._calendly.destroyBadgeWidget();
  }

  initInlineWidget(data: CalendlyData) {
    this.throwIfNotReady();
    return this._calendly.initInlineWidget(data);
  }

  initPopupWidget(data: CalendlyData) {
    this.throwIfNotReady();
    return this._calendly.initPopupWidget(data);
  }

  showPopupWidget() {
    this.throwIfNotReady();
    return this._calendly.showPopupWidget();
  }

  closePopupWidget() {
    this.throwIfNotReady();
    return this._calendly.closePopupWidget();
  }

  throwIfNotReady() {
    if (!this._hasReadyFired) {
      throw new Error("Calendly has not initialized yet. Wait for the onReady callback to fire");
    }
  }
}

/**
 * Create a new calendly class instance
 *
 */
let calendly: Calendly = null;

/**
 * Load widget script
 *
 */
function loadScript() {
  if (calendly == null) {
    calendly = new Calendly();

    // Create an async script element based on your key.
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = 'https://assets.calendly.com/assets/external/widget.js';

    script.onload = () => {
      calendly.onReady((window as any).Calendly);
    };

    // Insert our script next to the first script element.
    const first = document.getElementsByTagName('script')[0];
    first.parentNode.insertBefore(script, first);

    window.addEventListener('message', (e: MessageEvent) => {
      if (e.data && e.data.event === 'calendly.event_scheduled') {
        calendly.eventCallbacks.forEach((cb: CallbackFunc) => cb());
      }
    });
  }
}

export default function getCalendly() {
  // call load
  loadScript();

  // return the calendly class instance
  return calendly;
}

