
/**
 * @module Components.StripeForm
 *
 */
import React from 'react';
import StripeElementContainer, { PropsDef } from './StripeElementContainer';
import { CardExpiryElement } from 'react-stripe-elements';
import LOCALES from './locale';

const CardExpiry = (props: PropsDef) => {
  return (
    <StripeElementContainer
      elementType={CardExpiryElement}
      placeholder={LOCALES.card_expiry}
      {...props}
    />
  );
};

export default CardExpiry;
