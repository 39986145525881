import { PostingFormAction } from "app/actions/postingForm";
import PostModel from "app/models/PostModel";
import { RequestError } from "typedefs";

export type PostState = {
  postData: any | null | PostModel;
  error: RequestError | null;
  displayError: string | null;
  pending: boolean;
  postLocation: string | string[] | null;
};

const initialState: PostState = {
  postData: null,
  error: null,
  displayError: null,
  pending: false,
  postLocation: null,
};

export default function copyReducer(
  state = initialState,
  action: PostingFormAction,
) {
  switch (action.type) {
    case "@post.fetch.pending":
      return { ...state, pending: true, postData: null };
    case "@post.fetch.error":
      return {
        ...state,
        pending: false,
        postData: null,
        error: action.payload,
      };
    case "@post.fetch.displayError":
      return {
        ...state,
        pending: false,
        displayError: action.payload,
      };
    case "@post.fetch.success":
      return {
        ...state,
        pending: false,
        postData: action.payload,
        error: null,
      };
    case "@post.clear":
      return {
        ...state,
        postData: null,
        error: null,
        displayError: null,
        postLocation: null,
        pending: false,
      };
    default:
      return state;
  }
}
