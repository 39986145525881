import { activateReceipt, deactivateReceipt } from 'app/actions/views';
import React from 'react';
import styles from "../styles.module.scss";


interface Props {
  defaultValue: boolean | string;
  postId: string;
  userId: string;
}


const NotifyBtn = ({ defaultValue, userId, postId }: Props) => {
  const [notificationIsOn, setNotificationIsOn] = React.useState(defaultValue);

  const notificationHandler = async () => {
    setNotificationIsOn(!notificationIsOn);
    if(!notificationIsOn) {
      await activateReceipt(userId, postId);
    } else {
      if(typeof defaultValue === "string") {
        await deactivateReceipt(defaultValue);
      }
    }
  };

  return (
    <button
      className={
        notificationIsOn
          ? styles.notify_button_on
          : styles.notify_button
      }
      onClick={notificationHandler}
    >
      {notificationIsOn ? "Notification on" : "Notify me"}
    </button>
  );
};

export default NotifyBtn;
