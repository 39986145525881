/** @module utils */
import { Model } from 'typedefs';
import { isArray } from 'app/utils/array';
import { underscore } from 'app/utils/strings';

interface ModelPermission extends Model {
  planPermissions: string[];
}

export default function hasPermission<T extends ModelPermission>(model: T, type: string) {
  if (model != null && isArray(model.planPermissions) && model.planPermissions.indexOf(underscore(type)) !== -1) {
    return true;
  }
  return false;
}
