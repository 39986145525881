import { AddToRecruitPostAction } from "app/actions/addToRecruitPost";
import { RequestError } from "typedefs";

export type AddToRecruitPostState = {
  pendingAddToRecruitPost: boolean;
  errorAddToRecruitPost: RequestError | null;
  success: boolean;
};

const initialState: AddToRecruitPostState = {
  errorAddToRecruitPost: null,
  pendingAddToRecruitPost: false,
  success: false,
};

export default function addToRecruitPostReducer(
  state = initialState,
  action: AddToRecruitPostAction
) {
  switch (action.type) {
    case "@addToRecruitPost.create.pending":
      return {
        ...state,
        pendingAddToRecruitPost: true,
        errorAddToRecruitPost: null,
      };
    case "@addToRecruitPost.create.error":
      return {
        ...state,
        success: false,
        pendingAddToRecruitPost: false,
        errorAddToRecruitPost: action.payload,
      };
    case "@addToRecruitPost.create.success":
      return {
        ...state,
        success: action.payload,
        errorAddToRecruitPost: null,
        pendingAddToRecruitPost: false,
      };
    case "@addToRecruitPost.clear":
      return {
        ...state,
        success: false,
        errorAddToRecruitPost: null,
        pendingAddToRecruitPost: false,
      };
    default:
      return state;
  }
}
