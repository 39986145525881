/**
 * @module Utils
 *
 */

/**
 * Merges a with b if b is a matching type
 * with a otherwise return a
 *
 * @public
 * @method mergeTypes
 * @param {any} a
 * @param {any} b
 * @return {a&b | b}
 */
export default function mergeTypes(a: any, b: any) {
  if (a === null || a === undefined) { return b; } // allways return b when a is null or undefined
  if (b === null) { return b; } // allow null to override any value of a

  if (typeOf(a) !== typeOf(b)) { // types do not match so return a
    return a;
  }

  if (typeOf(b) === 'array') { // merge array
    return [ ...a, ...b ];
  } else if (typeOf(b) === 'object') { // merge objects
    return { ...a, ...b };
  } else { // do not merge string, number or boolean
    return b;
  }
}

function typeOf(value: any): string {
  if (value === null) {
    return 'null';
  } else if (value === undefined) {
    return 'undefined';
  } else if (Array.isArray(value)) {
    return 'array';
  } else {
    return (typeof value);
  }
}
