/**
 * @module Services
 *
 */
import { createService } from 'app/utils/service';
import AccountService from './account';
import AnalyticsService from './analytics';
import AuthService from './auth';
import FacebookService from './facebook';
import PhoneVerificationService from './phone_verification';
import ScheduledPostsService from './scheduled_posts';
import StripeService from './stripe';

export {
  AccountService,
  AnalyticsService,
  AuthService,
  FacebookService,
  PhoneVerificationService,
  ScheduledPostsService,
  StripeService
};

const rootServices = {
  AccountService,
  AnalyticsService,
  AuthService,
  FacebookService,
  PhoneVerificationService,
  StripeService
};

export default rootServices;

/**
 * Initialize services here if they need
 * to be initialize on app startup
 *
 */
if (module.hot) {
  const hotReload = function(req) {
    const Service = req().default;
    const ServiceClass = Service.__original;
    if (window.__SERVICES__.getService(ServiceClass) != null) {
      window.__SERVICES__.deleteService(ServiceClass);
    }
    return createService(ServiceClass, true);
  };

  /* eslint-disable */
  module.hot.accept('./account.js', () => hotReload(() => require('./account.js')));
  module.hot.accept('./analytics.ts', () => hotReload(() => require('./analytics.ts')));
  module.hot.accept('./auth.js', () => hotReload(() => require('./auth.js')));
  module.hot.accept('./stripe.js', () => hotReload(() => require('./stripe.js')));
  module.hot.accept('./facebook.js', () => hotReload(() => require('./facebook.js')));
  module.hot.accept('./phone_verification.js', () => hotReload(() => require('./phone_verification.js')));
  /* eslint-enable */
}
