import React from "react";
import styles from "./styles.module.scss";
import ModalLayout from "../ModalLayout/ModalLayout";
import { cls } from "app/utils";
import smartListsImg from "../../../../public/images/smart-list/smart_lists_modal.png";
import paywallModalPro from "app/utils/paywall";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const SmartListsUpgradeModal = ({ isOpen, onClose }: Props) => {
  const onUpgrade = () => {
    onClose();
    paywallModalPro();
  };

  return (
    <ModalLayout isOpen={isOpen} onClose={onClose}>
      <div className={styles.container}>
        <div className={styles.content_wrapper}>
          <h1>Smart lists - Available for CoachNow PRO</h1>
          <p>
            Smart lists automatically update in real time, based on the behavior
            of your athletes.
            <br />
            <br />
            Gain insight into which athletes haven&apos;t been engaged, and
            quickly bulk message them all at once to bring them back online.
          </p>
          <button
            className={cls("btn btn-primary", styles.button)}
            onClick={onUpgrade}
          >
            Upgrade Now
          </button>
        </div>
        <img src={smartListsImg} alt="smart lists" />
      </div>
    </ModalLayout>
  );
};

export default SmartListsUpgradeModal;
