/** @module utils */
import { featureIsEnabled } from './features';

/**
 * Opens the intercom message modal
 *
 * @method openIntercom
 * @return {void}
 */
export function openIntercom() {
  if (featureIsEnabled('analyticsTool')) {
    if ((window as any).Intercom != null) {
      (window as any).Intercom('show');
    }
  }
}

/**
 * Closes the intercom message modal
 *
 * @method closeIntercom
 * @return {void}
 */
export function closeIntercom() {
  if (featureIsEnabled('analyticsTool')) {
    if ((window as any).Intercom != null) {
      (window as any).Intercom('hide');
    }
  }
}
