import React, { useState, useRef, useEffect, useCallback } from "react";
import { usePopper } from "react-popper";
import popperStyles from "./styles.module.scss";

type PopperProps = {
  content: string;
  placement?: "auto" | "top" | "right" | "bottom" | "left";
};

const Popper: React.FC<PopperProps> = ({
  content,
  placement = "bottom",
}: PopperProps) => {
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  );
  const [referenceElement, setReferenceElement] =
    useState<HTMLDivElement | null>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement,
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 10],
        },
      },
    ],
  });

  const clickRef = useRef<HTMLDivElement>(null);

  const handleClick = useCallback(
    (event: MouseEvent): void => {
      if (
        clickRef.current &&
        !clickRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    },
    [setIsOpen]
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);
    document.addEventListener("touchstart", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
      document.removeEventListener("touchstart", handleClick);
    };
  }, [handleClick]);

  const handleToggle = (): void => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <div
        onClick={handleToggle}
        ref={setReferenceElement}
        className="icon-question-sign"
      />
      {isOpen && (
        <div
          ref={setPopperElement}
          style={{
            ...styles.popper,
            zIndex: 1,
            transform: `translate3d(${
              styles.popper.transform
                ? styles.popper.transform.split(",")[0]
                : "0"
            }, ${
              styles.popper.transform
                ? styles.popper.transform.split(",")[1]
                : "0"
            }, 0)`,
            top: `${attributes.popper && attributes.popper.top}px`,
            left: `${attributes.popper && attributes.popper.left}px`,
          }}
          {...attributes.popper}
        >
          <div ref={clickRef} className={popperStyles.container}>
            {content}
          </div>
          <div className={popperStyles.arrow} style={styles.arrow} />
        </div>
      )}
    </>
  );
};

export default Popper;
