/**
 * @module Utils
 *
 */
import { ngState } from './bridge';

export function run(cb: () => any): void {
  const scope = getScope();
  let res: any;
  if (scope != null && !scope.$$phase) {
    res = scope.$evalAsync(cb);
  } else {
    res = cb();
  }
  return res;
}

export function digest(cb?: () => any): any {
  cb = cb || (() => null);
  const scope = getScope();

  const result = cb();
  if (scope != null && !scope.$$phase) {
    scope.$apply();
  }
  return result;
}

const getScope = () => {
  let scope = null;

  const [ state ] = ngState();
  if (state.root != null) {
    scope = state.root;
  } else if ('__APP__' in window) {
    scope = (window as any).__APP__.app.get('$rootScope');
  }

  return scope;
};

