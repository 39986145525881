/**
 * @module Components.Inputs
 *
 */
import React, { PropsWithChildren } from 'react';
import { RequestError } from 'typedefs';
import { Loc } from 'app/components/helpers';
import { cls } from 'app/utils';
import styles from './styles.module.scss';

type PropsDef = PropsWithChildren<{
  className?: string,
  error?: RequestError | null,
}>;

/**
 * @class FormItem
 *
 */
const FormItem = (props: PropsDef) => {
  let errorString = props.error || null;
  if (errorString instanceof Error) {
    errorString = errorString.message;
  }

  return (
    <div className={cls(styles.formItem, props.className || '', props.error ? 'error' : '')}>
      {props.children}
      <div className={styles.invalid}>
        <Loc value={errorString} />
      </div>
    </div>
  );
};

export default FormItem;
