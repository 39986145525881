/**
 * @module Components.Routes
 *
 */
import React, { FormEvent, useState } from 'react';
import { FormattedMessage, MessageDescriptor } from 'react-intl';
import { useLoc } from 'app/utils/hooks';
import { cls } from 'app/utils';
import { Link } from 'react-router-dom';
import { fetchPost } from 'app/utils/request';
import { Wrapper } from 'app/components/Onboarding';
import { Unauthenticated } from 'app/components/Wrappers';
import FormItem from 'app/components/inputs/FormItem';
import styles from './styles.module.scss';
import LOCALES from './locale';

interface IProps {
  expired?: boolean;
}

interface IState {
  resetError: MessageDescriptor | null;
  successPage: boolean;
  inputVal: string;
  pending: boolean;
}

interface FormElements extends HTMLCollection {
  resetPassword: HTMLInputElement;
}

const initState: IState = {
  resetError: null,
  successPage: false,
  inputVal: '',
  pending: false
};

/**
 * @class Reset
 *
 */
function Reset(props: IProps) {
  const { formatMessage } = useLoc();
  const [ state, setState ] = useState(initState);

  const title = props.expired ? LOCALES.title_expired : LOCALES.title;
  const subtitle = props.expired ? LOCALES.subtitle_expired : LOCALES.subtitle;
  const resetButton = props.expired ? LOCALES.reset_button_expired : LOCALES.reset_button;

  const placeholderText = formatMessage(LOCALES.placeholder);

  const form = (
    <div className={cls(styles.mainWrapper, props.expired ? styles.expired : '')}>
      <h1 className={styles.title}><FormattedMessage {...title} /></h1>
      <p className={styles.subtitle}><FormattedMessage {...subtitle} /></p>

      <form noValidate={true} onSubmit={(e) => sendReset(e, state, setState)} target="_self">
        <FormItem error={state.resetError}>
          <input name="resetPassword" type="text" placeholder={placeholderText} />
        </FormItem>

        <FormItem>
          <button className="btn btn-primary full" disabled={state.pending}>
            <FormattedMessage {...resetButton} />
          </button>
        </FormItem>
      </form>

      <FormItem className={styles.link}>
        <Link to="/signin"><FormattedMessage {...LOCALES.login_link} /></Link>
      </FormItem>
    </div>
  );

  const sent = (
    <div className={styles.mainWrapper}>
      <h1 className={styles.sentTitle}><FormattedMessage {...LOCALES.sent_title} /></h1>
      <p className={styles.subtitle}><FormattedMessage {...LOCALES.sent_subtitle} /></p>

      <FormItem className={styles.link}>
        <a onClick={() => returnToReset(state, setState)}><FormattedMessage {...LOCALES.reset_link} /></a>
      </FormItem>

      <FormItem className={styles.link}>
        <Link to="/signin"><FormattedMessage {...LOCALES.login_link_too} /></Link>
      </FormItem>
    </div>
  );

  let page = form;
  if (state.successPage) {
    page = sent;
  }

  return (
    <Unauthenticated>
      <Wrapper>{page}</Wrapper>
    </Unauthenticated>
  );
}

export default Reset;


const sendReset = (e: FormEvent<HTMLFormElement>, state: IState, setState: (s: IState) => void): void => {
  e.preventDefault();

  const els = (e.currentTarget.elements as FormElements);
  const email = els.resetPassword.value.trim();

  if (!(email && email.length)) {
    // missing email error
    setState({ ...state, pending: false, resetError: LOCALES.missing_email });
    return;
  }

  // reset errors
  state = { ...state, resetError: null, pending: true };
  setState(state);

  fetchPost('account/password', { email }, { version: 1 })
    .then(() => setState({ ...state, pending: false, resetError: null, successPage: true }))
    .catch(err => setState({ ...state, pending: false, resetError: handleError(err) }));
};

const returnToReset = (state: IState, setState: (s: IState) => void): void => {
  setState({ ...state, pending: false, successPage: false });
};

const handleError = (err: any): MessageDescriptor => {
  if (err && err.errors) {
    if (err.errors[0] === 'email_not_found') {
      return LOCALES.email_not_found;
    }
  }
  return LOCALES.unknown_error;
};
