import React, { useCallback, useEffect, useState } from "react";
import styles from "./styles.module.scss";
import PagePanel from "app/components/PagePanel";
import Button from "app/components/Button/ButtonVariant";
import { useActions, useSelector } from "app/utils/hooks";
import {
  fetchMediaById,
  deleteMedia,
  editMedia,
  attachedData,
} from "app/actions/media-library-data";
import { RootState } from "typedefs";
import { cls } from "app/utils";
import VideoPlayer from "app/components/VideoPlayer";
import { TagSelect } from "app/components/inputs/SelectBox";
import TagModel from "app/models/TagModel";
import AudioPlayer from "app/components/AudioPlayer";
import { useDispatch } from "react-redux";
import SegmentsLayout from "app/components/Layouts/SegmentsLayout";
import { route } from "app/utils/router";

interface Props {
  resourceId: string;
}

const EditResource = ({ resourceId }: Props) => {
  const dispatch = useDispatch();
  const resourceActions = useActions({
    fetchMediaById,
    deleteMedia,
    editMedia,
  });
  const mediaData = useSelector((state: RootState) => state.mediaLibraryData);
  const [tags, selectedTags] = useState<string[]>([]);

  useEffect(() => {
    resourceActions.fetchMediaById(resourceId);

    return () => {
      dispatch(attachedData(null));
    };
  }, []);

  const onTagsChange = useCallback(
    (tags) => {
      const tagNames = tags.map((tag: TagModel) => tag.name);

      selectedTags(tagNames);
    },
    [tags],
  );

  const onSaveChanges = () => {
    if (mediaData) {
      resourceActions
        .editMedia(mediaData.attachment.id, {
          ...mediaData.attachment,
          tags,
        })
        .then(() => (window.location.pathname = "/resources"));
    }
  };

  const handleDeleteMedia = useCallback(() => {
    if (window.confirm("Are you sure you want to delete this media file?")) {
      resourceActions
        .deleteMedia(resourceId)
        // .then(() => (window.location.pathname = "/resources"));
        .finally(() => route("/resources"));
    } else {
      return null;
    }
  }, []);

  const getButtonTitle = () => {
    if (window.innerWidth <= 768) {
      return "Create post";
    } else {
      return "Create post with media";
    }
  };

  const getDeleteButtonTitle = () => {
    if (window.innerWidth <= 768) {
      return "";
    } else {
      return "Delete";
    }
  };

  const headerComponent = () => {
    return (
      <span className={styles.header_buttons}>
        <a href={`/resources/${resourceId}/post`}>
          <Button
            icon={<i className="icon-plus icon-white" />}
            childStyles={cls(styles.btn, styles.create_post_btn)}
            title={getButtonTitle()}
            buttonType="primary"
          />
        </a>
        <Button
          title={getDeleteButtonTitle()}
          icon={<i className="icon-trash icon-white" />}
          childStyles={styles.btn}
          buttonType="danger"
          onClickFunc={handleDeleteMedia}
        />
      </span>
    );
  };

  return (
    <SegmentsLayout
      isLoading={mediaData.pending || !mediaData.attachment}
      displayNoResult={!resourceId}
    >
      <div className={styles.container}>
        <PagePanel title="Edit Media" headerChildren={headerComponent()} />

        <div className={styles.content_wrapper}>
          <Media />
          <TagSelect
            defaultValue={
              mediaData && mediaData.attachment && mediaData.attachment.tags
            }
            onChange={onTagsChange}
          />
          <span className={styles.header_buttons}>
            <a href="/resources">
              <Button
                title={"Cancel"}
                buttonType="secondary-outline"
                buttonStyles={styles.control_btn}
              />
            </a>
            <Button
              title={"Save changes"}
              buttonType="primary"
              onClickFunc={onSaveChanges}
            />
          </span>
        </div>
      </div>
    </SegmentsLayout>
  );
};

export default EditResource;

export const Media = () => {
  const mediaData = useSelector(
    (state: RootState) => state.mediaLibraryData.attachment,
  );
  const icons: { [key: string]: any } = {
    xlxx: "datafile-icon-xls",
    xlsx: "datafile-icon-xls",
    doc: "datafile-icon-doc",
    docx: "datafile-icon-doc",
    ppt: "datafile-icon-ppt",
    pptx: "datafile-icon-ppt",
    pdf: "datafile-icon-pdf",
  };
  if ((mediaData && mediaData.type === "image") || mediaData.type === "video") {
    return (
      <div className={styles.preview_container}>
        {mediaData.type === "image" && (
          <a
            href={mediaData.originalUrl ? mediaData.originalUrl : ""}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={mediaData.originalUrl} alt="media" />
          </a>
        )}
        {mediaData.type === "video" && (
          <>
            <VideoPlayer src={mediaData} />
          </>
        )}
      </div>
    );
  } else if (mediaData.type === "datafile") {
    return (
      <div className={cls(styles.datafile_container, "datafile")}>
        <a
          href={mediaData.url ? mediaData.url : ""}
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className={icons[mediaData.extension]}></div>
          {mediaData.filename}
        </a>
      </div>
    );
  } else if (mediaData.type === "audio") {
    return <AudioPlayer data={mediaData} />;
  } else {
    return null;
  }
};
