import React, { useEffect, useMemo, useState } from "react";
import styles from "./styles.module.scss";
import { useActions, useSelector } from "app/utils/hooks";
import ContentCard from "../../../../components/ContentCard";
import { RootState } from "typedefs";
import SpaceModel from "app/models/SpaceModel";
import Dropdown from "../../../../components/Dropdown";
import { editSegment } from "app/actions/segments";
import { cls } from "app/utils";
import Button from "app/components/Button/ButtonVariant";
import paywallModalPro from "app/utils/paywall";

const SpacesList = () => {
  const segmentActions = useActions({ editSegment });
  const spaces = useSelector((state: RootState) => state.space);
  const segment = useSelector(
    (state: RootState) => state.segments.segmentsData
  );

  const [spacesData, setSpacesData] = useState<SpaceModel[] | []>(null);

  const canManageSpaces = useMemo(() => {
    if (segment) {
      return segment.planPermissions.includes("manage_spaces");
    }
  }, [segment]);

  useEffect(() => {
    if (spaces && spaces.data) {
      setSpacesData(spaces.data);
    }
  }, [spaces]);

  const onRemoveSpace = (id: string) => {
    if (canManageSpaces) {
      const confirmed = window.confirm(
        "Are you sure you want to remove this space from list?"
      );
      if (confirmed) {
        const filteredSpacesData = spacesData.filter(
          (space: { id: string }) => space.id !== id
        );
        setSpacesData(filteredSpacesData);

        const spaceIds = filteredSpacesData.map((space) => space.id);

        if (spaceIds.length === 0) {
          spaceIds.push(null);
        }

        segmentActions.editSegment(segment.id, {
          ...segment,
          spaceIds,
        });
      }
    } else {
      paywallModalPro();
    }
  };

  if (spaces && spaces.pending) {
    return <h3>Loading...</h3>;
  }

  return (
    <>
      <div className={styles.container}>
        {spacesData &&
          spacesData.map((item: SpaceModel) => {
            return (
              <div key={item.id} className={styles.list_item_wrapper}>
                <a href={`/spaces/${item.id}`}>
                  <ContentCard
                    img={item.athleteAvatar}
                    title={item.athleteName}
                    subtitle={item.sport}
                  />
                </a>
                <Dropdown
                  btnContent={<i className="ico ico-options" />}
                  dropdownClassname={styles.menu_container}
                  dropdownWrapperClass={styles.menu_btn}
                  closeOnClick
                >
                  <button
                    className={cls(styles.remove_btn, "btn")}
                    onClick={() => onRemoveSpace(item.id)}
                  >
                    Remove
                  </button>
                </Dropdown>
              </div>
            );
          })}
      </div>
      {((!spaces.pending && !spacesData) ||
        (spacesData && !spacesData.length && spaces)) &&
        segment && (
          <div className={styles.no_space}>
            <h3>There are no Spaces in this List</h3>
            {canManageSpaces ? (
              <a href={`/lists/${segment.id}/spaces/edit`}>
                <Button
                  buttonType="primary"
                  icon={<i className="icon-plus icon-white" />}
                  title="Add Spaces to this List"
                  buttonStyles={styles.add_space_btn}
                />
              </a>
            ) : (
              <Button
                buttonType="upgrade"
                icon={<i className="icon-plus icon-white" />}
                title="Add Spaces to this List"
                buttonStyles={styles.add_space_btn}
                onClickFunc={() =>
                  paywallModalPro({ displayModal: "segments" })
                }
              />
            )}
          </div>
        )}
    </>
  );
};

export default SpacesList;
