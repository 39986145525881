/**
 * @module Actions.User
 *
 */
import { Promise } from "rsvp";
import { Dispatch } from "react";
import { ActionType, action } from "typesafe-actions";
import { fetchGet } from "app/utils/request";
import { OnboardingModel } from "app/models";

export const asyncOnboardingGet = (id?: string): Promise<OnboardingModel> => {
  const query = id != null ? `/${id}` : "";
  return fetchGet<OnboardingModel>(
    `account${query}/onboarding`,
    {},
    { version: 1 }
  ).then((data: OnboardingModel) => data);
};

const pending = () => action("@onboarding.fetch.pending");
const error = (err: string) => action("@onboarding.fetch.error", err);
const success = (data: OnboardingModel) =>
  action("@onboarding.fetch.success", data);

const thisActions = { pending, error, success };

export type OnboardingAction = ActionType<typeof thisActions>;

export function fetchOnboarding() {
  return (dispatch: Dispatch<OnboardingAction>) => {
    dispatch(pending());

    const promise = asyncOnboardingGet();
    promise.then((data) => dispatch(success(data)));
    promise.catch((err) => dispatch(error(err)));

    return promise;
  };
}

export const actions = {
  fetchOnboarding,
};
