import { action, ActionType } from "typesafe-actions";
import { FilterModel } from "../models/FilterModel";

export const filterData = (filterValue: FilterModel) =>
  action("@filter.fetch.data", filterValue);

export const filterError = (err: string) => action("@filter.fetch.error", err);

export const clearFilter = () => action("@filter.clear");

const thisActions = {
  filterData,
  filterError,
  clearFilter,
};

export type filterAction = ActionType<typeof thisActions>;
