/**
 * @module Actions
 *
 */
import { reject } from 'rsvp';
import { Dispatch } from 'react';
import { ActionType, action } from 'typesafe-actions';
import { fetchGet, fetchPut } from 'app/utils/request';
import { UserSettingsModel } from 'app/models';

const url = "account/notifications_settings";

const fetchPending = () => action('@user-settings.pending.get');
const fetchError = (err: any) => action('@user-settings.error.get', err);
const fetchSuccess = (settings: UserSettingsModel) => action('@user-settings.success.get', settings);

const savePending = () => action('@user-settings.pending.put');
const saveError = (err: any) => action('@user-settings.error.put', err);
const saveSuccess = () => action('@user-settings.success.put');

const thisActions = { fetchPending, fetchError, fetchSuccess, saveSuccess, savePending, saveError };

export type UserSettingsAction = ActionType<typeof thisActions>;

export function fetchUserSettings(token?: string) {
  return (dispatch: Dispatch<UserSettingsAction>) => {
    dispatch(fetchPending());

    const tokenUrl = token != null ? `${url}?token=${token}` : url;
    return fetchGet(tokenUrl, {}, { version: 1, noAuth: token != null ? true : false})
      .then(settings => dispatch(fetchSuccess(settings)))
      .catch(err => dispatch(fetchError(err)));
  };
}

export function saveUserSettings(notifications_settings: UserSettingsModel, token?: string) {
  return (dispatch: Dispatch<UserSettingsAction>) => {
    dispatch(savePending());

    const tokenUrl = token != null ? `${url}?token=${token}` : url;
    return fetchPut(tokenUrl, { notifications_settings }, { version: 1, noAuth: token != null ? true : false })
      .then(() => dispatch(saveSuccess()))
      .catch(err => {
        dispatch(saveError(err));
        return reject(err);
      });
  };
}

export const actions = {
  fetchUserSettings,
  saveUserSettings,
};
