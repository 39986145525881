import React, { useMemo, useState } from "react";
import styles from "./styles.module.scss";

interface ScheduledAlertProps {
  scheduledIn?: number;
}

const ScheduledAlert = (props: ScheduledAlertProps) => {
  const { scheduledIn } = props;
  const [generatedTimeValue, setGeneratedTimeValue] = useState(scheduledIn);
  const [generatedTime, setGeneratedTime] = useState<string>(null);

  useMemo(() => {
    if (scheduledIn < 24) {
      setGeneratedTime("Hour");
    } else if (scheduledIn >= 24 && scheduledIn <= 168) {
      setGeneratedTime("Day");
      setGeneratedTimeValue(scheduledIn / 24);
    } else if (scheduledIn > 168) {
      setGeneratedTime("Week");
      setGeneratedTimeValue(scheduledIn / 7 / 24);
    }
  }, [scheduledIn]);

  return (
    <div className={styles.scheduled_container}>
      <i className="ico ico-time"></i>
      {generatedTimeValue >= 1 ? (
        <h1 className={styles.scheduledTime}>
          <b>
            Wait for {Math.floor(generatedTimeValue)} {generatedTime}
            {Math.floor(generatedTimeValue) > 1 ? "s" : ""}
          </b>{" "}
          and publish
        </h1>
      ) : (
        <h1 className={styles.immediately}>Publish Immediately</h1>
      )}
    </div>
  );
};

export default ScheduledAlert;
