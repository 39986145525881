import { request } from "app/utils/request";
import { Dispatch } from "react";
import { action, ActionType } from "typesafe-actions";
import config from "config/environment";
import { PostModel } from "app/models/PostModel";

const pending = () => action("@post.fetch.pending");
const error = (err: string) => action("@post.fetch.error", err);
export const displayError = (err: string) => action("@post.fetch.displayError", err);
const success = (post: any) => action("@post.fetch.success", post);

export const postLocation = (location: string[]) =>
  action("@post.fetch.postLocation", location);

export const clearPost = () => action("@post.clear");

const thisActions = {
  pending,
  error,
  displayError,
  success,
  postLocation,
  clearPost,
};

export type PostingFormAction = ActionType<typeof thisActions>;

export function fetchPost(post_id: string) {
  return (dispatch: Dispatch<PostingFormAction>) => {
    dispatch(pending());

    const url = config.API_SERVER + `/api/v2/posts/${post_id}`;
    const promise = request(url, "GET", {}, { version: 1 });
    promise.then((views) => dispatch(success(views)));
    promise.catch((err) => dispatch(error(err)));

    return promise;
  };
}

export function createPost(post: PostModel) {
  return (dispatch: Dispatch<PostingFormAction>) => {
    dispatch(pending());

    const url = config.API_SERVER + `/api/v2/posts`;
    const promise = request(url, "POST", { post }, { version: 1 });
    promise.then((views) => dispatch(success(views)));
    promise.catch((err) => dispatch(error(err)));

    return promise;
  };
}
