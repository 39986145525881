/**
 * @module Utils
 *
 */
import { Promise, resolve } from 'rsvp';
import { AnalyticsService } from 'app/services';
import { capitalize } from 'app/utils/strings';
import { get, set } from 'app/utils/object';
import { SpaceModel, TeamModel, TeamGroupModel } from 'app/models';
import { fetchGet } from 'app/utils/request';
import { errorLogger } from 'app/utils';

interface TeamMembership {
  type?: string;
}

interface PostAnalytics {
  id?: string;
  likesCount?: number;
  repliesCount?: number;
  space?: SpaceModel;
  team?: TeamModel;
  teamGroup?: TeamGroupModel;
  teamMembership?: TeamMembership;
  tags?: { count?: number; };
  type?: string;
}

type PostDetailsType = {
  [key: string]: string | number | boolean;
};

type SetValOpts = {
  defaultValue?: any;
  modifier?: ((val: any) => any)
};

function completePostDetails(post: PostAnalytics): Promise<PostDetailsType> {
  if (post == null) {
    errorLogger("Segment track post was called without post data", { severity: 'warning' });
    post = {};
  }

  const postDetails: PostDetailsType = {
    'Campaign': 'Organic',
    'Media Source': 'Organic'
  };

  const getLength = (list: any[]) => {
    return (list || []).length;
  };

  const setValue = (key: string, value: any, opts?: SetValOpts) => {
    opts = opts || {};
    if (value == null && opts.defaultValue !== undefined) {
      value = opts.defaultValue;
    }

    if (value != null) {
      if (opts.modifier !== undefined) {
        value = opts.modifier(value);
      }
      set(postDetails, key, value);
    }
  };

  setValue('ID', get(post, 'id'));
  setValue('Type', get(post, 'type'), { modifier: capitalize });
  setValue('Tag Count', get(post, 'tags'), { modifier: getLength });
  setValue('Reply Count', get(post, 'replies'), { modifier: getLength });
  setValue('Like Count', get(post, 'likesCount'), { defaultValue: 0 });

  if (get(post, 'space') != null) {                           // Space settings
    setValue('TS Type', get(post, 'space.type'), { modifier: capitalize });
    setValue('Space Id', get(post, 'space.id'));
    setValue('Space Name', get(post, 'space.athleteName'));
    setValue('Space Sport', get(post, 'space.sport'));
  } else if (get(post, 'team') != null) {                     // Team settings
    setValue('TS Type', get(post, 'teamMembership.type'), { modifier: capitalize });
    setValue('Team Id', get(post, 'team.id'));
    setValue('Team Name', get(post, 'team.name'));
    setValue('Team Sport', get(post, 'team.sport'));

    // get addition team data
    return fetchGet<TeamModel>(`/teams/${(post.team as TeamModel).id}`, {}).then((team: TeamModel | TeamModel[]): PostDetailsType => {
      setValue('Team Member Count', get((team as TeamModel), 'membersCount'));

      return postDetails;
    });
  } else if (get(post, 'teamGroup') != null) {                // Team Group settings
    setValue('TS Type', get(post, 'teamMembership.type'), { modifier: capitalize });
    setValue('Group Id', get(post, 'teamGroup.id'));
    setValue('Group Name', get(post, 'teamGroup.name'));
    setValue('Team Id', get(post, 'teamGroup.team.id'));
    setValue('Team Name', get(post, 'teamGroup.team.name'));
    setValue('Team Sport', get(post, 'teamGroup.team.sport'));

    // get addition team group data
    return fetchGet(`/team_groups/${(post.teamGroup as TeamGroupModel).id}`, {}).then((group: TeamGroupModel): PostDetailsType => {
      setValue('Channel Type', formatGroupType(get(group, 'groupType')));
      setValue('Group Member Count', get(group, 'teamMembersCount'));

      return postDetails;
    });
  }

  // no addition data needed return promise
  return resolve(postDetails);
}

export function postCreated(event: string, post: PostAnalytics): void {
  completePostDetails(post).then(postDetails => {
    AnalyticsService.track('Post ' + event, postDetails);
  });
}

export function postEdited(event: string, post: PostAnalytics): void {
  completePostDetails(post).then(postDetails => {
    AnalyticsService.track('Post Edit ' + event, postDetails);
  });
}

export function postReplied(event: string, post: PostAnalytics): void {
  completePostDetails(post).then(postDetails => {
    AnalyticsService.track('Post Reply ' + event, postDetails);
  });
}

export function postReported(post: PostAnalytics): void {
  completePostDetails(post).then(postDetails => {
    AnalyticsService.track('Post Reported', postDetails);
  });
}

export function postViewed(post: PostAnalytics): void {
  completePostDetails(post).then(postDetails => {
    AnalyticsService.track('Post Viewed', postDetails);
  });
}

export function postShared(post: PostAnalytics): void {
  completePostDetails(post).then(postDetails => {
    AnalyticsService.track('Post Shared', postDetails);
  });
}

export function postLiked(post: PostAnalytics): void {
  completePostDetails(post).then(postDetails => {
    AnalyticsService.track('Post Liked', postDetails);
  });
}

export function postDisliked(post: PostAnalytics): void {
  completePostDetails(post).then(postDetails => {
    AnalyticsService.track('Post Disliked', postDetails);
  });
}

export function postDeleted(post: PostAnalytics): void {
  completePostDetails(post).then(postDetails => {
    AnalyticsService.track(createPostTrackType('Deleted', post), postDetails);
  });
}

const createPostTrackType = (type: string, post: PostAnalytics | null | undefined): string => {
  post = post || {};

  if (get(post, 'team') != null) {
    return `Team Post ${type}`;
  } else if (get(post, 'teamGroup') != null) {
    return `Team Group Post ${type}`;
  } else {
    return `Post ${type}`;
  }
};

const formatGroupType = (type: string | None) => (
  (type || '').split('_').map(item => item.slice(0, 1).toUpperCase() + item.slice(1)).join('-')
);

