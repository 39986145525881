import React, { useEffect } from "react";
import styles from "./styles.module.scss";
import PagePanel from "app/components/PagePanel";
import { useActions, useSelector } from "app/utils/hooks";
import { fetchSpaces, clearSpace } from "app/actions/space";
import {
  fetchSegmentById,
  deleteSegment,
  clearSegments,
} from "app/actions/segments";
import { RootState } from "typedefs";
import Dropdown from "app/components/Dropdown";
import { cls } from "app/utils";
import SpacesList from "app/routes/Segments/SegmentSpaces/SpacesList";
import SegmentsLayout from "app/components/Layouts/SegmentsLayout";
import { route } from "app/utils/router";

interface Props {
  id: string;
}

const SegmentSpaces = ({ id }: Props) => {
  const spaceQuery = {
    segmentIds: [id],
    type: "athlete",
  };

  const segmentActions = useActions({
    fetchSpaces,
    clearSpace,
    fetchSegmentById,
    clearSegments,
    deleteSegment,
  });

  useEffect(() => {
    if (id) {
      segmentActions.fetchSpaces(spaceQuery);
      segmentActions.fetchSegmentById(id);
    }

    return () => {
      segmentActions.clearSpace();
      segmentActions.clearSegments();
    };
  }, [id]);

  const segmentData = useSelector(
    (state: RootState) => state.segments.segmentsData,
  );

  const segmentsPending = useSelector(
    (state: RootState) => state.segments.segmentsPending,
  );

  const canPost = segmentData && segmentData.planPermissions.includes("post");
  const canInvite =
    segmentData &&
    segmentData.planPermissions.includes("invite") &&
    segmentData.spaceIds &&
    segmentData.spaceIds.length > 0;
  const canManageSpaces =
    segmentData && segmentData.planPermissions.includes("manage_spaces");
  const canEdit = segmentData && segmentData.planPermissions.includes("edit");
  const canRemoveList =
    segmentData && segmentData.planPermissions.includes("delete");

  const listRemoveHandler = () => {
    const confirmed = window.confirm(
      "Are you sure you want to remove this space from list?",
    );
    if (confirmed) {
      segmentActions.deleteSegment(id).then(() => {
        // window.location.pathname = `/lists`;
        route("/lists");
      });
    }
  };

  const dropdownList = [
    {
      label: "Manage Spaces",
      link: "spaces/edit",
      permission: canManageSpaces,
    },
    { label: "Post into List", link: "spaces/post", permission: canPost },
    {
      label: "Invite Coaching Staff to all Spaces",
      link: "add_members",
      permission: canInvite,
    },
    { label: "Edit Name", link: "edit", permission: canEdit },
    {
      label: "Remove",
      link: "",
      permission: canRemoveList,
      onClickFunc: listRemoveHandler,
    },
  ];

  return (
    <SegmentsLayout isLoading={segmentsPending}>
      <div className={styles.container}>
        {segmentData && (
          <PagePanel
            title={segmentData && segmentData.name}
            headerChildren={(
              <Dropdown
                btnClassName={styles.dropdown_btn}
                dropdownWrapperClass={styles.dropdown_container}
                btnContent={(
                  <span className={styles.dropdown_icons}>
                    {" "}
                    <i className="icon-cog" /> <i className="ico ico-chevron" />{" "}
                  </span>
                )}
              >
                <ul>
                  {dropdownList.map((item, index) => {
                    return (
                      item.permission && (
                        <li
                          key={index}
                          onClick={item.onClickFunc ? item.onClickFunc : null}
                        >
                          <a
                            href={item.link ? `/lists/${id}/${item.link}` : ""}
                            className={cls({
                              [styles.remove_btn]:
                                item.label.includes("Remove"),
                            })}
                          >
                            {item.label}
                          </a>
                        </li>
                      )
                    );
                  })}
                </ul>
              </Dropdown>
            )}
          >
            <ol className={styles.breadcrumb_wrapper}>
              <li>
                <a href="/lists">List</a>
              </li>{" "}
              / <li>{segmentData.name}</li>
            </ol>
          </PagePanel>
        )}
        <SpacesList />
      </div>
    </SegmentsLayout>
  );
};

export default SegmentSpaces;
