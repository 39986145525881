/**
 * @module Components.StripeForm
 *
 */
import React from 'react';
import StripeElementContainer, { PropsDef } from './StripeElementContainer';
import { CardNumberElement } from 'react-stripe-elements';
import LOCALES from './locale';

const CardNumber = (props: PropsDef) => {
  return (
    <StripeElementContainer
      elementType={CardNumberElement}
      placeholder={LOCALES.card_number}
      {...props}
    />
  );
};

export default CardNumber;
