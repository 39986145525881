/**
 * @module Components.Helpers
 *
 */
import React from 'react';
import { error as logError } from 'app/utils/debug';

export default class ErrorBoundary extends React.Component {
  componentDidCatch(error: Error, info: React.ErrorInfo) {
    logError(error, info);
  }

  render() {
    return this.props.children;
  }
}

