/**
 * @module Reducers.Stripe
 *
 */
import { RequestError } from 'typedefs';
import { PaymentInfoModel } from 'app/models';
import { StripeAction } from 'app/actions/stripe';

export type StripeState = {
  data: PaymentInfoModel | null,
  error: RequestError | null,
  pending: boolean,
};

const initialState: StripeState = {
  data: null,
  error: null,
  pending: false,
};

export default function stripeReducer(state = initialState, action: StripeAction): StripeState {
  switch (action.type) {
    case '@stripe.post.pending':
      return { ...state, pending: true, error: null };
    case '@stripe.post.success':
      return { ...state, pending: false, data: action.payload };
    case '@stripe.post.error':
      return { ...state, pending: false, error: action.payload };

    default:
      return state;
  }
}
