/** @module components/layouts */
import React, { useState, useEffect, useRef } from 'react';
import CheckBox from 'app/components/inputs/CheckBox';
import { cls } from 'app/utils';
import { uuid } from 'app/utils/uuid';
import Styles from './styles.module.scss';

type DefaultItemProps = {
  model?: any;
};

type SelectObject = {
  [key: string]: any;
};

const DefaultItem = (props: DefaultItemProps) => {
  return (
    <div className={Styles.selectListDefault}>
      {props.model.name}
    </div>
  );
};

type PropsDef = React.PropsWithChildren<{
  className?: string;
  pending?: boolean;
  data?: SelectObject[];
  selected?: SelectObject[];
  title?: string;
  template?: typeof DefaultItem;
  onSelectAll?: (checked: boolean) => void;
  onItemChanged?: (item: SelectObject, checked: boolean) => void;
}>;

type State = {
  selectAll: boolean;
  data: any[];
};

export default function SelectList(props: PropsDef) {
  const [ uniqId ] = useState(uuid());
  const selector = `_selected_${uniqId}`;

  // set up data and pre select any objects included in props.selected
  const [ state, setState ] = useState<State>({ data: [], selectAll: false });
  const selectAllRef = useRef(null);

  const toggleSelectAll = (evt: React.MouseEvent) => {
    if (canForwardClick(selectAllRef, evt)) {
      selectAllRef.current.click();
    }
  };

  const isSelectAll = (data: SelectObject) => {
    const totalSelected = data.filter((item: any) =>
      item[selector] === true || item.locked === true
    );
    return (totalSelected.length === data.length);
  };

  useEffect(() => {
    if (props.data != null) {
      let fixedData = props.data;
      (props.selected || []).forEach(sel => {
        fixedData = selectInArray(fixedData, selector, sel, true);
      });
      setState({ ...state, data: fixedData, selectAll: isSelectAll(fixedData) });
    }
  }, [ props.data ]);

  const setSelected = (checked: boolean, selected?: SelectObject) => {
    const newData = selectInArray(state.data, selector, selected, checked);
    setState({ ...state, data: newData, selectAll: isSelectAll(newData) });
  };

  const selectAll = (checked: boolean) => {
    setSelected(checked);
    if (props.onSelectAll) {
      props.onSelectAll(checked);
    }
  };

  const changeHandler = (checked: boolean, model: SelectObject) => {
    // console.log('changeHandler', checked, model, index);
    setSelected(checked, model);
    if (props.onItemChanged) {
      props.onItemChanged(model, checked);
    }
  };

  const items = state.data.map((i: any, idx: number) => {
    return (
      <ListItem
        key={idx}
        isSelected={(i.selected || i[selector])}
        isLocked={i.locked}
        model={i}
        template={props.template}
        onChange={changeHandler}
      />
    );
  });

  return (
    <div className={cls(Styles.selectList, props.className)}>
      <div className={Styles.selectListHeader} onClick={toggleSelectAll}>
        <span>
          <CheckBox
            getRef={((inputRef: any) => selectAllRef.current = inputRef)}
            className={Styles.selectListCheck}
            onChange={selectAll}
            checked={state.selectAll}
          />
        </span>
        <span>{props.title}</span>
      </div>

      <div className={Styles.selectListContent}>{items}</div>
    </div>
  );
}

type ListItemProp = {
  isSelected?: boolean;
  isLocked?: boolean;
  model: SelectObject;
  onChange: (checked: boolean, model: SelectObject) => void;
  template?: typeof DefaultItem;
};

const ListItem = (props: ListItemProp) => {
  const Template = props.template || DefaultItem;
  const ref = useRef(null);

  const itemChange = (checked: boolean) => {
    if (props.isLocked) { return; }
    props.onChange(checked, props.model);
  };

  const toggleCheck = (evt: React.MouseEvent) => {
    if (props.isLocked) { return; }
    if (canForwardClick(ref, evt)) {
      ref.current.click();
    }
  };

  const selectedClass = cls(
    Styles.selectListItem,
    props.isSelected ? Styles.selected : '',
    props.isLocked ? Styles.locked : ''
  );

  return (
    <div className={selectedClass} onClick={toggleCheck}>
      <div className={Styles.overlay} />
      <span>
        <CheckBox
          getRef={((inputRef: any) => ref.current = inputRef)}
          className={Styles.selectListCheck}
          onChange={itemChange}
          checked={props.isSelected}
          disabled={props.isLocked}
        />
      </span>
      <span>
        <Template model={props.model} />
      </span>
    </div>
  );
};

const selectInArray = (data: SelectObject[], key: string, selected: SelectObject, checked: boolean) => {
  return data.map((item: SelectObject) => {
    if (selected == null || selected === item) {
      item[key] = checked;
    }
    return item;
  });
};

const canForwardClick = (ref: React.MutableRefObject<any>, evt: React.MouseEvent) => {
  if (ref && ref.current) {
    if (ref.current !== evt.target && !ref.current.contains(evt.target)) {
      return true;
    }
  }
  return false;
};

