import React from "react";
import styles from "./styles.module.scss";
import TagsList from "./TagsList/TagsList";
import { useSelector } from "react-redux";
import { RootState } from "typedefs";
import PagePanel from "app/components/PagePanel";

const Tags = () => {
  const user = useSelector((state: RootState) => state.user.data);
  const canAddTag = user && user.planPermissions.includes("create_tags");

  return (
    <div className={styles.container}>
      <PagePanel
        title="Tags"
        titleStyles={styles.title}
        buttonTitle="+ Add Tag"
        buttonType="primary"
        url={canAddTag && "/account/tags/new"}
      />
      <TagsList />
    </div>
  );
};

export default Tags;
