/**
 * @module Services
 *
 */
import { Promise } from 'rsvp';
import { Service, createService } from 'app/utils/service';
import { assert } from 'app/utils/debug';
import { fetchGet, fetchPut } from 'app/utils/request';
import moment from 'moment';
import config from 'config/environment';

class StripeService extends Service {
  static _name = "StripeService";
  isInit = true;
  locale = 'en';

  init() {
    assert(
      config.STRIPE_API_KEY && config.STRIPE_API_KEY.length,
      "Stripe is not setup\n\t - Add stripe_api_key='key' to your .env file to set it up.\n\t - Then restart you dev server."
    );

    this.isInit = false;
  }


  /**
  * Creates a stripe token to send to the server api
  *
  * @method createToken
  * @param {...args} stripe.createToken arguments
  */
  createToken(...args) {
    if (this.stripe && this.stripe.createToken) {
      return this.stripe.createToken.apply(this.stripe, args);
    }
  }

  getPaymentInfo() {
    return fetchGet('stripe/payment_info', {}, 1).then(json =>
      ({ ...json, cardExpiry: moment(json.cardExpiry).format('MM / YY') })
    );
  }

  updatePaymentInfo(card, name, zip) {
    return this.stripePromise().then(stripe =>
      stripe.createToken(card, { name: name, address_zip: zip }).then(response => {
        return fetchPut('stripe/payment_info', { token: response.token.id }, 1);
      })
    );
  }

  elements(config) {
    return this.stripe && this.stripe.elements(config);
  }

  stripePromise() {
    return new Promise(resolve => {
      if (!this._stripe) {
        this._initalizeStripe(() => {
          resolve(this._stripe);
        });
      } else {
        resolve(this._stripe);
      }
    });
  }

  get stripe() {
    if (this._stripe != null) {
      return this._stripe;
    }

    if (!this.isInit) {
      this._initalizeStripe();
    }
    return null;
  }

  _initalizeStripe(callback) {
    callback = callback || function () {};
    this._loadStripe();

    if (window.Stripe) {
      this._setStripeInstance();
      callback();
    } else {
      this._script.addEventListener('load', () => {
        this._setStripeInstance();
        callback();
      });
    }
  }

  _loadStripe() {
    if (!this.isInit) {
      this.isInit = true;
      const script = document.createElement('script');
      script.id = "stripe-js";
      script.src = "https://js.stripe.com/v3";
      script.async = true;
      document.body.appendChild(script);
      this._script = script;
    }
  }

  setLocale(locale) {
    this._locale = locale;
  }

  _setStripeInstance() {
    if (!this._stripe) {
      this._stripe = window.Stripe(config.STRIPE_API_KEY, { locale: this._locale });
    }
  }
}

export default createService(StripeService);
