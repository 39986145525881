/**
 * @module Components.Routes.ResetPassword
 *
 */
import React, { useState, FormEvent } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { LocaleString } from 'typedefs';
import { useLoc, useActions } from 'app/utils/hooks';
import { resetPasswordWithToken } from 'app/actions/user';
import { RootState } from 'app/reducers';
import { cls } from 'app/utils';
import { Wrapper } from 'app/components/Onboarding';
import FormItem from 'app/components/inputs/FormItem';
import styles from './styles.module.scss';
import LOCALES from './locale';

interface PropsDef {
  resetPasswordToken: string;
  onSuccess: (() => void);
  onInvalid: (() => void);
}

interface State {
  passwordError: LocaleString | null;
  confirmError: LocaleString | null;
  isMatch: boolean;
}

interface FormElements extends HTMLCollection {
  password: HTMLInputElement;
  passwordConfirm: HTMLInputElement;
}

const initState: State = {
  passwordError: null,
  confirmError: null,
  isMatch: false
};


/**
 * @class ResetForm
 *
 */
export default function ResetForm(props: PropsDef) {
  const { formatMessage } = useLoc();
  const [ state, setState ] = useState(initState);
  const userState = useSelector((state: RootState) => state.user);
  const actions = useActions({ resetPasswordWithToken });

  const checkMatch = (event: FormEvent<HTMLFormElement>) => {
    const els = (event.currentTarget.elements as FormElements);
    const password = els.password.value.trim();
    const confirmPassword = els.passwordConfirm.value.trim();

    const isMatch = password.length > 0 && password === confirmPassword;
    setState({ ...state, isMatch });
  };

  const callSave = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault();

    const els = (e.currentTarget.elements as FormElements);
    const password = els.password.value.trim();
    const passwordConfirmation = els.passwordConfirm.value.trim();

    let tempState: any = { ...state, confirmError: null, passwordError: null };
    if (password !== passwordConfirmation) {
      tempState = { ...tempState, confirmError: formatMessage(LOCALES.password_mismath) };
    }

    if (!/.{6,128}/.test(password)) {
      tempState = { ...tempState, passwordError: formatMessage(LOCALES.password_failure) };
    }

    setState(tempState);
    if (tempState.passwordError || tempState.confirmError) {
      return;
    }

    actions.resetPasswordWithToken(props.resetPasswordToken, password, passwordConfirmation)
      .then(() => props.onSuccess())
      .catch(() => props.onInvalid());
  };

  return (
    <Wrapper>
      <div className={styles.mainWrapper}>
        <h1 className={styles.title}><FormattedMessage {...LOCALES.title} /></h1>

        <form noValidate={true} onChange={checkMatch} onSubmit={callSave} target="_self">
          <FormItem error={state.passwordError}>
            <input
              name="password"
              type="password"
              placeholder={formatMessage(LOCALES.new_pass_placeholder)}
            />
            <span className={cls(styles.icon, state.isMatch ? styles.showing : '')}><i className="fas fa-check-circle fade in"></i></span>
          </FormItem>

          <FormItem error={state.confirmError}>
            <input
              name="passwordConfirm"
              type="password"
              placeholder={formatMessage(LOCALES.confirm_placeholder)}
            />
            <span className={cls(styles.icon, state.isMatch ? styles.showing : '')}><i className="fas fa-check-circle fade in"></i></span>
          </FormItem>

          <FormItem>
            <button className="btn btn-primary full" disabled={userState.pending}>
              <FormattedMessage {...LOCALES.reset_button} />
            </button>
          </FormItem>
        </form>

        <FormItem className={styles.link}>
          <Link to="/signin"><FormattedMessage {...LOCALES.back_link} /></Link>
        </FormItem>
      </div>
    </Wrapper>
  );
}

