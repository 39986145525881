/* eslint-disable react/prop-types */
/** @module components/modals */
import React, { useEffect } from "react";
import { defineMessages } from "react-intl";
import { AnalyticsService } from "app/services";
import { cls } from "app/utils";
// import { useLoc } from 'app/utils/hooks';
// import ModalWrapper from 'app/components/modals/ModalWrapper';
import { Loc } from "app/components/helpers";
import styles from "./styles.module.scss";
import ModalLayout from "app/components/Layouts/ModalLayout/ModalLayout";

interface PropsDef {
  isOpen: boolean;
  type: string;
  onClose: () => void;
}

export default function DemoPost(props: PropsDef) {
  // const { formatMessage } = useLoc();

  useEffect(() => {
    if (props.isOpen) {
      if (props.type === "reply") {
        AnalyticsService.track("Demo Space Reply Attempted");
      } else if (props.type === "scheduled") {
        AnalyticsService.track("Demo Space Scheduled Attempted");
      } else if (props.type === "members") {
        AnalyticsService.track("Demo Space Members Attempted");
      } else if (props.type === "filter") {
        AnalyticsService.track("Demo Space Filter Posts Attempted");
      } else {
        AnalyticsService.track("Demo Space Post Attempted");
      }
    }
  }, [props.isOpen]);

  const ContentText = () => {
    switch (props && props.type) {
      case "schedule":
        return <Loc value={locales.scheduleContent} />;
      case "filter":
        return <Loc value={locales.filtrationContent} />;
      default:
        return <Loc value={locales.content} />;
    }
  };

  return (
    <ModalLayout isOpen={props.isOpen} onClose={props.onClose}>
      <div className={styles.modal_body}>
        <i className="ico ico-feed-o" />
        <h1>
          <Loc value={locales.title} />
        </h1>
        <p>
          <ContentText />
        </p>

        <button
          className={cls(styles.button, "btn btn-primary")}
          onClick={props.onClose}
        >
          <Loc value={locales.button} />
        </button>
      </div>
    </ModalLayout>
  );
}

const locales = defineMessages({
  title: {
    id: "demo-post.title.modal",
    defaultMessage: "Inspirational Spaces",
  },
  content: {
    id: "demo-post.message.modal",
    defaultMessage:
      "This is just an example Space for inspiration, so you can't post here. But if this was a real Space, this is where you would!",
  },
  scheduleContent: {
    id: "demo-post.schedule-message.modal",
    defaultMessage:
      "This is just an example Space for inspiration so you can’t schedule posts here. But if it was a real space, this is where you would.",
  },
  filtrationContent: {
    id: "demo-post.schedule-message.modal",
    defaultMessage:
      "This is just an example Space for inspiration so you can’t search posts here. But if it was a real space, this is where you would.",
  },
  button: {
    id: "demo-post.button.modal",
    defaultMessage: "Ok, Got It",
  },
});
