import React from "react";
import styles from "./styles.module.scss";
import { InfoCardProps } from "./index.types";
import GreenCheckMark from "../../../public/images/green-check-icon.svg";
import { cls } from "app/utils";
// import { Link } from "react-router-dom";

const GuideCard = ({
  icon,
  title,
  description,
  stepDone,
  accomplishedText,
  onClickFunction,
}: InfoCardProps) => {
  return (
    <div className={styles.container} onClick={onClickFunction}>
      {icon && (
        <div className={styles.icon_wrapper}>
          <img className={styles.icon} src={icon} alt="info" />
        </div>
      )}
      <div className={styles.content_wrapper}>
        <div className={styles.info_wrapper}>
          {title && <div className={styles.title}>{title}</div>}
          {stepDone && (
            <div className={styles.acknowledged_container}>
              <img src={GreenCheckMark} alt="check" />
              <p>{accomplishedText}</p>
            </div>
          )}
          {description && (
            <div className={styles.description}>{description}</div>
          )}
          <button className={cls(styles.learn_more_btn, "btn btn-primary")}>
            Learn More
          </button>
        </div>
      </div>
    </div>
  );
};

export default GuideCard;
