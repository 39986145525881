import config from "config/environment";
import { action, ActionType } from "typesafe-actions";
import { request } from "app/utils/request";
import { Dispatch } from "react";
import SpaceModel from "app/models/SpaceModel";
import TeamGroupModel from "app/models/TeamGroupModel";

const pendingApplyTemplate = () => action("@applyTemplate.create.pending");
const errorApplyTemplate = (err: string) =>
  action("@applyTemplate.create.error", err);
const successApplyTemplate = (value: boolean) =>
  action("@applyTemplate.create.success", value);

export const locations = (spaces: string[]) =>
  action("@applyTemplate.fetch.locations", spaces);

export const spaceObj = (space: SpaceModel[]) =>
  action("@applyTemplate.fetch.spaceObj", space);

export const groupObj = (gorup: TeamGroupModel[]) =>
  action("@applyTemplate.fetch.groupObj", gorup);

export const groupChannelObj = (channel: TeamGroupModel[]) =>
  action("@applyTemplate.fetch.groupChannelObj", channel);

export const clearApplyTemplate = () => action("@applyTemplate.clear");

export function applyTemplateToSpace(space_ids: string[], templateId: string) {
  return (dispatch: Dispatch<ApplyTemplateAction>) => {
    dispatch(pendingApplyTemplate());

    const url =
      config.API_SERVER + `/api/v1/space_templates/${templateId}/apply`;
    const promise = request(url, "POST", { space_ids }, { version: 1 });
    promise.then(() => dispatch(successApplyTemplate(true)));
    promise.catch((err) => dispatch(errorApplyTemplate(err)));

    return promise;
  };
}

export function applyTemplateToGroup(team_ids: string[], templateId: string) {
  return (dispatch: Dispatch<ApplyTemplateAction>) => {
    dispatch(pendingApplyTemplate());

    const url =
      config.API_SERVER + `/api/v1/space_templates/${templateId}/apply`;
    const promise = request(url, "POST", { team_ids }, { version: 1 });
    promise.then(() => dispatch(successApplyTemplate(true)));
    promise.catch((err) => dispatch(errorApplyTemplate(err)));

    return promise;
  };
}

export function applyTemplateToChannel(team_group_ids: string[], templateId: string) {
  return (dispatch: Dispatch<ApplyTemplateAction>) => {
    dispatch(pendingApplyTemplate());

    const url =
      config.API_SERVER + `/api/v1/space_templates/${templateId}/apply`;
    const promise = request(url, "POST", { team_group_ids }, { version: 1 });
    promise.then(() => dispatch(successApplyTemplate(true)));
    promise.catch((err) => dispatch(errorApplyTemplate(err)));

    return promise;
  };
}

const thisActions = {
  pendingApplyTemplate,
  errorApplyTemplate,
  successApplyTemplate,
  locations,
  spaceObj,
  groupObj,
  groupChannelObj,
  clearApplyTemplate,
};

export type ApplyTemplateAction = ActionType<typeof thisActions>;
