/**
 * @module Utils
 *
 */
import { uuid } from 'app/utils/uuid';
import kebabCase from 'lodash/kebabCase';

/**
 * @class Service
 *
 */
export class Service {
  static _name: string = 'Service';

  public _id: string;
  public __original: typeof Service;

  constructor(id: string, ServiceClass: typeof Service) {
    this._id = id;
    this.__original = ServiceClass;
  }

  init(): void {}
  ready(): void {}

  public static _classname(): string {
    return kebabCase(this._name);
  }
}

/**
 * @public
 * @method createService
 */
export function createService<T extends Service>(ServiceClass: typeof Service): T {
  const instance = window.__SERVICES__.injectService(ServiceClass);
  instance.__original = ServiceClass;
  return instance;
}


/**
 * @class ServiceContainer
 *
 */
class ServiceContainer {
  public __instances__: { [key: string]: any };
  public __initialized__: string[];

  public constructor() {
    this.__instances__ = {};
    this.__initialized__ = [];
  }

  public setService(ServiceClass: typeof Service, instance: Service): void {
    this.__instances__[ServiceClass._name] = instance;
  }

  public getService(ServiceClass: typeof Service): Service | null | undefined {
    return this.__instances__[ServiceClass._name];
  }

  public deleteService(ServiceClass: typeof Service) {
    // const index = this.__initialized__.indexOf(ServiceClass._name);
    // if (index !== -1) {
    //   this.__initialized__ = [ ...this.__initialized__.slice(0, index), ...this.__initialized__.slice(index+1) ];
    // }
    delete this.__instances__[ServiceClass._name];
  }

  public injectService(ServiceClass: typeof Service): Service {
    const service = this.getService(ServiceClass);
    if (service != null) {
      return service;
    }
    return this._create(ServiceClass);
  }

  public _create(ServiceClass: typeof Service): Service {
    // create a new instance of the Service
    const instance = new ServiceClass(uuid(), ServiceClass);

    this.setService(ServiceClass, instance);

    // call init only the first time a service is loaded with HMR
    if (this.__initialized__.indexOf(ServiceClass._name) === -1) {
      this.__initialized__.push(ServiceClass._name);
      instance.init();
    }

    // call ready everytime a service is reloaded with HMR
    instance.ready();

    // return new initialized instance
    return instance;
  }
}

/**
 * initialize services container
 */
if (!window.__SERVICES__) {
  window.__SERVICES__ = new ServiceContainer();
}

