/**
 * @module Components.Inputs
 *
 */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import CheckBox, { PropsDef } from 'app/components/inputs/CheckBox/Base';
import PrivacyPolicy from 'app/components/links/PrivacyPolicy';
import TermsOfService from 'app/components/links/TermsOfService';
import Cookies from 'app/components/links/Cookies';

const consentText = (
  <FormattedMessage
    id="app.form-label.tos-consent"
    defaultMessage="I agree to CoachNow's {privacy}, {terms}, and {cookies}."
    values={{ privacy: <PrivacyPolicy />, terms: <TermsOfService />, cookies: <Cookies />}}
  />
);

export default function TermsOfServiceCheckbox(props: PropsDef) {
  return (
    <CheckBox
      {...props}
      name="tosConsent"
      label={consentText}
    />
  );
}

