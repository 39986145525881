/** @module components/subscription/upgrade */
import React, { useState } from 'react';
import { UserState } from 'app/reducers/user';
import { Redirect } from 'react-router-dom';
import { updateIdentity } from 'app/utils/bridge';
import { decodeQuery } from 'app/utils/object';
import PlanForm from './PlanForm';
import SuccessPage from './SuccessPage';
import styles from './styles.module.scss';

interface PropsDef {
  user: UserState;
}

/**
 * @class Upgrade
 *
 */
export default function Upgrade(props: PropsDef) {
  const [ isDone, setIsDone ] = useState<boolean>(false);
  const [ isSuccess, setIsSuccess ] = useState<boolean>(false);
  const [ activePlanId, setActivePlanId ] = useState<string>();

  const done = () => {
    updateIdentity();
    setIsDone(true);
  };

  const success = (activePlanId: string) => {
    setActivePlanId(activePlanId);
    setIsSuccess(true);
  };

  let returnURL = '/';
  if (window.location.search) {
    const search = decodeQuery(window.location.search);
    returnURL = search.return_url || search.returnURL || returnURL;
  }

  if (isDone) {
    return (<Redirect to={returnURL} />);
  }

  let child: JSX.Element;
  if (isSuccess) {
    child = (<SuccessPage plan={activePlanId} user={props.user} onDone={done} />);
  } else {
    child = (<PlanForm user={props.user} onSuccess={success} onCancel={done} />);
  }
  return (<div className={styles.mainContainer}>{child}</div>);
}
