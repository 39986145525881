/**
 * @module Utils
 *
 */
import isEmpty from './isEmpty';

/**
 * A value is blank if it is empty or a whitespace string.
 *
 * ```javascript
 *  import { isBlank } from 'app/utils';
 *  isBlank();                // true
 *  isBlank(null);            // true
 *  isBlank(undefined);       // true
 *  isBlank('');              // true
 *  isBlank([]);              // true
 *  isBlank('\n\t');          // true
 *  isBlank('  ');            // true
 *  isBlank({});              // false
 *  isBlank('\n\t Hello');    // false
 *  isBlank('Hello world');   // false
 *  isBlank([1,2,3]);         // false
 * ```
 *
 * @public
 * @method isBlank
 * @param value {any} Value to test
 * @return {boolean}
 */
export default function isBlank(value: any): boolean {
  if (isEmpty(value) || (typeof value === 'string' && /\S/.test(value) === false)) {
    return true;
  }
  return false;
}
