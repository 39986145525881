import React from "react";
import styles from "./styles.module.scss";
import GettingStartedProgress from "app/components/GettingStarted/GettingStartedProgress";
import { Spinner } from "app/components/Loading";
import { cls } from "app/utils";

interface Props {
  isLoading?: boolean;
  loaderChild?: React.ReactNode;
  loadingClassname?: string;
  displayNoResult?: boolean;
  noResultChild?: React.ReactNode;
  children: React.ReactNode;
}

const SegmentsLayout = ({
  isLoading,
  loaderChild,
  loadingClassname,
  children,
  displayNoResult,
  noResultChild,
}: Props) => {
  return (
    <div className={styles.container}>
      <GettingStartedProgress />
      {isLoading &&
        (loaderChild ? (
          loaderChild
        ) : (
          <Spinner
            className={cls("spinner", styles.spinner, loadingClassname)}
          />
        ))}
      {displayNoResult && !isLoading && <>{noResultChild}</>}
      {!displayNoResult && !isLoading && <>{children}</>}
    </div>
  );
};

export default SegmentsLayout;
