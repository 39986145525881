/**
 * @module Components.Wrappers
 *
 */
import React, { useEffect } from 'react';
import { useStoreActions } from 'app/utils/hooks';
import { RootState } from 'app/reducers';

type PropsDef = {
  actions: {[key: string]: any};
  stateChange: (state: Partial<RootState>) => void;
  noAuth?: boolean;
  wait?: number;
};

export default function Store(props: PropsDef) {
  const state = useStoreActions(props.actions, {
    noAuth: props.noAuth,
    wait: props.wait
  });

  useEffect(() => {
    props.stateChange(state);
  }, [ state ]);

  return (
    <div style={{ display: 'none' }} />
  );
}
