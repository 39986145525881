/**
 * @module Utils.Appcuues
 *
 * This is appcues specific helper functions.
 */
import { Promise, all } from 'rsvp';
import { Model } from 'typedefs';
import { fetchGet } from 'app/utils/request';
import { UserModel, SpaceMemberModel, SpaceInvitationModel } from 'app/models';
import jack_png from 'app/images/jumpin_jack.png';

const JUMPING_JACK_NAME = 'Jumping Jack';
const JUMPING_JACK_EMAIL = 'jack@coachnow';

interface CommonModel extends Model {
  name?: string;
  email?: string;
}

export function createMemberJack(): UserModel {
  const jack = {
    avatar: jack_png,
    email: 'jack@coachnow.io',
    firstName: 'Jumping',
    lastName: 'Jack',
    displayName: 'Jumping Jack',
    name: 'Jumping Jack',
  };
  return jack as UserModel;
}

export function findJack(list: CommonModel[], key: 'name' | 'email') {
  let reg = new RegExp(JUMPING_JACK_NAME, 'i');
  if (key === 'email') {
    reg = new RegExp(`^${JUMPING_JACK_EMAIL}`);
  }
  return list.find((item: CommonModel) => reg.test((item[key] as string)));
}

export function spaceIncludesJack(spaceId: string): Promise<boolean> {
  const jackQuery = { user_name: JUMPING_JACK_NAME };
  return all([
    fetchGet<SpaceMemberModel>(`/spaces/${spaceId}/members`, jackQuery),
    fetchGet<SpaceInvitationModel>(`/spaces/${spaceId}/invitations`, jackQuery)
  ]).then(([members, invitations]: [ SpaceMemberModel | SpaceMemberModel[], SpaceInvitationModel | SpaceInvitationModel[] ]) => {
    const hasJack = (list: CommonModel[]) => (
      findJack(list, 'name') != null
    );
    return (hasJack((members as SpaceMemberModel[])) || hasJack((invitations as SpaceInvitationModel[])));
  });
}
