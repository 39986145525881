/** @module utils/hooks */
import { AnyObject } from 'typedefs';
import { useLocation } from 'react-router-dom';
import { decodeQuery } from 'app/utils/object';

/**
 * Gets urls search params and parses them into a key: value store
 *
 * @method useUrlSeach
 * @return {object}
 */
export default function useUrlSeach(): [ AnyObject, string ] {
  const location = useLocation();
  const search = location.search || '';
  const params = decodeQuery(search);
  return [ params, search ];
}
