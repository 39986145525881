import { FilterModel } from "app/models/FilterModel";
import { useSelector } from "react-redux";
import { RootState } from "typedefs";

const useFilters = () => {
  const filterState = useSelector((state: RootState) => state.filter.data);
  if (!filterState) {
    return null;
  }
  const filters: FilterModel = {
    orderby: filterState.orderby,
    tags: filterState.tags,
    types: filterState.type,
    from: filterState.from,
    to: filterState.to,
    userIds: filterState.userIds,
  };

  return { filters };
};

export default useFilters;
