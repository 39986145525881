/**
 * main react app loader
 *
 */
import React, { useEffect } from 'react';
import { hot } from 'react-hot-loader/root';
import { useLocation } from 'react-router-dom';
import styles from './styles/app.module.scss';
import Router from './Router';
import { AuthService } from 'app/services';

/***/
const Auth = (AuthService as any);

/**
 * Application Class
 */
function App() {
  const location = useLocation();

  // This identifies user for analytics.
  useEffect(() => {
    if (location.pathname !== '/signin') {
      Auth.loadAuthUser();
    }
  }, [ Auth.isAuthenticated ]);

  return (
    <div className={styles.app}>
      <Router />
    </div>
  );
}

export default hot(App);
