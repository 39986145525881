/** @module components/modals */
import React, { useEffect, useState } from 'react';
import { defineMessages } from 'react-intl';
import { useSelector } from 'react-redux';
import { useLoc, useActions } from 'app/utils/hooks';
import { fetchTeamGroups } from 'app/actions/team-group';
import { RootState } from 'app/reducers';
import ModalWrapper from 'app/components/modals/ModalWrapper';
import { UserModel, TeamModel, TeamGroupModel } from 'app/models';
import { SelectList } from 'app/components/Layouts';
import { Dots } from 'app/components/Loading';
import { addItem, removeItem } from 'app/utils/array';
import Styles from './styles.module.scss';
import group_success_icon_png from 'app/images/group-success-icon.png';

type ResultType = {
  members: UserModel[];
  teamGroups: TeamGroupModel[];
};

type PropsDef = {
  show?: boolean;
  onClose?: (obj: { type: 'cancel' | 'close' }) => void; // 'cancel' on button click, 'close' on model close event
  closeText?: string;
  onDone?: (results: ResultType) => void;
  doneText?: string;
  team?: TeamModel;
  members?: UserModel[];
  teamGroups?: TeamGroupModel[];
};

/**
 * @class AddMembersToChannels
 *
 */
export default function AddMembersToChannels(props: PropsDef) {
  const { formatMessage } = useLoc();
  const actions = useActions({ fetchTeamGroups });
  const teamGroup = useSelector((state: RootState) => state.teamGroup);
  const [ selected, setSelected ] = useState(() => props.teamGroups || []);
  const [ teams, setTeams ] = useState([]);

  useEffect(() => {
    actions.fetchTeamGroups(props.team.id);
  }, []);

  useEffect(() => {
    if (!teamGroup.pending && teamGroup.data && teamGroup.data.length) {
      const general = {
        name: formatMessage(LOCALS.general_channel),
        membersCount: props.team.membersCount,
        locked: true,
        selected: true,
      };
      setTeams([ general, ...teamGroup.data ]);
    }
  }, [ teamGroup ]);

  const closeHandler = (isButton: boolean) => {
    if (props.onClose) {
      if (isButton) {
        props.onClose({ type: 'cancel' });
      } else {
        props.onClose({ type: 'close' });
      }
    }
  };

  const ListTemplate = ({ model }: { model: any }) => {
    return (
      <div className={Styles.listItem}>
        <h3>{model.name}</h3>
        <h6>{formatMessage(LOCALS.members_count, { count: model.membersCount})}</h6>
      </div>
    );
  };

  const handleSelectAll = (checked: boolean) => {
    if (checked) {
      setSelected(teamGroup.data);
    } else {
      setSelected([]);
    }
  };

  const handleItemChange = (item: any, checked: boolean) => {
    if (checked) {
      setSelected(addItem(selected, item));
    } else {
      setSelected(removeItem(selected, item));
    }
  };

  const handleSubmit = () => {
    if (props.onDone) {
      props.onDone({ members: props.members, teamGroups: selected });
    }
  };

  const Footer = () => {
    return (
      <div className={Styles.addMembersButtons}>
        <button
          className="btn btn-primary"
          onClick={handleSubmit}
        >{props.doneText || formatMessage(LOCALS.button_done)}</button>
        <button
          className="btn btn-outline-secondary"
          onClick={() => closeHandler(true)}
        >{props.closeText || formatMessage(LOCALS.button_cancel)}</button>
      </div>
    );
  };

  let content = (<Dots active={true} />);
  if (!teamGroup.pending && teamGroup.data != null) {
    if (teams && teams.length) {
      content = (
        <SelectList
          data={teams}
          template={ListTemplate}
          selected={selected}
          onSelectAll={handleSelectAll}
          onItemChanged={handleItemChange}
        />
      );
    } else {
      content = (<div>No Results</div>);
    }
  }

  return (
    <ModalWrapper
      containerClassName={Styles.addMemberModal}
      isOpen={props.show}
      onRequestClose={() => closeHandler(false)}
      title={formatMessage(LOCALS.modal_title)}
      footer={<Footer />}
    >
      <div className={Styles.addMembersToChannels}>
        <div className={Styles.container}>
          <div className={Styles.header}>
            <img src={group_success_icon_png} alt={formatMessage(LOCALS.add_members)} />
            <h3>{formatMessage(LOCALS.members_count_header, { count: (props.members || []).length })}</h3>
            <h6>{formatMessage(LOCALS.instructions)}</h6>
          </div>
          <div className={Styles.content}>{content}</div>
        </div>
      </div>
    </ModalWrapper>
  );
}

const LOCALS = defineMessages({
  modal_title: { id: 'add-members-channel.modal.title', defaultMessage: 'Add Members To Channels' },
  members_count: { id: 'add-members-channel.modal.members-count', defaultMessage: '{count} Members' },
  members_count_header: { id: 'add-members-channel.modal.members-count-header', defaultMessage: '{count} Member(s) Selected' },
  instructions: { id: 'add-members-channel.modal.instructions', defaultMessage: 'Select which Channels to add them to.' },

  add_members: { id: 'add-members-channel.modal.add-members', defaultMessage: 'Add Members' },
  button_cancel: { id: 'add-members-channel.modal.cancel-button', defaultMessage: 'Cancel' },
  button_done: { id: 'add-members-channel.modal.done-button', defaultMessage: 'Add To Selected' },

  general_channel: { id: 'add-members-channel.modal.general', defaultMessage: 'General' },
  add_members_pending: { id: 'add-members-channel.modal.pending-add', defaultMessage: 'Adding members to Channels...' },
});
