/**
 * @module Components.Routes
 *
 */
import React from 'react';
import { UpgradePlus, Authenticated } from 'app/components/Wrappers';
import UpgradeContainer from 'app/components/subscription/UpgradeContainer';

/**
 * @class Upgrade
 *
 */
export default function Upgrade() {
  return (
    <Authenticated>
      <UpgradePlus>
        <UpgradeContainer />
      </UpgradePlus>
    </Authenticated>
  );
}

