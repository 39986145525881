/**
  @module services
*/
import { Service, createService } from 'app/utils/service';
import { fetchPost, fetchPut } from 'app/utils/request';
import { reject } from 'rsvp';

const PHONE_VERIFICATION_FAILURE_REASONS = {
  unprocessable_entity: 'phone_verification_unprocessable_entity',
  unknown: 'phone_verification_unknown'
};

/**
 * @class PhoneVerificationService
 *
 */
class PhoneVerificationService extends Service {
  static _name = "PhoneVerificationService";

  /**
   * Request a start of a phone verification.
   *
   * @param {string} phone
   * @param {string} phone_country
   * @returns {Promise}
   */
  verify(phone, phone_country) {
    return fetchPost('verifications', { phone, phone_country }, { version: 1 })
      .catch(e => handleErrors(PHONE_VERIFICATION_FAILURE_REASONS, e));
  }

  /**
   * Request a confirmation of a phone verification with a code.
   *
   * @param {string} id
   * @param {string} code
   * @returns {Promise}
   */
  confirm(id, code) {
    return fetchPut('verifications/' + id, { code }, { version: 1 })
      .catch(e => handleErrors(PHONE_VERIFICATION_FAILURE_REASONS, e));
  }
}

const handleErrors = (messageTypes, e) => {
  if (e.errors && e.errors.length) {
    return reject(messageTypes[e.originalErrors.status] || messageTypes['unknown']);
  }
  return reject('unknown');
};

export default createService(PhoneVerificationService);
