/**
 * @module Components.Links
 *
 */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import config from 'config/environment';

/**
 * @class Support
 *
 */
export default function Support() {
  return (
    <a href={config.SUPPORT_URL}>
      <FormattedMessage
        id="app.links.support"
        defaultMessage="Support"
        description="Support link text"
      />
    </a>
  );
}

