/**
 * @module Utils.Hooks
 *
 */
import { Model } from "typedefs";
import { createUseFetch } from "fetch-suspense";
import { fetchApi, RInfo, RInit } from "app/utils/request/fetch";
import { serializeObject, serializeArray } from "app/utils/request/model";

type FetchApi = <T = Model>(
  input: RInfo,
  init?: RInit | undefined | null,
  lifespan?: number
) => T;
type CreateFetchApi = (fetch: typeof fetchApi) => FetchApi;

const createUseFetchExtended = createUseFetch as CreateFetchApi;
const useFetchPre = createUseFetchExtended(fetchApi);

/**
 *
 * @public
 * @method useFetch
 */
export default function useFetch<T = Model>(
  input: RInfo,
  init?: RInit,
  lifespan?: number
) {
  const data = useFetchPre<T>(input, init, lifespan);
  if (Array.isArray(data)) {
    return serializeArray<T>(data);
  }
  return serializeObject<T>(data);
}
