import { ngState } from "./bridge";

interface PaywallModalProps {
  displayModal?:
    | "library"
    | "explicit"
    | "segments"
    | "templates"
    | "spaceLimitReadOnly";
}

export default function paywallModalPro(props?: PaywallModalProps) {
  const [state] = ngState();

  if (state == null || state.app == null) {
    throw new Error("Paywall can't be called before initialization!");
  }

  const paywallModal = state.app.get("paywallModal") as any;

  if (!paywallModal) {
    throw new Error("paywallModal object not found in state.app");
  }

  // Determine which function to call based on props or use the default 'proUpgrade'
  if (props) {
    switch (props.displayModal) {
      case "explicit":
        paywallModal.explicit();
        break;
      case "library":
        paywallModal.library();
        break;
      case "segments":
        paywallModal.segments();
        break;
      case "templates":
        paywallModal.templates();
        break;
      case "spaceLimitReadOnly":
        paywallModal.spaceLimitReadOnly();
        break;
      default:
        paywallModal.proUpgrade();
    }
  } else {
    // Default behavior if no props provided
    paywallModal.proUpgrade();
  }
}
