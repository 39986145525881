/**
 * @module Library
 *
 */
import config from 'config/environment';
import { getFeatures } from 'app/utils/features';
import * as serviceWorker from 'app/serviceWorker';

interface AppInfo {
  version: string;
  environment: string;
  buildType: string;
  debugMode: boolean;
  features: {[key: string]: boolean };
}

declare global {
  interface Window {
    cn: AppInfo;
  }
}

export default function application() {
  const app: AppInfo = {
    version: config.VERSION,
    environment: config.ENVIRONMENT,
    buildType: config.BUILD,
    debugMode: config.DEBUG,
    features: getFeatures(),
  };

  if (isMobile()) {
    document.body.className = (`${document.body.className} mobile`).trim();
  }

  return window.cn = app;
}

const isMobile = () => {
  return (navigator.userAgent.match(/(Android|iPhone|iPad|iPod|BlackBerri|Opera Mini|IEMobile|WPDesktop)/i) != null);
};

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
if (config.INCLUDE_SERVICE_WORKER) {
  serviceWorker.register({
    serviceWorkerActive: config.INCLUDE_SERVICE_WORKER,
    publicUrl: config.PUBLIC_URL,
    // onUpdate: (data: any) => {
    //   console.log('ServiceWroker updated', data);
    // }
  });
} else {
  serviceWorker.unregister();
}
