/**
 * @module Utils
 *
 */
import { AnyObject } from 'typedefs';
import { uniq, clean, isArray } from './array';
import { isObject } from './object';
import { assert } from './debug';

interface HashObject<T> {
  [key: string]: T;
}

/**
 * @class HashMap
 *
 */
export default class HashMap<T extends any = any> {
  private _primaryKey: string = '';
  private _hash: HashObject<T> = {};

  constructor(hash?: HashObject<T> | T[], primaryKey?: string) {
    if (typeof primaryKey === 'string') {
      this._primaryKey = primaryKey;
    }

    if (hash != null) {
      if (isArray(hash)) {
        assert(
          !!(this._primaryKey != null && this._primaryKey.length),
          "primaryKey is required when passing an array to 'new HashMap(hash: Object[], primaryKey: string)'"
        );

        const mapList = (hash as T[]).map(item => {
          return { [item[this._primaryKey]]: item };
        });
        this._hash = Object.assign({}, ...mapList);
      } else if (isObject(hash)) {
        this._hash = (hash as HashObject<T>);
      } else {
        assert(
          false,
          `new HashMap(hash: Object) requires an object as the first param, you passed [ ${typeof hash} ]`
        );
      }
    }
  }

  get length(): number {
    return Object.keys(this._hash).length;
  }

  public set(key: string, value: T | T[]) {
    if (value != null) {
      if (typeof value === 'object') {
        if (Array.isArray(value)) {
          const dup: T[] = [ ...value ];
          value = dup;
        } else {
          value = { ...value };
        }
      }
    }
    this._hash[key] = (value as T);
  }

  public get(key: string): T {
    const value = this._hash[key];
    if (value != null) {
      if (typeof value === 'object') {
        if (Array.isArray(value)) {
          return (([ ...(value as T[]) ] as any) as T);
        } else {
          return { ...value };
        }
      }
    }
    return value;
  }

  public has(key: string): boolean {
    return this._hash.hasOwnProperty(key);
  }

  public first(): T {
    if (!this.length) {
      return undefined;
    }
    return this.values()[0];
  }

  public last(): T {
    if (!this.length) {
      return undefined;
    }
    return this.values()[this.length - 1];
  }

  public delete(key: string): void {
    if (this.has(key)) {
      delete this._hash[key];
    }
  }

  public values(): T[] {
    return Object.values(this._hash);
  }

  public keys(): string[] {
    return Object.keys(this._hash);
  }

  public indexOf(value: T): string {
    if (value != null) {
      if (this._primaryKey != null) {
        if (isObject(value)) {
          return value[this._primaryKey];
        }
      }

      const idx = this.values().indexOf(value);
      if (idx !== -1) {
        return (this.keys())[idx];
      }
    }
    return null;
  }

  public forEach(callback: (item: T, key?: string, idx?: number) => any): any {
    return this.keys().forEach((key: string, idx: number) =>
      callback(this.get(key), key, idx)
    );
  }

  public map(callback: (item: T, key?: string, idx?: number) => any): any[] {
    return this.keys().map((key: string, idx: number) =>
      callback(this.get(key), key, idx)
    );
  }

  public toObject() {
    return this._hash;
  }

  public get primaryKey() {
    return this._primaryKey;
  }
}


export function createMap<T extends AnyObject>(list: T[], key: string) {
  return new HashMap<T>(list, key);
}

export function mergeMaps<T extends AnyObject>(...maps: Array<HashMap<T>>): HashMap<T> {
  // get list of all primaryKey's from each map
  const primaryKey = uniq(clean(maps.map(item => item != null ? item.primaryKey : null)));

  // all primaryKey's must match so uniq() should result in an array of 1 primaryKey
  assert(
    primaryKey.length === 1,
    `mergeMaps can only merge maps with matching primaryKey's. mergeMaps found [ ${primaryKey.join(', ')} ]`
  );

  const mapData = Object.assign({}, ...maps.map((inst: HashMap<T>) => inst != null ? inst.toObject() : {}));
  return new HashMap<T>(mapData, primaryKey[0]);
}

