/**
 * @module Utils.File
 *
 */

/**
 * Determins if value is a File type.
 *
 * @param {any} file
 * @returns {boolean}
 */
export default function isFile(file: any): boolean {
  return file instanceof File;
}
