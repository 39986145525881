/**
 * @module Utils.Request
 *
 */

interface ErrorStatusObject {
  error: string;
  status: string;
}

export interface ErrorResponse {
  errors?: string[];
  originalErrors?: string[];
}

export default function errorHandler(err: any): ErrorResponse {
  if (err == null) {
    return {};
  }

  if (err.error) {
    if (err.error && typeof err.error === 'string' && typeof err.status === 'string') {
      return handleErrorStatus(err);
    } else {
      return createErrorObject(parseErrorByType(err.error), err.error);
    }
  } else if (err.errors) {
    return createErrorObject(parseErrorByType(err.errors), err.errors);
  } else {
    return createErrorObject(parseErrorByType(err), err);
  }
}

function handleErrorStatus(err: ErrorStatusObject) {
  const { error, status } = err;
  const errStr = error + '.' + status;
  return createErrorObject([ errStr ], err);
}

function createErrorObject(errors: string[], originalErrors: any) {
  return { errors, originalErrors };
}

function parseErrorByType(error: any): string[] {
  if (error == null) { return []; }

  if (Array.isArray(error)) {
    const listOut: string[] = [];
    return listOut.concat(...error.map((e: string | string[] | {[key: string]: any}) => parseErrorByType(e)));
  } else if (typeof error === 'object') {
    return [ ...normalizeErrors(error) ];
  } else if (typeof error === 'string' && FIELD_ERRORS[error] != null) {
    return [ FIELD_ERRORS[error] ];
  } else {
    return [ error ];
  }
}

const FIELD_ERRORS: { [key: string]: string } = {
  'is invalid': 'invalid',
  "can't be blank": 'empty',
  'is too short': 'length',
  'is already taken': 'already_taken',
  'is expired': 'expired',
  'is required': 'required',
  'not supported': 'unsupported',
  'not found': 'not_found',
  'Promo code does not support plan term.': 'unsupported',
  'Failed to fetch': 'no_connection',
};

const mapError = (name: string, type: string): string => {
  const errorIndex: string | undefined = Object.keys(FIELD_ERRORS).find(e => type.indexOf(e) !== -1);
  if (errorIndex == null) {
    return type[0];
  }
  return `${name}_${FIELD_ERRORS[errorIndex]}`;
};

const normalizeErrors = (error: {[key: string]: any}): string[] => {
  return Object.keys(error).map(k => {
    if (Array.isArray(error[k])) {
      return mapError(k, error[k][0]);
    } else {
      return mapError(k, error[k]);
    }
  });
};
