/**
 * @module Reducers.Coupon
 *
 */
import { RequestError } from 'typedefs';
import { CouponModel } from 'app/models';
import { CouponAction } from 'app/actions/coupon';

export type CouponState = {
  data: CouponModel | null,
  error: RequestError | null,
  pending: boolean,
};

const initialState: CouponState = {
  data: null,
  error: null,
  pending: false,
};

export default function couponReducer(state = initialState, action: CouponAction): CouponState {
  switch (action.type) {
    case '@coupon.fetch.success':
      return { ...state, data: action.payload, pending: false, error: null };
    case '@coupon.fetch.error':
      return { ...state, pending: false, error: action.error };
    case '@coupon.fetch.pending':
      return { ...state, pending: true, error: null };
    case '@coupon.clear':
      return { ...state, data: null, pending: false, error: null };
    default:
      return state;
  }
}
