/**
 * @module Components.Filter
 *
 */
import React, { useEffect, useState, useRef } from 'react';
import { useLoc, useStoreActions } from 'app/utils/hooks';
import { cls } from 'app/utils';
import { fetchSpaces } from 'app/actions/space';
import { SpaceModel } from 'app/models';
import CheckBox from 'app/components/inputs/CheckBox/Base';
import SearchBar from 'app/components/inputs/SearchBar';
import styles from './styles.module.scss';
import { FormattedHTMLMessage } from 'react-intl';
import LOCALES from './locale';

type PropsDef = {
  selectedSpaces: any;
  onSubmit: (value: any, objectTypes?: any) => void;
};

const initSpaces: SpaceModel[] = [];
const OBJECT_TYPES = ["athlete_space"];

/**
 * Renders a select drop down prefilled with spaces to select from
 *
 * @name SpacesSelect
 * @param {Properties} props
 * @return {Element}
 */
export default function SpacesSelect(props: PropsDef) {
  // fetch spaces list from api
  const { space } = useStoreActions({ space: () => fetchSpaces({}) });
  const [ spaces, setSpaces ] = useState(initSpaces);
  const [ search, setSearch] = useState('');
  const [ selectedSpaces, setSelectedSpaces] = useState([]);
  const [ dropdownOpened, setDropdownOpened ] = useState(false);
  const dropdownRef = useRef(null);
  const { formatMessage } = useLoc();

  useEffect(() => {
    if (space.data != null) {
      setSpaces(space.data);
    }

    if (props.selectedSpaces) {
      setSelectedSpaces(props.selectedSpaces.filter((target: SpaceModel) => OBJECT_TYPES.includes(target.object)));
    }
  }, [ space.data, props.selectedSpaces ]);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  useEffect(() => {
    if (search && search.length) {
      const filter = space.data.filter((item: SpaceModel) => {
        const regex = new RegExp(search, 'i');
        if (regex.test(item.athleteName) || regex.test(item.sport)) {
          return true;
        }
        return false;
      });
      setSpaces(filter);
    } else {
      setSpaces(space.data || []);
    }
  }, [ search ]);

  const updateSearch = (value: string) => {
    (value !== null && value.length > 0) ? setSearch(value) : setSearch('');
  };

  const handleClickOutside = (e: any) => {
    if (dropdownOpened && dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setDropdownOpened(false);
      setSearch('');
    }
  };

  const toggleDropdown = () => {
    setDropdownOpened(!dropdownOpened);

    if (!dropdownOpened) {
      setSearch('');
    }
  };

  const onSubmit = () => {
    toggleDropdown();
    props.onSubmit(selectedSpaces, OBJECT_TYPES);
  };

  const toggleSpaceClick = (space: SpaceModel) => {
    if (selectedSpaces.includes(space)) {
      setSelectedSpaces((selectedSpaces) => selectedSpaces.filter(_space => _space.id !== space.id));
    } else {
      setSelectedSpaces([ ...selectedSpaces, space ]);
    }
  };

  return (
    <div ref={dropdownRef} className={cls(styles.dropdown)}>
      <button onClick={toggleDropdown} className={cls(styles.filterButton, (dropdownOpened || selectedSpaces.length > 0 ? styles.active : ""))}>
        <i className={cls("ico", "ico-tspace", styles.icon)}></i>
        <span className={styles.buttonCaption}>
          <FormattedHTMLMessage id={LOCALES.spaces_plural.id} defaultMessage={formatMessage(LOCALES.spaces_plural, { count: selectedSpaces.length })} />
        </span>
        <i className={cls("ico", "ico-chevron", styles.chevron)}></i>
      </button>

      {
        dropdownOpened && (
          <div className={styles.dropdownBody}>
            <SearchBar
              className={styles.search}
              placeholder={formatMessage(LOCALES.spaces_search_placeholder)}
              onChange={updateSearch}
            />

            <div className={styles.dropdownOptions}>
              {spaces.map(space => (
                <div key={space.id}>
                  <div className={styles.dropdownItem} onClick={() => toggleSpaceClick(space)}>
                    <img src={space.avatar} width="44" height="44"/>
                    <div className={styles.name}>
                      {space.athleteName}
                      <div className={styles.sport}>
                        {space.sport}
                      </div>
                    </div>

                    <CheckBox
                      className={styles.checkbox}
                      disabled={true}
                      checked={selectedSpaces.indexOf(space) > -1}
                    />
                  </div>
                </div>
              ))}
            </div>

            <div className={styles.buttonsContainer}>
              <button onClick={toggleDropdown} className={cls("btn", "btn-outline-secondary")}>
                {formatMessage(LOCALES.cancel)}
              </button>

              <button onClick={onSubmit} className={cls("btn", "btn-primary")}>
                {formatMessage(LOCALES.filter)}
              </button>
            </div>
          </div>
        )
      }
    </div>
  );
}
