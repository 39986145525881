/**
 * @module Utils.File
 *
 */
import isFile from './isFile';
import mimeType from './mimeType';

/**
 * Gets the ContentType of a file
 *
 * @method getContentType
 * @param {File|string}
 * @return {string}
 */
export default function getContentType(file: File | string): string {
  if (isFile(file)) {
    return (file as File).type;
  }
  return mimeType((file as string));
}
