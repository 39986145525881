/**
 * @module Utils.Actions
 *
 */
import { Dispatch } from 'react';
import { AnyFunction, FunctionResult } from 'typedefs';

/**
 * Creates an action type for typescript actions
 *
 * @method createActionType
 * @param {string} name
 * @param {any} payload - optional
 * @return {({type: 'string literal', payload?: any})}
 */
export function actionType<Type extends string = string, Payload = undefined>(name: Type, payload?: Payload) {
  let result: { type: Type, payload?: Payload } = { type: name as Type };
  if (payload != null) {
    result = { type: name as Type, payload: payload as Payload };
  }
  return result;
}


/**
 * @method actionFunc
 * @param {string} name
 * @param {((...args: any[]) => any)} payloadFunc
 * @return {((data?: any) => actionType)}
 */
export function actionFunc<Type extends string = string, Payload extends AnyFunction = AnyFunction>(name: Type, payloadFunc?: Payload) {
  return (payloadContainer?: any) => {
    let payload;
    if (payloadFunc != null) {
      payload = payloadFunc(payloadContainer);
    }
    return actionType<Type, FunctionResult<Payload>>(name, payload);
  };
}

export function actionUpdater<A extends any, F extends AnyFunction>(dispatch: Dispatch<A>, func: F, ...args: any[]): F {
  const updater = (...overrides: any[]) => {
    const newArgs = args.map((v, i) => overrides[i] !== undefined ? overrides[i] : v);
    const dispatchFunc = func.apply(null, newArgs);
    return dispatchFunc(dispatch);
  };

  return (updater as F);
}

export type UpdaterFunc = ReturnType<typeof actionUpdater>;
