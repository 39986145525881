import React, { useCallback, useEffect, useState } from "react";
import styles from "./styles.module.scss";
import GettingStartedProgress from "app/components/GettingStarted/GettingStartedProgress";
import { cls } from "app/utils";
import { useActions, useSelector } from "app/utils/hooks";
import { createTag, clearTags } from "../../../actions/tag";
import { RootState } from "typedefs";
import { TagsProps } from "../Tags.types";

const AddTag = ({ changeToTagsUrl }: TagsProps) => {
  const tagAction = useActions({ createTag, clearTags });
  const [tagName, setTagName] = useState<null | string>(null);
  const [tagIsEmpty, setTagIsEmpty] = useState(false);

  const tagState = useSelector((state: RootState) => state.tag);

  const tagHandler = (name: string) => {
    if (name && name.length) {
      setTagName(name);
      setTagIsEmpty(false);
    } else {
      setTagIsEmpty(true);
    }
  };

  const submitHandler = useCallback(() => {
    tagAction.clearTags();
    if (tagName && tagName.length > 0) {
      tagAction.createTag(tagName.trim()).then(() => {
        setTagIsEmpty(false);
        changeToTagsUrl();
      });
    } else {
      setTagIsEmpty(true);
    }
  }, [tagName, tagState]);

  useEffect(() => {
    if (!tagState.pending && tagState && tagState.error) {
      alert(tagState.error ? tagState.error : "Something went wrong");
    }
  }, [tagState]);

  return (
    <div className={styles.container}>
      <GettingStartedProgress />
      <div className={styles.add_tag_container}>
        <div className={styles.panel}>
          <h1>Add Tag</h1>
        </div>
        <form className={styles.add_new_wrapper}>
          <span className={cls(styles.input_wrapper, "w-2-lg")}>
            <label htmlFor="tag">Name</label>
            <input
              type="text"
              id="tag"
              name="tag"
              onChange={(e) => tagHandler(e.target.value)}
              className={cls({
                [styles.error]: tagIsEmpty,
              })}
            />
          </span>
          <span className={styles.buttons_container}>
            <button
              className="btn btn-primary"
              onClick={submitHandler}
              disabled={tagState.pending || tagIsEmpty}
              type="submit"
            >
              <span>{tagState.pending ? "Please Wait..." : "Create"}</span>
            </button>
            <button
              className={cls("btn btn-outline-secondary")}
              onClick={changeToTagsUrl}
              ng-disabled="processing"
            >
              Cancel
            </button>
          </span>
        </form>
      </div>
    </div>
  );
};

export default AddTag;
