/**
 * @module Components.StripeForm
 *
 */
import React, { useState, CSSProperties } from 'react';
import { MessageDescriptor } from 'react-intl';
import { cls, isEmpty } from 'app/utils';
import { ElementChangeResponse } from 'app/utils/stripe';
import { useLoc } from 'app/utils/hooks';
import FormItem from 'app/components/inputs/FormItem';
import styles from './styles.module.scss';
// import LOCALES from './locale';

export type PropsDef = {
  elementType?: any,
  placeholder?: MessageDescriptor,
  value?: string | null,
  onReady?: (el: ElementChangeResponse) => void,
  onChange?: (el: ElementChangeResponse) => void,
  onFocus?: (el: ElementChangeResponse) => void,
  onBlur?: (el: ElementChangeResponse) => void,
  error?: string | MessageDescriptor | null,
  disabled?: boolean,
};


type Styles = {
  base: CSSProperties & {
    ":focus": CSSProperties,
    "::placeholder": CSSProperties
  },
  invalid: CSSProperties,
};

type State = {
  error: MessageDescriptor | string | null,
};

const initState: State = {
  error: null
};

function StripeElementContainer(props: PropsDef) {
  const { formatMessage } = useLoc();
  const [ state, setState ] = useState(initState);
  const err = state.error || props.error;
  const mainClass = cls(styles.formControl, styles.stripe);

  let placeholder = '';
  if (!isEmpty(props.placeholder)) {
    if (typeof props.placeholder === 'string') {
      placeholder = props.placeholder;
    } else {
      placeholder = formatMessage(props.placeholder);
    }
  }

  const onStateChange = (el: ElementChangeResponse) => {
    // handle errors
    let error: string | MessageDescriptor = el.error && el.error.code ? el.error.code : null;
    if (el.error && el.error.code) {
      error = el.error.message;
    }
    setState({ ...state, error });

    // call parent element onChange
    if (props.onChange) {
      props.onChange(el);
    }
  };

  return (
    <FormItem className={mainClass} error={err}>
      <div className={cls(styles.formInput, props.disabled ? styles.disabled : '')}>
        <props.elementType
          onReady={props.onReady}
          onBlur={props.onBlur}
          onFocus={props.onFocus}
          onChange={onStateChange}
          placeholder={placeholder}
          value={props.value}
          style={INPUT_STYLE}
          disabled={props.disabled}
        />
      </div>
    </FormItem>
  );
}

export default StripeElementContainer;


export const INPUT_STYLE: Styles = {
  'base': {
    'border': '1px solid #ccc',
    'color': '#363636',
    'fontFamily': '"Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif',
    'fontSize': '16px',
    ':focus': {
      'border': '1px solid #039be5',
    },
    '::placeholder': {
      'color': '#8c8c8c'
    }
  },
  'invalid': {
    'color': '#eb1c26'
  }
};


// type InputMap = {
//   [index: string]: MessageDescriptor,

//   invalid_number: MessageDescriptor,
//   incomplete_number: MessageDescriptor,
//   invalid_expiry_year_past: MessageDescriptor,
//   incomplete_expiry: MessageDescriptor,
//   invalid_cvc: MessageDescriptor,
//   incomplete_cvc: MessageDescriptor,
//   unknown: MessageDescriptor
// };

// const INPUT_ERROR_MAP: InputMap = {
//   invalid_number: LOCALES.error_invalid_card_number,
//   incomplete_number: LOCALES.error_required_card_number,
//   invalid_expiry_year_past: LOCALES.error_invalid_card_expiry,
//   incomplete_expiry: LOCALES.error_required_card_expiry,
//   invalid_cvc: LOCALES.error_invalid_security_code,
//   incomplete_cvc: LOCALES.error_required_security_code,
//   unknown: LOCALES.error_unknown
// };
