/** @module actions */
import { Dispatch } from 'react';
import { ActionType, action } from 'typesafe-actions';
import { fetchGet, fetchPost } from 'app/utils/request';
import { TeamGroupModel, TeamGroupMemberModel } from 'app/models';

const fetchAllPending = () => action('@team-group.fetch.pending');
const fetchAllError = (err: string) => action('@team-group.fetch.error', err);
const fetchAllSuccess = (data: TeamGroupModel[]) => action('@team-group.fetch.success', data);

export function fetchTeamGroups(teamId: string) {
  return (dispatch: Dispatch<TeamGroupActions>) => {
    dispatch(fetchAllPending());

    return fetchGet('/team_groups', { teamId })
      .then((data: TeamGroupModel[]) => dispatch(fetchAllSuccess(data)))
      .catch(err => dispatch(fetchAllError(err)));
  };
}

export type ErrObj = {
  id: string;
  error: string;
};

export type BatchResult = {
  success: TeamGroupMemberModel[];
  error: ErrObj[];
};

const addMembersPending = () => action('@team-group-member.post.pending');
const addMembersDone = (data: BatchResult) => action('@team-group-member.post.done', data);

export function addMembersToGroups(ids: string[], memberIds: string[]) {
  return (dispatch: Dispatch<TeamGroupActions>) => {
    dispatch(addMembersPending());

    const result: BatchResult = {
      success: [],
      error: []
    };

    return Promise.all(ids.map(id => {
      return fetchPost(`/team_groups/${id}/memberships`, { memberIds }, { version: 2 })
        .then((model: TeamGroupMemberModel) => (result.success.push(model), model))
        .catch((e: any) => (result.error.push({ id, error: e }), undefined));
    }))
    .then(() => dispatch(addMembersDone(result)));
  };
}

/*** return actions ***/

const thisActions = {
  fetchAllPending,
  fetchAllError,
  fetchAllSuccess,
  addMembersPending,
  addMembersDone
};

export type TeamGroupActions = ActionType<typeof thisActions>;

export const actions = {
  fetchTeamGroups,
  addMembersToGroups,
};
