import {
  fetchOthersSpaces,
  fetchSpaces,
  fetchMyOthersSpaces,
} from "app/actions/space";
import SpaceModel from "app/models/SpaceModel";
import { useActions, useSelector } from "app/utils/hooks";
import { useState } from "react";
import { RootState } from "typedefs";

interface Props {
  debouncedSearch: string | null;
}

type AbortControllerType = {
  abort: AbortController;
};

const useFetchSpaces = ({ debouncedSearch }: Props) => {
  const force_per_page = 100;

  const spaceActions = useActions({
    fetchSpaces,
    fetchMyOthersSpaces,
    fetchOthersSpaces,
  });
  const { page, query } = useSelector((state: RootState) => state.space);

  const [loading, setLoading] = useState(false);

  // fetch all spaces when using search
  const fetchAllSpaces = async ({ abort }: AbortControllerType) => {
    try {
      setLoading(true);

      const res: SpaceModel[] = await spaceActions.fetchSpaces(
        {
          ...query,
          athlete_name: debouncedSearch,
        },
        abort ? abort.signal : undefined
      );
      if (res && Array.isArray(res)) {
        setLoading(false);
        return res;
      } else {
        setLoading(false);
        return [];
      }
    } catch (error) {
      return [];
    } finally {
      setLoading(false);
    }
  };

  const fetchInitialSpaces = async (
    abort?: AbortController
  ): Promise<SpaceModel[]> => {
    try {
      setLoading(true);

      const res: SpaceModel[] = await spaceActions.fetchMyOthersSpaces({
        paramsMySpaces: {
          ...query,
        },
        paramsOthersSpaces: {
          page,
          ...query,
          force_per_page,
        },
        signal: abort ? abort.signal : undefined,
      });

      if (res && Array.isArray(res)) {
        setLoading(false);
        return res;
      } else {
        setLoading(false);
        return [];
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
      return [];
    }
  };

  // fetch others endpoint when the initial fetch is done and want to make pagination
  const fetchSpacesWithPagination = async (
    abort?: AbortController
  ): Promise<SpaceModel[]> => {
    try {
      setLoading(true);

      const res: SpaceModel[] = await spaceActions.fetchOthersSpaces(
        {
          page,
          ...query,
          force_per_page,
          athlete_name: debouncedSearch,
        },
        abort ? abort.signal : undefined
      );

      if (res && Array.isArray(res)) {
        setLoading(false);
        return res;
      } else {
        setLoading(false);
        return [];
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
      return [];
    }
  };

  return {
    loading,
    fetchAllSpaces,
    fetchInitialSpaces,
    fetchSpacesWithPagination,
  };
};

export default useFetchSpaces;
