import { action, ActionType } from "typesafe-actions";

export interface scheduleDateProps {
  pickedAt: number | null;
  pickedIn: string;
}

const pending = () => action("@schedulePost.fetch.pending");
const error = (err: string) => action("@schedulePost.fetch.error", err);
export const scheduleIn = (scheduleIn: scheduleDateProps) =>
  action("@schedulePost.fetch.scheduleIn", scheduleIn);

const pendingSchedulePost = () => action("@schedulePost.create.pending");
const errorSchedulePost = (err: string) =>
  action("@schedulePost.create.error", err);

export const clearSchedulePost = () => action("@schedulePost.clear");

const thisActions = {
  pending,
  error,
  scheduleIn,
  pendingSchedulePost,
  errorSchedulePost,
  clearSchedulePost,
};

export type SchedulePostAction = ActionType<typeof thisActions>;
