/** @module utils */
import PropTypes from 'prop-types';

export { default as isNone } from './isNone';
export { default as isEmpty } from './isEmpty';
export { default as isBlank } from './isBlank';
export { default as cls } from './classNames';
export { default as hasPermission } from './hasPermission';
export { default as errorLogger } from './errorLogger';

/**
 * react-intl messageDescriptor type
 * this is used for PropTypes when expecting
 * a messageDescripter and not a string
 */
export const ReactIntlDescriptor = PropTypes.shape({
  id: PropTypes.string,
  defaultMessage: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
});

