/**
 * @module Components.Wrappers
 *
 */
import React from 'react';
import { Spinner } from 'app/components/Loading';
// import cn_plus_image from 'app/images/cn-plus-logo.png';
// import cn_pro_image from 'app/images/cn-pro-logo-white.png';
import cn_logo from 'app/images/edufii-logo2.png';
import styles from './styles.module.scss';

type PropsDef = React.PropsWithChildren<{
  loading?: boolean;
}>;

/**
 * @class UpgradePlus
 *
 */
export default function UpgradePlus(props: PropsDef) {
  let children = props.children;
  if (props.loading) {
    children = (<Spinner />);
  }

  return (
    <div className={styles.upgradeWrapper}>
      <div className={styles.header}>
        <span><img src={cn_logo} alt="CoachNow+" /></span>
      </div>

      <div className={styles.body}>{children}</div>
    </div>
  );
}
