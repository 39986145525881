/** @module components/Layouts */
import React from 'react';
import { defineMessages } from 'react-intl';
import { Loc } from 'app/components/helpers';
import { cls } from 'app/utils';
import Styles from './styles.module.scss';

/***/
type PropsDef = React.PropsWithChildren<{
  className?: string;
  missingTerm?: string;
}>;

/**
 * @name NoFilteredResults
 */
export default function NoFilteredResults(props: PropsDef) {
  return (
    <div className={cls(Styles.noFilteredResults, props.className)}>
      <h3><Loc value={LOCALES.no_results} /></h3>
      <p className={cls(Styles.message, props.missingTerm && props.missingTerm.length ? 'show' : '')}>
        <Loc value={{ ...LOCALES.no_results_message, values: { message: props.missingTerm }}} />
      </p>
    </div>
  );
}

const LOCALES = defineMessages({
  no_results: { id: 'no-results.no-results.key', defaultMessage: 'No Results' },
  no_results_message: { id: 'no-results.no-results.message', defaultMessage: 'Nothing found for "{message}", please search for something else.' }
});
