import React from "react";
import styles from "./styles.module.scss";
import Button from "app/components/Button/ButtonVariant";
import { cls } from "app/utils";
import { TagSelect } from "app/components/inputs/SelectBox";
import { PostingFormProps } from "./index.types";
import usePostingForm from "./indexModel";
import CreateMedia from "./CreateMedia";
import LocationDropDown from "../LocationsDropdown";
import AttachmentPreview from "./AttachmentPreview";
import ScheduledAtPicker from "../ScheduledPosts/ScheduledAtPicker";

const PostingForm = ({
  attachment,
  expanded,
  canSelectMedia,
  canSchedule = true,
  canUploadMedia,
  hasAddMedia = true,
  hasSchedule = true,
  hasLocationSelect = true,
  hasTagSelect = true,
  onPost,
  textAreaHandler,
  onTagsChange,
  onScheduleChange,
  errorMessage,
}: PostingFormProps) => {
  const { pending, mediaData } = usePostingForm();

  return (
    <>
      <div className={styles.container}>
        <div className={styles.content_wrapper}>
          <textarea
            placeholder="Create post..."
            className={cls(styles.textArea, {
              [styles.expanded]: expanded,
            })}
            onChange={(e) => textAreaHandler(e.target.value)}
          />
          {hasTagSelect && (
            <TagSelect
              defaultValue={attachment ? (attachment.tags as []) : null}
              onChange={onTagsChange as any}
            />
          )}

          {attachment && <AttachmentPreview attachment={attachment} />}

          <span className={styles.footer_buttons}>
            <div className={styles.buttons_wrapper}>
              {hasLocationSelect && (
                <LocationDropDown classNames={styles.location_btn} />
              )}
              {hasAddMedia && (
                <CreateMedia
                  hasPermission={canUploadMedia}
                  titleContent="Media"
                  icon={<i className="ico ico-media" />}
                  canSelectMedia={canSelectMedia}
                />
              )}
              {canSchedule && hasSchedule && (
                <ScheduledAtPicker onChange={(e) => onScheduleChange(e)} />
              )}
            </div>
            <div className={styles.buttons_wrapper}>
              <a href="/resources">
                <Button
                  title={"Cancel"}
                  buttonType="secondary-outline"
                  buttonStyles={styles.control_btn}
                />
              </a>
              <Button
                title={"Create"}
                buttonType="primary"
                disabled={
                  !canUploadMedia || pending || errorMessage || !mediaData
                }
                onClickFunc={onPost}
              />
            </div>
          </span>
          {errorMessage && (
            <p className={styles.error_msg}>Please select location</p>
          )}
        </div>
      </div>
    </>
  );
};

export default PostingForm;
