import React from "react";
import styles from "./styles.module.scss";
import ModalLayout from "../Layouts/ModalLayout/ModalLayout";
import { cls } from "app/utils";
import { useScheduleModal } from "./indexModel";
import CustomSelect from "../inputs/SelectBox/CustomSelect";
import { scheduleDateProps } from "app/actions/schedule";

interface ScheduleModalProps {
  isOpen: boolean;
  onClose: () => void;
  onScheduleChange: (pickedAt: number | string) => void;
  scheduledAt: scheduleDateProps;
}

const ScheduleModal = (Props: ScheduleModalProps) => {
  const { isOpen, onClose, onScheduleChange, scheduledAt } = Props;

  const {
    handleSubmit,
    selectTimeOptions,
    selectOptions,
    handleScheduleSelect,
    handleScheduleTimeSelect,
    selectedValue,
    selectedTime,
  } = useScheduleModal({
    onClose,
    onScheduleChange,
    scheduledAt,
  });

  return (
    <ModalLayout isOpen={isOpen} onClose={onClose}>
      <div className={styles.schedule_container}>
        <h1>Schedule</h1>
        <p>
          After applying the template, how much time should pass before this
          post appears?
        </p>
        <form className={styles.day_select_wrapper}>
          <div className={styles.input_wrapper}>
            <label htmlFor="days">Wait for</label>
            <div className={styles.timeZoneWrapper}>
              <CustomSelect
                placeholder={"0 (Post today)"}
                className={styles.customSelectOne}
                value={selectedValue}
                inputClassName={styles.selectInputOne}
                __options={selectOptions}
                onSelectFunc={handleScheduleSelect}
                hideArrow
              />
              <hr />
              <CustomSelect
                placeholder={"Days"}
                className={styles.time_zone}
                __options={selectTimeOptions}
                onSelectFunc={handleScheduleTimeSelect}
                value={selectedTime}
              />
            </div>
          </div>
        </form>
        <button
          type="button"
          className={cls(styles.done_btn, "btn btn-primary")}
          onClick={handleSubmit}
        >
          Done
        </button>
      </div>
    </ModalLayout>
  );
};

export default ScheduleModal;
