/**
 * @module Actions.Stripe
 *
 */
import { Dispatch } from 'react';
import { RequestError } from 'typedefs';
import { action, ActionType } from 'typesafe-actions';
import { MessageDescriptor, defineMessages } from 'react-intl';
import { track } from 'app/utils/analytics-helpers';
import { fetchPost, fetchPut } from 'app/utils/request';
import { PaymentInfoModel, SubscriptionModel } from 'app/models';

const pending = () => action('@stripe.post.pending');
const error = (error: RequestError) => action('@stripe.post.error', error);
const success = (resp: PurchaseResponse) => action('@stripe.post.success', resp.paymentInfo);
const updateSuccess = (resp: any) => action('@stripe.post.success', resp);

const actionHandlers = { pending, error, success };

export type StripeAction = ActionType<typeof actionHandlers>;

export interface PurchaseParams {
  token: string;
  couponCode?: string;
  productKind: string;
  planCode: string;
}

interface PurchaseResponse {
  paymentInfo: PaymentInfoModel;
  subscriptions: SubscriptionModel[];
}

export const purchaseSubscription = (params: PurchaseParams) => {
  return (dispatch: Dispatch<StripeAction>) => {
    dispatch(pending());

    return fetchPost('stripe/checkout', params, { version: 1 })
      .then((resp: PurchaseResponse) => {
        // track plan purchase
        track('Purchase Completed', {
          SKU: 'Stripe' + params.productKind,
          Source: 'Web',
          PlanCode: params.planCode,
          Coupon: params.couponCode
        });
        return dispatch(success(resp));
      })
      .catch(err => dispatch(error(mapErrorMessage(err))));
  };
};

export const updateSubscription = (planCode: string, coupon: string) => {
  return (dispatch: Dispatch<StripeAction>) => {
    dispatch(pending());

    return fetchPut('subscriptions/update', { planCode, coupon }, { version: 1 })
      .then((resp: any) => dispatch(updateSuccess(resp)))
      .catch(err => dispatch(error(mapErrorMessage(err))));
  };
};

export const actions = {
  purchaseSubscription, updateSubscription
};

const LOCALE = defineMessages({
  DECLINED_ERROR: { id: 'error.declined.api', defaultMessage: 'The was an error with your card. Please try another card.' },
  UNKNOWN_ERROR: { id: 'error.unknown.api', defaultMessage: 'An unknown error occurred. Please try again.' },
  SEVERE_UNKNOWN_ERROR: { id: 'error.unknown.api.severe', defaultMessage: 'An unknown error occurred. Please contact customer service.' },
});

const ERROR_MAP: { [key: string]: MessageDescriptor } = {
  unknown_error: LOCALE.UNKNOWN_ERROR,
  "checkout_failed.card_invalid": LOCALE.DECLINED_ERROR,
  card_invalid: LOCALE.DECLINED_ERROR,

  // show message for customer to contact
  // customer service if these errors happen
  // on production server.
  term_kind_required: LOCALE.SEVERE_UNKNOWN_ERROR,
  product_kind_required: LOCALE.SEVERE_UNKNOWN_ERROR,
  token_required: LOCALE.SEVERE_UNKNOWN_ERROR,
};

function mapErrorMessage(err: any): RequestError {
  let error = ERROR_MAP.unknown_error;
  if (err.errors && err.errors.length && ERROR_MAP[err.errors[0]] != null) {
    error = ERROR_MAP[err.errors[0]];
  }
  return error;
}
