/**
 * @module Components.Subscription
 *
 */
import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { cls } from "app/utils";
import { UserState } from "app/reducers/user";
import { Spinner } from "app/components/Loading";
import { Loc } from "app/components/helpers";
import { useLoc, useActions } from "app/utils/hooks";
import { updateSubscription } from "app/actions/stripe";
import UpgradeErrorModal from "app/components/modals/UpgradeErrorModal";
import PlanTypes, { PLAN_TYPES } from "./../Upgrade/PlanTypes";
import { COACH_PRO_MONTHLY, COACH_PRO_YEARLY } from "./../Upgrade/PlanForm";
import SuccessPage from "./../Upgrade/SuccessPage";
import { FormattedMessage, FormattedHTMLMessage } from "react-intl";
import { openIntercom } from "app/utils/intercom";
import { decodeQuery } from "app/utils/object";
import styles from "./styles.module.scss";
import LOCALES from "./locale.js";

type PropsDef = {
  self: UserState;
};

type PlanPropsDef = {
  source?: string;
  name?: string;
  planCode?: string;
  licensed?: boolean;
  canUpgrade?: boolean;
};

export const UPGRADE_PLANS_MATCH = "coachpro";
const INIT_PLAN = COACH_PRO_YEARLY;
const COUPONS_DATA: any = {
  FOUNDER4LIFE: {
    discount: 0.35,
    allowedUpgradePlan: null,
    allowedUpgradePeriod: null,
  },
  "25OFFLIFE": {
    discount: 0.25,
    allowedUpgradePlan: null,
    allowedUpgradePeriod: null,
  },
  "20OFFLIFE": {
    discount: 0.2,
    allowedUpgradePlan: null,
    allowedUpgradePeriod: null,
  },
  FOUNDERMON25PRO: {
    discount: 0.25,
    allowedUpgradePlan: COACH_PRO_YEARLY,
    allowedUpgradePeriod: "year",
  },
  FOUNDERMON30PRO: {
    discount: 0.3,
    allowedUpgradePlan: COACH_PRO_YEARLY,
    allowedUpgradePeriod: "year",
  },
  PRO10MONFOUNDER: {
    discount: 0.1,
    allowedUpgradePlan: COACH_PRO_MONTHLY,
    allowedUpgradePeriod: "month",
  },
  PRO20MONFOUNDER: {
    discount: 0.2,
    allowedUpgradePlan: COACH_PRO_MONTHLY,
    allowedUpgradePeriod: "month",
  },
};

const PLANS: any = {
  "coachpro+monthly": { amount: 49.99, period: "month" },
  "coachpro+yearly": { amount: 499.0, period: "year" },
};

export default function Status(props: PropsDef) {
  const actions = useActions({ updateSubscription });
  const [pending, setPending] = useState<boolean>(false);
  const [error, setError] = useState<string>(null);
  const [back, setBack] = useState<boolean>(false);
  const [plan, setPlan] = useState<PlanPropsDef>({
    source: null,
    name: null,
    planCode: null,
  });
  const [hasActivePro, setHasActivePro] = useState<boolean>(false);
  const [upgradePlan, setUpgradePlan] = useState<string>(INIT_PLAN);
  const [upgradeSuccess, setUpgradeSuccess] = useState<boolean>(false);
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
  const [coupon, setCoupon] = useState<string>();
  const { formatNumber } = useLoc();

  useEffect(() => {
    setPlan(props.self.data.plan);

    // Take coupon from params
    if (window.location.search) {
      const search = decodeQuery(window.location.search);

      setCoupon(search.coupon);

      const currentCoupon = COUPONS_DATA[search.coupon];

      if (currentCoupon && currentCoupon.allowedUpgradePlan) {
        setUpgradePlan(currentCoupon.allowedUpgradePlan || INIT_PLAN);
      }
    }

    if (
      plan.planCode &&
      (plan.planCode === COACH_PRO_YEARLY ||
        plan.planCode === COACH_PRO_MONTHLY)
    ) {
      setHasActivePro(true);
    }
  }, [plan]);

  if (props.self.pending) {
    return <Spinner />;
  } else if (back) {
    return <Redirect to="/" />;
  }

  if (upgradeSuccess) {
    return (
      <SuccessPage
        plan={upgradePlan}
        user={props.self}
        onDone={() => setBack(true)}
      />
    );
  }

  const closeErrorModal = () => {
    setShowErrorModal(false);
  };

  const handleBackClick = () => {
    setBack(true);
  };

  const canApplyCoupon = () => {
    const couponData = COUPONS_DATA[coupon];
    const upgradePlanData = PLANS[upgradePlan];

    if (!couponData || !couponData.discount) {
      return false;
    }

    if (
      couponData.allowedUpgradePeriod &&
      upgradePlanData.period !== couponData.allowedUpgradePeriod
    ) {
      return false;
    }

    return true;
  };

  const handleUpgradeClick = () => {
    setPending(true);
    setError(null);

    let couponToSubmit = null;
    if (canApplyCoupon()) {
      couponToSubmit = coupon;
    } else {
      couponToSubmit = null;
    }

    actions
      .updateSubscription(upgradePlan, couponToSubmit)
      .then((result: any) => {
        if (result.type === "@stripe.post.success") {
          setPending(false);
          setUpgradeSuccess(true);
        } else if (result.type === "@stripe.post.error") {
          setPending(false);
          setError(result.payload.defaultMessage);
          setShowErrorModal(true);
        }
      });
  };

  const changePlanType = (data: any) => {
    if (data !== null) {
      setUpgradePlan(data.id);
    }
  };

  const showDiscount = () => {
    const couponData = COUPONS_DATA[coupon];

    if (!couponData || !couponData.discount) {
      return null;
    }

    const upgradePlanData = PLANS[upgradePlan];

    // Check if coupon is working with selected upgrade plan
    if (
      couponData.allowedUpgradePeriod &&
      upgradePlanData.period !== couponData.allowedUpgradePeriod
    ) {
      return (
        <div className={cls("mt-1")}>
          <div className={styles.totalPrice}>
            <Loc value={LOCALES.coupon_not_allowed} />
          </div>
        </div>
      );
    }

    const newPlanAmount = upgradePlanData.amount;
    const planPeriod = upgradePlanData.period;
    const discountAmount = newPlanAmount * couponData.discount;
    const total = newPlanAmount - discountAmount;

    const subtotal = formatNumber(newPlanAmount, {
      style: "currency",
      currency: "USD",
    });
    const discount =
      "-" +
      formatNumber(discountAmount, { style: "currency", currency: "USD" });
    const totalDue =
      formatNumber(total, { style: "currency", currency: "USD" }) +
      " / " +
      planPeriod;

    return (
      <div className={cls("mt-1")}>
        <div className={styles.totalPrice}>
          <h5>
            <span>
              <Loc value={LOCALES.normal_price} />
            </span>
            <span>{subtotal}</span>
          </h5>
          <h5>
            <span>
              <Loc value={LOCALES.your_discount} />
            </span>
            <span>
              <span className={styles.red}>({discount})</span>
            </span>
          </h5>
          <h6>
            <Loc value={LOCALES.your_price} />
          </h6>
          <h1>{totalDue}</h1>
        </div>
      </div>
    );
  };

  const showUpgradeOptions = () => {
    return (
      <div>
        <UpgradeErrorModal
          show={showErrorModal}
          error={error}
          onClose={closeErrorModal}
        />

        <div className={hasActivePro ? styles.hidden : cls("mt-1")}>
          <h3 className={styles.title}>
            <Loc value={LOCALES.upgrade_to_pro_title} />
          </h3>
          <p className={styles.subtitle}>
            <FormattedHTMLMessage {...LOCALES.upgrade_to_pro_subtitle} />
          </p>

          <PlanTypes
            selectPlan={upgradePlan}
            onChange={changePlanType}
            onlyPlans={UPGRADE_PLANS_MATCH}
          />
          {showDiscount()}
        </div>

        <button
          disabled={pending}
          className={cls("btn", "btn-outline-secondary", "pull-left", "mt-1")}
          onClick={handleBackClick}
        >
          <Loc value={LOCALES.back_button} />
        </button>

        <button
          disabled={pending}
          className={
            hasActivePro
              ? styles.hidden
              : cls("btn", "btn-primary", "pull-right", "mt-1")
          }
          onClick={handleUpgradeClick}
        >
          <FormattedMessage
            {...LOCALES.upgrade_button}
            values={{
              planName: PLAN_TYPES.find(
                (planType) => planType.id === upgradePlan
              ).title.defaultMessage,
            }}
          />
        </button>
      </div>
    );
  };

  const showContactSupport = () => {
    return (
      <div>
        <div className={cls("mt-1", "mb-1")}>
          <FormattedHTMLMessage {...LOCALES.cant_upgrade} />
        </div>

        <button
          className={cls("btn", "btn-secondary", "pull-left", "mt-1")}
          onClick={handleBackClick}
        >
          <Loc value={LOCALES.back_button} />
        </button>

        <button
          className={cls("btn", "btn-primary", "pull-right", "mt-1")}
          onClick={openIntercom}
        >
          <Loc value={LOCALES.contact_support} />
        </button>
      </div>
    );
  };

  return (
    <div className={styles.status}>
      <div className={styles.statusBox}>
        <div className={styles.title}>
          <FormattedMessage
            {...LOCALES.status_active_message}
            values={{ planName: plan.name }}
          />
        </div>

        <div className={styles.info}>
          <div className={styles.rowItem}>
            <span>
              <Loc value={LOCALES.status_plan} />:
            </span>
            <span>{props.self.data.plan.name}</span>
          </div>
          <div className={styles.rowItem}>
            <span>
              <Loc value={LOCALES.status} />:
            </span>
            <span
              className={`${
                props.self.data.subscription.status === "active"
                  ? styles.green
                  : styles.red
              }`}
            >
              {props.self.data.subscription.status}
            </span>
          </div>
        </div>

        {plan.canUpgrade ? showUpgradeOptions() : showContactSupport()}

        <div className={styles.clearFloat}></div>
      </div>
    </div>
  );
}
