import { useState } from "react";

const IndexModel = () => {
  const [selectedIndex, setSelectedIndex] = useState<number>(1);
  const handleSelect = (tabIndex: number) => {
    setSelectedIndex(tabIndex);
  };

  return {
    selectedIndex,
    handleSelect,
  };
};

export default IndexModel;
