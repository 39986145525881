/**
 * @module Utils.File
 *
 */
import isTypeOf from './isTypeOf';
import getExtension from './getExtension';

/**
 * get file type from filename
 *
 * @public
 * @method getType
 * @param {string} filename
 * @return {string}
 */
export default function getType(filename: string): string {
  if (isDoc(filename)) {
    return 'doc';
  } else if (isPdf(filename)) {
    return 'pdf';
  } else if (isPpt(filename)) {
    return 'ppt';
  } else if (isXls(filename)) {
    return 'xls';
  } else if (isTypeOf(filename, ['audio/*'])) {
    return 'audio';
  } else if (isTypeOf(filename, ['document/*'])) {
    return 'document';
  } else if (isTypeOf(filename, ['image/*'])) {
    return 'image';
  } else if (isTypeOf(filename, ['video/*'])) {
    return 'video';
  }
  return '';
}

export function isDoc(fileName: string): boolean {
  return ['.doc', '.docx'].indexOf(getExtension(fileName)) !== -1;
}

export function isPdf(fileName: string): boolean {
  return getExtension(fileName) === '.pdf';
}

export function isXls(fileName: string): boolean {
  return ['.xls', '.xlsx'].indexOf(getExtension(fileName)) !== -1;
}

export function isPpt(fileName: string): boolean {
  return ['.ppt', '.pptx'].indexOf(getExtension(fileName)) !== -1;
}

