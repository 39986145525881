/**
 * @module Reducers.Space
 *
 */
import { RequestError } from "typedefs";
import { SpaceApplicationAction } from "app/actions/spaceApplication";

export type spaceApplicationState = {
  data: any | null;
  error: RequestError | null;
  pending: boolean;
};

const initialState: spaceApplicationState = {
  data: null,
  error: null,
  pending: false,
};

export default function spaceReducer(
  state = initialState,
  action: SpaceApplicationAction
): spaceApplicationState {
  switch (action.type) {
    case "@spaceApplication.fetch.pending":
      return { ...state, error: null, pending: true };
    case "@spaceApplication.fetch.spaceApplicationSuccess":
      return { ...state, data: action.payload, pending: false };
    case "@spaceApplication.clear":
      return {
        ...state,
        data: null,
        error: null,
        pending: false,
      };

    default:
      return state;
  }
}
