/**
 * @module Config.Helpers
 *
 */

export const getWithDefault = (content, key, defaultValue=null) => {
  if (content != null && content[key] != null) {
    const value = content[key];
    if (value === 'true') {
      return true;
    } else if (value === 'false') {
      return false;
    } else if (!isNaN(parseFloat(value)) && (parseFloat(value) + '') === value) {
      return parseFloat(value);
    } else {
      return value;
    }
  }
  return defaultValue;
};

export const featureOverrides = (features) => {
  Object.keys(features).forEach(key => {
    if (ENV[key] != null) {
      if (ENV[key] === 'true') {
        features[key] = true;
      } else if (ENV[key] === 'false') {
        features[key] = false;
      }
    }
  });
};
