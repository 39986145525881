/**
 * @module Components.StripeForm
 *
 */
import React from 'react';
import StripeElementContainer, { PropsDef } from './StripeElementContainer';
import { CardCVCElement } from 'react-stripe-elements';
import LOCALES from './locale';

const CardCVC = (props: PropsDef) => {
  return (
    <StripeElementContainer
      elementType={CardCVCElement}
      placeholder={LOCALES.security_code}
      {...props}
    />
  );
};

export default CardCVC;
