import React from "react";
import styles from "./styles.module.scss";
import { cls } from "app/utils";
import { ApplyTemplateToProps } from "./index.types";
import { useApplyTemplateDropdown } from "./indexModel";
import ApplyTemplateToSpace from "../ApplyTemplateToSpace";
import ApplyTemplateSuccessModal from "app/components/Layouts/ApplyTemplateSuccessModal";
import ApplyTemplateToGroup from "../ApplyTemplateToGroup";
import ApplyTemplateToChannel from "../ApplyTemplateToChannel";
import SpaceSelectModal from "app/components/Layouts/SpaceSelectModal";
import GroupSelectModal from "app/components/Layouts/GroupSelectModal";
import ChannelSelectModal from "app/components/Layouts/ChannelSelectModal";

const ApplyTemplateDropdown = ({
  // canApplyTemplate,
  templateId,
  templateName,
}: ApplyTemplateToProps) => {
  const {
    isDropdownOpen,
    dropDownHandler,
    successModal,
    onCloseSuccessModal,
    location,
    applied,
    dropdownRef,
    isSpaceSelectModalOpen,
    handleSpaceSelectModal,
    isGroupSelectModalOpen,
    handleGroupSelectModal,
    isChannelSelectModalOpen,
    handleChannelSelectModal,
  } = useApplyTemplateDropdown();

  return (
    <>
      <div className={styles.container} ref={dropdownRef}>
        <div className={cls("btn btn-primary")} onClick={dropDownHandler}>
          Apply to...
        </div>
        {isDropdownOpen && (
          <div className={styles.dropdown_container}>
            <ApplyTemplateToSpace
              className={styles.applyButton}
              spaceSelectModalHandler={handleSpaceSelectModal}
            />
            <ApplyTemplateToGroup
              className={styles.applyButton}
              groupSelectModalHanlder={handleGroupSelectModal}
            />
            <ApplyTemplateToChannel
              className={styles.applyButton}
              channelSelectModalHanlder={handleChannelSelectModal}
            />
          </div>
        )}
      </div>
      {isSpaceSelectModalOpen && (
        <SpaceSelectModal
          templateId={templateId}
          isOpen={isSpaceSelectModalOpen}
          onClose={handleSpaceSelectModal}
        />
      )}
      {isGroupSelectModalOpen && (
        <GroupSelectModal
          templateId={templateId}
          isOpen={isGroupSelectModalOpen}
          onClose={handleGroupSelectModal}
        />
      )}
      {isChannelSelectModalOpen && (
        <ChannelSelectModal
          templateId={templateId}
          isOpen={isChannelSelectModalOpen}
          onClose={handleChannelSelectModal}
        />
      )}
      <ApplyTemplateSuccessModal
        isSuccessModalOpen={successModal}
        onSuccessModalClose={onCloseSuccessModal}
        templateName={templateName}
        applied={applied}
        appliedLocation={location}
      />
    </>
  );
};

export default ApplyTemplateDropdown;
