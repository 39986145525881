/**
 * @module Routes.Spaces
 *
 */
import React, { useCallback } from "react";
import { defineMessages } from "react-intl";

// import UserAvatar from 'app/components/UserAvatar';
import SearchBar from "app/components/inputs/SearchBar";
import Styles from "./styles.module.scss";
import SmartList from "app/components/SmartList";
import { cls } from "app/utils";
import feedPostIcon from "../../../public/images/smart-list/feed-post-icon.svg";
import fifteenDaysIcon from "../../../public/images/smart-list/15-calendar-icon.svg";
import thirtyDaysIcon from "../../../public/images/smart-list/30-calendar-icon.svg";
import sixtyDaysIcon from "../../../public/images/smart-list/60-plus-calendar-icon.svg";
import "react-virtualized/styles.css";

import SpacesList from "./SpacesList";
import { SpaceListContainerModel } from "./SpaceListContainerModel";

/**
 * @class SpaceListContainer
 *
 */
export default function SpaceListContainer() {
  const {
    formatMessage,
    selectedSmartList,
    hasSpacesData,
    onPostToListClick,
    user,
    userIsCoach,
    updateSearch,
    searchTerm,
    demoSpace,
  } = SpaceListContainerModel();

  const getHeaderContentInfo = () => {
    if (selectedSmartList) {
      if (selectedSmartList.name.includes("Unanswered")) {
        return {
          icon: feedPostIcon,
          title: "Unanswered by me",
          infoText: (
            <span>
              Spaces where an athlete made the most recent post, and you have
              not replied yet. The Space will come out of this List{" "}
              <b>once you respond to the post</b>.
            </span>
          ),
        };
      } else if (selectedSmartList.name.includes("15 days inactive")) {
        return {
          icon: fifteenDaysIcon,
          title: "15 days inactive",
          infoText: (
            <span>
              Spaces where no one posted or replied in <b>the last 15 days</b>
            </span>
          ),
        };
      } else if (selectedSmartList.name.includes("15 days active")) {
        return {
          icon: fifteenDaysIcon,
          title: "15 days active",
          infoText: (
            <span>
              Spaces where someone has posted or replied in{" "}
              <b>the last 15 days</b>
            </span>
          ),
        };
      } else if (selectedSmartList.name.includes("30")) {
        return {
          icon: thirtyDaysIcon,
          title: "30 days inactive",
          infoText: (
            <span>
              Spaces where no one posted or replied in <b>the last 30 days</b>
            </span>
          ),
        };
      } else if (selectedSmartList.name.includes("60+")) {
        return {
          icon: sixtyDaysIcon,
          title: "60+ days inactive",
          infoText: (
            <span>
              Spaces where no one posted or replied in <b>the last 60+ days</b>
            </span>
          ),
        };
      }
    }
    return null;
  };

  const HeaderTitle = useCallback(() => {
    const contentInfo = getHeaderContentInfo();

    return (
      contentInfo && (
        <div className={Styles.content_header_info}>
          <div className={Styles.main_content}>
            <span className={Styles.img_wrapper}>
              <img src={contentInfo.icon} alt="" />
            </span>
            <div className={Styles.content_header_content}>
              {contentInfo.title && <h3>{contentInfo.title}</h3>}
              {contentInfo.infoText && <p>{contentInfo.infoText}</p>}
            </div>
          </div>

          {hasSpacesData && selectedSmartList.object === "smart_list" && (
            <button
              className={cls("btn btn-primary", Styles.post_to_list_btn)}
              onClick={onPostToListClick}
            >
              Post to list
            </button>
          )}
        </div>
      )
    );
  }, [selectedSmartList, hasSpacesData]);

  return (
    <div
      className={cls(Styles.spaceListContainer, {
        [Styles.column]: !userIsCoach,
        ["space-container-athlete"]: userIsCoach,
      })}
    >
      <div className={Styles.spaceListMenu}>
        <span className={Styles.smartList}>
          <SearchBar
            className={Styles.search}
            placeholder={formatMessage(locales.search_bar_placeholder)}
            onChange={updateSearch}
          />
        </span>
        {userIsCoach && <SmartList />}
      </div>
      <div className={Styles.spaceListWrapper}>
        {userIsCoach && selectedSmartList && <HeaderTitle />}
        <SpacesList
          pending={demoSpace.pending}
          user={user}
          searchTerm={searchTerm}
        />
      </div>
    </div>
  );
}

const locales = defineMessages({
  search_bar_placeholder: {
    id: "spaces.search-bar.placeholder",
    defaultMessage: "Search spaces...",
  },
});
