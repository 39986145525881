/** @module routes */
import React, { useCallback, useMemo, useState } from "react";
import { defineMessages } from "react-intl";
import { cls, hasPermission } from "app/utils";
import { useLoc, useStoreActions } from "app/utils/hooks";
import { isAuthenticated } from "app/utils/auth";
// import { useTitle } from 'app/utils/translate';
import { fetchSelf } from "app/actions/user";
import { UserState } from "app/reducers/user";
import { TabView, TabContent } from "app/components/TabView";
import { SelectBtn, SelectBtnOption } from "app/components/Button";
import { Loc } from "app/components/helpers";
import { Loading } from "app/components/Wrappers";
import SpaceListContainer from "./SpaceListContainer";
// import SpaceDemoList from './SpaceDemoList';
import { SpacesLimit } from "app/components/paywalls";
import { route, routeNative } from "app/utils/router";
import { featureIsEnabled } from "app/utils/features";
import Styles from "./styles.module.scss";
import GettingStartedProgress from "app/components/GettingStarted/GettingStartedProgress";
import paywallModalPro from "app/utils/paywall";

type PropsDef = {
  activeTab?: number;
  jacksjourney?: boolean;
  selectedSmartList?: (e: string) => void;
};

/**
 * @class Spaces
 *
 */
export default function Spaces(props: PropsDef) {
  const { formatMessage } = useLoc();
  const [showUpgrade, setUpgradeState] = useState(false);
  const { user } = useStoreActions({ user: fetchSelf });
  // const getTitle = useTitle('coach', { defaultValue: 'Coach', plural: true });

  const openUpgrade = useCallback(() => {
    setUpgradeState(true);
  }, []);

  const closeUpgrade = useCallback(() => {
    setUpgradeState(false);
  }, []);

  const handleTabChange = useCallback((tab: number) => {
    if (isAuthenticated()) {
      if (tab === 0) {
        route("/spaces", true);
      } else if (tab === 1) {
        if (user.data && user.data.kind === "coach") {
          route("/spaces/demo", true);
        } else {
          route("/spaces", true);
        }
      }
    } else {
      route("/spaces/demo", true);
    }
    return false; // prevent TabView from setting tab
  }, []);

  const classList = [Styles.spaces];
  let tabs = <div />;

  const spaceTab = useMemo(() => {
    return (
      <TabContent
        key={0}
        tabTitle={formatMessage(LOCALES.spaces_tab_title)}
        headerTitle={formatMessage(LOCALES.spaces_tab_title)}
        actions={(
          <SpaceListAction
            user={user}
            onUpgrade={openUpgrade}
            jacksjourney={props.jacksjourney || false}
          />
        )}
      >
        <SpaceListContainer />
        <SpacesLimit show={showUpgrade} onClose={closeUpgrade} />
      </TabContent>
    );
  }, [user]);

  // const kindTitle = getTitle(user.data != null ? user.data.kindTitle : null);
  // const demoTab = (
  //   <TabContent
  //     key={1}
  //     tabTitle={formatMessage(LOCALES.inspiration_tab_title)}
  //     headerTitle={formatMessage(LOCALES.inspiration_header_title)}
  //     headerSubTitle={formatMessage(LOCALES.inspiration_header_sub_title, { title: kindTitle })}
  //     hideActions={true}
  //   >
  //     <SpaceDemoList sport={user.data != null ? user.data.sport : null} />
  //   </TabContent>
  // );

  let isLoading = false;
  if (isAuthenticated()) {
    isLoading = user.data == null;

    // if user is authenticated and user
    // is a caoch then show demo space
    if (
      featureIsEnabled("demoSpaces") &&
      user.data &&
      user.data.kind === "coach"
    ) {
      tabs = (
        <div
          className={cls("non-scrollable", {
            ["spaces-container"]: user.data && user.data.kind === "coach",
          })}
        >
          {user && user.data && user.data.needsOnboarding && (
            <GettingStartedProgress />
          )}
          <TabView
            onSelect={handleTabChange}
            defaultIndex={props.activeTab || 0}
          >
            {spaceTab}
            {/* {demoTab} */}
          </TabView>
        </div>
      );
    } else {
      // only show spaceTab if use is authenticated
      tabs = (
        <TabView onSelect={handleTabChange} defaultIndex={0}>
          {spaceTab}
        </TabView>
      );
    }
  } else {
    if (featureIsEnabled("demoSpaces")) {
      classList.push(Styles.noAuth);

      // if user is not authenticated then
      // only show demospace for public access
      // tabs = (
      //   <TabView onSelect={handleTabChange} defaultIndex={0}>
      //     {demoTab}
      //   </TabView>
      // );
    } else {
      routeNative("/signin", { replace: true });
      return <div />;
    }
  }

  return (
    <Loading className={cls(...classList)} isLoading={isLoading}>
      {tabs}
    </Loading>
  );
}

type SpaceListActionProps = {
  user: UserState;
  jacksjourney: boolean;
  onUpgrade: () => void;
};

function SpaceListAction(props: SpaceListActionProps) {
  const { formatMessage } = useLoc();

  const newSingle = () => {
    if (props.jacksjourney) {
      route("/spaces/new", false, { jacksjourney: "true" });
    } else {
      route("/spaces/new");
    }
  };

  const newMulti = () => {
    route("/spaces/new/multiple/true");
  };

  if (
    props.user.data != null &&
    !hasPermission(props.user.data, "createSpaces")
  ) {
    return (
      <button className="btn btn-upgrade" onClick={() => paywallModalPro()}>
        <Loc value={LOCALES.new_space_upgrade} />
      </button>
    );
  }

  return (
    <SelectBtn title={formatMessage(LOCALES.space_action_title)}>
      <SelectBtnOption onClick={newSingle}>
        <i className="ico ico-tspace mr-1" />
        <Loc value={LOCALES.space_action_single} />
      </SelectBtnOption>

      <SelectBtnOption onClick={newMulti}>
        <i className="ico ico-groups mr-1" />
        <Loc value={LOCALES.space_action_multi} />
      </SelectBtnOption>
    </SelectBtn>
  );
}

const LOCALES = defineMessages({
  spaces_tab_title: { id: "spaces.tab-name.spaces", defaultMessage: "Spaces" },
  space_action_title: {
    id: "spaces.action-title.new-space",
    defaultMessage: "+ New Space",
  },
  space_action_single: {
    id: "spaces.action-title.single-space",
    defaultMessage: "Create one Space",
  },
  space_action_multi: {
    id: "spaces.action-title.multiple-space",
    defaultMessage: "Create multiple Spaces",
  },
  new_space_upgrade: {
    id: "spaces.action-title.space-upgrade",
    defaultMessage: "New Space",
  },

  inspiration_tab_title: {
    id: "spaces.tab-name.inspiration",
    defaultMessage: "Inspiration",
  },
  inspiration_header_title: {
    id: "spaces.header-title.inspiration",
    defaultMessage: "Inspiration Spaces",
  },
  inspiration_header_sub_title: {
    id: "spaces.header-sub-title.inspiration",
    defaultMessage:
      "See examples of real Spaces created by other {title} like you",
  },
});
