import React from "react";
import styles from "./styles.module.scss";
import GettingStartedModal from "app/components/Layouts/GettingStartedModal";
import { GettingStartedModalProps } from "../index.types";
import SpacesImageDesktop from "../../../../public/images/sapces-img-desktop.png";
import SpacesImageMobile from "../../../../public/images/sapces-img-mobile.png";
import { cls } from "app/utils";

const SpacesModal = ({ onClose, isOpen, url }: GettingStartedModalProps) => {
  return (
    <GettingStartedModal onClose={onClose} isOpen={isOpen}>
      <a
        href={typeof url === "string" ? url : "#"}
        className={styles.container}
      >
        <div className={styles.information_content}>
          <div className={styles.info_title}>
            Spaсes - your private, athlete communication channel
          </div>
          <p>
            Create posts, analyze videos, and give feedback in a secure, private
            channel dedicated solely to your athlete&apos;s improvement.
          </p>
          <div className={styles.create_space_btn}>Create new Space</div>
        </div>
        <div className={styles.gallery_content}>
          <img
            className={cls(styles.gallery_space_desktop, styles.gallery_space)}
            src={SpacesImageDesktop}
            alt="spaces"
          />
          <img
            className={cls(styles.gallery_space_mobile, styles.gallery_space)}
            src={SpacesImageMobile}
            alt="spaces"
          />
        </div>
      </a>
    </GettingStartedModal>
  );
};

export default SpacesModal;
