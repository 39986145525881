import React from "react";
import styles from "./styles.module.scss";
import { cls } from "app/utils";

interface PagePanelProps {
  title?: string;
  titleStyles?: string;
  buttonType?: "primary" | "secondary" | "upgrade";
  buttonTitle?: string;
  buttonStyles?: string;
  onButtonClick?: () => void;
  url?: string;
  headerChildren?: React.ReactNode;
  children?: React.ReactNode;
}

const PagePanel = ({
  title,
  titleStyles,
  buttonType,
  buttonTitle,
  buttonStyles,
  onButtonClick,
  url,
  headerChildren,
  children,
}: PagePanelProps) => {
  return (
    <div className={styles.container}>
      {(title || buttonTitle) && (
        <div className={styles.header_container}>
          {title && <h2 className={cls(styles.title, titleStyles)}>{title}</h2>}
          {buttonTitle && (
            <a href={url ? url : "#"}>
              <button
                className={cls("btn", styles.button, buttonStyles, {
                  ["btn-primary"]: buttonType === "primary",
                  ["btn-secondary"]: buttonType === "secondary",
                  ["btn-upgrade"]: buttonType === "upgrade",
                })}
                onClick={onButtonClick}
              >
                {buttonTitle}
              </button>
            </a>
          )}
          {headerChildren}
        </div>
      )}
      {children}
    </div>
  );
};

export default PagePanel;
