import React from "react";
import styles from "./styles.module.scss";
import Button from "app/components/Button/ButtonVariant";

const UploadMedia = () => {
  const fileInputRef = React.useRef(null);
  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  return (
    <div className={styles.container}>
      <div className={styles.container_wrapper}>
        <div className={styles.text_content}>
          <h1 className={styles.content_header}>Drag Media Here</h1>
          <p className={styles.content_small_text}>or you can</p>
        </div>
        <input
          type="file"
          accept="*"
          className={styles.file_uploader_input}
          ref={fileInputRef}
        />
        <div className={styles.select_button_wrapper}>
          <Button
            primary
            title="Select Media to Upload"
            onClickFunc={handleButtonClick}
          />
        </div>
      </div>
    </div>
  );
};

export default UploadMedia;
