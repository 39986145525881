/**
 * @module Reducers.User
 *
 */
import { RequestError } from "typedefs";
import { UserModel } from "app/models";
import { OnboardingAction } from "app/actions/onboarding";

export type OnboardingState = {
  data: UserModel | null;
  error: RequestError | null;
  pending: boolean;
};

const initialState: OnboardingState = {
  data: null,
  error: null,
  pending: false,
};

export default function onboardingReducer(
  state = initialState,
  action: OnboardingAction
): OnboardingState {
  switch (action.type) {
    case "@onboarding.fetch.pending":
      return { ...state, error: null, pending: true };
    case "@onboarding.fetch.success":
      return { ...state, data: action.payload, pending: false };
    case "@onboarding.fetch.error":
      return { ...state, error: action.payload, pending: false };

    default:
      return state;
  }
}
