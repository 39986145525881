import React, { memo } from "react";
import styles from "./styles.module.scss";

type Props = {
  percentage: number;
};

const ProgressBar = ({ percentage }: Props) => {
  const progressBarStyle = {
    width: `${percentage}%`,
  };

  return (
    <div className={styles.container}>
      <div className={styles.progressBarContainer}>
        <div className={styles.progressBar} style={progressBarStyle}></div>
      </div>
      <p>
        {percentage}
        {"%"}
      </p>
    </div>
  );
};

export default memo(ProgressBar);
