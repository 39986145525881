/**
 * @module Reducer
 *
 */
import { combineReducers } from "redux";
import coupon from "./coupon";
import connection from "./connection";
import demoSpace from "./DemoSpace";
import invitation from "./invitation";
import list from "./list";
import paymentInfo from "./payment-info";
import space from "./space";
import sports from "./sports";
import stripe from "./stripe";
import subscription from "./subscription";
import subscriptionPlan from "./subscription-plan";
import tag from "./tag";
import team from "./team";
import teamGroup from "./team-group";
import teamGroupMemberImport from "./team-group-member-import";
import user from "./user";
import userSettings from "./user-settings";
import views from "./views";
import currentLikes from "./currentLikes";
import mediaLibraryData from "./media-library-data";
import destinations from "./destinations";
import copy from "./copy";
import move from "./move";
import segments from "./segments";
import onboarding from "./onboarding";
import schedulePost from "./schedule";
import applyTemplate from "./applyTemplate";
import smartList from "./smartList";
import spaceApplication from "./spaceApplication";
import addToRecruitPost from "./addToRecruitPost";
import postingForm from "./postingForm";
import filter from "./filter";

export const rootReducer = combineReducers({
  coupon,
  connection,
  demoSpace,
  invitation,
  list,
  paymentInfo,
  space,
  sports,
  stripe,
  subscription,
  subscriptionPlan,
  tag,
  team,
  teamGroup,
  teamGroupMemberImport,
  user,
  userSettings,
  views,
  currentLikes,
  mediaLibraryData,
  destinations,
  copy,
  move,
  segments,
  onboarding,
  schedulePost,
  applyTemplate,
  smartList,
  spaceApplication,
  addToRecruitPost,
  postingForm,
  filter,
});

export type RootState = ReturnType<typeof rootReducer>;
