/**
 * @module Components
 *
 */
import React, { useState, useEffect } from 'react';
import ReactImage from 'react-image-crop';
import { getMaxAspectArea, cropImage, ElementRect } from 'app/utils/dom';
import styles from './styles.module.scss';

type PropsDef = {
  width: number,
  height: number,
  src: string,
  onCrop?: ((s: string) => void),
};

const initCrop: ReactImage.Crop = {
  aspect: 1,
  width: 100,
  height: 100,
  x: 0,
  y: 0
};

export default function ImageCropper(props: PropsDef) {
  const [ cropRef, setCrop ] = useState(initCrop);
  const [ imageRef, setImage ] = useState((null as HTMLImageElement));

  const setInitCropBounds = () => {
    if (imageRef != null) {
      const aspect = (props.width / props.height);
      const scale = getMaxAspectArea(imageRef, aspect);
      const crop = { ...cropRef, aspect, width: scale.width, height: scale.height, x: scale.left, y: scale.top };
      setCrop(crop);
      cropFunc(imageRef, crop);
    }
  };

  useEffect(() => (setInitCropBounds(), undefined), [ imageRef ]);

  const saveCropData = (crop: ReactImage.Crop) => {
    if (imageRef != null) {
      const data = { ...cropRef, ...crop };
      setCrop(data);
      return data;
    }
    return cropRef;
  };

  const cropFunc = (image: HTMLImageElement, { width, height, x: left, y: top }: ReactImage.Crop) => {
    if (image != null) {
      const dimensions: ElementRect = { top, left, width, height };
      const data = cropImage(image, dimensions, [props.width, props.height ]);
      if (props.onCrop != null) {
        props.onCrop(data);
      }
    }
  };

  return (
    <div className={styles.cropper}>
      <ReactImage
        crop={cropRef}
        src={props.src}
        keepSelection={true}
        onChange={(crop: ReactImage.Crop) => saveCropData(crop)}
        onComplete={(crop: ReactImage.Crop) => cropFunc(imageRef, saveCropData(crop))}
        onImageLoaded={(image: HTMLImageElement) => setImage(image)}
      />
    </div>
  );
}

