/**
 * @module Button
 *
 */
import React, { useState, useEffect, useRef } from 'react';
import { cls } from 'app/utils';
import Styles from './styles.module.scss';

type OptionsProps = React.PropsWithChildren<{
  className?: string;
  onClick: () => void;
}>;

export function SelectBtnOption(props: OptionsProps) {
  return (
    <div
      className={cls(Styles.option, props.className)}
      onClick={props.onClick}
    >{props.children}</div>
  );
}

type PropsDef = {
  className?: string;
  title: string;
  children: JSX.Element[];
};

/**
 * @class Select
 *
 */
export function SelectBtn({ className, title, children }: PropsDef) {
  const [ isOpen, setIsOpen ] = useState(false);
  const ref = useRef(null);

  const handleClickOutside = (evt: any) => {
    if (ref.current && !ref.current.contains(evt.target)) {
      if (isOpen) {
        setIsOpen(!isOpen);
      }
    }
  };

  const toggleDropDown = (evt: React.MouseEvent<HTMLSpanElement>) => {
    evt.preventDefault();
    setIsOpen(!isOpen);
  };

  const handleMainClick = (evt: React.MouseEvent<HTMLSpanElement>) => {
    evt.preventDefault();
    if (children && children.length && children[0].props && children[0].props.onClick) {
      children[0].props.onClick();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  return (
    <div className={cls(Styles.selectButton, className)}>
      <button className={cls("btn btn-primary", Styles.button)}>
        <div onClick={handleMainClick}>{title}</div>
        <div className={cls(Styles.line)}></div>
        <div ref={ref} onClick={toggleDropDown}>
          <i className="ico ico-chevron" />
        </div>
      </button>
      <div className={cls(Styles.dropdown, isOpen ? Styles.show : '')}>{children}</div>
    </div>
  );
}
