/**
 * @module Components.Buttons
 *
 */
import React from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import { cls } from 'app/utils';
import styles from './styles.module.scss';

const LOCALES = defineMessages({
  title: {
    id: 'app.button.facebook',
    defaultMessage: 'Facebook'
  }
});

const logo = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAAAXNSR0IArs4c6QAAAhdJREFUeAHtWj1LBDEQvVVBxC8EaxsRC8HCv6AWgpX+BAsLu0MstNHS0j+iYGXjjxDRVhA/GhEsrrC59QWuCreZmzAbs7MzMEUyk+y8Ny+X9c5Ox8wYMAaMAWOgvQwUOUEvy3IK9SzAx+FlwHtFUfQQb7YB8Bz8EH4L/4T34aPYuRTyCamNOPsAoetwF34Gn+eslc5NTgDAzwDENXxbGkzMfkkJGHT+BoVuxRRbx5qxOjYN7HmCWDbgXZ3JCED3F/G8U/fQnCwZAQB9AHfnPytLScB+VsgHxSQhAPKfxvM2ciQg1S2wCvDu7h/VvpB4AX+GuzdC3178iazHUMAOnGO7qQAlOQIAM8kEdM/Mj05PRQC3wF/ugtj8XAmIxcNeZwSwKVO2oPUKEH8PwF23B5Ese0JZ88bU8NjdmYGkR3wjdBeI/18Idbtvd+q2SymETT0CH0aAEAOmACEiU2/T6iPgbodWE/CNK1Dsb4Umfga8S563JhIgJn9HpPhvg3gDWsK+s16XNjG+8uZCw3UE+xUJPzgCbxUx9rT4qzCKe/WrACkr/hwxfsI+VQQQS3nhJh4BHkIi2wggCFIfNgWobzEB0BRAEKQ+bApQ32ICoCmAIEh92BSgvsUEQFMAQZD6sClAfYsJgKYAgiD1YVOA+hYTAE0BBEHqw6YA9S0mAIr/MlTxvAfMH1XEhk2H/kFqWL7NGQORDPwBRKAbLej6jn8AAAAASUVORK5CYII=";

type PropsDef = {
  className?: string,
  onClick?: () => void,
  disabled?: boolean
};

/**
 * @class FacebookButton
 *
 */
export default function Facebook(props: PropsDef) {
  const handleClick = () => {
    if (props.onClick) {
      return props.onClick();
    }
  };

  const clsname = cls(styles.btnLogo, styles.facebook, props.className);

  return (
    <button className={clsname} onClick={handleClick} disabled={props.disabled}>
      <span className={styles.logo} style={{backgroundImage: `url(${logo})`}}></span>
      <span><FormattedMessage {...LOCALES.title} /></span>
    </button>
  );
}

