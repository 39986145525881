/**
 * @module Utils.Request
 *
 */
import { Model } from 'typedefs';
import isArray from 'lodash/isArray';
import { isUUID } from 'app/utils/uuid';
import { camelize, underscore } from 'app/utils/strings';

type stringModFuncType = (key: string) => string;

const EXCLUDE_KEYS = [ '_id' ];
const REMOVE_KEYS = [ '$$hashKey' ];
const DONT_PARSE = [ 'traits' ];

/**
 * filters out exclude keys before applying the modifyKey method
 *
 * @private
 * @method exclude
 * @param str {string}
 * @param modifyKey {function}
 * @return {string}
 */
function exclude(str: string, modifyKey: stringModFuncType): string {
  // filter reserved keys
  if (EXCLUDE_KEYS.indexOf(str) !== -1) {
    return str;
  } else if (isUUID(str)) {
    return str;
  }
  return modifyKey(str);
}

/**
 * loops through each model and model property passing each prop key to the modifyKey callback
 *
 * @private
 * @method parseData
 * @param data: {any}
 * @param modifyKey {function}
 * @return {any}
 */
function parseData(data: any, modifyKey: stringModFuncType): any {
  if (isArray(data)) {
    if (!data.length) { return undefined; }
    // handle array data
    return data.map(d => parseData(d, modifyKey));
  } else if (data != null && typeof data === 'object') {
    // handle object data
    return Object.assign(
      {},
      ...Object.keys(data).map(key => {
        if (REMOVE_KEYS.indexOf(key) !== -1) {
          return { [key]: undefined };
        } else if (DONT_PARSE.indexOf(key) !== -1) {
          return { [key]: data[key] };
        }
        return { [exclude(key, modifyKey)]: parseData(data[key], modifyKey) };
      }).filter(item => Object.values(item)[0] !== undefined)
    );
  } else {
    // return everything else unchanged
    return data;
  }
}

/**
 * ts method for narrowing down the data input type
 *
 * @private
 * @method handleModel
 * @param data {Model}
 * @param modifyKey {function}
 * @return {Model}
 */
function handleModel<T = Model>(data: T, modifyKey: stringModFuncType): T | null {
  const result = parseData(data, modifyKey);
  if (result) {
    return result;
  }
  return null;
}

/**
 * ts method for narrowing down the data input type
 *
 * @private
 * @method handleModelArray
 * @param data {Model[]}
 * @param modifyKey {function}
 * @return {Model[]}
 */
function handleModelArray<T = Model>(data: T[], modifyKey: stringModFuncType): T[] {
  const result = parseData(data, modifyKey);
  if (isArray(result)) {
    return result;
  }
  return [];
}

export function serializeObject<T = Model>(data: T) {
  return handleModel<T>(data, camelize);
}
export function serializeArray<T = Model>(data: T[]) {
  return handleModelArray<T>(data, camelize);
}

/**
 * serialize data into camel case properties
 *
 * calls serializeObject or serializeArray
 *
 * @public
 * @method serialize
 * @param data {Model | Model[]}
 * @return {Model | Model[]}
 */
export function serialize<T = Model>(data: T | T[]) {
  if (isArray(data)) {
    return serializeArray(data);
  } else {
    return serializeObject(data);
  }
}


export function deserializeObject<T = Model>(data: T) {
  return handleModel<T>(data, underscore);
}
export function deserializeArray<T = Model>(data: T[]) {
  return handleModelArray<T>(data, underscore);
}

/**
 * deserialize data into camel case properties
 *
 * calls deserializeObject or deserializeArray
 *
 * @public
 * @method deserialize
 * @param data {Model | Model[]}
 * @return {Model | Model[]}
 */
export function deserialize<T = Model>(data: T | T[]) {
  if (isArray(data)) {
    return deserializeArray(data);
  } else {
    return deserializeObject(data);
  }
}

export function parseChanges<T extends Model>(model: T, data: T): T {
  if (data == null) {
    return model;
  }

  const changes = Object.keys(data).filter(key => {
    return data[key] !== model[key];
  });

  const result: any = {};
  changes.forEach(key => {
    result[key] = data[key];
  });
  return (result as T);
}

