import React, { FC } from "react";
import styles from "./styles.module.scss";
import { cls } from "app/utils";

interface Props {
  img?: string;
  icon?: string;
  title?: string;
  subtitle?: string;
  hasContextMenu?: boolean;
  buttonName?: string;
  onClickButton?: () => void;
  buttonStyles?: string;
  date?: string;
  className?: string;
}

const ContentCard: FC<Props> = ({
  img,
  icon,
  title,
  subtitle,
  buttonName,
  onClickButton,
  buttonStyles,
  date,
  className,
}: Props) => {
  return (
    <div className={cls(styles.contentWrap, className)}>
      <div className={styles.contentInfoWrapper}>
        {img && (
          <div className={styles.contentCardAvatar}>
            <div className={styles.contentCardAvatarShape}>
              {img && <img src={img} alt="icon" />}
            </div>
          </div>
        )}
        {icon && (
          <div className={styles.contentCardAvatar}>
            <div className={styles.contentCardAvatarShape}>
              {icon && <i className={`ico ico-${icon}`}></i>}
            </div>
          </div>
        )}
        <div className={styles.contentCardInfoText}>
          <span className={styles.title}>
            <h2>{title}</h2>
            {date && <p>{date}</p>}
          </span>
          <h3>{subtitle}</h3>
        </div>
      </div>
      {buttonName && (
        <button
          className={cls(styles.button, buttonStyles)}
          onClick={onClickButton ? onClickButton : null}
        >
          {buttonName}
        </button>
      )}
    </div>
  );
};

export default ContentCard;
