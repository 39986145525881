/**
 * @module Utils
 *
 */

interface ElementSize {
  currentHeight: number;
  currentWidth: number;
  width: number;
  height: number;
  scale: number;
}

export interface ElementRect {
  top: number;
  left: number;
  width: number;
  height: number;
}

/**
 * Gets the element rectangle relative the user agent.
 *
 * @param {Element} element
 * @returns {object}
 */
export function getElementRectangle(element: HTMLElement): ElementRect {
  const rect = element.getBoundingClientRect();
  const root = document.documentElement;

  return {
    top: rect.top + window.pageYOffset - root.clientTop,
    left: rect.left + window.pageXOffset - root.clientLeft,
    width: rect.width,
    height: rect.height
  };
}

/**
 * Computes element height.
 *
 * @param {Element}
 * @returns {number}
 */
export function getHeight(element: HTMLElement): number {
  return getElementRectangle(element).height;
}

/**
 * Returns the natural size of an image. The image must be loaded.
 *
 * @param {Element} image
 * @return {object}
 */
export function getImageSize(image: HTMLImageElement): ElementSize {
  let scale = (image.width / image.naturalWidth);
  if (image.naturalWidth > image.naturalHeight) {
    scale = (image.height / image.naturalHeight);
  }

  return {
    currentHeight: image.height,
    currentWidth: image.width,
    height: image.naturalHeight,
    width: image.naturalWidth,
    scale,
  };
}

/**
 * Crop options that are merged in with crop calculates to ensure all values are provided.
 */
const DEFAULT_CROP_OPTIONS: ElementRect = { top: 0, left: 0, width: 100, height: 100 };

/**
 * Crops an image located in an image element to the specific crop area. The result is a base64 image.
 *
 * @param {HTMLImageElement} image
 * @param {object} crop
 * @param {number[]} size
 * @returns {string}
 */
export function cropImage(image: HTMLImageElement, crop: ElementRect, size: number[] | null = null): string | null {
  const dimensions = getImageSize(image);
  const options = { ...DEFAULT_CROP_OPTIONS, ...crop };
  const getCoord = (val: number) => val / dimensions.scale;

  const x1 = getCoord(options.left);
  const y1 = getCoord(options.top);
  const x2 = getCoord(options.width);
  const y2 = getCoord(options.height);

  if (x2 && y2) {
    const canvas = document.createElement('canvas');
    canvas.width = size != null ? size[0] : x2;
    canvas.height = size != null ? size[1] : y2;
    const context = canvas.getContext('2d');

    if (context != null) {
      context.drawImage(image, x1, y1, x2, y2, 0, 0, canvas.width, canvas.height);
    }
    return canvas.toDataURL('image/jpeg');
  }
  return null;
}

export function getMaxAspectArea(image: HTMLImageElement, aspect: number): ElementRect {
  const { currentWidth, currentHeight } = getImageSize(image);
  const imageAspect = currentWidth / currentHeight;

  let width = currentWidth;
  let height = currentHeight;
  if (aspect > 1) {
    height = currentWidth / aspect;
    if (height > currentHeight) {
      height = currentHeight;
      width = currentHeight * aspect;
    }
  } else if (aspect < 1) {
    width = currentHeight * aspect;
    if (width > currentWidth) {
      width = currentWidth;
      height = currentWidth / aspect;
    }
  } else if (imageAspect > 1) {
    width = currentWidth / imageAspect;
  } else if (imageAspect < 1) {
    height = currentHeight * imageAspect;
  }

  const left = (currentWidth - width) / 2;
  const top = (currentHeight - height) / 2;

  return { top, left, width, height };
}

/**
 * Returns value of an input.
 *
 * @param {HTMLInputElement} element
 * @returns {string|boolean}
 */
export function valueOf(element: HTMLInputElement): string | boolean {
  switch (element.type) {
    case 'text':
      return element.value;
    case 'checkbox':
      return element.checked;
    default:
      return element.value;
  }
}

