import React from "react";
import styles from "./styles.module.scss";
import ModalLayout from "../ModalLayout/ModalLayout";
import { cls } from "app/utils";
import { AddToRecruitProfileProps } from "app/components/Button/AddToRecruitProfile/index.types";

const AddToRecruitPostModal = ({
  modalOpen,
  onModalClose,
  yesClickHandler,
}: AddToRecruitProfileProps) => {
  return (
    <>
      <ModalLayout isOpen={modalOpen} onClose={onModalClose}>
        <div className={styles.container}>
          <h1 className={styles.title}>
            Are you sure you want to add this post to your Recruit profile?
          </h1>
          <div className={styles.button_container}>
            <button
              className={cls(styles.done_button, "btn btn-primary")}
              onClick={yesClickHandler}
            >
              Yes
            </button>
            <button
              className={cls(styles.done_button, "btn btn-outline-secondary")}
              onClick={onModalClose}
            >
              Cancel
            </button>
          </div>
        </div>
      </ModalLayout>
    </>
  );
};

export default AddToRecruitPostModal;
