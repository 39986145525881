import { useCallback, useEffect, useMemo, useState } from "react";
import { useActions } from "app/utils/hooks";
import Fuse from "../../../../vendor/fuse";
import { GroupSelectProps } from "./index.types";
import { useDispatch, useSelector } from "react-redux";
import {
  locations,
  groupChannelObj,
  applyTemplateToChannel,
} from "app/actions/applyTemplate";
import { RootState } from "typedefs";
import TeamGroupModel from "app/models/TeamGroupModel";
import TeamModel from "app/models/TeamModel";

const initLists: TeamModel[] = [];

const useChannelSelectModal = ({ onClose, templateId }: GroupSelectProps) => {
  const dispatch = useDispatch();

  const team = useSelector((state: RootState) => state.team);

  const channelIds = useSelector(
    (state: RootState) => state.applyTemplate.locations
  );
  const channelsArr = useSelector(
    (state: RootState) => state.applyTemplate.groupChannelObj
  );

  const applyTemplateActions = useActions({
    applyTemplateToChannel,
  });
  const [searchTerm, setSearch] = useState<string>("");
  const [channels, setChannels] = useState(initLists);
  const [selectedChannelsId, setSelectedChannelsId] =
    useState<string[]>(channelIds);
  const [selectedChannel, setSelectedChannel] =
    useState<TeamGroupModel[]>(channelsArr);

  const fuseOptions = {
    keys: ["athleteName", "name"],
    includeScore: true,
    shouldSort: true,
    threshold: 0.5,
  };

  useEffect(() => {
    if (team.data != null) {
      setChannels(team.data || []);
    }
  }, [team.data]);

  const fuse = useMemo(() => {
    const items = channels.map((channel: TeamModel) => channel);
    return new (Fuse as any)(items, fuseOptions);
  }, [channels, fuseOptions]);

  const results = useMemo(() => {
    if (searchTerm !== "") {
      return fuse.search(searchTerm).map((result: any) => result.item);
    } else {
      return channels;
    }
  }, [searchTerm, team, fuse]);

  const onSearchChannel = useCallback(
    (value: string) => {
      if (value != null && value.length > 0) {
        setSearch(value);
      } else {
        setSearch("");
      }
    },
    [searchTerm]
  );

  const channelSelectHandler = useCallback(
    (value: string, groupChannelObj: TeamGroupModel) => {
      setSelectedChannelsId((prevSelectedChannelsId) => {
        if (prevSelectedChannelsId.includes(value)) {
          return prevSelectedChannelsId.filter((val) => val !== value);
        } else {
          return [...prevSelectedChannelsId, value];
        }
      });
      setSelectedChannel((prevSelectedChannel) => {
        if (prevSelectedChannel.includes(groupChannelObj)) {
          return prevSelectedChannel.filter((val) => val !== groupChannelObj);
        } else {
          return [...prevSelectedChannel, groupChannelObj];
        }
      });
    },
    [selectedChannelsId, selectedChannel]
  );

  useEffect(() => {
    dispatch(locations(selectedChannelsId));
    dispatch(groupChannelObj(selectedChannel));
  }, [selectedChannelsId, selectedChannel]);

  const cancelHandler = () => {
    setSelectedChannelsId([]);
    setSelectedChannel([]);
    setSearch("");
    onClose();
  };

  const applyHandler = () => {
    applyTemplateActions.applyTemplateToChannel(selectedChannelsId, templateId);
    setSearch("");
    onClose();
  };

  return {
    channelSelectHandler,
    cancelHandler,
    applyHandler,
    onSearchChannel,
    team,
    searchTerm,
    results,
    selectedChannelsId,
  };
};

export default useChannelSelectModal;
