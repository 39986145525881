/**
 * @module Components.ScheduledPosts.ScheduledAtPicker
 *
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  schedule: { id: 'app.scheduled_at_picker.schedule', defaultMessage: "Schedule" },
  publish_at: { id: 'app.scheduled_at_picker.publish_at', defaultMessage: "<strong>{date}</strong> publish at <strong>{time}</strong>" }
});
