/**
 * @module Utils
 *
 */
import postalCodes from 'app/translations/postal-codes.json';
import { MessageDescriptor } from 'react-intl';

export function isValidEmail(value: string) {
  const email_regex = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
  return email_regex.test(value);
}

export function validatePostalCode(str: string, locale: string | string[], ...locales: string[]): boolean {
  if (typeof locale === 'string') {
    locales = [locale, ...locales];
  } else if (Array.isArray(locale)) {
    locales = [...locale, ...locales];
  }

  if (!locales.length) {
    return /^\d{5}$/.test(str);
  }

  return locales
    .map((locale: string): boolean => (new RegExp((postalCodes as any)[locale])).test(str))
    .reduce((a: boolean, b: boolean): boolean => a || b, false);
}

export interface IValidator {
  test: (val: any) => boolean;
  message: string | MessageDescriptor;
}

/**
 * @method createValidator
 * @param message {string|MessageDescriptor}
 */
export function createValidator(message: string | MessageDescriptor, test: IValidator["test"]): IValidator {
  return { test: val => test(val), message };
}
