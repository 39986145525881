import React, { useCallback, useEffect, useMemo, useState } from "react";
import { fetchConnections } from "app/actions/connection";
import { useStoreActions } from "app/utils/hooks";
import { Loading } from "app/components/Wrappers";
import styles from "./styles.module.scss";
import Dropdown from "app/components/Dropdown";
import { cls } from "app/utils";
import UserModel from "app/models/UserModel";
import UserAvatar from "app/components/UserAvatar";
import Fuse from "../../../../vendor/fuse";
import Button from "app/components/Button/ButtonVariant";

const UserSelect = ({
  onChange,
}: {
  onChange: (selectedUsers: UserModel[]) => void;
}) => {
  const { connection } = useStoreActions({ connection: fetchConnections });
  const [userOptions, setUserOptions] = useState<UserModel[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [selectedUsers, setSelectedUsers] = useState<UserModel[]>([]);

  const fuseOptions = {
    keys: ["displayName", "email"],
    includeScore: true,
    shouldSort: true,
    threshold: 0.5,
  };

  useEffect(() => {
    if (connection.data) {
      setUserOptions(connection.data || []);
    }
  }, [connection.data]);

  const fuse = useMemo(() => {
    const users = userOptions.map((user: UserModel) => user);
    return new (Fuse as any)(users, fuseOptions);
  }, [userOptions, fuseOptions]);

  const results = useMemo(() => {
    if (searchTerm !== "" || searchTerm) {
      return fuse.search(searchTerm).map((result: any) => result.item);
    } else {
      return userOptions;
    }
  }, [searchTerm, userOptions, fuse]);

  const onSearchTeam = useCallback((value: string) => {
    if (value != null && value.length > 0) {
      setSearchTerm(value);
    } else {
      setSearchTerm("");
    }
  }, []);

  const toggleUserSelection = (user: UserModel) => {
    const isUserSelected = selectedUsers.some(
      (selectedUser) => selectedUser.id === user.id,
    );

    if (isUserSelected) {
      setSelectedUsers(
        selectedUsers.filter((selectedUser) => selectedUser.id !== user.id),
      );
    } else {
      setSelectedUsers([...selectedUsers, user]);
    }

    setUserOptions((prevUserOptions) =>
      prevUserOptions.filter((option) => option.id !== user.id),
    );
    onChange(selectedUsers);
  };

  const removeUser = useCallback(
    (user: UserModel) => {
      setSelectedUsers(
        selectedUsers.filter((selectedUser) => selectedUser.id !== user.id),
      );

      setUserOptions((prevUserOptions) => [...prevUserOptions, user]);
      onChange(selectedUsers);
    },
    [selectedUsers],
  );

  useEffect(() => {
    onChange(selectedUsers);
  }, [selectedUsers]);

  if (connection.pending) {
    return <Loading loadType="spinner" />;
  }

  const SelectedUsers = ({
    selectedAuthors,
    onRemoveUserClick,
  }: {
    selectedAuthors: UserModel[];
    onRemoveUserClick: (author: UserModel) => void;
  }) => {
    return (
      <div className={styles.users_list}>
        {selectedAuthors.map((author: UserModel) => {
          return (
            <div className={styles.user_container} key={author.id}>
              <div className={styles.user_info_container}>
                <UserAvatar
                  className={styles.avatar}
                  sizeWidth={44}
                  sizeHeight={44}
                  altName="author"
                  userName={author.displayName}
                  srcName={author.avatar as string}
                />
                <div className={styles.user_info}>
                  <h4>{author.displayName}</h4>
                  <p>{author.email}</p>
                </div>
              </div>
              <Button
                title={<i className={cls("ico ico-x text-danger")} />}
                onClickFunc={() => onRemoveUserClick(author)}
                buttonType="secondary-outline"
              />
            </div>
          );
        })}
      </div>
    );
  };

  const DropdownContent = () => {
    return (
      <span className={styles.input_container}>
        <input
          type="text"
          className={styles.input}
          onChange={(e) => onSearchTeam(e.target.value)}
          placeholder="Search by name or email"
        />
        <span className={styles.svg_container}>
          <DropdownIndicator />
        </span>
      </span>
    );
  };

  return (
    <>
      <Dropdown
        closeOnClick
        hideButtonStyles
        dropdownWrapperClass={styles.dropdown}
        btnClassName={styles.btnn}
        btnContent={<DropdownContent />}
      >
        <UsersList
          results={results}
          toggleUserSelection={toggleUserSelection}
        />
      </Dropdown>

      {selectedUsers && selectedUsers.length > 0 && (
        <SelectedUsers
          onRemoveUserClick={removeUser}
          selectedAuthors={selectedUsers}
        />
      )}
    </>
  );
};

export default UserSelect;

const UsersList = ({
  results,
  toggleUserSelection,
}: {
  results: UserModel[];
  toggleUserSelection: (e: UserModel) => void;
}) => {
  if (!results || !results.length) {
    return <p className={cls("needsclick", styles.no_options)}>No options</p>;
  }
  return (
    <div className={styles.list_container}>
      {results &&
        results.map((user: UserModel) => {
          return (
            <div
              className={cls("menu-item needsclick", styles.user_wrapper)}
              key={user.id}
              onClick={() => toggleUserSelection(user)}
            >
              <div className="profile-block user-select-user profile-block-default needsclick">
                <UserAvatar
                  className="avatar needsclick"
                  userName={user.name}
                  srcName={user.avatar as string}
                />
                <div className="profile-block-details needsclick">
                  <div className="profile-block-title needsclick">
                    {user.displayName}
                  </div>

                  <div className="profile-block-summary needsclick">
                    {user.email || user.phoneNormalized}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
};

const DropdownIndicator = () => {
  return (
    <svg
      height="20"
      width="20"
      viewBox="0 0 20 20"
      aria-hidden="true"
      focusable="false"
      className={cls("css-6q0nyr-Svg", styles.dropdown_indicator)}
    >
      <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
    </svg>
  );
};
