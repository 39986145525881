/* eslint-disable no-undef */
/* eslint-disable react/display-name */
/** @module routes/spaces */
import React from "react";
import { defineMessages } from "react-intl";
import { AnalyticsService } from "app/services";
import { useLoc, useStoreActions } from "app/utils/hooks";
import { route } from "app/utils/router";
import { fetchDemoSpaceAction, DemoSpaceModel } from "app/actions/DemoSpace";
import { NoResults } from "app/components/Layouts";
import { Loading } from "app/components/Wrappers";
import { isAuthenticated } from "app/utils/auth";
import UserAvatar from "app/components/UserAvatar";
import no_space_png from "app/images/null-training-space.png";
import Styles from "./styles.module.scss";

type PropsDef = {
  sport?: string;
};

// const HOVER_CLASS = 'hover';

/**
 * @class SpaceDemoList
 *
 */
export default function SpaceDemoList(props: PropsDef) {
  const { formatMessage } = useLoc();
  const { demoSpace } = useStoreActions(
    { demoSpace: () => fetchDemoSpaceAction(null, props.sport) },
    { noAuth: true }
  );

  const spaceClicked = (model: DemoSpaceModel) => {
    const trackProps = { "Demo Space Name": model.space.athleteName };
    AnalyticsService.track("Demo Space Viewed", trackProps, () =>
      routeToSpace(model)
    );
  };

  const routeToSpace = (model: DemoSpaceModel) => {
    const params: { back?: string; frame?: string } = {};
    if (!isAuthenticated()) {
      params.back = "true";
    }
    if (/(\?|&)frame/.test(window.location.search)) {
      params.frame = "true";
    }
    // route to demo space
    route(`/spaces/demo/${model.demoSpaceId}`, false, params);
  };

  let content: JSX.Element;
  if (demoSpace.data != null && demoSpace.data.length) {
    let spaceList: JSX.Element[] = [];
    spaceList = demoSpace.data.map((demoSpace: DemoSpaceModel, key: any) => {
      return (
        <SpaceItem key={key} demoSpace={demoSpace} onClick={spaceClicked} />
      );
    });

    content = (
      <div className={Styles.spaceContainer}>
        {spaceList}
        <div className={Styles.clear} />
      </div>
    );
  } else {
    const filterFunc = () => {
      demoSpace.fetchUpdate(undefined, null);
    };

    const formattedSubText = formatMessage(locales.no_results_subtext, {
      sport: props.sport,
      bold: (str: string) => <b>{str}</b>,
      click: (str: string) => (
        <a className={Styles.moreOpts} onClick={filterFunc}>
          {str}
        </a>
      ),
    });

    content = (
      <NoResults
        image={no_space_png}
        text={formatMessage(locales.no_results_text)}
        subtext={formattedSubText}
      />
    );
  }

  return (
    <Loading className={Styles.spaceList} isLoading={demoSpace.pending} loadType="spinner">
      {content}
    </Loading>
  );
}

type ItemPropsDef = {
  demoSpace: DemoSpaceModel;
  onClick: (model: DemoSpaceModel) => void;
};

function SpaceItem({ demoSpace, onClick }: ItemPropsDef) {
  return (
    <div className={Styles.grid} onClick={() => onClick(demoSpace)}>
      <div className={Styles.expandingBox}>
        <div className={Styles.expander}>
          <div className={Styles.avatar}>
            <UserAvatar
              srcName={demoSpace.space.athleteAvatar}
              altName={demoSpace.space.athleteName}
            />
          </div>
          <div className={Styles.title}>
            <h4>{demoSpace.space.athleteName}</h4>
            <h6>{demoSpace.space.sport}</h6>
          </div>
        </div>
      </div>
    </div>
  );
}

const locales = defineMessages({
  no_results_text: {
    id: "demo_spaces.no_results.text",
    defaultMessage: "Inspirational Spaces",
  },
  no_results_subtext: {
    id: "demo_spaces.no_results.subtext",
    defaultMessage:
      "There are currently no Space examples for <bold>{sport}</bold> sports. <click>Click here</click> to see more sports.",
  },
});
