import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import closeIcon from "../../../public/images/close-icon.svg";
import { IModalProps } from "./index.types";
import ModalLayout from "../Layouts/ModalLayout/ModalLayout";
import { useActions, useSelector } from "app/utils/hooks";
import { clearViewsState, fetchViews } from "app/actions/views";
import { RootState } from "typedefs";
import moment from 'moment';
import { Spinner } from "../Loading";
import NotifyBtn from "./components/NotifyBtn";
import NoEye from "../../../public/images/Eye.svg";

const PostViewsModal = ({ isOpen, onCloseFunc, postId }: IModalProps) => {
  const actions = useActions({ fetchViews, clearViewsState });
  const { data: views, notSeenUsers, pending, receipts } = useSelector((state: RootState) => state.views);
  const [search, setSearch] = useState("");
  const [filtredViews, setFilteredViews] = useState(views);

  useEffect(() => {
    if (isOpen) {
      actions.fetchViews(postId);
    }

    return () => {
      actions.clearViewsState();
    };
  }, [isOpen]);

  useEffect(() => {
    if (search.length > 0) {
      const viewedUsers = views.filter((view: any) => view.viewer.displayName.toLowerCase().includes(search));
      const unseenUsers = notSeenUsers.filter((view: any) => view.displayName.toLowerCase().includes(search));
      setFilteredViews([...viewedUsers, ...unseenUsers]);
    }
  }, [search]);

  const checkIsReceptActivate = function(userId: string) {
    const found = receipts && receipts.find((recept) => recept.recipient.id === userId);
    if (found) {
      return found.id;
    }
    return false;
  };

  return (
    <ModalLayout onClose={onCloseFunc} isOpen={isOpen}>
      <div className={styles.content_wrapper}>
        <div className={styles.modal_header}>
          <h1>Post Views</h1>
          <img
            src={closeIcon}
            alt="close"
            className={styles.close_icon}
            onClick={onCloseFunc}
          />
        </div>
        <div className={styles.modal_description}>
          You can enable read receipts for specific athletes if you&apos;d like
          to be directly notified when they view this post.
        </div>
        <div className={styles.search_container}>
          <input
            type="text"
            className={styles.search_input}
            placeholder="Search..."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <i className="ico ico-search gray text-middle"></i>
        </div>

        {!pending && search.length === 0 && (
          <div className={styles.modal_content_container}>
            {views && views.length === 0 && (
              <div className={styles.modal_no_views}>
                <img src={NoEye} alt="icon" width={40} height={40} />
                <h2>0 views</h2>
                <p>Your post has not been seen yet</p>
              </div>
            )}
            {views && views.length > 0 && (
              <>
                <div className={styles.modal_body_header}>Saw this post</div>
                <div className={styles.modal_body_container}>
                  {views && views.map((view: any) => (
                    <div key={view.id} className={styles.body_content}>
                      <div className={styles.content_info_wrapper}>
                        <img className={styles.avatar} src={view.viewer.avatar} alt="avatar" />
                        <div className={styles.user_info}>
                          <h3 className={styles.username}>{view.viewer.displayName}</h3>
                          <p className={styles.role}>Coach</p>
                        </div>
                      </div>
                      <p className={styles.seen_timer}>{moment(view.seenAt).fromNow()}</p>
                    </div>
                  ))}
                </div>
              </>
            )}
            {notSeenUsers && notSeenUsers.length > 0 && (
              <>
                <div className={styles.modal_body_header}>
                  Didn&apos;t see this post
                </div>
                <div className={styles.modal_body_container}>
                  {notSeenUsers && notSeenUsers.map((user: any) => (
                    <div key={user.id} className={styles.body_content}>
                      <div className={styles.content_info_wrapper}>
                        <img className={styles.avatar} src={user.avatar} alt="avatar" />
                        <div className={styles.user_info}>
                          <h3 className={styles.username}>{user.displayName}</h3>
                          <p className={styles.role}>Athlete</p>
                        </div>
                      </div>
                      <NotifyBtn
                        defaultValue={checkIsReceptActivate(user.id)}
                        postId={postId}
                        userId={user.id}
                      />
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>
        )}
        {!pending && search.length > 0 && (
          <div className={styles.modal_content_container}>
            <div className={styles.modal_body_container}>
              {filtredViews && filtredViews.map((view: any) => (
                <div key={view.id} className={styles.body_content}>
                  <div className={styles.content_info_wrapper}>
                    <img className={styles.avatar} src={view.viewer ? view.viewer.avatar : view.avatar} alt="avatar" />
                    <div className={styles.user_info}>
                      <h3 className={styles.username}>{view.viewer ? view.viewer.displayName : view.displayName}</h3>
                      <p className={styles.role}>Coach</p>
                    </div>
                  </div>
                  <p className={styles.seen_timer}>{view.seenAt && moment(view.seenAt).fromNow()}</p>
                </div>
              ))}
            </div>
          </div>
        )}
        {pending && (
          <Spinner />
        )}
      </div>
    </ModalLayout>
  );
};

export default PostViewsModal;
