import { fetchOnboarding } from "app/actions/onboarding";
import { useStoreActions } from "app/utils/hooks";
import { useState } from "react";

const useGettingStartedModel = () => {
  const { onboarding } = useStoreActions({ onboarding: fetchOnboarding });
  const [isInspirationModalOpen, setIsInspirationModalOpen] = useState(false);
  const handleInspirationModal = () => {
    setIsInspirationModalOpen((prev) => !prev);
  };

  const hyperLinkUrl = {
    learnMore: "https://coachnow.io/video",
    playStoreLink:
      "https://play.google.com/store/apps/details?id=com.edufii&referrer=utm_source%3Dwebapp%26utm_medium%3Donboarding%26utm_campaign%3Dv1",
    appStoreLink:
      "https://apps.apple.com/app/apple-store/id596598472?pt=117236&ct=web-onboarding&mt=8",
    book_a_demo_link: "https://bit.ly/42ea4c3",
  };

  return {
    onboarding,
    isInspirationModalOpen,
    handleInspirationModal,
    hyperLinkUrl,
  };
};

export default useGettingStartedModel;
