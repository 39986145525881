/**
 * @module Components.ScheduledPosts.Filter
 *
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  all: { id: 'app.scheduled_posts.filter.all', defaultMessage: "All" },
  spaces: { id: 'app.scheduled_posts.filter.spaces_select.spaces', defaultMessage: "Spaces" },
  spaces_plural: {
    id: 'app.scheduled_posts.filter.spaces_select.plural_spaces',
    defaultMessage: "{count, plural, =0 {Spaces} one {# Space} other {# Spaces}}"
  },
  spaces_plural_lists: {
    id: 'app.scheduled_posts.filter.spaces_select.plural_spaces',
    defaultMessage: "({count, plural, =0 {Spaces} one {# Space} other {# Spaces}})"
  },
  spaces_search_placeholder: { id: 'app.scheduled_posts.filter.spaces_select.spaces_search_placeholder', defaultMessage: "Search Spaces" },

  groups: { id: 'app.scheduled_posts.filter.groups_select.groups', defaultMessage: "Groups" },
  groups_plural: {
    id: 'app.scheduled_posts.filter.groups_select.plural_groups',
    defaultMessage: "{count, plural, =0 {Groups} one {# Group} other {# Groups}}"
  },
  channel: { id: 'app.scheduled_posts.filter.groups_select.channel', defaultMessage: "Channel" },
  general_channel: { id: 'app.scheduled_posts.filter.groups_select.general_channel', defaultMessage: "General" },
  groups_search_placeholder: { id: 'app.scheduled_posts.filter.groups_select.groups_search_placeholder', defaultMessage: "Search groups..." },

  lists: { id: 'app.scheduled_posts.filter.lists_select.lists', defaultMessage: "Lists" },
  lists_plural: {
    id: 'app.scheduled_posts.filter.lists_select.plural_lists',
    defaultMessage: "{count, plural, =0 {Lists} one {# List} other {# Lists}}"
  },
  list: { id: 'app.scheduled_posts.filter.lists_select.list', defaultMessage: "List" },
  lists_search_placeholder: { id: 'app.scheduled_posts.filter.lists_select.lists_search_placeholder', defaultMessage: "Search Lists" },
  list_no_spaces: { id: 'app.scheduled_posts.filter.lists_select.list_no_spaces', defaultMessage: "No Spaces" },

  filter: { id: 'app.scheduled_posts.filter.filter_button', defaultMessage: "Filter" },
  cancel: { id: 'app.scheduled_posts.filter.cancel_button', defaultMessage: "Cancel" },

  search: { id: 'app.scheduled_posts.filter.search', defaultMessage: "Search" }
});
