/* eslint-disable no-console */
import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import PagePanel from "app/components/PagePanel";
import { cls } from "app/utils";
import paywallModalPro from "app/utils/paywall";
import SegmentList from "app/components/SegmentList";
import { useActions, useStoreActions } from "app/utils/hooks";
import { createSegment } from "../../actions/segments";
import ModalLayout from "app/components/Layouts/ModalLayout/ModalLayout";
import Button from "app/components/Button/ButtonVariant";
import { fetchSelf } from "app/actions/user";
import SegmentsLayout from "app/components/Layouts/SegmentsLayout";
import { route } from "app/utils/router";

const Segments = () => {
  const { user, pending } = useStoreActions({ user: fetchSelf });
  const segmentActions = useActions({ createSegment });
  const [newListModalOpen, setNewListModalOpen] = useState<boolean>(false);
  const [newListName, setNewListName] = useState<string | null>(null);
  const [newListError, setNewListError] = useState<boolean>(false);
  const [canCreateSegments, setCanCreateSegments] = useState(false);

  useEffect(() => {
    if (user && user.data && user.data.planPermissions) {
      setCanCreateSegments(
        user.data.planPermissions.includes("create_segments"),
      );
    }
  }, [user.data]);

  const modalHandler = () => {
    setNewListModalOpen((prev) => !prev);
  };

  const onNewListClick = () => {
    if (canCreateSegments) {
      modalHandler();
    } else {
      paywallModalPro({ displayModal: "segments" });
    }
  };

  const onNewListNameChange = (e: any) => {
    const name = e.target.value;
    setNewListName(name);
    if (name && name.length > 0) {
      setNewListError(false);
    } else {
      setNewListError(true);
    }
  };

  const onSubmit = async () => {
    if (canCreateSegments) {
      if (!newListName || newListName.trim().length === 0) {
        setNewListError(true);
        return;
      }

      try {
        const res = await segmentActions.createSegment({
          name: newListName.trim(),
        });

        if (res.id) {
          const cancelUrl = `/lists/${res.id}/spaces/edit`;
          // window.location.pathname = cancelUrl;
          route(cancelUrl);
        } else {
          console.error("Segment creation response is missing 'id'.");
        }
      } catch (error) {
        console.error("Error creating segment:", error);
      }
    } else {
      paywallModalPro();
    }
    modalHandler();
  };

  return (
    <SegmentsLayout isLoading={pending}>
      <div className={styles.container}>
        <PagePanel
          title="Lists"
          buttonTitle="+ New List"
          buttonStyles={cls({
            ["btn-primary"]: canCreateSegments,
            ["btn-upgrade"]: !canCreateSegments,
          })}
          onButtonClick={onNewListClick}
        />
        <SegmentList />
      </div>
      <ModalLayout
        classNames={styles.modal_container}
        isOpen={newListModalOpen}
        onClose={modalHandler}
      >
        <div className={styles.modal_content_wrapper}>
          <p>New List</p>
          <span className={styles.input_wrapper}>
            <label htmlFor="new-list">List Name</label>
            <input
              id="new-list"
              name="new-list"
              onChange={(e) => onNewListNameChange(e)}
              className={cls({
                [styles.error]: newListError,
              })}
            />
            {newListError && (
              <p
                className={cls({
                  [styles.error_text]: newListError,
                })}
              >
                Name Required
              </p>
            )}
          </span>
          <span className={styles.buttons_container}>
            <Button
              title="Cancel"
              buttonType="secondary-outline"
              onClickFunc={modalHandler}
            />
            <Button
              title="Create"
              buttonType="primary"
              onClickFunc={onSubmit}
              disabled={newListName && newListName.length <= 0}
            />
          </span>
        </div>
      </ModalLayout>
    </SegmentsLayout>
  );
};

export default Segments;
