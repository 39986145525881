import { ApplyTemplateAction } from "app/actions/applyTemplate";
import ListModel from "app/models/ListModel";
import SpaceModel from "app/models/SpaceModel";
import TeamGroupModel from "app/models/TeamGroupModel";
import { RequestError } from "typedefs";

export type ApplyTemplateState = {
  pendingApplyTemplate: boolean;
  errorApplyTemplate: RequestError | null;
  locations: string[];
  spaceObj: SpaceModel[];
  groupObj: TeamGroupModel[];
  groupChannelObj: ListModel[];
  success: boolean;
};

const initialState: ApplyTemplateState = {
  errorApplyTemplate: null,
  pendingApplyTemplate: false,
  locations: [],
  spaceObj: [],
  groupObj: [],
  groupChannelObj: [],
  success: false,
};

export default function applyTemplateReducer(
  state = initialState,
  action: ApplyTemplateAction
) {
  switch (action.type) {
    case "@applyTemplate.create.error":
      return {
        ...state,
        pendingApplyTemplate: false,
        errorApplyTemplate: action.payload,
      };
    case "@applyTemplate.create.success":
      return {
        ...state,
        success: action.payload,
        errorApplyTemplate: false,
        pendingApplyTemplate: false,
      };
    case "@applyTemplate.fetch.locations":
      return {
        ...state,
        locations: action.payload,
        pendingApplyTemplate: false,
      };
    case "@applyTemplate.fetch.spaceObj":
      return {
        ...state,
        spaceObj: action.payload,
        pendingApplyTemplate: false,
      };
    case "@applyTemplate.fetch.groupObj":
      return {
        ...state,
        groupObj: action.payload,
        pendingApplyTemplate: false,
      };
    case "@applyTemplate.fetch.groupChannelObj":
      return {
        ...state,
        groupChannelObj: action.payload,
        pendingApplyTemplate: false,
      };
    case "@applyTemplate.clear":
      return {
        ...state,
        success: false,
        errorApplyTemplate: null,
        locations: [],
        spaceObj: [],
        groupObj: [],
        groupChannelObj: [],
        pendingApplyTemplate: null,
      };
    default:
      return state;
  }
}
