import CheckBox from "app/components/inputs/CheckBox";
import React from "react";
import styles from "../styles.module.scss";
import { cls } from "app/utils";
import { Loading } from "app/components/Wrappers";

interface Props {
  lists: any;
  toggleListClick: (item: {}) => void;
  selectedLists: any;
}

const ListLocation = ({ lists, toggleListClick, selectedLists }: Props) => {
  return (
    <>
      <label className={styles.list_title}>Lists</label>
      {lists.data &&
        lists.data.map((list: any) => (
          <div
            key={list.id}
            onClick={() =>
              list.permissions.includes("post") && list.spaceIds
                ? toggleListClick(list)
                : null
            }
          >
            <div className={styles.dropdownItem}>
              <div className="avatar">
                <i className="ico ico-groups" />
              </div>
              <div
                className={cls(styles.name, {
                  [styles.disabled]:
                    !list.permissions.includes("post") || !list.spaceIds,
                })}
              >
                {list.name}
                <div className={styles.sport}>{list.sport}</div>
              </div>
              <CheckBox
                className={styles.checkbox}
                name={list}
                checked={selectedLists.has(list)}
              />
            </div>
          </div>
        ))}
      {lists.pending && <Loading isLoading loadType="spinner" />}
    </>
  );
};

export default ListLocation;
