/**
 * @module Utils
 *
 * @exports { isValidVersion, compareVersion }
 */

const buildOrder: string[] = [
  'development',
  'alpha',
  'beta',
  'staging',
  'production'
];

/**
 * Version Utils follow the semver format for versioning numbers
 *
 * Note that build type version are not valid for the purposes of this class
 *  - build meta data `X.X.X+build.2.b8f12d7` regex => (?:\+([0-9a-zA-Z-]+(?:\.[0-9a-zA-Z-]+)*))?
 *
 *
 * for more information got to `https://semver.org/`
 *
 */

/**
 * validate if a string is formattedto the semver standards
 *
 * @method isValidVersion
 * @param vers {string} value to test
 * @returns {boolean}
 */
export function isValidVersion(vers: string): boolean {
  return /^v?(0|[1-9]\d*)\.(0|[1-9]\d*)\.(0|[1-9]\d*)(?:-((?:0|[1-9]\d*|\d*[a-zA-Z-][0-9a-zA-Z-]*)(?:\.(?:0|[1-9]\d*|\d*[a-zA-Z-][0-9a-zA-Z-]*))*))?$/.test(vers); // tslint:disable-line max-line-length
}

type tribool = 0 | 1 | -1 ;

/**
 * Compare two version numbers
 *  returns 0 when versions match
 *  returns 1 when version 1 is greater than version 2
 *  returns -1 when version 1 is less than version 2
 *
 * @method compareVersion
 * @param version {string} semver formatted string
 * @param compare {string} semver formatted string to compare version against
 * @return {number}
 */
export function compareVersion(version: string, compare: string): tribool {
  if (!isValidVersion(version)) {
    throw new Error("compareVersion requires a valid semver formatted string as the first param");
  }

  // string compare is valid to check if versions are equal
  if (version === compare) {
    return 0;
  }

  const pv1 = createVersionArray(version);
  const pv2 = createVersionArray(compare);

  // simple method to return
  //  1 if greater than
  //  -1 is less than
  //  0 if equal
  const comparorator = (a: number, b: number): tribool => (a > b ? 1 : (b > a ? -1 : 0));

  return pv1
    .map((vers, idx) => comparorator(vers, pv2[idx]))
    .reduce((a, b) => a === 0 ? b : a, 0);
}

function createVersionArray(vers: string) {
  if (!isValidVersion(vers)) {
    return [0, 0, 0]; // return lowest version if not a valid version number
  }

  // remove letter `v` if present
  vers = vers.replace(/^v/, '');

  const [ ver, pre ] = vers.split(/-/);
  const versionArray: number[] = ver.split(/\./).map((n: string): number => normalizeNumber(n));
  if (pre && pre.length) {
    const [ rcType, rcVersion ] = pre.split(/\./);

    if (rcVersion !== undefined && rcVersion !== null && rcVersion.length > 0) {
      const rcIdx: number = buildOrder.indexOf(rcType);
      if (rcIdx !== -1) {
        versionArray.push(rcIdx);
      }
      versionArray.push(normalizeNumber(rcVersion));
    }
  }
  return versionArray;
}

function normalizeNumber(num: string): number {
  let result = 0;
  if (num && num.length) {
    result = Number(num);
    if (isNaN(result)) {
      result = 0;
    }
  }
  return result;
}

