import { ViewsAction } from "app/actions/views";
import { RequestError } from "typedefs";

export type ViewsState = {
  data: any | null;
  error: RequestError | null;
  pending: boolean;

  notSeenUsers: any[] | null;
  notSeenUsersError: RequestError | null;
  notSeenUsersPending: boolean;

  receipts: any[] | null;
  receiptsError: RequestError | null;
  receiptsPending: boolean;
};

const initialState: ViewsState = {
  data: null,
  error: null,
  pending: false,

  notSeenUsers: null,
  notSeenUsersError: null,
  notSeenUsersPending: false,

  receipts: null,
  receiptsError: null,
  receiptsPending: false,
};

export default function viewsReducer(state = initialState, action: ViewsAction) {
  switch (action.type) {
    case "@views.fetch.pending":
      return { ...state, pending: true, error: null };
    case "@views.fetch.error":
      return { ...state, error: action.payload, pending: false };
    case "@views.fetch.success":
      return { ...state, data: action.payload, pending: false };
    case "@views.fetch.notSeenUsers.pending":
      return { ...state, snotSeenUsersPending: true };
    case "@views.fetch.notSeenUsers.error":
      return { ...state, notSeenUsersError: action.payload, notSeenUsersPending: false };
    case "@views.fetch.notSeenUsers.success":
      return { ...state, notSeenUsers: action.payload, notSeenUsersPending: false };
    case "@views.fetch.receipts.pending":
      return { ...state, receiptsPending: false };
    case "@views.fetch.receipts.error":
      return { ...state, receiptsError: action.payload, receiptsPending: false };
    case "@views.fetch.receipts.success":
      return { ...state, receipts: action.payload, receiptsPending: false };
    case "@views.clear":
      return {
        ...state,
        data: null,
        error: null,
        pending: false,
        notSeenUsers: null,
        notSeenUsersError: null,
        notSeenUsersPending: false,
        receipts: null,
        receiptsPending: false,
        receiptsError: null
      };
    default:
      return state;
  }
}
