import { request } from "app/utils/request";
import { Dispatch } from "react";
import { action, ActionType } from "typesafe-actions";
import config from "config/environment";

const pending = () => action("@segments.fetch.pending");
const error = (err: string) => action("@segments.fetch.error", err);
const success = (post: any) => action("@segments.fetch.success", post);

export const segments = (location: any) =>
  action("@segments.fetch.segments", location);

export const clearSegments = () => action("@segments.clear");

const thisActions = {
  pending,
  error,
  success,
  segments,
  clearSegments,
};

export type segmentsAction = ActionType<typeof thisActions>;

export function fetchSegments(params?: { [key: string]: any }) {
  return (dispatch: Dispatch<segmentsAction>) => {
    dispatch(pending());

    const url = config.API_SERVER + `/api/v1/segments`;
    const promise = request(url, "GET", { params }, { version: 1 });
    promise.then((segments) => dispatch(success(segments)));
    promise.catch((err) => dispatch(error(err)));

    return promise;
  };
}

export function fetchSegmentById(id: string) {
  return (dispatch: Dispatch<segmentsAction>) => {
    dispatch(pending());

    const url = config.API_SERVER + `/api/v1/segments/${id}`;
    const promise = request(url, "GET", {}, { version: 1 });
    promise.then((segments) => dispatch(success(segments)));
    promise.catch((err) => dispatch(error(err)));

    return promise;
  };
}

export function editSegment(id: string, segment?: { [key: string]: any }) {
  return (dispatch: Dispatch<segmentsAction>) => {
    const url = config.API_SERVER + `/api/v1/segments/${id}`;
    const promise = request(url, "PUT", { segment }, { version: 1 });
    promise.catch((err) => dispatch(error(err)));

    return promise;
  };
}

export function createSegment(segment?: { [key: string]: any }) {
  return (dispatch: Dispatch<segmentsAction>) => {
    dispatch(pending());

    const url = config.API_SERVER + `/api/v1/segments`;
    const promise = request(url, "POST", { segment }, { version: 1 });
    promise.then((segments) => dispatch(success(segments)));
    promise.catch((err) => dispatch(error(err)));

    return promise;
  };
}

export function deleteSegment(id: string) {
  return (dispatch: Dispatch<segmentsAction>) => {
    dispatch(pending());

    const url = config.API_SERVER + `/api/v1/segments/${id}`;
    const promise = request(url, "DELETE", {}, { version: 1 });
    promise.then((segments) => dispatch(success(segments)));
    promise.catch((err) => dispatch(error(err)));

    return promise;
  };
}

export function addSegmentMember(
  id: string,
  invitation?: { [key: string]: any }
) {
  return (dispatch: Dispatch<segmentsAction>) => {
    const url = config.API_SERVER + `/api/v2/segments/${id}/add_member`;
    const promise = request(url, "POST", { invitation }, { version: 1 });

    promise.catch((err) => dispatch(error(err)));

    return promise;
  };
}
