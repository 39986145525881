import { useCallback, useEffect, useMemo, useState } from "react";
import {
  useActions,
  useLoc,
  useSelector,
  useStoreActions,
} from "app/utils/hooks";
import { route } from "app/utils/router";
import { fetchDemoSpaceAction } from "app/actions/DemoSpace";
import { fetchSpaces, clearSpace, spaceQuery } from "app/actions/space";
import { fetchSmartLists, clearSmartList } from "app/actions/smartList";
import { RootState } from "typedefs";
import { SpaceQueryModel } from "app/models";
import { useDispatch } from "react-redux";
import { initQuery } from "app/reducers/space";

export const SpaceListContainerModel = () => {
  const { formatMessage } = useLoc();
  const dispatch = useDispatch();

  const [searchTerm, setSearch] = useState("");
  // const [spaces, setSpaces] = useState(null);

  const selectedSmartList = useSelector(
    (state: RootState) => state.smartList.selectedListValue
  );
  const user = useSelector((state: RootState) => state.user.self);
  const userIsCoach = user.kind === "coach";
  const { demoSpace } = useStoreActions(
    { demoSpace: () => fetchDemoSpaceAction(null, "") },
    { noAuth: true }
  );
  const { pending, query, hasSpacesData } = useSelector(
    (state: RootState) => state.space
  );

  const spaceActions = useActions({
    fetchSpaces,
    fetchDemoSpaceAction,
    clearSpace,
    fetchSmartLists,
    clearSmartList,
  });

  const spaceQueryHandler = (value: SpaceQueryModel) => {
    dispatch(spaceQuery({ ...query, ...value }));
    return () => {
      dispatch(spaceQuery(initQuery));
    };
  };

  const smartListsData = useSelector(
    (state: RootState) => state.smartList.smartListsData
  );

  useEffect(() => {
    if (user && user.kind === "coach" && !smartListsData) {
      spaceActions.fetchSmartLists();
    }
  }, [user]);

  const onPostToListClick = useCallback(() => {
    route(
      `/spaces/smartList/${selectedSmartList.name
        .toLocaleLowerCase()
        .replace(/\s+/g, "_")}/post_to_smart_list/${
        selectedSmartList && selectedSmartList.id
      }`
    );
  }, [selectedSmartList]);

  useMemo(() => {
    spaceActions.clearSpace();

    if (selectedSmartList.object && selectedSmartList.object !== "demo_space") {
      if (selectedSmartList && selectedSmartList.object === "smart_list") {
        spaceQueryHandler({
          ...initQuery,
          smart_list_ids: [selectedSmartList.id],
          segment_ids: [],
        });
        return;
      } else if (selectedSmartList && selectedSmartList.object === "segment") {
        spaceQueryHandler({
          ...initQuery,
          segment_ids: [selectedSmartList && selectedSmartList.id],
          smart_list_ids: [],
        });
        return;
      } else {
        spaceQueryHandler({
          ...initQuery,
          segment_ids: [],
          smart_list_ids: [],
        });
        return;
      }
    }
  }, [selectedSmartList.object]);

  const updateSearch = (value: string) => {
    if (value != null && value.length > 0) {
      setSearch(value);
      // spaceQueryHandler({ ...query, athlete_name: value });
    } else {
      setSearch("");
    }
  };

  return {
    formatMessage,
    selectedSmartList,
    onPostToListClick,
    hasSpacesData,
    user,
    userIsCoach,
    updateSearch,
    searchTerm,
    pending,
    demoSpace,
  };
};
