import React, { useCallback, useEffect, useRef, useState } from "react";
import styles from "./styles.module.scss";
import arrowIcon from "../../../../public/images/select-arrow.svg";
import { cls } from "app/utils";

interface CustomSelectProps {
  placeholder?: string | number | null;
  defaultValue?: string | number | null;
  __options: any[];
  onSelectFunc?: (e: string | number) => void;
  className?: any;
  inputClassName?: any;
  hideArrow?: boolean;
  value?: string | number;
}

const CustomSelect = (props: CustomSelectProps) => {
  const {
    placeholder,
    defaultValue,
    __options,
    onSelectFunc,
    className,
    hideArrow,
    inputClassName,
    value,
  } = props;

  const dropdownRef = useRef<HTMLDivElement>();
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const handleInputClick = useCallback(() => {
    setIsMenuOpen((prev) => !prev);
  }, []);

  const handleSelectValue = useCallback((value) => {
    onSelectFunc(value);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsMenuOpen(false);
      }
    };
    if (isMenuOpen) {
      document.addEventListener("click", handleClickOutside);
    }
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isMenuOpen, dropdownRef]);

  return (
    <div className={cls(styles.custom_container, className)}>
      <div
        ref={dropdownRef}
        className={cls(styles.custom_input, inputClassName)}
        onClick={handleInputClick}
      >
        {value
          ? value
          : defaultValue
          ? defaultValue
          : placeholder
          ? placeholder
          : "Select..."}
        {!hideArrow && (
          <img className={styles.arrow} src={arrowIcon} alt="select" />
        )}
      </div>
      {isMenuOpen && (
        <div className={styles.custom_menu_container}>
          <ul>
            {__options.map((item, index) => {
              return (
                <li
                  key={index}
                  onClick={(e: any) => handleSelectValue(e.target.textContent)}
                >
                  {item}
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
};

export default CustomSelect;
