/**
 * @module Actions.SubscriptionPlan
 *
 */
import { Dispatch } from 'react';
import { action, ActionType } from 'typesafe-actions';
import { RequestError } from 'typedefs';
import { SubscriptionPlanModel } from 'app/models';
import { fetchGet } from 'app/utils/request';

const pending = () => action('@subscription-plan.fetch.pending');
const error = (error: RequestError) => action('@subscription-plan.fetch.error', error);
const success = (plans: SubscriptionPlanModel[]) => action('@subscription-plan.fetch.success', plans);

const actionHandlers = { pending, error, success };

export type SubscriptionPlanAction = ActionType<typeof actionHandlers>;

export const fetchPlans = () => {
  return (dispatch: Dispatch<SubscriptionPlanAction>) => {
    dispatch(pending());

    return fetchGet('stripe/plans', {}, { version: 1 })
      .then((data: { plans: SubscriptionPlanModel[]}) => dispatch(success(data.plans)))
      .catch(err => dispatch(error(err)));
  };
};

export const actions = {
  fetchPlans,
};
