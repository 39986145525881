import React, { memo } from "react";
import styles from "./styles.module.scss";
import { useSmartLists } from "./indexModel";
import { cls } from "app/utils";
import SmartListModel from "app/models/SmartListModel";
import SmartListsUpgradeModal from "../Layouts/SmartListsUpgradeModal";
import { Loading } from "../Wrappers";

const SmartList = () => {
  const {
    user,
    data,
    handleDataClick,
    selectedList,
    isDropdownOpen,
    handleDropdownBtn,
    isUpgradeModalOpen,
    handleUpgradeModal,
    lastElementRef,
    segmentsLoading,
  } = useSmartLists();

  return (
    <>
      <div
        className={cls(
          styles.container,
          user && user.needsOnboarding && styles.needsOnboarding
        )}
      >
        {selectedList && (
          <div className={styles.dropdown_btn} onClick={handleDropdownBtn}>
            <div className={styles.selected_value}>
              <img src={selectedList.icon} alt="icon" />
              <p>{selectedList.name}</p>
            </div>
            <i className="ico ico-chevron" />
          </div>
        )}
        <div
          className={cls(styles.list_wrapper, {
            [styles.open]: isDropdownOpen,
            [styles.close]: !isDropdownOpen,
          })}
        >
          {data &&
            data.map((dataItem: SmartListModel[], index: number) => {
              return (
                <div key={index} className={styles.menu_list_container}>
                  {dataItem &&
                    dataItem.map((item: SmartListModel) => {
                      return (
                        <div
                          key={item.id}
                          className={cls(styles.menu, {
                            [styles.active]:
                              selectedList && selectedList.name === item.name,
                          })}
                          onClick={() =>
                            handleDataClick({
                              name: item.name,
                              icon: item.icon,
                              id: item.id ? item.id : null,
                              object: item.object
                                ? item.object
                                : "athlete_space",
                            })
                          }
                        >
                          <div className={styles.menu_item}>
                            <img
                              src={item.icon}
                              alt="menuIcon"
                              className={styles.icon}
                            />
                            <p>{item.name}</p>
                          </div>
                        </div>
                      );
                    })}
                  {index !== data.length - 1 &&
                    data[index + 1] &&
                    data[index + 1].length !== 0 && (
                      <hr className={styles.separator} />
                    )}
                </div>
              );
            })}
          <div ref={lastElementRef} />
          {segmentsLoading && (
            <Loading isLoading={segmentsLoading} loadType="spinner" />
          )}
        </div>
      </div>
      <SmartListsUpgradeModal
        isOpen={isUpgradeModalOpen}
        onClose={handleUpgradeModal}
      />
    </>
  );
};

export default memo(SmartList);
