/**
 * @module Actions.Space
 *
 */
import { Dispatch } from "react";
import { ActionType, action } from "typesafe-actions";
import { fetchGet } from "app/utils/request";
import { SpaceModel, SpaceQueryModel } from "app/models";
import { SpaceState } from "app/reducers/space";

const fetchPending = () => action("@space.fetch.pending");
const fetchError = (err: string) => action("@space.fetch.error", err);

export const hasMoreSpaces = (hasMore: boolean) =>
  action("@space.fetch.hasMore", hasMore);

const fetchSuccess = (spaces: SpaceModel[]) =>
  action("@space.fetch.success", spaces);

export const hasSpacesData = (hasSpacesData: boolean) =>
  action("@space.fetch.hasSpacesData", hasSpacesData);

export const spacePage = (page: number) =>
  action("@space.fetch.spacePage", page);

export const spaceQuery = (spaceQuery: SpaceQueryModel) =>
  action("@space.fetch.spaceQuery", spaceQuery);

export const selectedListValue = (
  selectedListValue: { name: string; icon: string; id: string | null } | null
) => action("@space.fetch.selectedListValue", selectedListValue);

export const clearSpace = () => action("@space.clear");

export const clearSpaceForSearch = () => action("@space.clearSpaceForSearch");

const thisActions = {
  fetchPending,
  fetchError,
  fetchSuccess,
  hasSpacesData,
  spaceQuery,
  spacePage,
  hasMoreSpaces,
  selectedListValue,
  clearSpace,
  clearSpaceForSearch,
};

export type SpaceAction = ActionType<typeof thisActions>;

export function fetchSpaces(
  params?: { [key: string]: any },
  signal?: AbortSignal
) {
  return (dispatch: Dispatch<SpaceAction>) => {
    dispatch(fetchPending());

    return fetchGet<SpaceAction>("spaces", params || {}, {}, signal).then(
      (res: SpaceModel[] | any) => {
        dispatch(fetchSuccess(res));
        return res;
      }
    );
  };
}

export function fetchMySpaces(
  params?: { [key: string]: any },
  signal?: AbortSignal
): any {
  return (dispatch: Dispatch<SpaceAction>) => {
    dispatch(fetchPending());

    return fetchGet<SpaceAction>("spaces/my", params || {}, {}, signal).then(
      (res: SpaceModel[] | any) => {
        if (res && Array.isArray(res)) {
          dispatch(fetchSuccess(res));
        }
        return res;
      }
    );
  };
}

export function fetchOthersSpaces(
  params?: { [key: string]: any },
  signal?: AbortSignal
): any {
  return (dispatch: Dispatch<SpaceAction>, getState: () => SpaceState) => {
    dispatch(fetchPending());

    const state = getState().space;

    return fetchGet<SpaceAction>("spaces/other", params || {}, {}, signal).then(
      (res: SpaceModel[] | any) => {
        if (res && Array.isArray(res)) {
          dispatch(fetchSuccess(res));
          dispatch(spacePage(state.page + 1));
          if (res.length < (params && params.force_per_page)) {
            dispatch(hasMoreSpaces(false));
          }
        }

        return res;
      }
    );
  };
}

export function fetchMyOthersSpaces({
  paramsMySpaces,
  paramsOthersSpaces,
  signal,
}: {
  paramsMySpaces?: { [key: string]: any };
  paramsOthersSpaces?: { [key: string]: any };
  signal?: AbortSignal;
}) {
  return async (dispatch: Dispatch<SpaceAction>) => {
    dispatch(fetchPending());

    try {
      const mySpacesResult: any = await dispatch(
        fetchMySpaces({ ...paramsMySpaces }, signal)
      );
      const othersSpacesResult: any = await dispatch(
        fetchOthersSpaces({ ...paramsOthersSpaces }, signal)
      );
      const combinedResults: SpaceModel[] = [
        ...mySpacesResult,
        ...othersSpacesResult,
      ];
      return combinedResults;
    } catch (error) {
      console.error("Error fetching spaces:", error);
      throw error;
    }
  };
}

export const actions = {
  fetchSpaces,
  fetchMySpaces,
  fetchOthersSpaces,
  fetchMyOthersSpaces,
};
