/**
 * @module Components.Links
 *
 */
import React from 'react';
import { FormattedMessage } from 'react-intl';

/**
 * @class Contact
 *
 */
export default function Contact() {
  return (
    <a href="https://coachnow.io/contact-us">
      <FormattedMessage
        id="app.links.contact-us"
        defaultMessage="Contact Us"
        description="Contact page link text"
      />
    </a>
  );
}

