/** @module components/uploads/images */
import React, { useState } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { cls, isNone } from 'app/utils';
import { useLoc } from 'app/utils/hooks';
import { dataUrlToBlob, PreviewFile } from 'app/utils/file';
import ModalWrapper from 'app/components/modals/ModalWrapper';
import { UploadFile } from 'app/components/Button';
import ImageCropper from 'app/components/ImageCropper';
import styles from './styles.module.scss';

type PropsDef = {
  file?: PreviewFile,
  disabled?: boolean,
  onChange: ((f: PreviewFile) => void),
};

const LOCALES = defineMessages({
  dialog_title: { id: "avatar.crop-dialog.title", defaultMessage: "Adjust Your Picture" },
  dialog_crop_button: { id: "avatar.crop-dialog.button.set-picture", defaultMessage: "Set Picture" },
  dialog_cancel: { id: "app.button.cancel", defaultMessage: "Cancel" }
});

const PROFILE_IMAGE_SIZE = {
  width: 256,
  height: 256
};

type State = {
  showDialog: boolean,
  file: PreviewFile | null,
  croppedFile: string | null,
};

const initState: State = {
  showDialog: false,
  file: null,
  croppedFile: null,
};

/**
 * @class UserAvatar
 *
 */
export default function UserAvatar(props: PropsDef) {
  const [ state, setState ] = useState(() => ({ ...initState, file: props.file }));
  const { formatMessage } = useLoc();

  const onFileChange = (files: PreviewFile[]) => {
    const file = files && files.length ? files[0] : null;

    setState({ ...state, file, showDialog: !isNone(file) });

    if (props.onChange) {
      props.onChange(file);
    }
  };

  const handleCrop = (data: string) => {
    setState({ ...state, croppedFile: data });
  };

  const removeCrop = () => {
    const file = state.file;
    delete file.url;

    setState({ ...state, file, croppedFile: null, showDialog: false });

    if (props.onChange) {
      props.onChange(null);
    }
  };

  const onConfirmCrop = () => {
    const file = state.file;
    file.url = state.croppedFile;
    file.blob = dataUrlToBlob(file.url, file.type);

    setState({ ...state, file, showDialog: false });

    if (props.onChange) {
      props.onChange(file);
    }
  };

  const shouldRenderCropper = () => {
    if (state.file != null) {
      return (
        <ImageCropper
          width={PROFILE_IMAGE_SIZE.width}
          height={PROFILE_IMAGE_SIZE.height}
          src={state.file.originalUrl}
          onCrop={(data: string) => handleCrop(data)}
        />
      );
    }
    return "";
  };

  return (
    <div className={styles.main}>
      <UploadFile file={state.file} disabled={props.disabled} onChange={(files: PreviewFile[]) => onFileChange(files)} />

      <ModalWrapper
        className={cls('media small', styles.modalWrapper)}
        headerClassName={styles.modalHeader}
        headerCloseClassName={styles.headerCloseBtn}
        size="auto"
        title={formatMessage(LOCALES.dialog_title)}
        isOpen={state.showDialog}
        onRequestClose={() => setState({...state, showDialog: false})}
        footer={(<CropperFooter onConfirm={() => onConfirmCrop()} onCancel={() => removeCrop()} />)}
      >
        <div className={styles.mainCropper}>
          <div className={styles.cropPhoto}>
            {shouldRenderCropper()}
          </div>
        </div>
      </ModalWrapper>
    </div>
  );
}


type CropperPropTypes = {
  onConfirm: (() => void),
  onCancel: (() => void),
};

function CropperFooter(props: CropperPropTypes) {
  return (
    <div className="button-bar">
      <button className="btn btn-primary right" onClick={() => props.onConfirm()}>
        <FormattedMessage {...LOCALES.dialog_crop_button} />
      </button>
      <button className="btn btn-outline-secondary left" onClick={() => props.onCancel()}>
        <FormattedMessage {...LOCALES.dialog_cancel} />
      </button>
    </div>
  );
}

