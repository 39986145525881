/**
 * @module Utils.File
 *
 */
import { all } from 'rsvp';
import { FormEvent } from 'react';
import readAsDataUrl from './readAsDataUrl';

/**
 * Get file data from file input event
 *
 * @method readFilesFromInput
 */
export default function readFilesFromInput(evt: FormEvent<HTMLInputElement>) {
  const fileListTarget = (evt.currentTarget.files || []) as File[];
  const fileList = Array.from(fileListTarget);
  return all(
    fileList.map((file: File & { url?: string, originalUrl?: string }) =>
      readAsDataUrl(file).then(fd => {
        file.url = fd;
        file.originalUrl = fd;
        return file;
      })
    )
  );
}
