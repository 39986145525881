import React from "react";
import styles from "./styles.module.scss";
import { TagSelect } from "app/components/inputs/SelectBox";

import { Spinner } from "app/components/Loading";
import playIcon from "app/images/play_btn.svg";
import duration from "app/components/inputs/SelectBox/helpers/DurationConverter";
import useUploadsPicker from "./indexModel";
import useDatafileIcons from "app/components/inputs/SelectBox/helpers/DatafileIcons";

interface dataObjectProps {
  object: string;
  id: string;
  _id: string;
  type: string;
  userId: string;
  capturedAt: Date;
  createdAt: Date;
  postsCount: number;
  processed: boolean;
  duration: number;
  mediumUrl: string;
  thumbnails: {
    default: string;
  };
  tags: [];
  url: string;
  filename: string;
  extension: string;
}

const UploadsPicker = () => {
  const { pending, libraryData, handleSelectMedia } = useUploadsPicker();
  const { dataFileIconHandler } = useDatafileIcons();

  return (
    <div className={styles.container}>
      <div className={styles.search_container}>
        <TagSelect />
        {/* <CustomDropDown dropDownData={dropDownData} label="All&nbsp;Types" /> */}
      </div>
      <div className={styles.media_content}>
        {pending && (
          <div className={styles.loading_spinner}>
            <Spinner />
          </div>
        )}
        {Array.isArray(libraryData) &&
          libraryData.length > 0 &&
          libraryData.map((item: dataObjectProps) => {
            return (
              <div
                key={item.id}
                onClick={() => handleSelectMedia({ ...item })}
                className={styles.object_container}
              >
                <div className={styles.object_thumbnail_wrapper}>
                  {item.mediumUrl && (
                    <img src={item.mediumUrl} alt="thumbnail" />
                  )}
                  {item.type === "datafile" && (
                    <img
                      src={dataFileIconHandler(item.extension)}
                      alt="datafile"
                    />
                  )}
                  {item.type === "audio" && (
                    <img
                      src={dataFileIconHandler(item.object)}
                      alt="datafile"
                    />
                  )}
                  {item.thumbnails && (
                    <img src={item.thumbnails.default} alt="thumbnail" />
                  )}

                  <div className={styles.object_item}>
                    {item.object === "video" &&
                      (item.duration && duration(item.duration) !== "0" ? (
                        <div className={styles.duraton_play_wrap}>
                          <img src={playIcon} alt="play" />
                          <p>{duration(item.duration)}</p>
                        </div>
                      ) : null)}
                    {item.tags && (
                      <div className={styles.tags_container}>
                        {item.tags.map((tag: string, index: number) => {
                          return (
                            <div key={index} className={styles.tag}>
                              #{tag}
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default UploadsPicker;
