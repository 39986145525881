/**
 * @module Utils
 *
 */
import { UserModel } from 'app/models';
import angular from 'angular';

interface IdentityFactory {
  request: () => Promise<UserModel>;
  setUser: (user: UserModel) => UserModel;
  $refresh: () => Promise<UserModel>;
}

export interface AngularGlobalState {
  angular: typeof angular | null;
  app: angular.auto.IInjectorService | null;
  root: angular.IRootScopeService | null;
  identity: IdentityFactory | null;
  qProvider: angular.IQService | null;
}

const initState: AngularGlobalState = {
  angular: null,
  app: null,
  root: null,
  identity: null,
  qProvider: null
};

if (window.__gsa__ == null) {
  window.__gsa__ = initState;
}

export function getState(): AngularGlobalState {
  return window.__gsa__;
}

export function setState(state: AngularGlobalState): void {
  window.__gsa__ = state;
}

/**
 * access angular state with [ state, setState ]
 *
 * @method ngState
 */
export function ngState(): [ AngularGlobalState, typeof setState ] {
  return [ getState(), setState ];
}


/**
 * updates user for angular app
 *
 * @method updateIdentity
 */
export function updateIdentity(): void {
  const state = getState();
  if (state.identity && state.identity.$refresh) {
    state.identity.$refresh();
  }
}
