/**
 * @module Store
 *
 */
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { rootReducer } from './reducers';
import settings from 'config/environment';

/**
 * NOTE: DO NOT REMOVE
 *
 * redux-connect-standalone is a package that helps
 * inject redux into containers that use ngreact
 */

let composeEnhancers = (input: any): any => {
  return input;
};

if (settings.DEBUG) {
  // adds redux dev tools to chrome console
  composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
}

// configure middlewares
const middlewares = [ thunk ];

// compose enhancers
const enhancer = composeEnhancers(applyMiddleware(...middlewares));

// rehydrate state on app start
const initialState = {};

export const store = createStore(rootReducer, initialState, enhancer);
