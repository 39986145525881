import React from "react";
import styles from "./styles.module.scss";

import MobileAppDesign from "../../../../public/images/mobile-app-design-nobg.svg";
import AndroidAppStoreBadge from "../../../../public/images/android-app-store-badge.svg";
import IphoneAppStoreBadge from "../../../../public/images/iphone-app-store-badge.svg";
import { GettingStartedModalProps } from "../index.types";
import GettingStartedModal from "app/components/Layouts/GettingStartedModal";

const VideoAnalysisModal = ({
  onClose,
  isOpen,
  url,
}: GettingStartedModalProps) => {
  return (
    <GettingStartedModal isOpen={isOpen} onClose={onClose}>
      <div className={styles.container}>
        <img src={MobileAppDesign} alt="application" />
        <h1 className={styles.title}>
          Get started with <br /> Video Analysis Suite
        </h1>
        <p>
          Access the Video Analysis Tools directly in an athlete Space or Group
          on <b>iOS</b> or <b>Android</b>. When creating a post, you can shoot a
          new video, re-use a video from your Personal CoachNow library, or
          upload one from your camera roll.
          <br />
          <a
            href={typeof url === "string" ? url : url.learnMore}
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn more about special featuress
          </a>
        </p>
        <div className={styles.market_icons}>
          <a
            href={typeof url === "string" ? url : url.playStoreLink}
            target="_blank"
            rel="noreferrer noopener"
          >
            <img src={AndroidAppStoreBadge} alt="playstore" />
          </a>
          <a
            href={typeof url === "string" ? url : url.appStoreLink}
            target="_blank"
            rel="noreferrer noopener"
          >
            <img src={IphoneAppStoreBadge} alt="appstore" />
          </a>
        </div>
      </div>
    </GettingStartedModal>
  );
};

export default VideoAnalysisModal;
