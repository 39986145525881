/**
 * @module Components.Layouts
 *
 */
import React from 'react';
import { cls } from 'app/utils';
import Styles from './styles.module.scss';

type PropsDef = React.PropsWithChildren<{
  className?: string;
  onClick?: () => void;
  containerRef?: any;
}>;

/**
 * @class GridItem
 *
 */
export default function GridItem(props: PropsDef) {
  const clickhandler = () => {
    if (props.onClick != null) {
      props.onClick();
    }
  };

  return (
    <div className={cls(Styles.gridItem, props.className)} onClick={clickhandler} ref={props.containerRef}>
      {props.children}
    </div>
  );
}
