/**
 * @module TabView
 *
 */
import React from 'react';
import { cls } from 'app/utils';
import Styles from './styles.module.scss';

type PropsDef = React.PropsWithChildren<{
  className?: string;
  tabTitle: string;
  headerTitle?: string;
  headerSubTitle?: string;
  hideActions?: boolean;
  actions?: JSX.Element;
  icon?: JSX.Element;
}>;

/**
 * @class TabHeader
 *
 */
export default function TabHeader({ className, children }: PropsDef) {
  return (
    <div className={cls(Styles.tabContent, className)}>
      {children}
    </div>
  );
}
