/**
 * @module Utils.Hooks
 *
 */
import { useEffect, useDebugValue } from 'react';
import { ActionCreator } from 'redux';
import { useSelector } from 'react-redux';
import useActions from './useActions';
import { RootState } from 'app/reducers';
import { isAuthenticated } from 'app/utils/auth';
import { isTesting } from 'app/utils/debug';

export type ActionObject<A extends any> = {
  [key: string]: ActionCreator<A>;
};

export type Options = {
  wait?: number;
  noAuth?: boolean;
};

export default function useStoreActions<A extends any>(map: ActionObject<A>, options?: Options) {
  options = options || {};
  const noAuth = options.noAuth === true ? true : false;

  const stateKeys = Object.keys(map);
  const actions = useActions(map);

  useDebugValue(`useStoreActions: [ ${stateKeys.join(', ')} ]`);

  const state = useSelector((state: RootState) => {
    const stateTypes = stateKeys.map((key: keyof RootState) => ({ [key]: state[key] }));
    return Object.assign({}, ...stateTypes);
  });

  const dispatchAction = (name: string) => {
    if (actions && typeof actions[name] === 'function') {
      if (isTesting() || noAuth === true || isAuthenticated()) {
        // prevent dispatching more calls while the state is pending
        if (state && state[name] && !state[name].pending) {
          actions[name].call(actions[name]);
        }
      }
    }
  };

  useEffect(() => {
    stateKeys.forEach((key: string) => dispatchAction(key));
  }, stateKeys);

  return state;
}
