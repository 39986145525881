/**
 * @module Actions.Tag
 *
 */
import { Dispatch } from "react";
import { ActionType, action } from "typesafe-actions";
import { fetchGet, request } from "app/utils/request";
import { TagModel } from "app/models";
import config from "config/environment";

export const asyncTagGet = (tag_id?: string): Promise<TagModel> => {
  return fetchGet<TagModel>(
    tag_id ? `account/tags/${tag_id}` : "tags",
    {},
    { version: 1 }
  );
};

const pending = () => action("@tag.fetch.pending");
const error = (err: string) => action("@tag.fetch.error", err);
const success = (tags: TagModel[]) => action("@tag.fetch.success", tags);
export const clearTags = () => action("@tag.clear");

const thisActions = { pending, error, success, clearTags };

export type TagAction = ActionType<typeof thisActions>;

export function fetchTags(tag_id?: string) {
  return (dispatch: Dispatch<TagAction>) => {
    dispatch(pending());

    return asyncTagGet(tag_id)
      .then((tags: TagModel[]) => dispatch(success(tags)))
      .catch((err) => dispatch(error(err)));
  };
}

export function createTag(tagName: string) {
  return (dispatch: Dispatch<TagAction>) => {
    dispatch(pending());

    const url = config.API_SERVER + `/api/v1/account/tags`;
    const promise = request(
      url,
      "POST",
      { tag: { name: tagName } },
      { version: 1 }
    );
    promise.then((tags: TagModel[]) => dispatch(success(tags)));
    promise.catch((err) => dispatch(error(err.errors[0])));

    return promise;
  };
}

export function updateTag(
  tag: TagModel,
  name: string,
  updateAllUsages: boolean
) {
  return (dispatch: Dispatch<TagAction>) => {
    dispatch(pending());

    const url = config.API_SERVER + `/api/v1/account/tags/${tag.id}`;
    const promise = request(
      url,
      "PUT",
      { tag: { ...tag, name, updateAllUsages } },
      { version: 1 }
    );
    promise.then((tags: TagModel[]) => dispatch(success(tags)));
    promise.catch((err) => dispatch(error(err.errors[0])));

    return promise;
  };
}

export function deleteTag(tag: TagModel, deleteAllUsages: boolean) {
  return (dispatch: Dispatch<TagAction>) => {
    dispatch(pending());

    const url =
      config.API_SERVER +
      `/api/v1/account/tags/${tag.id}?delete_all_usages=${deleteAllUsages}`;
    const promise = request(url, "DELETE", {}, { version: 1 });
    promise.then((tags: TagModel[]) => dispatch(success(tags)));
    promise.catch((err) => dispatch(error(err.errors[0])));

    return promise;
  };
}

export const actions = { fetchTags };
