/* eslint-disable */
import GettingStartedProgress from "app/components/GettingStarted/GettingStartedProgress";
import PagePanel from "app/components/PagePanel";
import React, { useCallback, useState } from "react";
import styles from "./styles.module.scss";
import { useSelector } from "react-redux";
import { RootState } from "typedefs";
import Button from "app/components/Button/ButtonVariant";
import { TagSelect } from "app/components/inputs/SelectBox";
import { useActions } from "app/utils/hooks";
import { editMedia, fetchMediaById } from "../../../actions/media-library-data";
import CreateMedia from "app/components/PostingForm/CreateMedia";
import AttachmentPreview from "app/components/PostingForm/AttachmentPreview";
import { MediaTypes } from "../ResourceCard/index.types";
import { route } from "app/utils/router";

const CreateNewResource = () => {
  const mediaActions = useActions({ fetchMediaById, editMedia });
  const user = useSelector((state: RootState) => state.user.data);
  const uploadedMedia = useSelector(
    (state: RootState) => state.mediaLibraryData.attachment,
  );

  const canAddMedia =
    user && user.planPermissions.includes("upload_to_library");

  const [tags, setTags] = useState<string[]>([]);
  // const [initialUploadedMedia, setInitialUploadedMedia] =
  //   useState(uploadedMedia);

  const onTagsChange = useCallback(
    (tags: []) => {
      if (tags) {
        const tagNames = tags.map((tag: { name: string }) => tag.name);
        setTags(tagNames);
      }
      return () => {
        setTags([]);
      };
    },
    [tags],
  );

  let parsedRes: MediaTypes;

  parsedRes =
    typeof uploadedMedia === "string"
      ? JSON.parse(uploadedMedia)
      : uploadedMedia;

  const addUploadedMedia = () => {
    if (parsedRes && parsedRes.id) {
      mediaActions
        .fetchMediaById(parsedRes.id)
        .then((res) => {
          mediaActions.editMedia(res.id, {
            resource: {
              ...res,
              tags,
              // tmp = temporary
              tmp: false,
            },
          });
        })
        // .finally(() => (window.location.pathname = "/resources"));
        .finally(() => route("/resources"));
    }
  };

  return (
    <>
      <GettingStartedProgress />
      <div className={styles.container}>
        <PagePanel title="Upload Media" titleStyles={styles.title}>
          <div className={styles.content_wrapper}>
            {uploadedMedia && <AttachmentPreview attachment={uploadedMedia} />}
            <CreateMedia
              hasPermission={canAddMedia}
              hideUploadBtn={uploadedMedia}
              canSelectMedia={false}
            />
            <span className={styles.tags_container}>
              <label>Tags</label>
              <TagSelect
                className={styles.select_box}
                placeholder={"Add some tags."}
                isClearable
                isCreatable={true}
                isMulti={true}
                onChange={onTagsChange}
              />
            </span>
            <span className={styles.button_container}>
              <Button
                title="Add"
                buttonType="primary"
                buttonStyles={styles.upload_btn}
                onClickFunc={addUploadedMedia}
                disabled={!uploadedMedia}
              />
              <a href="/resources">
                <Button
                  title="Cancel"
                  buttonType="secondary-outline"
                  buttonStyles={styles.upload_btn}
                />
              </a>
            </span>
          </div>
        </PagePanel>
      </div>
    </>
  );
};

export default CreateNewResource;
