import React from "react";
import LikesViewsModal from "../LikesViewsModal";

interface Props {
  likes: number;
  postId: string;
}

const LikesText = ({ likes, postId }: Props) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const modalHandler = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <div
        onClick={modalHandler}
        className={`post-count ${likes >= 1 && "active"}`}
      >
        {likes === 1 ? "Like" : "Likes"}
      </div>
      {isOpen && (
        <LikesViewsModal
          isOpen={isOpen}
          onCloseFunc={modalHandler}
          likesCounter={likes}
          postId={postId}
        />
      )}
    </>
  );
};

export default LikesText;
