/**
 * @module Actions.PaymentInfo
 *
 */
import { Dispatch } from 'react';
import { defineMessages, MessageDescriptor } from 'react-intl';
import { action, ActionType } from 'typesafe-actions';
import { PaymentInfoModel } from 'app/models';
import { fetchGet, fetchPut } from 'app/utils/request';

const LOCALES = defineMessages({
  "update_payment_failed.card_invalid": { id: 'error.declined.api', defaultMessage: 'The was an error with your card. Please try another card.' },
  unknown_error: { id: 'error.unknown.api', defaultMessage: 'An unknown error occurred. Please try again.' },
});

const pending = () => action('@payment-info.request.pending');
const error = (errorObj: any) => {
  let errResult: MessageDescriptor = LOCALES.unknown_error;
  if (errorObj && errorObj.errors && errorObj.errors[0]) {
    const firstErr: keyof typeof LOCALES = errorObj.errors[0];
    if (LOCALES[firstErr]) {
      errResult = LOCALES[firstErr];
    }
  }
  return action('@payment-info.request.error', errResult);
};
const successFetch = (paymentInfo: PaymentInfoModel) => action('@payment-info.fetch.success', paymentInfo);
const successPut = (paymentInfo: PaymentInfoModel) => action('@payment-info.put.success', paymentInfo);

const actionHandlers = { pending, error, successFetch, successPut };

export type PaymentInfoAction = ActionType<typeof actionHandlers>;

export const fetchPaymentInfo = () => {
  return (dispatch: Dispatch<PaymentInfoAction>) => {
    return fetchGet('stripe/payment_info', {}, { version: 1} )
      .then(data => dispatch(successFetch(data)))
      .catch(err => dispatch(error(err)));
  };
};

export const updatePaymentInfo = (token: string) => {
  return (dispatch: Dispatch<PaymentInfoAction>) => {
    dispatch(pending());

    return fetchPut('stripe/payment_info', { token }, { version: 1 })
      .then(data => dispatch(successPut(data)))
      .catch(err => dispatch(error(err)));
  };
};

export const actions = {
  fetchPaymentInfo,
  updatePaymentInfo
};
