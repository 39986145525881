/**
 * @module Components.StripeForm
 *
 */
import React from 'react';
import StripeFormWrapper, { PropsDef } from './StripeFormWrapper';

export default function StripeForm(props: PropsDef) {
  return (
    <StripeFormWrapper {...props} />
  );
}

