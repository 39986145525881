/**
 * @module Routes.Unsubscribe
 *
 */
import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { defineMessages, MessageDescriptor } from 'react-intl';
import { fetchPost } from 'app/utils/request';
import { Loc } from 'app/components/helpers';
import { Public } from 'app/components/Wrappers';
import Styles from './styles.module.scss';

type PropsDef = RouteComponentProps<{
  invitationId: string;
}>;

type State = {
  error: MessageDescriptor | null;
  pending: boolean;
};

const initialState: State = {
  error: null,
  pending: true,
};

/**
 * @class Invitation
 *
 */
export default function User(props: PropsDef) {
  const invitationId = props.match.params.invitationId;
  const [ state, setState ] = useState(initialState);

  useEffect(() => {
    if (invitationId != null) {
      fetchPost(`invitations/${invitationId}/mute`, {}, { version: 1.2, noAuth: true })
        .then(() => setState({ ...state, pending: false, error: null }))
        .catch(() => setState({ ...state, pending: false, error: locales.error }));
    } else {
      setState({ ...state, pending: false, error: locales.invalid });
    }
  }, [ invitationId ]);

  let message = (
    <h3 className="text-center">
      <Loc value={locales.pending} />
    </h3>
  );

  if (state.error) {
    message = (
      <h3 className="text-danger text-center">
        <Loc value={state.error} />
      </h3>
    );
  } else if (!state.pending) {
    message = (
      <h3 className="text-success text-center">
        <Loc value={locales.success} />
      </h3>
    );
  }

  return (
    <Public isLoading={false}>
      <div className={Styles.unsubscribeMain}>
        <div className="panel">
          {message}
        </div>
      </div>
    </Public>
  );
}

const locales = defineMessages({
  pending: { id: 'unsubscribe.invitation.pending', defaultMessage: 'Please, wait...' },
  success: { id: 'unsubscribe.invitation.success', defaultMessage: 'You have unsubscribed from email notifications for this invitation' },
  error: { id: 'unsubscribe.invitation.error', defaultMessage: 'Due to server error we were not able to save your changes' },
  invalid: { id: 'unsubscribe.invitation.invalid', defaultMessage: 'The invitation is invalid or no longer exists.' }
});
