/**
 * @module Components.Links
 *
 */
import React from 'react';
import { FormattedMessage } from 'react-intl';

/**
 * @class PrivacyPolicy
 *
 */
export default function PrivacyPolicy() {
  return (
    <a href="https://coachnow.io/privacy-policy">
      <FormattedMessage
        id="app.links.privacy-policy"
        defaultMessage="Privacy Policy"
        description="Privacy policy link text"
      />
    </a>
  );
}

