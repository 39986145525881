/**
 * @module Services
 *
 */
import { AnyObject } from 'typedefs';
import initializeAnalyitics from 'app/library/analytics-sdk';
import { UserModel } from 'app/models';
import { isAuthenticated, loadAuthUser } from 'app/utils/auth';
import { featureIsEnabled } from 'app/utils/features';
import { Service, createService } from 'app/utils/service';
import config from 'config/environment';

const Environment = config.ENVIRONMENT === 'production' ? 'production' : 'staging';

/**
 * Allows for analytics to be disabled with
 * FEATURES.analyticsTool=false in config/environment or
 * analyticsTool=false in .env
 *
 * @method runIfNotDisabled
 * @param {function} callback - called when analytics can be run
 * @param {mixed} disabledValue - value to return if analytics is not running
 * @return {mixed}
 */
const runIfNotDisabled = (callback: () => any, disabledValue?: any) => {
  if (featureIsEnabled('analyticsTool')) {
    return callback();
  }

  if (disabledValue != null && typeof disabledValue === 'function') {
    return disabledValue();
  }
  return disabledValue;
};

const IDENTIFY_DELAY = 500; // 1/2 second in milliseconds

type AnalyticsObj = {[key: string]: any};
type VoidCallback = () => void;

/**
 * @class AnalyticsService
 *
 */
class AnalyticsService extends Service {
  static _name = "AnalyticsService";
  public isReady: boolean = false;
  public isRouting: boolean = false;

  private _analytics: AnalyticsObj | null = null;
  private _preAnalytics: AnalyticsObj | null = null;
  private authUserId: string | null = null;
  private lastIdentify: number = 0;
  private currentPage: string | null = null;
  private readyListeners: VoidCallback[] = [];
  private _userTraits: AnalyticsObj | null = null;


  init() {
    runIfNotDisabled(() => {
      // create analytics instance
      this.isReady = false;
      this._preAnalytics = initializeAnalyitics((analytics: AnalyticsObj) => {
        this._analytics = analytics;
        this.isReady = true;
        this._dispatchListeners();
      });
    });
  }

  get analytics() {
    return runIfNotDisabled(() => {
      if (!this._analytics) {
        return this._preAnalytics;
      }
      return this._analytics;
    }, this);
  }

  _dispatchListeners() {
    if (this.readyListeners.length > 0) {
      const listener = (this.readyListeners.shift() as VoidCallback);
      listener.call(null);
      this._dispatchListeners();
    }
  }

  routeChangeStarted() {
    if (this.isReady) {
      this.isRouting = true;
    }
  }

  routeChangeDone() {
    if (this.isRouting) {
      this.isRouting = false;
      this._dispatchListeners();
    }
  }

  onReady(callback: VoidCallback) {
    if (!this.isReady || this.isRouting) {
      this.readyListeners.push(callback);
    } else {
      callback.call(null);
    }
  }

  runIfUserTrackable(callback: () => any, disabledValue?: any) {
    const forceUpdate = true;

    loadAuthUser(forceUpdate).then((user: UserModel) => {
      if (user.traits && user.traits.Trackable) {
        return callback();
      } else {
        if (disabledValue != null && typeof disabledValue === 'function') {
          return disabledValue();
        }
        return disabledValue;
      }
    });
  }

  identify(userId: string, traits?: AnyObject, options?: AnyObject, callback?: () => any): void {
    callback = callback != null ? callback : function() {};

    runIfNotDisabled(() => {
      this.onReady(() => {
        traits = Object.assign({}, { Environment, Authenticated: isAuthenticated() }, traits);
        this._userTraits = traits;
        this.authUserId = userId;
        this.lastIdentify = Date.now();
        this.analytics.identify(userId, traits, options, callback);
      });
    }, () => (callback as VoidCallback)());
  }

  track(key: string, props?: {[key: string]: any}, callback?: VoidCallback): void {
    callback = callback != null ? callback : function() {};

    runIfNotDisabled(() => {
      this.onReady(() => {
        const traits = this._userTraits != null ? this._userTraits : { Environment };
        props = Object.assign({}, traits, props);

        this.runIfUserTrackable(() => {
          this.analytics.track(key, props, null, () => {
            if (callback != null) {
              callback();
            }
          });
        }, () => (callback as VoidCallback)());
      });
    }, () => (callback as VoidCallback)());
  }

  page(): void {
    runIfNotDisabled(() => {
      this.onReady(() => {
        if (this.authUserId != null && this.authUserId.length) {
          if ((Date.now() - this.lastIdentify) > IDENTIFY_DELAY) {
            this.identify(this.authUserId);
          }
        }

        // prevent double calling page
        const newPage = window.location.pathname;
        if (this.currentPage !== newPage) {
          this.currentPage = newPage;
          this.analytics.page({ Environment });
        }
      });
    });
  }

  alias(userId: string): void {
    runIfNotDisabled(() => {
      this.analytics.alias(userId);
    });
  }

  user(): any {
    return runIfNotDisabled(() => {
      if (this.analytics && this.analytics.user) {
        return this.analytics.user();
      }
      return {
        anonymousId: (() => '')
      };
    });
  }

  getAnonymousId(): string | void {
    return runIfNotDisabled(
      () => {
        return this.user().anonymousId();
      },
      // always pass undefined here this
      // prevents anonymous_id from sending
      // empty data to the server in development
      undefined
    );
  }

  reset(): void {
    runIfNotDisabled(() => {
      this.analytics.reset();
    });
  }
}

export default createService<AnalyticsService>(AnalyticsService);
