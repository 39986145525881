/**
 * @module Components.StripeForm
 *
 */
import React, { useEffect, useState } from 'react';
import { useLoc } from 'app/utils/hooks';
import { StripeProvider, Elements } from 'react-stripe-elements';
import { StripeService } from 'app/services';
import StripeFormContainer, { PropsDef as StripePropsDef } from './StripeFormContainer';

const Stripe = (StripeService as any);

const STRIPE_FONTS = [
  { cssSrc: "https://fonts.googleapis.com/css?family=Montserrat:400,400i,700,700i&subset=cyrillic-ext,latin-ext" }
];

export type PropsDef = StripePropsDef;

export default function StripeFormWrapper(props: PropsDef) {
  const intl = useLoc();
  const [ stripe, setStripe ] = useState<stripe.Stripe>(null);

  useEffect(() => (Stripe.setLocale(intl.locale), undefined), [ intl.locale ]);
  useEffect(() => {
    Stripe.stripePromise().then((st: stripe.Stripe) =>
      setStripe(st)
    );
  }, [ Stripe.stripe ]);

  return (
    <StripeProvider stripe={stripe}>
      <Elements fonts={STRIPE_FONTS}>
        <StripeFormContainer {...props} />
      </Elements>
    </StripeProvider>
  );
}

