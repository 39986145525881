/**
 * @module Reducers.Space
 *
 */
import { RequestError } from "typedefs";
import { SpaceAction } from "app/actions/space";
import { SpaceModel, SpaceQueryModel } from "app/models";

export type SpaceState = {
  data: SpaceModel[] | null;
  spaces: SpaceModel[] | null;
  page: number;
  query: SpaceQueryModel;
  hasSpacesData: boolean;
  hasMore: boolean;
  selectedListValue: { name: string; icon: string; id: string | null } | null;
  error: RequestError | null;
  pending: boolean;
  sync?: number;
};

const initPage = 1;

export const initQuery = {
  options: {
    include: ["team_info"],
    exclude: ["permissions", "state"],
  },
};

const initialState: SpaceState = {
  data: null,
  spaces: null,
  page: initPage,
  query: { ...initQuery },
  hasSpacesData: false,
  hasMore: true,
  selectedListValue: { name: "All spaces", icon: "", id: null },
  error: null,
  pending: false,
};

export default function spaceReducer(
  state = initialState,
  action: SpaceAction
): SpaceState {
  switch (action.type) {
    case "@space.fetch.pending":
      return { ...state, error: null, pending: true };
    case "@space.fetch.success":
      return { ...state, data: action.payload, pending: false };
    case "@space.fetch.hasSpacesData":
      return { ...state, hasSpacesData: action.payload };
    case "@space.fetch.spacePage":
      return { ...state, page: action.payload };
    case "@space.fetch.spaceQuery":
      return { ...state, query: action.payload };
    case "@space.fetch.hasMore":
      return { ...state, hasMore: action.payload };
    case "@space.fetch.error":
      return { ...state, error: action.payload, pending: false };
    case "@space.clear":
      return {
        ...state,
        data: null,
        spaces: null,
        page: initPage,
        query: { ...initQuery },
        hasMore: true,
        selectedListValue: { id: "1", name: "All spaces", icon: "" },
        error: null,
        pending: false,
      };
    case "@space.clearSpaceForSearch":
      return {
        ...state,
        data: null,
        spaces: null,
        hasSpacesData: false,
        page: initPage,
        hasMore: true,
        error: null,
        pending: false,
      };

    default:
      return state;
  }
}
