/**
 * @module Actions.Space
 *
 */
import { Dispatch } from "react";
import { ActionType, action } from "typesafe-actions";
import { fetchGet } from "app/utils/request";

const fetchPending = () => action("@spaceApplication.fetch.pending");

const fetchSpaceApplicationError = (err: string) =>
  action("@spaceApplication.fetch.spaceApplicationError", err);

const fetchSpaceApplicationSuccess = (spaceApplication: any[]) =>
  action("@spaceApplication.fetch.spaceApplicationSuccess", spaceApplication);

export const clearAppliedTemplates = () => action("@spaceApplication.clear");

const thisActions = {
  fetchSpaceApplicationError,
  fetchSpaceApplicationSuccess,
  fetchPending,
  clearAppliedTemplates,
};

export type SpaceApplicationAction = ActionType<typeof thisActions>;

export function fetchAppliedTemplates(params?: { [key: string]: any }) {
  return (dispatch: Dispatch<any>) => {
    dispatch(fetchPending());

    return fetchGet<any>("space_template_applications", params || {})
      .then((model: any) => dispatch(fetchSpaceApplicationSuccess(model)))
      .catch((err) => dispatch(fetchSpaceApplicationError(err)));
  };
}

export const actions = {
  fetchAppliedTemplates,
};
