/**
 * @module Components.Helpers
 *
 */
import React, { useState, useEffect, PropsWithChildren } from 'react';
import ReactDOM from 'react-dom';
import { cls } from 'app/utils';

type PropsDef = {
  className?: string;
  info?: string | null;
  success?: string | null;
  error?: string | null;
  timeout?: number
  onDone: () => void;
};

type State = {
  message: string | null;
};

const initState: State = {
  message: null
};

export default function Toast(props: PropsDef) {
  const [ state, setState ] = useState(initState);
  let message: string | null = null;
  let cname: string | null = null;

  if (props.info && props.info.length) {
    message = props.info;
    cname = 'toast-info';
  }

  if (props.success && props.success.length) {
    message = props.success;
    cname = 'toast-success';
  }

  if (props.error && props.error.length) {
    message = props.error;
    cname = 'toast-danger';
  }

  useEffect(() => (setState({ ...state, message }), undefined), [ props.info, props.success, props.error ]);

  const closeToast = (() => props.onDone());

  useEffect(() => {
    if (state.message != null && state.message.length) {
      setTimeout(() => closeToast(), props.timeout || 5000);
    }
  }, [ state.message ]);

  return (
    <Portal>
      <div className={cls("toast-box", state.message && state.message.length ? 'show' : 'hide')}>
        <div className={cls('toast-container', props.className)}>
          <div className={cls('toast', cname)} onClick={() => closeToast()}>
            <div className="toast-alert">{state.message}</div>
          </div>
        </div>
      </div>
    </Portal>
  );
}

function Portal(props: PropsWithChildren<{}>) {
  const element = document.getElementById('toast-portal') || document.body;
  return ReactDOM.createPortal(
    props.children,
    element
  );
}
