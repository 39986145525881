import React from "react";
import { ResourcesListModel } from "./indexModel";
import styles from "./styles.module.scss";
import { Spinner } from "app/components/Loading";
import { MediaTypes } from "../ResourceCard/index.types";
import ResourceCard from "../ResourceCard";
import NoResources from "../NoResources";

interface Props {
  canSelectMedia?: boolean;
}

const ResourcesList = ({ canSelectMedia }: Props) => {
  const { containerRef, libraryData, isLoading } = ResourcesListModel();

  return (
    <div className={styles.container}>
      {Array.isArray(libraryData) && libraryData.length > 0 && (
        <div className={styles.items_container}>
          {Array.isArray(libraryData) &&
            libraryData.length > 0 &&
            libraryData.map((item: MediaTypes) => {
              return !canSelectMedia ? (
                <a
                  href={item.id ? `/resources/${item.id}` : "#"}
                  key={item && item.id}
                  className={styles.media_container}
                >
                  <ResourceCard data={item} />
                </a>
              ) : (
                <ResourceCard
                  key={item.id}
                  data={item}
                  thumbnailClassname={styles.thumbnailClassname}
                  hasGradientOverlay={false}
                  showPlayIcon={false}
                  showTags={false}
                />
              );
            })}
        </div>
      )}
      {!isLoading && Array.isArray(libraryData) && libraryData.length <= 0 && (
        <NoResources />
      )}
      {isLoading && (
        <div className={styles.loading_container}>
          <div className={styles.loading_spinner}>
            <Spinner className="spinner" />
          </div>
        </div>
      )}
      <div ref={containerRef} />
    </div>
  );
};

export default ResourcesList;
