/**
 * @module Utils.Hooks
 *
 */
import { useIntl } from 'react-intl';

/**
 *
 * @public
 * @method useLoc
 */
export default function useLoc() {
  return useIntl();
}
