import React from "react";
import styles from "./styles.module.scss";
import ModalLayout from "../ModalLayout/ModalLayout";
import { ModalLayoutProps } from "../ModalLayout/index.types";
import CloseIcon from "../../../../public/images/close-icon.svg";

const GettingStartedModal = ({
  children,
  onClose,
  isOpen,
}: ModalLayoutProps) => {
  return (
    <ModalLayout onClose={onClose} isOpen={isOpen}>
      <div className={styles.container}>
        <img
          className={styles.close_icon}
          onClick={onClose}
          src={CloseIcon}
          alt="close"
        />
        {children}
      </div>
    </ModalLayout>
  );
};

export default GettingStartedModal;
