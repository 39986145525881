/**
 * @module Reducers.SubscriptionPlan
 *
 */
import { RequestError } from 'typedefs';
import { SubscriptionModel } from 'app/models';
import { SubscriptionAction } from 'app/actions/subscription';

export type SubscriptionState = {
  data: SubscriptionModel | null;
  trialStarted: boolean;
  error: RequestError | null;
  pending: boolean;
};

const initialState: SubscriptionState = {
  data: null,
  error: null,
  trialStarted: false,
  pending: false,
};

export default function subscriptionReducer(state = initialState, action: SubscriptionAction): SubscriptionState {
  switch (action.type) {
    case '@subscription.fetch.pending':
      return { ...state, pending: true, error: null };
    case '@subscription.fetch.success':
      return { ...state, pending: false, data: action.payload, trialStarted: true };
    case '@subscription.fetch.error':
      return { ...state, pending: false, error: action.payload };

    default:
      return state;
  }
}
