/**
 * @module Components.Subscription.Upgrade
 *
 */
import React, { useState, useEffect } from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { cls } from 'app/utils';
import { UserState } from 'app/reducers/user';
import LOCALES from './locale';
import { UPGRADE_PLANS_MATCH } from './../Status';
import styles from './styles.module.scss';
import upgrade_success_image from 'app/images/upgrade-success.jpg';

interface PropsDef {
  onDone: () => void;
  user: UserState;
  plan?: string;
}

/**
 * @class SuccessPage
 *
 */
function SuccessPage(props: PropsDef) {
  const [isPro, setIsPro] = useState<boolean>(false);

  useEffect(() => {
    if (props.plan && props.plan.match(UPGRADE_PLANS_MATCH)) {
      setIsPro(true);
    }
  }, [props.plan]);

  return (
    <div className={styles.successMain}>
      <div className={cls(styles.panel, styles.panelOne)}>
        <div className={styles.successImage} style={{ backgroundImage: `url(${upgrade_success_image})` }} />
      </div>

      <div className={cls(styles.panel, styles.panelTwo)}>
        <div className={styles.panelContainer}>
          <div className={styles.panelHeader}>
            {isPro ? <FormattedMessage {...LOCALES.success_title_pro} values={{ first_name: props.user.self.firstName }} /> : <FormattedMessage {...LOCALES.success_title} values={{ first_name: props.user.self.firstName }} />}
          </div>

          <div className={styles.panelBody}>
            <p><FormattedMessage {...LOCALES.success_info_one} /></p>
            <p>
              {isPro ? <FormattedMessage {...LOCALES.success_info_two_pro} /> : <FormattedMessage {...LOCALES.success_info_two} />}
            </p>
            <p>
              {isPro ? <FormattedHTMLMessage {...LOCALES.success_info_three_pro} /> : <FormattedMessage {...LOCALES.success_info_three} />}
            </p>
            <div className="clear-float"></div>
          </div>

          <div className={styles.panelFooter}>
            <a className={cls('btn', 'btn-primary', 'btn-success', styles.doneButton)} onClick={() => props.onDone()}>
              <FormattedMessage {...LOCALES.success_button} />
            </a>
          </div>

          <div className="clear-float"></div>
        </div>
      </div>
    </div>
  );
}

export default SuccessPage;
