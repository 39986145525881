/**
 * @module Components.Routes
 *
 */
import React, { useState } from 'react';
import moment from 'moment';
import { AuthService } from 'app/services';
import { camelize } from 'app/utils/strings';
import ResetForm from './ResetForm';
import Success from './Success';
import ForgotPassword from 'app/routes/ForgotPassword';

interface State {
  isValidToken: boolean;
  resetPasswordToken: string;
  isSuccess: boolean;
}

/**
 * @class Reset
 *
 */
export default function Reset() {
  const [ state, setState ] = useState((): State => {
    const { resetPasswordToken, e } = parseUrlToken();
    const isValidToken = isTokenValid(e);
    return { resetPasswordToken, isValidToken, isSuccess: false };
  });

  if ((AuthService as any).isAuthenticated) {
    (AuthService as any).invalidate(true);
  }

  if (state.isSuccess) {
    return (<Success />);
  } else if (state.isValidToken) {
    return (
      <ResetForm
        resetPasswordToken={state.resetPasswordToken}
        onSuccess={() => setState({ ...state, isSuccess: true })}
        onInvalid={() => setState({ ...state, isValidToken: false })}
      />
    );
  } else {
    return (<ForgotPassword expired />);
  }
}

interface TokenProps {
  resetPasswordToken: string;
  e: number;
  s: string;
}

const parseUrlToken = (): TokenProps => {
  let search = decodeURIComponent(window.location.search.slice(1));
  search = search.replace(/&(amp;)?/g, ' ');
  const parts = Object.assign({}, ...search.split(' ').map(p => {
    const [ key, value ] = p.split('=');
    return { [camelize(key)]: value };
  }));
  parts.s = decodeURIComponent(parts.s);
  return parts;
};

const isTokenValid = (exp: number): boolean => {
  const expires = moment.unix(exp).unix();
  const currentTime = moment().unix();

  return currentTime <= expires;
};

