/**
 * @module Reducers.List
 *
 */
import { RequestError } from "typedefs";
import { ListAction } from "app/actions/list";
import { ListModel } from "app/models";

export type ListState = {
  data: ListModel[] | null;
  paginatedData: ListModel[] | null;
  error: RequestError | null;
  pending: boolean;
  sync?: number;
  currentPage?: number;
  showPerPage?: number;
  hasMore?: boolean;
};

const initialState: ListState = {
  data: null,
  paginatedData: null,
  error: null,
  pending: false,
  currentPage: 1,
  showPerPage: 8,
  hasMore: true,
};

export default function listReducer(
  state = initialState,
  action: ListAction
): ListState {
  switch (action.type) {
    case "@list.fetch.pending":
      return { ...state, error: null, pending: true };
    case "@list.fetch.currentPage":
      return { ...state, currentPage: action.payload };
    case "@list.fetch.showPerPage":
      return { ...state, showPerPage: action.payload };
    case "@list.fetch.hasMore":
      return { ...state, hasMore: action.payload };
    case "@list.fetch.success":
      return { ...state, data: action.payload, pending: false };
    case "@list.fetch.paginatedData":
      return { ...state, paginatedData: action.payload };
    case "@list.fetch.error":
      return { ...state, error: action.payload, pending: false };
    case "@list.clear":
      return {
        ...state,
        ...initialState,
      };

    default:
      return state;
  }
}
