/**
 * @module Components
 *
 */
import React, { PropsWithChildren } from 'react';
import { Dots } from 'app/components/Loading';
import Footer from './Footer';
import styles from './styles.module.scss';
import logo_png from 'app/images/edufii-logo2.png';

type PropsDef = PropsWithChildren<{
  loading?: boolean;
}>;

export default function Wrapper(props: PropsDef) {

  let body = (
    <div className={styles.wrapperContent}>{props.children}</div>
  );
  let footer = (<Footer />);
  if (props.loading) {
    body = (<Dots className={styles.dotsWrapper} active={true} />);
    footer = (<div />);
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapperContainer}>
        <div className={styles.wrapperBody}>
          <div className={styles.wrapperHeaderLogo}>
            <a className={styles.wrapperLink} href={window.location.origin}>
              <img className={styles.wrapperImg} src={logo_png} />
            </a>
          </div>

          {body}

          <div className="clear-float"></div>
        </div>
      </div>

      {footer}
    </div>
  );
}

