/**
 * @module Reducers.UserSettings
 *
 */
import { combineReducers } from 'redux';
import { RequestError } from 'typedefs';
import { UserSettingsModel } from 'app/models';
import { UserSettingsAction } from 'app/actions/user-settings';

type UserSettingsType = {
  data: UserSettingsModel | null;
  error: RequestError | null;
  pending: boolean;
};

export type UserSettingsState = {
  fetch: UserSettingsType;
  save: UserSettingsType;
};

const initialState: UserSettingsType = {
  data: null,
  error: null,
  pending: false,
};

export default combineReducers<UserSettingsState, UserSettingsAction>({
  fetch: (state = initialState, action) => {
    switch (action.type) {
      case '@user-settings.success.get':
        return { ...state, data: action.payload, pending: false, error: null };
      case '@user-settings.error.get':
        return { ...state, pending: false, error: action.payload };
      case '@user-settings.pending.get':
        return { ...state, pending: true, error: null };
      default:
        return state;
    }
  },

  save: (state = initialState, action) => {
    switch (action.type) {
      case '@user-settings.success.put':
        return { ...state, pending: false, error: null };
      case '@user-settings.error.put':
        return { ...state, pending: false, error: action.payload };
      case '@user-settings.pending.put':
        return { ...state, pending: true, error: null };
      default:
        return state;
    }
  }
});
