import CheckBox from "app/components/inputs/CheckBox";
import React from "react";
import styles from "../styles.module.scss";
import { Loading } from "app/components/Wrappers";
interface Props {
  teams: any;
  toggleListClick: (item: {}) => void;
  selectedLists: any;
}

const TeamLocation = ({ teams, toggleListClick, selectedLists }: Props) => {
  return (
    <>
      <label className={styles.list_title}>Groups</label>
      {teams.data &&
        teams.data.map((team: any) => (
          <React.Fragment key={team.id}>
            <div
              onClick={() =>
                (team.permissions.includes("post") ||
                  team.planPermissions.includes("post")) &&
                toggleListClick(team)
              }
            >
              <div className={styles.dropdownItem}>
                <img src={team.logo} />
                <div className={styles.name}>
                  {team.name}
                  <div className={styles.sport}>{team.sport}</div>
                </div>
                <CheckBox
                  className={styles.checkbox}
                  name={team.name}
                  checked={selectedLists.has(team)}
                />
              </div>
            </div>
            {Number(team.teamGroupsCount) > 0 &&
              team.teamGroups.map((channel: { id: string; name: string }) => (
                <div key={channel.id} className={styles.channel_container}>
                  <div className={styles.channel}></div>

                  <div
                    className={styles.dropdownItem}
                    onClick={() =>
                      (team.permissions.includes("post") ||
                        team.planPermissions.includes("post")) &&
                      toggleListClick(channel)
                    }
                  >
                    <i className="ico ico-team avatar" />

                    <div className={styles.name}>
                      {channel.name}
                      <div className={styles.sport}>
                        {/* {formatMessage(LOCALES.channel)} */}
                        {team.name}
                      </div>
                    </div>

                    <CheckBox
                      className={styles.checkbox}
                      checked={selectedLists.has(channel)}
                    />
                  </div>
                </div>
              ))}
          </React.Fragment>
        ))}
      {teams.pending && <Loading isLoading loadType="spinner" className={styles.pending} />}
    </>
  );
};

export default TeamLocation;
