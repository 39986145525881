/**
 * @module Features
 *
 */

/**
 * Toggle Feature flags on/off here
 *
 * featureFlags are set by order of priority.
 *  - [ production, staging, test, development ]
 *
 *  production will turn the flag on for all environments
 *  staging will turn the flag on for staging, test, and development
 *  test will turn on the flag for test, and development
 *  development will turn on the flag for development only
 *  true will turn the flag on for all environments
 *  false will turn the flag off for all environments
 *
 */
const features = {
  demoSpaces: 'production',

  analyticsTool: 'production',
  bugsnagTool: 'production',
  benefitsPage: 'production',
};

export default features;
