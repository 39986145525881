
import { defineMessages } from 'react-intl';

export default defineMessages({
  edit_submit_btn: { id: 'app.button.save-changes', defaultMessage: "Save Changes" },

  view_card_on_file: { id: 'subscription.billing-info.card-on-file', defaultMessage: "Credit Card on file" },
  view_credit_card: { id: 'app.placeholders.credit-card', defaultMessage: "Credit Card" },
  view_valid_until: { id: 'app.placeholders.valid-till', defaultMessage: "Valid Till" },
  view_update_card: { id: 'app.buttons.update-credit-card', defaultMessage: "Update Credit Card" },
});
