/**
 * @module Routes.ResetPassword
 *
 */
import React, { useState } from 'react';
import { useDOMState } from 'app/utils/hooks';
import { Redirect, Link } from 'react-router-dom';
import { Wrapper } from 'app/components/Onboarding';
import { Loc } from 'app/components/helpers';
import styles from './styles.module.scss';
import LOCALES from './locale';

const initState: number = 5;

export default function Success() {
  const [ remaining, setState ] = useState(initState);
  const domState = useDOMState();
  const updateState = () => {
    if (domState.isRendered) {
      setState(remaining - 1);
    }
  };

  if (remaining <= 0) {
    return (<Redirect to="/signin" />);
  } else {
    setTimeout(() => updateState(), 1000);
  }

  const redirectMessage = {
    ...LOCALES.reset_success_info,
    values: {time: remaining}
  };

  return (
    <Wrapper>
      <div className={styles.successWrapper}>
        <h1><Loc value={LOCALES.title} /></h1>

        <h3><Loc value={LOCALES.reset_success_status} /></h3>

        <p><Loc html value={redirectMessage} /></p>

        <div>
          <Link to="/signin">
            <Loc value={LOCALES.reset_success_link} />
          </Link>
        </div>
      </div>
    </Wrapper>
  );
}

