/**
 * @module Components.inputs
 *
 */
import React, { PropsWithChildren, Component, ReactNode } from 'react';
import { LocaleString } from 'typedefs';
import { cls } from 'app/utils';
import styles from './styles.module.scss';

type EventTypes = 'onChange' | 'onFocus' | 'onClick';

const EVENT_TYPES: EventTypes[] = ['onChange', 'onFocus', 'onClick'];

type StateDef = {
  checked: boolean
};

export type EventState = {
  checked: boolean,
  label: LocaleString | ReactNode,
  name: string,
};

type PropsDef = PropsWithChildren<{
  className?: string,
  name?: string,
  src?: string,
  checked?: boolean,
  controlled?: boolean,
  label?: LocaleString | Element,
  onChange?: (s: EventState) => void,
  onClick?: (s: EventState) => void,
  onFocus?: (s: EventState) => void,
}>;

class Image extends Component<PropsDef, StateDef> {
  constructor(props: PropsDef) {
    super(props);
    this.state = {
      checked: props.checked
    };
  }

  dispatchEvents(state: EventState) {
    EVENT_TYPES.forEach(type => {
      if (this.props[type]) {
        this.props[type].call(null, state);
      }
    });
  }

  stateChanged = () => {
    const label = this.props.label;
    const name = this.props.name;
    let checked = !this.state.checked;
    if (this.props.controlled) {
      checked = !this.props.checked;
    }

    this.setState({ ...this.state, checked }, () => this.dispatchEvents({ name, label, checked }));
  }

  render() {
    let checked = this.state.checked;
    if (this.props.controlled) {
      checked = this.props.checked;
    }

    const clsName = cls(styles.image, this.props.className, { checked });
    return (
      <div className={clsName} onClick={this.stateChanged}>
        <div className={styles.imageCheckbox} style={{ backgroundImage: `url(${this.props.src})` }}>
          <i className="fas fa-check-circle" aria-hidden="true"></i>
        </div>
        <label>{this.props.label}</label>
      </div>
    );
  }
}


export default Image;

