/**
 * @module Utils.LocalStorage
 *
 */
import store from 'store';


/**
 * Get data from localStorage
 *
 * @method localGet
 * @param {string} key - the localStorage name for the data
 * @param {any} defaultValue - value returned if no data is found in localStorage
 * @return {any}
 */
export function localGet(key: string, defaultValue?: any) {
  let value = store.get(key);
  if (value == null) {
    value = defaultValue;
  }
  return value;
}

/**
 * Set data to localStorage
 *
 * @method localSet
 * @param {string} key - the localStorage name for the data
 * @param {any} value - the data to store
 * @return {void}
 */
export function localSet(key: string, value: any) {
  store.set(key, value);
}

/**
 * Deletes data from localStorage
 *
 * @method localDelete
 * @param {string} key - the localStorage name for the data
 * @return {void}
 */
export function localDelete(key: string) {
  store.remove(key);
}

export function localClear() {
  store.clearAll();
}

