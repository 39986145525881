/** @module actions */
import { Dispatch } from 'react';
import { AnyObject } from 'typedefs';
import { ActionType, action } from 'typesafe-actions';
import { fetchGet, fetchPost } from 'app/utils/request';
import { TeamModel } from 'app/models';

const getTeamByToken = (token: string) => {
  return fetchGet<TeamModel>(`/teams/by_token/${token}`, {}, { version: 1 });
};

const postTeamByToken = (token: string, query: AnyObject) => {
  return fetchPost<TeamModel>(`/teams/${token}/join`, query, { version: 2 });
};

const fetchAllPending = () => action('@team.fetch.pending');
const fetchAllError = (err: string) => action('@team.fetch.error', err);
const fetchAllSuccess = (data: TeamModel[]) => action('@team.fetch.success', data);

const pendingTeamByToken = () => action('@team.fetch-by-token.pending');
const errorTeamByToken = (err: string) => action('@team.fetch-by-token.error', err);
const successTeamByToken = (data: TeamModel) => action('@team.fetch-by-token.success', data);

const pendingJoin = () => action('@team.join-by-token.pending');
const errorJoin = (err: string) => action('@team.join-by-token.error', err);
const successJoin = (data: TeamModel) => action('@team.join-by-token.success', data);

const thisActions = {
  fetchAllPending,
  fetchAllSuccess,
  fetchAllError,
  pendingTeamByToken,
  errorTeamByToken,
  successTeamByToken,
  pendingJoin,
  errorJoin,
  successJoin
};

export type TeamAction = ActionType<typeof thisActions>;

export function fetchTeams(query: AnyObject) {
  return (dispatch: Dispatch<TeamAction>) => {
    dispatch(fetchAllPending());

    return fetchGet('/teams', query)
      .then((data: TeamModel[]) => dispatch(fetchAllSuccess(data)))
      .catch(err => dispatch(fetchAllError(err)));
  };
}

export function fetchTeam(teamId: string) {
  return (dispatch: Dispatch<TeamAction>) => {
    dispatch(fetchAllPending());

    return fetchGet('/teams', { teamId })
      .then((data: TeamModel[]) => dispatch(fetchAllSuccess(data)))
      .catch(err => dispatch(fetchAllError(err)));
  };
}

export function fetchTeamByToken(token: string) {
  return (dispatch: Dispatch<TeamAction>) => {
    dispatch(pendingTeamByToken());

    const promise = getTeamByToken(token);
          promise.then((data: TeamModel) => dispatch(successTeamByToken(data)));
          promise.catch(err => dispatch(errorTeamByToken(err)));

    return promise;
  };
}

export function joinTeamByToken(token: string, query: AnyObject) {
  return (dispatch: Dispatch<TeamAction>) => {
    dispatch(pendingJoin());

    const promise = postTeamByToken(token, query);
          promise.then((data: TeamModel) => dispatch(successJoin(data)));
          promise.catch(err => dispatch(errorJoin(err)));

    return promise;
  };
}

export const actions = {
  fetchTeam,
  fetchTeams,
  fetchTeamByToken,
  joinTeamByToken,
};
