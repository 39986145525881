import React from "react";
import styles from "./styles.module.scss";
import { Props } from "./index.types";
import { cls } from "app/utils";

const Button = ({
  onClickFunc,
  title,
  buttonType,
  buttonStyles,
  childStyles,
  icon,
  disabled,
  ...rest
}: Props) => {
  return (
    <button
      type="button"
      disabled={disabled}
      className={cls("btn", styles.button, buttonStyles, {
        ["btn-primary"]: buttonType === "primary",
        ["btn-secondary"]: buttonType === "secondary",
        ["btn-outline-secondary"]: buttonType === "secondary-outline",
        ["btn-danger"]: buttonType === "danger",
        ["btn-upgrade"]: buttonType === "upgrade",
      })}
      onClick={onClickFunc}
      {...rest}
    >
      <div className={cls(childStyles)}>
        {icon && icon} {title}
      </div>
    </button>
  );
};

export default Button;
