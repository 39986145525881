import React from "react";
import styles from "./styles.module.scss";
import nullMedia from "../../../../public/images/null-media.png";

const NoResources = () => {
  return (
    <div className={styles.null_result}>
      <img src={nullMedia} alt="no media" className="null-result-hero" />
      <span className={styles.info_wrapper}>
        <h3 className="null-result-heading">No Library Items Yet</h3>
        <p className="null-result-summary">
          Easily collect and store all your instructional media.
        </p>
      </span>
    </div>
  );
};

export default NoResources;
