import React from "react";
import styles from "./styles.module.scss";
import playIcon from "../../../public/images/ico_play.svg";
import pauseIcon from "../../../public/images/ico_pause.svg";
import { AudioPlayerProps } from "./index.types";
import useAudioPlayerModel from "./indexModel";

const AudioPlayer = ({ data }: AudioPlayerProps) => {
  const { handlePlayPause, isPlaying, remainingDuration } = useAudioPlayerModel(
    {
      data,
    }
  );
  return (
    <div className={styles.container}>
      <div className={styles.audio_player}>
        <div onClick={handlePlayPause} className={styles.audio_control}>
          <img src={isPlaying ? pauseIcon : playIcon} alt="play/pause btn" />
        </div>
        <div id={`waveform-${data.id}`}></div>
        <div className={styles.audio_timer}>{remainingDuration}</div>
      </div>
    </div>
  );
};

export default AudioPlayer;
