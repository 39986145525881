/**
 * @module Utils.File
 *
 */

/**
 * convert a data url to a blob
 *
 * @public
 * @method dataUrlToBlob
 * @param {string} base64
 * @param {string} type
 * @return {Blob}
 */
export default function dataUrlToBlob(
  base64: string,
  type: string = "image/jpeg"
) {
  if (base64 && typeof base64 === "string") {
    const data = base64.split(",")[1];
    if (data) {
      const binary = atob(data);
      const buffer = [];
      for (let i = 0; i < binary.length; i++) {
        buffer.push(binary.charCodeAt(i));
      }
      return new Blob([new Uint8Array(buffer)], { type });
    } else {
      throw new Error("Invalid base64 input: Data part is missing");
    }
  } else {
    throw new Error("Invalid base64 input");
  }
}
