/**
 * @module Components.Subscription
 *
 */
import React, { useEffect } from 'react';
import moment from 'moment';
import { RootState } from 'app/reducers';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { route } from 'app/utils/router';
import { useActions } from 'app/utils/hooks';
import { fetchSelf } from 'app/actions/user';
import { fetchPaymentInfo } from 'app/actions/payment-info';
import { Spinner } from 'app/components/Loading';
import { Loc } from 'app/components/helpers';
import styles from './styles.module.scss';
import LOCALES from './locale';


const BillingInfo = () => {
  const actions = useActions({ fetchSelf, fetchPaymentInfo });
  useEffect(() => (actions.fetchSelf(), actions.fetchPaymentInfo(), undefined), [actions]);

  return (
    <div className={styles.billingInfoView}>
      {renderChild()}
    </div>
  );
};


function renderChild() {
  const state = useSelector((_state: RootState) => _state);

  if (state.paymentInfo.error != null) {
    return (<div className={styles.error}><Loc value={state.paymentInfo.error} /></div>);
  }

  if (state.paymentInfo.data != null) {
    return (
      <div className={styles.container}>
        <div className={styles.title}><FormattedMessage {...LOCALES.view_card_on_file} /></div>

        <div className={styles.details}>
          <div className={styles.rowItem}>
            <span><FormattedMessage {...LOCALES.view_credit_card} /></span>
            <span>XXXX XXXX XXXX {state.paymentInfo.data.cardLast4} / &nbsp;{state.paymentInfo.data.cardType}</span>
            <div className={styles.clear}></div>
          </div>

          <div className={styles.rowItem}>
            <span><FormattedMessage {...LOCALES.view_valid_until} /></span>
            <span>{moment(state.paymentInfo.data.cardExpiry).format('MM / YY')}</span>
            <div className={styles.clear}></div>
          </div>
        </div>

        <a className="btn btn-outline-secondary" onClick={() => route("/account/billing_info/edit")}>
          <FormattedMessage {...LOCALES.view_update_card} />
        </a>
      </div>
    );
  }

  return (<Spinner />);
}

export default BillingInfo;
