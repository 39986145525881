import React from "react";
import styles from "./styles.module.scss";
import { TagSelect } from "../inputs/SelectBox";
import MediaUploader from "../Button/MediaUploader";
import useCopyMovePostModel from "./indexModel";
import duration from "../inputs/SelectBox/helpers/DurationConverter";
import playIcon from "app/images/play_btn.svg";
import TagModel from "app/models/TagModel";
import MoveModal from "../MoveModal";
import CopyModal from "../CopyModal";
import LocationDropDown from "../LocationsDropdown";

interface Props {
  isMove?: boolean;
}

const CopyMovePost = ({ isMove }: Props) => {
  const {
    handleCancel,
    post,
    postPending,
    dataFileIconHandler,
    setPostDetails,
    postDetails,
    createMovePost,
    setPostTags,
    openMoveModal,
    setOpenMoveModal,
    movePostReplies,
    sendNotification,
    setMovePostReplies,
    setSendNotification,
    errorMessage,
    handleMoveClick,
    openCopyModal,
    setOpenCopyModal,
    copyRedirect,
    btnLabel,
    selectedDestinations,
    isPostMove,
    handleCopyClick,
  } = useCopyMovePostModel();

  if (postPending) {
    return <div>Loading....</div>;
  }

  return (
    post && (
      <>
        <MoveModal
          createMovePost={createMovePost}
          openMoveModal={openMoveModal}
          setOpenMoveModal={setOpenMoveModal}
          movePostReplies={movePostReplies}
          sendNotification={sendNotification}
          setMovePostReplies={setMovePostReplies}
          setSendNotification={setSendNotification}
        />
        <CopyModal
          countedLocation={selectedDestinations && selectedDestinations.length}
          openCopyModal={openCopyModal}
          setOpenCopyModal={setOpenCopyModal}
          copyRedirect={copyRedirect}
        />
        <div className={styles.container}>
          <h1 className={styles.title}>
            {!isMove ? "Copy Post" : "Move Post"}
          </h1>
          <form className={styles.posting_container}>
            <textarea
              className={styles.text_input}
              placeholder="Create post..."
              value={postDetails}
              onChange={(e) => setPostDetails(e.target.value)}
            />
            {post.tags && (
              <TagSelect
                defaultValue={post && post.tags}
                onChange={(tags) => setPostTags(tags as TagModel[])}
              />
            )}
            {post.type && post.type !== "note" ? (
              <div className={styles.object_container}>
                <div className={styles.object_thumbnail_wrapper}>
                  {post.type === "datafile" && (
                    <img
                      src={dataFileIconHandler(post.datafile.extension)}
                      alt="datafile"
                    />
                  )}
                  {post.type === "audio" && (
                    <img src={dataFileIconHandler(post.type)} alt="audio" />
                  )}
                  {post.type === "video" && (
                    <>
                      {post.thumbnailUrl && (
                        <img src={post.thumbnailUrl} alt="thumbnail" />
                      )}
                      <div className={styles.object_item}>
                        {post.video.duration &&
                          duration(post.video.duration) !== "0" && (
                            <div className={styles.duraton_play_wrap}>
                              <img src={playIcon} alt="play" />
                              <p>{duration(post.video.duration)}</p>
                            </div>
                          )}
                        {post.video.tags ? (
                          <div className={styles.tags_container_video}>
                            {post.video.tags.map(
                              (tag: string, index: number) => {
                                return (
                                  <div key={index} className={styles.tag}>
                                    #{tag}
                                  </div>
                                );
                              }
                            )}
                          </div>
                        ) : null}
                      </div>
                    </>
                  )}
                  {post.datafile && post.datafile.tags ? (
                    <div className={styles.tags_container}>
                      {post.datafile.tags.map((tag: string, index: number) => {
                        return (
                          <div key={index} className={styles.tag}>
                            #{tag}
                          </div>
                        );
                      })}
                    </div>
                  ) : null}
                  {post.audio && post.audio.tags ? (
                    <div className={styles.tags_container}>
                      {post.audio.tags.map((tag: string, index: number) => {
                        return (
                          <div key={index} className={styles.tag}>
                            #{tag}
                          </div>
                        );
                      })}
                    </div>
                  ) : null}
                </div>
              </div>
            ) : null}
            <div className={styles.form_buttons_wrapper}>
              <div className={styles.location_media_wrapper}>
                <LocationDropDown hideLists={isMove} hideSmartLists={isMove} />
                <MediaUploader disabled={true} />
              </div>

              <div className={styles.save_cancel_container}>
                <button className={styles.cancel_btn} onClick={handleCancel}>
                  <div>Cancel</div>
                </button>

                <button
                  type="button"
                  className={styles.save_btn}
                  onClick={isPostMove ? handleMoveClick : handleCopyClick}
                >
                  <div>{btnLabel}</div>
                </button>
              </div>
            </div>
            {errorMessage && <p className={styles.error_msg}>{errorMessage}</p>}
          </form>
        </div>
      </>
    )
  );
};

export default CopyMovePost;
