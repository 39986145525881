import spacesIcon from "../../../public/images/smart-list/spaces-icon.svg";
import inspirationIcon from "../../../public/images/smart-list/inspiration-icon.svg";
import feedPostIcon from "../../../public/images/smart-list/feed-post-icon.svg";
import fifteenDaysIcon from "../../../public/images/smart-list/15-calendar-icon.svg";
import thirtyDaysIcon from "../../../public/images/smart-list/30-calendar-icon.svg";
import sixtyDaysIcon from "../../../public/images/smart-list/60-plus-calendar-icon.svg";
import listIcon from "../../../public/images/smart-list/list-icon.svg";
import { useDispatch, useSelector } from "react-redux";
import { selectedListValue, clearSmartList } from "app/actions/smartList";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { RootState } from "typedefs";
import SmartListModel from "app/models/SmartListModel";
import {
  fetchLists,
  currentPage,
  paginatedData,
  hasMore,
  clearList,
} from "app/actions/list";
import { useActions } from "app/utils/hooks";
import { route } from "app/utils/router";

// Custom hook to manage smart lists
export const useSmartLists = () => {
  const dispatch = useDispatch();
  const smartListActions = useActions({
    fetchLists,
    currentPage,
    paginatedData,
    hasMore,
    clearSmartList,
    clearList,
  });

  // State and selectors
  const user = useSelector((state: RootState) => state.user.self);
  const smartListsData = useSelector(
    (state: RootState) => state.smartList.smartListsData
  );
  const selectedList = useSelector(
    (state: RootState) => state.smartList.selectedListValue
  );
  const {
    data: fetchedSegmentData,
    pending: segmentsLoading,
    hasMore: hasMoreSegment,
    currentPage: currentPageState,
    paginatedData: segmentsPaginatedData,
    showPerPage: per_page,
  } = useSelector((state: RootState) => state.list);

  // State for dropdown and modal
  const [isDropdownOpen, setDropdownOpen] = useState<boolean>(false);
  const [isUpgradeModalOpen, setIsUpgradeModalOpen] = useState<boolean>(false);
  const lastElementRef = useRef<HTMLDivElement | null>(null);

  // Query for API request
  const query = useMemo(() => {
    return { page: currentPageState, per_page };
  }, [currentPageState, per_page]);

  // Initial fetch and pagination logic
  useEffect(() => {
    if (segmentsPaginatedData && !segmentsPaginatedData.length) {
      dispatch(currentPage(currentPageState + 1));
      smartListActions.fetchLists(query);
    }
  }, []);

  // Data for predefined spaces
  const AllSpacesData = [
    {
      id: "1",
      name: "All spaces",
      object: "athlete_space",
      icon: spacesIcon,
    },
    {
      id: "2",
      name: "Inspiration",
      object: "demo_space",
      icon: inspirationIcon,
    },
  ];

  // Transform smart list data with appropriate icons
  const transformSmartLists = (data: any) => {
    return (
      data &&
      data.map((item: SmartListModel) => {
        const { name, object } = item;

        let icon: string | null;

        if (name.includes("15")) {
          icon = fifteenDaysIcon;
        } else if (name.includes("30")) {
          icon = thirtyDaysIcon;
        } else if (name.includes("60")) {
          icon = sixtyDaysIcon;
        } else if (name.includes("Unanswered")) {
          icon = feedPostIcon;
        } else if (object.includes("segment")) {
          icon = listIcon;
        }
        return {
          ...item,
          icon,
        };
      })
    );
  };

  // Toggle upgrade modal
  const handleUpgradeModal = useCallback(() => {
    setIsUpgradeModalOpen((prev) => !prev);
  }, []);

  const handleDataClick = useCallback(
    (value: { name: string; icon: string; id: string; object: string }) => {
      if (
        (!user.plan.planCode.startsWith("coachpro+") ||
          user.plan.status === "trial_expired") &&
        value.object === "smart_list"
      ) {
        handleUpgradeModal();
      } else {
        dispatch(selectedListValue(value));
        setDropdownOpen((prev) => !prev);

        const objectRoute =
          value.object === "smart_list"
            ? "smartList"
            : value.object === "segment"
            ? "list"
            : "all";

        const newRoute = `/spaces/${objectRoute}/${value.name
          .toLocaleLowerCase()
          .replace(/\s+/g, "_")}/${value.id ? value.id : "_"}`;

        route(newRoute);
      }
    },
    [user, dispatch, handleUpgradeModal, route]
  );

  // Toggle dropdown
  const handleDropdownBtn = useCallback(() => {
    setDropdownOpen((prev) => !prev);
  }, []);

  // Intersection observer for infinite scrolling
  useEffect(() => {
    const observer = new IntersectionObserver((entries: any) => {
      if (!hasMoreSegment || segmentsLoading) {
        return;
      }

      const entry = entries[0];
      if (entry.isIntersecting && !entry.wasIntersecting) {
        smartListActions.fetchLists(query);
        // Dispatch only when the element starts intersecting
        dispatch(currentPage(currentPageState + 1));
        dispatch(
          paginatedData([
            ...(segmentsPaginatedData || []),
            ...(Array.isArray(fetchedSegmentData) ? fetchedSegmentData : []),
          ])
        );
        if (
          (fetchedSegmentData && fetchedSegmentData.length < per_page) ||
          !paginatedData.length
        ) {
          dispatch(hasMore(false));
        } else {
          dispatch(hasMore(true));
        }
      }
    });

    if (lastElementRef.current) {
      observer.observe(lastElementRef.current);
    }

    return () => {
      if (lastElementRef.current) {
        observer.unobserve(lastElementRef.current);
      }
    };
  }, [lastElementRef, segmentsLoading, hasMoreSegment, query]);

  // Calculate valid data for rendering
  const validData = useMemo(() => {
    const allSpacesData = Array.isArray(AllSpacesData) ? [AllSpacesData] : [];
    const userData =
      user && user.kind === "coach" && Array.isArray(smartListsData)
        ? [transformSmartLists(smartListsData)]
        : [];

    const segments = segmentsPaginatedData
      ? transformSmartLists(segmentsPaginatedData)
      : [];

    return [...allSpacesData, ...userData, segments];
  }, [AllSpacesData, segmentsPaginatedData, smartListsData]);

  useEffect(() => {
    const pathname = window.location.pathname;
    const parts = pathname.split("/").filter(Boolean);
    let pathId = "";
    if (parts.length >= 4) {
      pathId = parts[3];
    }
    let matchFound = false;
    validData.some((dataArray) => {
      if (matchFound) {
        return true;
      }
      return dataArray.some((item: { id: string }) => {
        if (item.id === pathId) {
          matchFound = true;
          return true;
        }
        return false;
      });
    });

    if (!matchFound) {
      smartListActions.clearList();
      smartListActions.clearSmartList();
      route("/spaces");
    }
  }, []);

  return {
    data: validData,
    handleDataClick,
    selectedList,
    isDropdownOpen,
    handleDropdownBtn,
    handleUpgradeModal,
    isUpgradeModalOpen,
    lastElementRef,
    segmentsLoading,
    user,
  };
};
