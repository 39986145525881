/**
 * @module Components.Layouts
 *
 */
import React from 'react';
import { cls } from 'app/utils';
import Styles from './styles.module.scss';

type PropsDef = React.PropsWithChildren<{
  className?: string;
  columns?: number;
}>;

/**
 * @class ColumnBox
 *
 */
export default function ColumnBox(props: PropsDef) {
  const columns = props.columns || 2;

  return (
    <div className={cls(Styles.columnBox, `cols-${columns}`, props.className)}>
      {props.children}
    </div>
  );
}
