/**
 * @module Utils
 *
 */
import { UserModel, UserPermissionTypes } from 'app/models/UserModel';

export function hasCoachPlus(user: UserModel) {
  return user.subscription.status === 'active' || user.subscription.status === 'trial';
}

export function hasCoachPlusTrial(user: UserModel) {
  return user.subscription.status === 'trial';
}

export function hasExpiredCoachPlus(user: UserModel) {
  return user.subscription.status === 'expired' || user.subscription.status === 'trial_expired';
}

export function hasExpiredCoachPlusSubscription(user: UserModel) {
  return user.subscription.status === 'expired';
}

export function hasExpiredCoachPlusTrial(user: UserModel) {
  return user.subscription.status === 'trial_expired';
}

export function hasFreeSlots(user: UserModel) {
  return user.plan.slotsCount < user.plan.spacesLimit;
}


export function permissionFor(user: UserModel, permission: UserPermissionTypes) {
  return (user.planPermissions.indexOf(permission) !== -1);
}
