/**
 * @module Reducers.Connection
 *
 */
import moment from 'moment';
import { RequestError } from 'typedefs';
import { ConnectionAction, fetchConnections } from 'app/actions/connection';
import { UserModel } from 'app/models';

export interface ConnectionState {
  data?: UserModel[];
  error?: RequestError;
  pending?: boolean;
  sync?: number;
  fetchUpdate?: typeof fetchConnections;
}

const initState: ConnectionState = {
  data: null,
  error: null,
  pending: false,
  sync: 0,
  fetchUpdate: null,
};

export default function connectionReducer(state = initState, action: ConnectionAction): ConnectionState {
  const sync = moment().unix();
  switch (action.type) {
    case '@connection.fetch.pending':
      return { ...state, pending: true };
    case '@connection.fetch.success':
      return { ...state, pending: false, data: action.payload.model, fetchUpdate: action.payload.updater, sync };
    case '@connection.fetch.error':
      return { ...state, pending: false, error: action.payload, sync };

    default:
      return state;
  }
}
