import { useActions, useSelector } from "app/utils/hooks";
import { useEffect, useRef } from "react";
import {
  fetchMedia,
  clearMediaState,
  page,
  mediaLibraryData,
} from "app/actions/media-library-data";
import { RootState } from "typedefs";
import { useDispatch } from "react-redux";
import useFilters from "app/components/helpers/useFilters";
import { clearFilter } from "../../../actions/filter";

export const ResourcesListModel = () => {
  const dispatch = useDispatch();
  const perPage = 20;
  const actions = useActions({ fetchMedia, clearMediaState, clearFilter });
  const { filters } = useFilters();

  const { pending, hasMore, data, currentPage, libraryData } = useSelector(
    (state: RootState) => state.mediaLibraryData
  );

  const containerRef = useRef(null);
  const pollingIntervalRef = useRef(null);

  useEffect(() => {
    if (!pending && (!libraryData || (libraryData && libraryData.length < 1))) {
      actions.fetchMedia(currentPage, perPage, filters).then((res: []) => {
        dispatch(mediaLibraryData(res));
        dispatch(page(currentPage + 1));
      });
    }
    return () => {
      actions.clearMediaState();
      clearInterval(pollingIntervalRef.current);
    };
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      if (!hasMore || pending || !data) {
        return;
      }

      const entry = entries[0];
      if (entry.isIntersecting) {
        actions
          .fetchMedia(currentPage, perPage, filters)
          .then((res: []) => {
            dispatch(mediaLibraryData([...libraryData, ...res]));
          })
          .finally(() => {
            dispatch(page(currentPage + 1));
          });
      }
    });

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, [containerRef, currentPage, pending, hasMore, data]);

  useEffect(() => {
    let currentIndex = 0;

    const poll = () => {
      if (
        libraryData &&
        libraryData.length > 0 &&
        currentIndex < libraryData.length
      ) {
        if (!libraryData[currentIndex].processed) {
          actions.fetchMedia(1, perPage, filters).then((res) => {
            if (res[0].processed) {
              const updatedData: any = [...libraryData];
              updatedData[currentIndex] = res[0];
              dispatch(mediaLibraryData(updatedData));
              currentIndex++;
            }
          });
        } else {
          currentIndex++;
        }
      }
    };

    pollingIntervalRef.current = setInterval(poll, 5000);

    return () => {
      clearInterval(pollingIntervalRef.current);
    };
  }, [libraryData]);

  return {
    containerRef,
    libraryData,
    isLoading: pending,
  };
};
