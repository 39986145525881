/**
 * @module Routes.Reset
 *
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  placeholder: { id: 'app.title.email', defaultMessage: 'Email' },
  title: { id: 'forgot-password.title', defaultMessage: 'Forgot Password?' },
  title_expired: { id: 'forgot-password.title-expired', defaultMessage: 'Reset Link Expired' },
  sent_title: { id: 'forgot-password.sent-title', defaultMessage: 'Reset Link Sent' },
  reset_button: { id: 'forgot-password.button', defaultMessage: 'Send Reset Password Email' },
  reset_button_expired: { id: 'forgot-password.button-expired', defaultMessage: 'Send Password Reset Link' },
  login_link: { id: 'forgot-password.login-link', defaultMessage: 'I know my password' },
  login_link_too: { id: 'forgot-password.login-link-too', defaultMessage: 'Back to Sign In' },
  reset_link: { id: 'forgot-password.reset-link', defaultMessage: 'Didn\'t get the email?' },
  subtitle: {
    id: 'forgot-password.subtitle',
    defaultMessage: 'Enter your email address and we\'ll send you a link to reset your password.'
  },
  subtitle_expired: {
    id: 'forgot-password.subtitle-expired',
    defaultMessage: "This password reset link has expired or is invalid. Enter your email again and we'll send you a new reset link."
  },
  sent_subtitle: {
    id: 'forgot-password.sent-subtitle',
    defaultMessage: 'An email has been sent to you containing a password reset link.'
  },
  missing_email: { id: 'forgot-password.missing-email', defaultMessage: 'Please enter your email address.' },
  email_not_found: { id: 'forgot-password.invalid-email', defaultMessage: 'Email doesn\'t match any accounts on file.' },
  unknown_error: { id: 'forgot-password.unknown-error', defaultMessage: 'An unknown error occurred. Please try again.' },
});
