/**
 * @module Components.PhoneVerification
 *
 */
import React, { useState, useEffect } from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { useLoc } from 'app/utils/hooks';
import { PhoneVerificationService } from 'app/services';
import styles from './styles.module.scss';
import LOCALES from './locale';

type PropsDef = {
  phone?: string;
  phoneCountry?: string;
};

/**
 * @class ResendButton
 *
 */
const ResendButton = (props: PropsDef) => {
  const { phone, phoneCountry } = props;
  const [counter, setCounter] = useState(60);
  const [error, setError] = useState(null);
  const { formatMessage } = useLoc();

  useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  const onResendClick = (event: any) => {
    event.preventDefault();
    setError(null);

    (PhoneVerificationService as any).verify(phone, phoneCountry)
      .then(() => {
        setCounter(60);
      })
      .catch((err: 'phone_verification_unprocessable_entity' | 'phone_verification_unknown') => {
        setCounter(60);
        setError(err);
      });
  };

  return (
    <div className={styles.resendCodeButton}>
      <FormattedMessage {...LOCALES.no_code} />&nbsp;
      {counter === 0 ? <a onClick={onResendClick}><FormattedMessage {...LOCALES.resend} /></a> : <span><FormattedHTMLMessage id={LOCALES.retry.id} defaultMessage={formatMessage(LOCALES.retry, { counter })} /></span>}
      <br/>
      {error !== null ? <span className={styles.error}><FormattedMessage {...LOCALES.error} /></span> : ''}
    </div>
  );
};

export default ResendButton;
