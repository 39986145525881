/**
 * @module Utils.Request
 *
 */
import { resolve, reject } from 'rsvp';
import successHandler from './successHandler';
import errorHandler from './errorHandler';

export interface ResponseType {
  status: number;
  statusText: string;
  success: boolean;
  xhr: Response;
  data?: any;
  errors?: string[];
  originalErrors?: string[];
}

/**
 * Handles api request responses
 *
 * @method responseHandler
 * @param {Response} xhr
 * @return {Promise}
 */
export default function responseHandler(xhr: Response) {
  const handleResp = (resp: any) => buildResponse(xhr, resp);
  if (xhr.status == null && xhr instanceof Error) {
    // handle no connection error
    return reject(handleResp({
      ok: false, status: 599,
      statusText: 'Network connection timeout error',
      error: ((xhr as any) as Error).message
    }));
  } else if (xhr.status === 204 && xhr.statusText === 'No Content') { // no content response
    return resolve(handleResp(null));
  } else if (xhr.status === 201 && xhr.statusText === 'Created') { // sometimes has no content?
    return xhr.json().then(handleResp).catch((e) => {
      // this is caused when the call returns without a response
      if (e instanceof SyntaxError) {
        // ignore the error and return success with a empty content
        return resolve(handleResp(null));
      } else {
        // handle actual error
        return reject(e);
      }
    });
  }

  const contentType = xhr.headers.get('Content-Type');
  if (contentType && contentType.includes("application/json")) {
    return xhr.json().then(handleResp); // Promise<any>
  } else if (contentType && contentType.includes("image/")) {
    return xhr.blob().then(handleResp); // Promise<Blob>
  } else {
    return xhr.text().then(handleResp); // Promise<string>
  }
}

function buildResponse(xhr: Response, response: any): ResponseType {
  const { ok, status, statusText } = xhr;
  let result = {};
  if (response != null) {
    if (ok) {
      result = successHandler(response);
    } else {
      result = errorHandler(response);
    }
  }
  return { xhr, status, statusText, success: ok, ...result };
}

