/**
 * @module Library
 *
 */
import React from "react";
import bs from "@bugsnag/js";
import bsr from "@bugsnag/plugin-react";
import { Bugsnag } from "@bugsnag/browser";
import { ErrorBoundary as DevErrors } from "app/components/helpers";
import { UserModel } from "app/models";
import { featureIsEnabled } from "app/utils/features";
import config from "config/environment";

let bugsnag: Partial<Bugsnag.Client> = {
  notify: () => false,
};
let ErrorBoundary = DevErrors;

if (featureIsEnabled("bugsnagTool")) {
  bugsnag = bs({
    apiKey: config.BUGSNAG_API_KEY,
    appVersion: config.VERSION,
    releaseStage: config.ENVIRONMENT,
  });

  bugsnag.use(bsr, React);

  // wrap your entire app tree in the ErrorBoundary provided
  ErrorBoundary = bugsnag.getPlugin("react");
}

export { bugsnag, ErrorBoundary };

export function bugsnagAddUser(user: UserModel) {
  if (user != null) {
    bugsnag.user = {
      id: user.id,
      name: user.name,
      email: user.email,
    };

    if (bugsnag.metaData == null) {
      bugsnag.metaData = {};
    }

    (bugsnag.metaData as any).user = {
      kind: user.kind,
      customerType: user.customerType,
      hasActiveSubscription: user.hasActiveSubscription,
      hasBillingInfo: user.hasBillingInfo,
      hasCoachPlusSubscription: user.hasCoachPlusSubscription,
      hasTrialSubscription: user.hasTrialSubscription,
      needsOnboarding: user.needsOnboarding,
      createdAt: user.createdAt,
      updatedAt: user.updatedAt,
    };
  }
}
