/**
 * @module Components.Calendar
 *
 */
import React, { useState, useEffect } from "react";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styles from './styles.module.scss';
import { ScheduledPostsService } from 'app/services';
import { Dots } from 'app/components/Loading';

type PropsDef = {
  scheduledPostsCount: number;
  onChange: (value: any) => void;
};

/**
 * @class ScheduleCalendar
 *
 */
const ScheduleCalendar = (props: PropsDef) => {
  const [scheduledPostsCount, setScheduledPostsCount] = useState(null);
  const [scheduledByDate, setScheduledByDate] = useState({});
  const [loading, setLoading] = useState(true);
  const getScheduledData = () => {
    const now = new Date();
    const timezoneOffset = now.getTimezoneOffset();

    (ScheduledPostsService as any).groupedByDate(timezoneOffset)
      .then((data: any) => {
        setScheduledByDate(data);
        setLoading(false);
      });
  };

  const onChange = (data: string) => {
    props.onChange(data);
  };

  useEffect(() => {
    if (scheduledPostsCount !== props.scheduledPostsCount) {
      getScheduledData();
      setScheduledPostsCount(props.scheduledPostsCount);
    }

  }, [props.scheduledPostsCount]);

  if (loading) {
    return (<Dots active={true} />);
  }

  const renderDayContents = (day: number, date: any) => {
    const formattedDate = moment(date).format('DDMMYYYY');
    const scheduledCount = scheduledByDate[formattedDate] || 0;

    return(
      <div>
        <span className={scheduledCount === 0 ? styles.hidden : styles.scheduledPostsCount}>
          <span className={styles.counter}>{scheduledCount}</span>
        </span>

        <span className={scheduledCount === 0 ? '' : styles.scheduleExists}>
          {day}
        </span>
      </div>
    );
  };

  return (
    <DatePicker
      onChange={onChange}
      inline={true}
      monthsShown={3}
      renderDayContents={renderDayContents}
    />
  );
};

export default ScheduleCalendar;
