/**
 * @module Components
 *
 */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import TermsOfService from 'app/components/links/TermsOfService';
import PrivacyPolicy from 'app/components/links/PrivacyPolicy';
import Support from 'app/components/links/Support';
import Contact from 'app/components/links/Contact';
import styles from './styles.module.scss';
import config from 'config/environment';

export default function LoggedOutFooter() {
  return (
    <div className={styles.footer}>
      <div className={styles.footerContainer}>
        <div className={styles.footerBody}>
          <span><TermsOfService /></span>
          <span><PrivacyPolicy /></span>
          <span><Support /></span>
          <span><Contact /></span>
          <span>
            &copy;
            <FormattedMessage
              id="app.copyright"
              defaultMessage="{year} CoachNow"
              description="copyright statement"
              values={{ year: config.CURRENT_YEAR }}
            />
          </span>
        </div>
      </div>
    </div>
  );
}
