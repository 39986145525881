import React, { useState } from "react";
import styles from "./styles.module.scss";
import MediaPickerModal from "app/components/Layouts/MediaPickerModal";

interface Props {
  disabled?: boolean;
}

const MediaUploader = ({ disabled }: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const modalHandler = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <div
        className={`${disabled ? styles.container_disabled : styles.container}`}
        onClick={!disabled ? modalHandler : null}
      >
        <i className="ico ico-media" />
        <p>Media</p>
      </div>
      {isOpen && (
        <MediaPickerModal isOpen={isOpen} onCloseFunc={modalHandler} />
      )}
    </>
  );
};

export default MediaUploader;
