/**
 * @module Utils.Hooks
 *
 */
import { useReducer } from 'react';
import useDOMState from './useDOMState';

const pendingAction = () => ({ type: 'PENDING' });
const stopPendingAction = () => ({ type: 'NOT_PENDING' });

type PendingAction = ReturnType<typeof pendingAction> | ReturnType<typeof stopPendingAction>;
type PendingState = { status: boolean };

function pendingReducer(pending: PendingState, action: PendingAction) {
  switch (action.type) {
    case 'PENDING':
      return { ...pending, status: true };
    case 'NOT_PENDING':
      return { ...pending, status: false };
    default:
      return pending;
  }
}

/**
 *
 * @public
 * @method usePending
 */
export default function usePending(): [ PendingState, (() => void), (() => void) ] {
  const [ pending, dispatchPending ] = useReducer(pendingReducer, { status: false });
  const dom = useDOMState();

  function start() {
    if (dom.isRendered) {
      dispatchPending(pendingAction());
    }
  }

  function stop() {
    if (dom.isRendered) {
      dispatchPending(stopPendingAction());
    }
  }

  return [ pending, start, stop ];
}

