/**
 * @module Components.Loading
 *
 */
import React from 'react';
import { cls } from 'app/utils';
import loading_gif from 'app/images/loading.gif';
import styles from './styles.module.scss';

type PropsDef = {
  className?: string;
  isLoading?: boolean;
};

/**
 * @class Spinner
 *
 */
export default function Spinner(props: PropsDef) {
  const isLoading = props.isLoading != null ? props.isLoading : true;

  return (
    <div className={cls(styles.main, props.className, isLoading ? styles.show : '')}>
      <img className={styles.loader} src={loading_gif} />
    </div>
  );
}

