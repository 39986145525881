import { useActions } from "app/utils/hooks";
import { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "typedefs";
import { clearApplyTemplate } from "app/actions/applyTemplate";
import SpaceModel from "app/models/SpaceModel";
import TeamGroupModel from "app/models/TeamGroupModel";

export const useApplyTemplateDropdown = () => {
  const dropdownRef = useRef<HTMLDivElement>(null);

  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

  const [clickedOutside, setClickedOutside] = useState<boolean>(false);

  const [isSpaceSelectModalOpen, setSpaceSelectModalOpen] =
    useState<boolean>(false);

  const [isGroupSelectModalOpen, setGroupSelectModalOpen] =
    useState<boolean>(false);

  const [isChannelSelectModalOpen, setChannelSelectModalOpen] =
    useState<boolean>(false);

  const [successModal, setSuccessModal] = useState<boolean>(false);

  const [appliedTo, setAppliedTo] = useState<{
    location: string;
    applied: string[] | string;
  }>({
    location: `space`,
    applied: [],
  });

  const applyTemplateSuccess = useSelector(
    (state: RootState) => state.applyTemplate.success
  );

  const applyTemplateActions = useActions({
    clearApplyTemplate,
  });

  const space = useSelector((state: RootState) => state.applyTemplate.spaceObj);
  const groupObj = useSelector(
    (state: RootState) => state.applyTemplate.groupObj
  );
  const channel = useSelector(
    (state: RootState) => state.applyTemplate.groupChannelObj
  );

  const applyTemplateError = useSelector(
    (state: RootState) => state.applyTemplate.errorApplyTemplate
  );

  const handleSpaceSelectModal = useCallback(() => {
    setSpaceSelectModalOpen((prev) => !prev);

    if (!isSpaceSelectModalOpen) {
      applyTemplateActions.clearApplyTemplate();
    }
  }, [isSpaceSelectModalOpen]);

  const handleGroupSelectModal = useCallback(() => {
    setGroupSelectModalOpen((prev) => !prev);

    if (!isGroupSelectModalOpen) {
      applyTemplateActions.clearApplyTemplate();
    }
  }, [isGroupSelectModalOpen]);

  const handleChannelSelectModal = useCallback(() => {
    setChannelSelectModalOpen((prev) => !prev);

    if (!isChannelSelectModalOpen) {
      applyTemplateActions.clearApplyTemplate();
    }
  }, [isChannelSelectModalOpen]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setClickedOutside(true);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const dropDownHandler = () => {
    setIsDropdownOpen((prev) => !prev);
  };

  useEffect(() => {
    if (clickedOutside) {
      setIsDropdownOpen(false);
      setClickedOutside(false);
    }
  }, [clickedOutside]);

  const appliedLocationHandler = useCallback(() => {
    if (space && space.length > 0) {
      const appliedStrings = space.map((item: SpaceModel) => item.athleteName);
      const locationString = `space${space.length > 1 ? "s" : ""}`;
      setAppliedTo({
        applied: formatAppliedStrings(appliedStrings),
        location: locationString,
      });
    } else if (groupObj && groupObj.length > 0) {
      const appliedStrings = groupObj.map((item: TeamGroupModel) => item.name);
      const locationString = `group${groupObj.length > 1 ? "s" : ""}`;
      setAppliedTo({
        applied: formatAppliedStrings(appliedStrings),
        location: locationString,
      });
    } else if (channel && channel.length > 0) {
      const appliedStrings = channel.map((item: TeamGroupModel) => item.name);
      const locationString = `channel${channel.length > 1 ? "s" : ""}`;
      setAppliedTo({
        applied: formatAppliedStrings(appliedStrings),
        location: locationString,
      });
    } else {
      setAppliedTo({ ...appliedTo, location: null });
    }
  }, [space, groupObj, channel]);

  const formatAppliedStrings = (appliedStrings: string[]) => {
    if (appliedStrings.length <= 1) {
      return appliedStrings.join("");
    } else {
      const formattedString = appliedStrings.reduce(
        (accumulator, currentString, index) => {
          if (index === appliedStrings.length - 1) {
            return accumulator + currentString;
          } else {
            return accumulator + currentString + ", ";
          }
        },
        ""
      );
      return formattedString;
    }
  };

  const onCloseSuccessModal = useCallback(() => {
    setSuccessModal((prev) => !prev);
    applyTemplateActions.clearApplyTemplate();
  }, []);

  useEffect(() => {
    if (applyTemplateSuccess === true || applyTemplateError) {
      appliedLocationHandler();
      setSuccessModal((prev) => !prev);
    }
  }, [applyTemplateSuccess, applyTemplateError]);

  const { location, applied } = appliedTo;

  return {
    isDropdownOpen,
    dropDownHandler,
    onCloseSuccessModal,
    successModal,
    location,
    applied,
    dropdownRef,
    clickedOutside,
    isSpaceSelectModalOpen,
    isGroupSelectModalOpen,
    isChannelSelectModalOpen,
    handleSpaceSelectModal,
    handleGroupSelectModal,
    handleChannelSelectModal,
  };
};
