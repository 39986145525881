import { request } from "app/utils/request";
import { Dispatch } from "react";
import { action, ActionType } from "typesafe-actions";
import config from "config/environment";
import { MovePostModel } from "app/models/MovePostModel";

const pending = () => action("@move.fetch.pending");
const error = (err: string) => action("@move.fetch.error", err);
const success = (post: any) => action("@move.fetch.success", post);

const pendingMove = () => action("@move.create.pending");
const errorMove = (err: string) => action("@move.create.error", err);
const successMove = (post: any) => action("@move.create.success", post);

export const moveLocation = (location: string) =>
  action("@move.fetch.moveLocation", location);

export const moveObjectLocation = (location: string) =>
  action("@move.fetch.moveObjectLocation", location);

export const clearMove = () => action("@move.clear");

const thisActions = {
  pending,
  error,
  success,
  clearMove,
  pendingMove,
  errorMove,
  successMove,
  moveLocation,
  moveObjectLocation,
};

export type MoveAction = ActionType<typeof thisActions>;

export function movePost(post_id: string, post: MovePostModel) {
  return (dispatch: Dispatch<MoveAction>) => {
    dispatch(pending());

    const url = config.API_SERVER + `/api/v2/posts/${post_id}/move`;
    const promise = request(url, "POST", post, { version: 1 });
    promise.then((views) => dispatch(successMove(views)));
    promise.catch((err) => dispatch(errorMove(err)));

    return promise;
  };
}
