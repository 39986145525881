/** @module actions */
// import { Dispatch } from 'react';
// import { ActionType, action } from 'typesafe-actions';
import { fetchGet, fetchPost } from 'app/utils/request';
// import { actionUpdater } from 'app/utils/actions';
import { NotificationModel } from 'app/models/NotificationModel';

export const getUnseen = () => {
  return fetchGet<NotificationModel>('notifications/total', {}, { version: 2 });
};

export const markAllSeen = () => {
  return fetchPost<NotificationModel>('notifications/seen', {}, { version: 2 });
};

// const pending = () => action('@connection.fetch.pending');
// const error = (err: string) => action('@connection.fetch.error', err);
// const success = (model: UserModel[], updater: typeof fetchConnections) => action('@connection.fetch.success', { model, updater });

// const thisActions = { pending, error, success };

// export type ConnectionAction = ActionType<typeof thisActions>;

// export function fetchConnections() {
//   return (dispatch: Dispatch<ConnectionAction>) => {
//     dispatch(pending());

//     const updaterFunc = actionUpdater(dispatch, fetchConnections);

//     return connectionGet()
//       .then((model: UserModel[]) => dispatch(success(model, updaterFunc)))
//       .catch(err => dispatch(error(err)));
//   };
// }

// export const actions = { fetchConnections };
