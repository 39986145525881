import { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "typedefs";

export const useScheduleBtnModel = () => {
  const user = useSelector((state: RootState) => state.user.self);
  const [isOpen, setIsOpen] = useState(false);
  const [canUserSchedule, setCanUserSchedule] = useState<boolean>(false);
  const scheduledIn = useSelector((state: RootState) =>
    state.schedulePost.scheduleIn.pickedIn.slice(0, -1)
  );

  useMemo(() => {
    if (user && user.plan.planCode.startsWith("coachpro+")) {
      setCanUserSchedule(true);
    } else {
      setCanUserSchedule(false);
    }
  }, [user]);

  const handleScheduleModal = useCallback(() => {
    setIsOpen((prev) => !prev);
  }, []);

  return { handleScheduleModal, isOpen, canUserSchedule, scheduledIn };
};
