import { useActions } from "app/utils/hooks";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "typedefs";
import { clearLikesState, fetchcurrentLikes } from "app/actions/currentLikes";

const IndexModel = (isOpen: boolean, postId: string) => {
  const actions = useActions({ fetchcurrentLikes, clearLikesState });
  const [currentPage, setCurrentPage] = useState(1);
  const containerRef = useRef<any>(null);

  const {
    data: data,
    pending,
    hasMore,
  } = useSelector((state: RootState) => state.currentLikes);

  useEffect(() => {
    if (isOpen) {
      actions.fetchcurrentLikes(postId, currentPage);
      setCurrentPage(currentPage + 1);
    }

    return () => {
      actions.clearLikesState();
    };
  }, [isOpen]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries: any) => {
      if (!hasMore || pending) {
        return;
      }

      const entry = entries[0];
      if (entry.isIntersecting) {
        actions.fetchcurrentLikes(postId, currentPage);
        setCurrentPage(currentPage + 1);
      }
    });

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, [containerRef, postId, currentPage, hasMore]);

  return {
    data,
    pending,
    containerRef,
  };
};

export default IndexModel;
