/**
 * @module Transactions
 *
 */
import intl from 'intl';
import messages_en from './en.json';

if (!(window as any).Intl) {
  (window as any).Intl = intl;
}

// import { addLocaleData } from "react-intl";
// import locale_en from 'react-intl/locale-data/en';
// add react-intl locale packs [ ...pack, ...pack ];
// addLocaleData([ ...locale_en ]);


interface JSONLocales {
  [key: string]: JSONStruct;
}

interface TranslationObject {
  locale: string;
  messages: JSONStruct;
}

// app translations go here
const messages: JSONLocales = {
  en: messages_en,
};

export function translations(defaultLang: string = 'en'): TranslationObject {
  // languages without region code
  let [ lang ] = (window.navigator.language || 'en').split(/[-_]/);
  if (!(lang && messages[lang])) {
    lang = defaultLang;
  }

  window.__intl__ = messages[lang];

  return { locale: lang, messages: messages[lang] };
}
