/**
 * @module Router
 *
 */
import React, { Component } from 'react';
import { AnalyticsService } from 'app/services';
import {
  Route,
  Redirect,
  Switch,
  withRouter,
  RouteComponentProps
} from 'react-router-dom';

/** Routes */
import Benefits from 'app/routes/Benefits';
import SignIn from 'app/routes/SignIn';
import SignUp from 'app/routes/SignUp';
import ForgotPassword from 'app/routes/ForgotPassword';
import ResetPassword from 'app/routes/ResetPassword';
import Upgrade from 'app/routes/Upgrade';
import Trial from 'app/routes/Trial';
import LegacyApp from 'app/legacy';
import {
  UnsubscribeInvitation,
  UnsubscribeSpace,
  UnsubscribeUser
} from 'app/routes/Unsubscribe';


/**
 * @class AppRouter
 *
 */
class AppRouter extends Component<RouteComponentProps> {
  componentDidUpdate() {
    AnalyticsService.page();
  }

  render() {
    return (
      <Switch>
        <Route path="/benefits" component={Benefits} />
        <Route path="/reset" component={ForgotPassword} />
        <Route path="/upgrade" component={Upgrade} />
        <Route path="/trial" component={Trial} />
        <Route path="/password/edit" component={ResetPassword} />
        <Route path="/login" component={() => (<Redirect to="/signin" />)} />
        <Route path="/signin" component={SignIn} />
        <Route path={["/signup/:user_role?", "/i/:team_token/:team_role?", "/invitation/:invitation_token", "/l/:invitation_token"]} component={SignUp} />
        <Route path="/public/unsubscribe/invitation/:invitationId" component={UnsubscribeInvitation} />
        <Route path="/public/unsubscribe/user/:token" component={UnsubscribeUser} />
        <Route path="/public/unsubscribe/:spaceId/:token" component={UnsubscribeSpace} />
        <Route component={LegacyApp} />
      </Switch>
    );
  }
}

export default withRouter(AppRouter);
