import React from "react";
import styles from "./styles.module.scss";
import { useScheduleBtnModel } from "./indexModel";
import ScheduleModal from "app/components/ScheduleModal";
// import { useSelector } from "react-redux";
// import { RootState } from "typedefs";
import { cls } from "app/utils";
// import { scheduleDateProps } from "app/actions/schedule";

interface Props {
  onScheduleChange: (pickedAt: number) => void;
  scheduledAt: any;
  scheduledTime?: string;
}

const ScheduleBtn = ({
  onScheduleChange,
  scheduledAt,
  scheduledTime,
}: Props) => {
  const { handleScheduleModal, isOpen, canUserSchedule, scheduledIn } =
    useScheduleBtnModel();

  return (
    <>
      <div
        className={cls(styles.container, {
          [styles.scheduled]: scheduledAt,
          ["btn btn-outline-secondary"]: !scheduledAt,
          ["btn btn-upgrade"]: !canUserSchedule,
        })}
        onClick={handleScheduleModal}
      >
        <i className="ico ico-time"></i>
        {!scheduledAt ? (
          <p>Schedule</p>
        ) : (
          <p>
            <b>
              {" "}
              Wait for {scheduledAt && Math.floor(scheduledAt)}{" "}
              {scheduledTime ? scheduledTime : scheduledIn}
              {Math.floor(scheduledAt) > 1 && "s"}
            </b>{" "}
            and publish
          </p>
        )}
      </div>

      {canUserSchedule && isOpen && (
        <ScheduleModal
          scheduledAt={scheduledAt}
          onScheduleChange={onScheduleChange}
          isOpen={isOpen}
          onClose={handleScheduleModal}
        />
      )}
    </>
  );
};

export default ScheduleBtn;
