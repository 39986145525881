/**
 * @module Components
 *
 */
import React, { useState, FormEvent } from "react";
import { useUrlSearch, usePending, useError, useLoc } from "app/utils/hooks";
import {
  FormattedMessage,
  FormattedHTMLMessage,
  MessageDescriptor,
} from "react-intl";
import { useParams, useLocation } from "react-router-dom";
import { AuthService } from "app/services";
import { UserModel } from "app/models";
import { cls } from "app/utils";
import { PreviewFile } from "app/utils/file";
import FormItem from "app/components/inputs/FormItem";
import PhoneInput from "react-phone-input-2";
import UserAvatarUploader from "app/components/UserAvatarUploader";
// import { Strike } from 'app/components/Onboarding';
import { AgeConsent, TermsOfService } from "app/components/inputs/CheckBox";
// import { Facebook } from 'app/components/Button';
// import { validateProperty, validateUser } from './validate';
import { validateUser } from "./validate";
import styles from "./styles.module.scss";
import LOCALES from "./locale";

type PropsDef = {
  user: Partial<UserModel>;
  verificationToken: string;
  phoneCountry: string;
  phoneNormalized: string;
  onUserChange: (user: Partial<UserModel>) => void;
  onConsent: (user: Partial<UserModel>) => void;
  onSignin: (user: Partial<UserModel>) => void;
  onDone: (data: { user: UserModel; provider?: string }) => void;
  consentToken?: string;
  lockEmail?: boolean;
  hideOutsideSources?: boolean;
  coachSignup?: boolean;
};

type FormErrors = {
  firstName?: string | MessageDescriptor;
  lastName?: string | MessageDescriptor;
  email?: string | MessageDescriptor;
  phone?: string | MessageDescriptor;
  password?: string | MessageDescriptor;
  ageConsent?: string | MessageDescriptor | null;
  tosConsent?: string | MessageDescriptor | null;
  avatar?: string | MessageDescriptor | null;
  facebook?: string | MessageDescriptor | null;
  unknown?: string | MessageDescriptor | null;
};

const initErrors: FormErrors = {};
const initMatch: boolean = false;

// type ProviderType = {
//   data: UserModel,
//   provider: string
// };

type UtmObjectType = {
  utm_source?: string;
  utm_medium?: string;
  utm_campaign?: string;
  utm_content?: string;
  utm_term?: string;
};

const SIGNUP_SOURCE = "self_sign_up";

export default function SignUpForm(props: PropsDef) {
  const { formatMessage } = useLoc();
  const [isMatch, setMatch] = useState(initMatch);
  const [pending, startPending, stopPending] = usePending();
  const [errorsForm, addErrors, clearErrors] = useError(initErrors);
  const { team_token, invitation_token } = useParams();
  const [searchParams] = useUrlSearch();
  const location = useLocation();
  const isPhoneInvite = props.phoneNormalized !== null;

  let signinPath: string = "/signin";
  if (team_token != null || invitation_token != null) {
    // invitaions that have accounts will click signin
    // after a successfull signin the redirect should come back to signup
    // this will allow signup to redirect to the proper team or space after signin
    signinPath = `${signinPath}?return_url=${encodeURIComponent(
      location.pathname + location.search
    )}`;
  } else if (location.search != null && location.search.length) {
    signinPath = `/signin${location.search}`;
  }

  const updateUserState = (userState: Partial<UserModel>) => {
    const user = { ...props.user, ...userState };
    props.onUserChange(user);
  };

  const addUTMParams = () => {
    const utmParams: UtmObjectType = {};
    if (searchParams.utm_source) {
      utmParams.utm_source = searchParams.utm_source;
    }
    if (searchParams.utm_medium) {
      utmParams.utm_medium = searchParams.utm_medium;
    }
    if (searchParams.utm_campaign) {
      utmParams.utm_campaign = searchParams.utm_campaign;
    }
    if (searchParams.utm_content) {
      utmParams.utm_content = searchParams.utm_content;
    }
    if (searchParams.utm_term) {
      utmParams.utm_term = searchParams.utm_term;
    }
    return utmParams;
  };

  const handleSignup = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    clearErrors();
    const form = getFormElements(e);
    const user = getUserFromForm(form, props);
    const validation = validateUser(user, isPhoneInvite);

    if (!validation.isValid) {
      addErrors(validation.errors);
      return;
    }

    if (!user.ageConsent && !user.consentToken) {
      props.onConsent(user);

      return;
    }

    startPending();
    const authAny = (AuthService as any).signUp(
      user,
      user.tosConsent,
      addUTMParams(),
      props.verificationToken
    );

    authAny
      .then((user: UserModel) => {
        // eslint-disable-next-line no-console
        console.log("sign up -> then");
        props.onDone({ user });
        // eslint-disable-next-line no-console
        console.log("sign up -> then -> onDone", user);
      })
      .catch((e: any) => handleApiError(e.errors, addErrors))
      .finally(stopPending);

    // eslint-disable-next-line no-console
    console.log("promise state: ", Promise.resolve(authAny));
  };

  // const handleFacebookSignup = () => {
  //   clearErrors();
  //   const { tosConsent } = props.user;
  //   const validation = validateProperty('tosConsent', tosConsent);
  //   if (!validation.isValid) {
  //     addErrors({ tosConsent: validation.error });
  //     return;
  //   }
  //
  //   const signinIfEmailExists = (error: string) => {
  //     if (error === 'facebook_email_exists' || error === 'facebook_email_taken') {
  //       return (AuthService as any).authenticateWithFacebook()
  //         .then((user: UserModel) => props.onSignin(user));
  //     } else {
  //       return handleResponseError('facebook', error, addErrors);
  //     }
  //   };
  //
  //   startPending();
  //   (AuthService as any).signUpWithFacebook(SIGNUP_SOURCE, tosConsent, addUTMParams())
  //     .then((data: ProviderType) => props.onDone({ user: data.data, provider: data.provider }))
  //     .catch(signinIfEmailExists)
  //     .finally(stopPending);
  // };

  const checkMatch = (event: FormEvent<HTMLFormElement>) => {
    const els = getFormElements(event);
    const password = els.password.value.trim();
    const confirmPassword = els.passwordVerify.value.trim();

    const match = password.length > 0 && password === confirmPassword;
    setMatch(match);
  };

  return (
    <div className={styles.signupForm}>
      {props.coachSignup ? (
        <div className={cls(styles.coachSignupHeaderCopy, "mb-1 mt-1")}>
          <FormattedHTMLMessage
            id={LOCALES.coach_signup_header_copy.id}
            defaultMessage={LOCALES.coach_signup_header_copy.defaultMessage}
          />
        </div>
      ) : (
        ""
      )}

      <form
        noValidate
        onChange={checkMatch}
        onSubmit={handleSignup}
        target="_self"
      >
        <FormItem error={getError("firstName", errorsForm)}>
          <input
            name="firstName"
            defaultValue={props.user.firstName}
            placeholder={formatMessage(LOCALES.first_name)}
            type="text"
            disabled={pending.status}
          />
        </FormItem>

        <FormItem error={getError("lastName", errorsForm)}>
          <input
            name="lastName"
            defaultValue={props.user.lastName}
            placeholder={formatMessage(LOCALES.last_name)}
            type="text"
            disabled={pending.status}
          />
        </FormItem>

        <FormItem error={getError("email", errorsForm)}>
          <input
            name="email"
            defaultValue={props.user.email}
            placeholder={formatMessage(LOCALES.email)}
            type="text"
            disabled={props.lockEmail || pending.status}
          />
        </FormItem>

        <FormItem
          className={!isPhoneInvite ? styles.hidden : ""}
          error={getError("phone", errorsForm)}
        >
          <PhoneInput
            country={props.phoneCountry}
            value={props.phoneNormalized}
            countryCodeEditable={false}
            inputStyle={{ width: "100%", height: "42px" }}
            disabled={isPhoneInvite}
          />
        </FormItem>

        <FormItem
          className={styles.checkForm}
          error={getError("password", errorsForm)}
        >
          <input
            name="password"
            defaultValue={props.user.password}
            placeholder={formatMessage(LOCALES.password)}
            type="password"
            disabled={pending.status}
          />
          <span className={cls(styles.icon, isMatch ? styles.showing : "")}>
            <i className="fas fa-check-circle fade in"></i>
          </span>
        </FormItem>

        <FormItem className={styles.checkForm}>
          <input
            name="passwordVerify"
            defaultValue={props.user.passwordVerify}
            placeholder={formatMessage(LOCALES.password_verify)}
            type="password"
            disabled={pending.status}
          />
          <span className={cls(styles.icon, isMatch ? styles.showing : "")}>
            <i className="fas fa-check-circle fade in"></i>
          </span>
        </FormItem>

        <FormItem error={getError("avatar", errorsForm)}>
          <UserAvatarUploader
            file={props.user.avatar as PreviewFile}
            onChange={(file: PreviewFile) => updateUserState({ avatar: file })}
            disabled={pending.status}
          />
        </FormItem>

        <FormItem
          className="text-left"
          error={getError("ageConsent", errorsForm)}
        >
          <AgeConsent
            disabled={pending.status}
            checked={props.user.ageConsent}
            onChange={(checked: boolean) =>
              updateUserState({ ageConsent: checked })
            }
          />
        </FormItem>

        <FormItem
          className={cls(styles.coachSignupCopy, "text-left")}
          error={getError("tosConsent", errorsForm)}
        >
          <TermsOfService
            disabled={pending.status}
            checked={props.user.tosConsent}
            onChange={(checked: boolean) =>
              updateUserState({ tosConsent: checked })
            }
          />
        </FormItem>

        {props.coachSignup ? (
          <FormItem className={styles.coachSignupCopy}>
            <FormattedMessage {...LOCALES.coach_signup_copy} />
          </FormItem>
        ) : (
          ""
        )}

        <FormItem error={getError("unknown", errorsForm)}>
          <button className="btn btn-primary full" disabled={pending.status}>
            {renderSingupButton(props)}
          </button>
        </FormItem>
      </form>

      <FormItem>
        <a className="btn btn-link full" href={signinPath}>
          {renderSigninButton(props)}
        </a>
      </FormItem>
    </div>
  );
}

const renderSigninButton = (props: PropsDef) => {
  if (props.coachSignup) {
    return <FormattedMessage {...LOCALES.coach_signin} />;
  } else {
    return <FormattedMessage {...LOCALES.signin} />;
  }
};

const renderSingupButton = (props: PropsDef) => {
  if (!props.user.ageConsent && !props.user.consentToken) {
    return <FormattedMessage {...LOCALES.signup_consent} />;
  } else if (props.coachSignup) {
    return <FormattedMessage {...LOCALES.coach_signup} />;
  } else {
    return <FormattedMessage {...LOCALES.signup} />;
  }
};

type EnhancedMessageDescriptor = { id: string; message: MessageDescriptor };

type ErrorObjMap = {
  [key: string]: {
    [key: string]: MessageDescriptor | EnhancedMessageDescriptor;
  };
};

interface FormElements extends HTMLCollection {
  firstName: HTMLInputElement;
  lastName: HTMLInputElement;
  email: HTMLInputElement;
  password: HTMLInputElement;
  passwordVerify: HTMLInputElement;
}

const getFormElements = (e: FormEvent<HTMLFormElement>) => {
  const form = e.currentTarget.elements as FormElements;
  return form;
};

const getUserFromForm = (form: FormElements, props: PropsDef) => {
  const user = Object.assign(
    {},
    {
      consentToken: props.user.consentToken,
      ageConsent: props.user.ageConsent,
      tosConsent: props.user.tosConsent,
      avatar: props.user.avatar,
    },
    {
      source: SIGNUP_SOURCE,
      firstName: getFormValue(form, "firstName"),
      lastName: getFormValue(form, "lastName"),
      email: getFormValue(form, "email"),
      password: getFormValue(form, "password"),
      passwordVerify: getFormValue(form, "passwordVerify"),
    }
  );
  return user;
};

const getError = (key: keyof FormErrors, errorsForm: FormErrors) => {
  if (errorsForm[key]) {
    return errorsForm[key];
  }
  return null;
};

const getFormValue = (form: any, key: string) => {
  const ref = form[key];
  if (ref) {
    return ref.value.trim();
  }
  return "";
};

const response_errors: ErrorObjMap = {
  signup: {
    password_length: {
      id: "password",
      message: LOCALES.error_invalid_password,
    },
    email_unavailable: {
      id: "email",
      message: LOCALES.error_unavailable_email,
    },
    duplicate_email: { id: "email", message: LOCALES.error_unavailable_email },
    email_already_taken: {
      id: "email",
      message: LOCALES.error_unavailable_email,
    },
    email_invalid: { id: "email", message: LOCALES.error_invalid_email },
    phone_already_taken: {
      id: "phone",
      message: LOCALES.error_unavailable_phone,
    },
    unknown: { id: "unknown", message: LOCALES.error_signup_unknown },
    no_connection: { id: "unknown", message: LOCALES.error_no_connection },
  },
  facebook: {
    facebook_email_exists: LOCALES.error_facebook_email_exists,
    facebook_email_taken: LOCALES.error_facebook_email_taken,
    email_not_allowed: LOCALES.error_facebook_email_unavailable,
    unknown: LOCALES.error_facebook_unknown,
  },
};

// type ErrKeys = keyof typeof response_errors;

// function handleResponseError(key: ErrKeys, error: string, addErrors: (e: FormErrors) => void) {
//   addErrors({ [key]: response_errors[key][error] || response_errors[key]['unknown'] });
// }

const handleApiError = (
  errors: string[],
  addErrors: (e: FormErrors) => void
) => {
  const typeErrors = response_errors["signup"];

  let formErrors: FormErrors;
  if (errors == null) {
    errors = ["unknown"];
  }

  formErrors = Object.assign(
    {},
    ...errors.map((key: string) => {
      const err = typeErrors[key];
      if (err && (err as EnhancedMessageDescriptor).message) {
        return { [err.id]: (err as EnhancedMessageDescriptor).message };
      } else if (err) {
        return err as MessageDescriptor;
      }
      return {};
    })
  );

  addErrors(formErrors as FormErrors);
};
