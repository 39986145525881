import React from "react";
import styles from "./styles.module.scss";
import ModalLayout from "../Layouts/ModalLayout/ModalLayout";
import Button from "../Button/ButtonVariant";
import { cls } from "app/utils";
import useFileUpload from "./indexModel";
import ResourcesList from "app/routes/Resources/ResourcesList";
import FileUploadFilter from "./FileUploadFilter";
interface FileUploadProps {
  isOpen: boolean;
  onClose: () => void;
  canSelectMedia?: boolean;
}

const FileUpload = ({ isOpen, onClose, canSelectMedia }: FileUploadProps) => {
  const {
    isLoading,
    uploadError,
    progress,
    selectedTab,
    onTabSelect,
    getRootProps,
    getInputProps,
    isDragActive,
    modalHandler,
    mediaSelectHandler,
    isMediaSelected,
  } = useFileUpload({ onClose });

  return (
    <>
      <ModalLayout
        onClose={modalHandler}
        isOpen={isOpen}
        classNames={styles.modal}
      >
        <div className={styles.content_wrapper}>
          <h3 className={styles.modal_title}>Select Media</h3>
          {canSelectMedia && (
            <div className={styles.header_tab_container}>
              <span
                className={cls({
                  [styles.selected_tab]: selectedTab === "upload",
                })}
                onClick={() => onTabSelect("upload")}
              >
                <i className="tab-icon ico ico-add" />
                Upload
              </span>
              <span
                className={cls({
                  [styles.selected_tab]: selectedTab === "library",
                })}
                onClick={() => onTabSelect("library")}
              >
                <i className="tab-icon ico ico-library-o" />
                Library
              </span>
            </div>
          )}
          {isLoading ? (
            <div className={styles.upload_progress}>
              <div className={styles.upload_progress_content}>
                {!uploadError && <p>Loading...</p>}
                {uploadError && <p>Upload failed</p>}

                <div className={styles.progress_bar_container}>
                  <div
                    className={styles.progress_bar}
                    style={{ width: `${progress}%` }}
                  ></div>
                </div>
              </div>
            </div>
          ) : selectedTab !== "library" ? (
            <div
              className={styles.dropzone_container}
              // htmlFor="media__uploader"
            >
              <div {...getRootProps({ className: "dropzone" })}>
                <div
                  className={cls(styles.dropzone_inner, {
                    [styles.dropzone_inner_active]: isDragActive,
                  })}
                >
                  <h1>Drag Media Here</h1>
                  <p>or you can...</p>
                  <label
                    htmlFor="media__uploader"
                    className={cls("btn btn-primary", styles.upload_btn)}
                  >
                    Select Media to Upload
                  </label>
                </div>
              </div>
              <input {...getInputProps()} id="media__uploader" />
            </div>
          ) : (
            <div className={styles.resources_container}>
              <div className={styles.filter_wrapper}>
                <FileUploadFilter />
              </div>
              <div className={styles.resources_list_container}>
                <ResourcesList canSelectMedia />
              </div>
            </div>
          )}
          <div className={styles.footer_buttons}>
            {selectedTab === "library" && (
              <Button
                title="Select"
                buttonType="primary"
                buttonStyles={styles.cancel_btn}
                onClickFunc={mediaSelectHandler}
                disabled={!isMediaSelected}
              />
            )}
            <Button
              title="Cancel"
              buttonType="secondary-outline"
              buttonStyles={styles.cancel_btn}
              onClickFunc={modalHandler}
            />
          </div>
        </div>
      </ModalLayout>
    </>
  );
};

export default FileUpload;
