/**
 * @module Components.Modals
 *
 */
import React from "react";
import ReactModal from "react-modal";
import { cls } from "app/utils";
import config from "config/environment";
import Styles from "./styles.module.scss";

interface ModalProps extends ReactModal.Props {
  title?: string;
  containerClassName?: string;
  headerClassName?: string;
  headerCloseClassName?: string;
  headerTitleClassName?: string;
  footer?: React.ReactNode;
  size?: "xlarge" | "large" | "medium" | "small" | "auto";
}

type PropsDef = React.PropsWithChildren<ModalProps>;

if (config.ENVIRONMENT !== "test") {
  ReactModal.setAppElement("#root");
}

/**
 * @class ModalWrapper
 *
 */
export default function ModalWrapper({
  className,
  containerClassName,
  headerClassName,
  bodyOpenClassName,
  overlayClassName,
  portalClassName,
  headerTitleClassName,
  headerCloseClassName,
  closeTimeoutMS,
  shouldCloseOnOverlayClick,
  shouldCloseOnEsc,
  onRequestClose,
  title,
  footer,
  size,
  children,
  ...props
}: PropsDef) {
  bodyOpenClassName = bodyOpenClassName || "open-dialog";
  shouldCloseOnOverlayClick =
    shouldCloseOnOverlayClick === false ? false : true;
  shouldCloseOnEsc = shouldCloseOnEsc === true ? true : false;
  closeTimeoutMS = typeof closeTimeoutMS === "number" ? closeTimeoutMS : 200;

  const closeHandler = (event: React.MouseEvent | React.KeyboardEvent) => {
    if (onRequestClose) {
      onRequestClose(event);
    }
  };

  let clsSize = Styles.small;
  if (size === "xlarge") {
    clsSize = Styles.xlarge;
  } else if (size === "large") {
    clsSize = Styles.large;
  } else if (size === "medium") {
    clsSize = Styles.medium;
  } else if (size === "auto") {
    clsSize = Styles.auto;
  }

  return (
    <ReactModal
      className={cls(
        Styles.modal,
        clsSize,
        className,
        footer ? Styles.hasFooter : ""
      )}
      bodyOpenClassName={bodyOpenClassName}
      overlayClassName={cls(Styles.overlay, overlayClassName)}
      portalClassName={cls(Styles.portal, portalClassName)}
      closeTimeoutMS={closeTimeoutMS}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      shouldCloseOnEsc={shouldCloseOnEsc}
      onRequestClose={closeHandler}
      {...props}
    >
      <div className={cls(Styles.header, headerClassName)}>
        <h3 className={cls(Styles.headerTitle, headerTitleClassName)}>
          {title}
        </h3>
        <button
          className={cls(Styles.headerClose, headerCloseClassName)}
          onClick={closeHandler}
        >
          ✕
        </button>
      </div>

      <div className={cls(Styles.body, containerClassName)}>{children}</div>

      <div className={Styles.footer}>{footer}</div>
    </ReactModal>
  );
}
