/**
 * @module Utils
 *
 */
import moment from 'moment';
import { resolve, reject } from 'rsvp';
import config from 'config/environment';
import isEmpty from './isEmpty';
import { assert } from './debug';
import { UserModel } from 'app/models';
import { localSet, localGet, localDelete } from 'app/utils/LocalStorage';
import { fetchGet, fetchDelete } from 'app/utils/request';
import { decodeQuery } from 'app/utils/object';
import { errorLogger } from 'app/utils';

// local storage class
const auth_user_store: { user: UserModel | null } = { user: null };

/**
 * Takes a token and puts it into local storage
 *
 * @public
 * @method setAuth
 * @param token {string}
 */
export function setAuth(token: string): void {
  assert(!isEmpty(token), "setAuth requires a string token");
  setCacheKey();
  localSet(config.AUTH_STORE_KEY, btoa(token));
}

function generateCacheKey(): string {
  return `${moment().unix()}`;
}

function setCacheKey(key?: string) {
  key = key != null ? key : generateCacheKey();
  localSet(`${config.AUTH_STORE_KEY}__cache`, key);
}

export function getCacheKey() {
  let cache = localGet(`${config.AUTH_STORE_KEY}__cache`);
  if (!(cache != null && cache.length)) {
    cache = generateCacheKey();
    setCacheKey(cache);
  }
  return cache;
}

/**
 * Gets the token from local storage returns null if token is not set
 *
 * @public
 * @method getAuth
 * @return {null|string}
 */
export function getAuth(): string {
  let token = localGet(config.AUTH_STORE_KEY);
  if (token && token.length) {
    try {
      token = atob(token);
    } catch(e) {
      errorLogger(e, { severity: 'error' });
      removeAuth();
      return '';
    }
  }
  return token;
}

/**
 * Clears the local storage auth token
 *
 * @public
 * @method removeAuth
 */
export function removeAuth(): void {
  auth_user_store.user = null;
  setCacheKey();
  localDelete(config.AUTH_STORE_KEY);
}

/**
 * Checks if local store has a token present
 *
 * @public
 * @method isAuthenticated
 * @return {boolean}
 */
export function isAuthenticated(): boolean {
  const token = getAuth();
  return typeof token === 'string' && !isEmpty(token);
}

/**
 * Creates a Authorization header for authenticated api calls
 *
 * @public
 * @method authHeader
 * @param token {string}
 * @return {string}
 */
export function authHeader(token: string): string {
  assert(!isEmpty(token), "authHeader requires a string token");
  return `Token token=${token}`;
}

export function invalidateSession(noRedirect?: boolean) {
  return fetchDelete('account/session', {}, { credentials: 'include', version: 1 })
    .catch(() => resolve())
    .finally(() => {
      // remove auth token
      removeAuth();

      if (!noRedirect && !isPublicDomain()) {
        (window as any).location = "/signin";
      }
    });
}

/**
 * invalidate session if status 401 found on user get
 *
 * @method shouldInvalidateSession
 */
export function shouldInvalidateSession(err: Response) {
  if (err.status === 401) {
    return invalidateSession().then(() => reject(err));
  }
  return reject(err);
}

export function loadAuthUser(forceUpdate?: boolean): Promise<UserModel | null> {
  if (!isAuthenticated()) {
    return resolve(null);
  }

  if (auth_user_store.user && !forceUpdate) {
    return resolve(auth_user_store.user);
  }

  const handleUser = (user: UserModel | UserModel[]) => {
    auth_user_store.user = (user as UserModel);
    return (user as UserModel);
  };

  return fetchGet<UserModel>('account', {}, { version: 1 })
    .then(handleUser)
    .catch(shouldInvalidateSession);
}

/**
 * Sets the auth token based on a cookie or url argument
 *
 * @method checkForAuth
 * @returns {void}
 */
export function checkForAuth() {
  const search = decodeQuery(window.location.search);
  if (search.access_token) {
    setAuth(search.access_token);
  } else if (document.cookie != null) {
    const cookies = Object.assign({}, ...document.cookie.split(/ ?; ?/).map(item => {
      const [ key, value ] = item.split('=');
      return { [key]: value };
    }));

    if (cookies && cookies.token) {
      setAuth(cookies.token);
    }
  }
}

const PUBLIC = [ "spaces.demo", "public" ];

const normalizePath = () => {
  let path = window.location.pathname;
  path = path.slice(1);
  path = path.replace(/\//g, '.');
  return path;
};

/**
 * helper for angular app support. This is
 * not meant to be used for any react routes
 *
 */
export const isPublicDomain = () => {
  const path = normalizePath();
  return PUBLIC.find(key => {
    key = key.replace(/\*/g, '+');
    const reg = new RegExp(key);
    return reg.test(path);
  }) != null;
};
