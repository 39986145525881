/**
 * @module Components.Helpers
 *
 */
import React from 'react';
import { FormattedMessage, MessageDescriptor } from 'react-intl';

interface PropsDef {
  message: MessageDescriptor | string;
}

/**
 * @class ErrorMessage
 *
 */
function ErrorMessage({ message }: PropsDef) {
  if (message != null) {
    if (typeof message === 'string') {
      return (<div className="error show">{message}</div>);
    }

    return (
      <div className="error show">
        <FormattedMessage {...message} />
      </div>
    );
  }

  return (<div className="error"></div>);
}

export default ErrorMessage;
