import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import Button from "app/components/Button/ButtonVariant";
import FileUpload from "app/components/FileUpload";
import paywallModalPro from "app/utils/paywall";
import { attachedData, clearMediaState } from "app/actions/media-library-data";
import { useDispatch } from "react-redux";

interface Props {
  titleContent?: React.ReactNode;
  icon?: React.ReactNode;
  hideUploadBtn?: boolean;
  canSelectMedia?: boolean;
  hasPermission?: boolean;
}

const CreateMedia = ({
  titleContent,
  icon,
  hideUploadBtn,
  canSelectMedia = true,
  hasPermission = false,
}: Props) => {
  const dispatch = useDispatch();
  const onClose = () => {
    setModalIsOpen(false);
  };

  const onModalOpen = () => {
    if (hasPermission) {
      setModalIsOpen(true);
    } else {
      return paywallModalPro();
    }
  };
  const [isModalOpen, setModalIsOpen] = useState(false);

  useEffect(() => {
    return () => {
      dispatch(clearMediaState());
      dispatch(attachedData(null));
    };
  }, []);

  return (
    <>
      <div className={styles.content_wrapper}>
        {!hideUploadBtn && (
          <Button
            title={titleContent ? titleContent : "Upload"}
            icon={icon && icon}
            buttonType={hasPermission ? "secondary-outline" : "upgrade"}
            buttonStyles={styles.upload_btn}
            onClickFunc={onModalOpen}
          />
        )}
      </div>
      <FileUpload
        isOpen={isModalOpen}
        onClose={onClose}
        canSelectMedia={canSelectMedia}
      />
    </>
  );
};

export default CreateMedia;
