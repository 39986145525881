import ModalLayout from "../Layouts/ModalLayout/ModalLayout";
import React from "react";
import styles from "./styles.module.scss";
import Button from "../Button/ButtonVariant";
import NoEye from "../../../public/images/no-eye.svg";

interface PostNoViewsModalProps {
  onClose: () => void;
  isOpen: boolean;
  isDemoSpace?: boolean;
}

const PostNoViewsModal = (props: PostNoViewsModalProps) => {
  const { isOpen, onClose } = props;

  const title = !props.isDemoSpace
    ? "Views and Likes info is unavailable"
    : "Inspiration Spaces";
  const description = !props.isDemoSpace
    ? "The owner of this group has disabled post data information"
    : "Views and Likes info is unavailable in this Space but are available for PRO members in real Spaces and Groups!";

  return (
    <ModalLayout isOpen={isOpen} onClose={onClose}>
      <div className={styles.content_wrapper}>
        <img src={NoEye} alt="icon" width={40} height={40} />
        <div className={styles.info_block}>
          <h2>{title}</h2>
          <p>{description}</p>
        </div>
        <Button title="Ok" buttonType="primary" onClickFunc={onClose} />
      </div>
    </ModalLayout>
  );
};

export default PostNoViewsModal;
