/**
 * @module Components.Links
 *
 */
import React from 'react';
import { FormattedMessage } from 'react-intl';

/**
 * @class TermsOfService
 *
 */
export default function TermsOfService() {
  return (
    <a href="https://coachnow.io/terms-of-service">
      <FormattedMessage
        id="app.links.terms-of-service"
        defaultMessage="Terms of Service"
        description="Terms of service link text"
      />
    </a>
  );
}

