/* eslint-disable no-console */
import React, { useCallback, useEffect, useState } from "react";
import styles from "./styles.module.scss";
import GettingStartedProgress from "app/components/GettingStarted/GettingStartedProgress";
import { cls } from "app/utils";
import { useActions, useSelector } from "app/utils/hooks";
import { fetchTags, updateTag, clearTags } from "../../../actions/tag";
import { RootState } from "typedefs";
import { TagsProps } from "../Tags.types";

type TagResponse = {
  payload: {
    name: string;
  };
};

const EditTag = ({ changeToTagsUrl, tagId }: TagsProps) => {
  const tagAction = useActions({ fetchTags, updateTag, clearTags });
  useEffect(() => {
    tagAction
      .fetchTags(tagId)
      .then((res: TagResponse) => {
        setTagName(res.payload.name);
      })
      .catch((err) => {
        console.error(`Something went wrong: ${err}`);
      });
    return () => {
      tagAction.clearTags();
    };
  }, [tagId]);

  const tagState = useSelector((state: RootState) => state.tag);
  const [tagName, setTagName] = useState<null | string>(null);
  const [tagIsEmpty, setTagIsEmpty] = useState(false);
  const [updateallUsages, setUpdateallUsages] = useState(false);

  const tagHandler = (name: string) => {
    if (name && name.length) {
      setTagName(name);
      setTagIsEmpty(false);
    } else {
      setTagIsEmpty(true);
    }
  };

  const submitHandler = useCallback(() => {
    if (tagName && tagName.length) {
      tagAction
        .updateTag(tagState.data, tagName.trim(), true)
        .then(() => {
          setTagIsEmpty(false);
          changeToTagsUrl();
        })
        .catch((err) => {
          alert(err ? err.errors[0] : "Something went wrong");
        });
    } else if (!tagName && !tagName.length) {
      setTagIsEmpty(true);
    }
  }, [tagName, tagState]);

  const checkboxHandler = () => {
    setUpdateallUsages((prev) => !prev);
  };

  return (
    <div className={styles.container}>
      <GettingStartedProgress />
      <div className={styles.add_tag_container}>
        <div className={styles.panel}>
          <h1>Edit Tag</h1>
        </div>
        <form className={styles.add_new_wrapper}>
          <span className={cls(styles.input_wrapper, "w-2-lg")}>
            <label htmlFor="tag">Name</label>
            <input
              type="text"
              id="tag"
              name="tag"
              onChange={(e) => tagHandler(e.target.value)}
              className={cls({
                [styles.error]: tagIsEmpty,
              })}
              defaultValue={tagName}
            />
          </span>
          <div className={cls(styles.update_all_usages_wrapper)}>
            <div className={cls("checkbox")}>
              <input
                type="checkbox"
                name="usages"
                id="usages"
                defaultChecked={updateallUsages}
                onChange={checkboxHandler}
              />
              <label htmlFor="usages" className="tag_update_all"></label>
            </div>
            <label htmlFor="usages">
              Update all occurences of this tag in my posts
            </label>
          </div>
          <span className={styles.buttons_container}>
            <button
              className="btn btn-primary"
              onClick={submitHandler}
              disabled={tagState.pending || tagIsEmpty}
              type="submit"
            >
              <span>{tagState.pending ? "Please Wait..." : "Update"}</span>
            </button>
            <button
              className={cls("btn btn-outline-secondary")}
              onClick={changeToTagsUrl}
              ng-disabled="processing"
            >
              Cancel
            </button>
          </span>
        </form>
      </div>
    </div>
  );
};

export default EditTag;
