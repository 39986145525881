import { action, ActionType } from "typesafe-actions";

// const pending = () => action("@newPost.fetch.pending");

// const error = (err: string) => action("@newPost.fetch.error", err);

// const success = (success: any) => action("@newPost.fetch.success", success);

// const data = (data: any[]) => action("@newPost.fetch.data", data);

const postText = (postText: string) =>
  action("@newPost.fetch.postText", postText);

export const selectedMedia = (selectedMedia: any) =>
  action("@newPost.fetch.selectedMedia", selectedMedia);

export const uploadedMedia = (uploadedMedia: any) =>
  action("@newPost.fetch.uploadedMedia", uploadedMedia);

export const selectedTags = (selectedTags: []) =>
  action("@newPost.fetch.selectedTags", selectedTags);

export const selectedLocations = (selectedLocations: []) =>
  action("@newPost.fetch.selectedLocations", selectedLocations);

export const clearNewPostState = () => action("@newPost.clear");

const thisActions = {
  postText,
  selectedTags,
  uploadedMedia,
  selectedMedia,
  selectedLocations,
  clearNewPostState,
};

export type newPostDataAction = ActionType<typeof thisActions>;

// export function fetchMedia() {
//   return (dispatch: Dispatch<newPostAction>) => {

//   };
// }
