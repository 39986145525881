/**
 * @module Components.Filter
 *
 */
import React from 'react';
import { useLoc } from 'app/utils/hooks';
import { cls } from 'app/utils';
import SearchBar from 'app/components/inputs/SearchBar';
import styles from './styles.module.scss';
import LOCALES from './locale';

type PropsDef = {
  clearSearchInput: boolean;
  onSearchSubmit: (value: any) => void;
};

export default function Search(props: PropsDef) {
  const { formatMessage } = useLoc();

  const onSearchSubmit = (query: string) => {
    props.onSearchSubmit(query);
  };

  return (
    <div className={cls(styles.searchContainer, "pull-right")}>
      <SearchBar
        className={styles.search}
        placeholder={formatMessage(LOCALES.search)}
        clearInput={props.clearSearchInput}
        onClick={onSearchSubmit}
      />
    </div>
  );
}
