/**
 * @module Reducers.SubscriptionPlan
 *
 */
import { RequestError } from 'typedefs';
import { SubscriptionPlanModel } from 'app/models';
import { SubscriptionPlanAction } from 'app/actions/subscription-plan';

export type SubscriptionPlanState = {
  data: SubscriptionPlanModel[],
  error: RequestError | null,
  pending: boolean,
};

const initialState: SubscriptionPlanState = {
  data: null,
  error: null,
  pending: false,
};

export default function subscriptionPlanReducer(state = initialState, action: SubscriptionPlanAction): SubscriptionPlanState {
  switch (action.type) {
    case '@subscription-plan.fetch.pending':
      return { ...state, pending: true, error: null };
    case '@subscription-plan.fetch.success':
      return { ...state, pending: false, data: action.payload };
    case '@subscription-plan.fetch.error':
      return { ...state, pending: false, error: action.payload };

    default:
      return state;
  }
}
