/**
 * @module Utils
 *
 */
import noop from 'lodash/noop';
import { isValidVersion, compareVersion } from './version';
import config from 'config/environment';

const CONSOLE = window.console || { log: noop, error: noop, warn: noop };
const LOG = CONSOLE.log;
const ERROR = CONSOLE.error;
const WARN = CONSOLE.warn;

export function isTesting() {
  if (config.ENVIRONMENT === 'test') {
    return true;
  }
  return false;
}

/**
 * Wrapper method to only run the contents while in debug mode
 *
 * @method runInDebug
 * @param {function} callback
 * @return {mixed}
 */
export function runInDebug(callback: () => any, target?: object): any {
  if (config.DEBUG) {
    return callback.call(target || null);
  }
  return noop();
}

/**
 * Safe console.log
 *
 * @method log
 */
export const log = (...args: [any?, ...any[]]): void => (
  runInDebug(() => LOG.apply(null, args))
);

/**
 * Safe console.log method with prepend INFO:
 *
 * @method print
 */
export const print = (...args: any[]): void => (
  runInDebug(() => LOG.apply(null, ['INFO:', ...args]))
);

/**
 * Safe console.error method
 *
 * @method error
 */
export const error = (...args: [any?, ...any[]]): void => (
  runInDebug(() => ERROR.apply(null, args))
);

/**
 * Safe console.warn method
 *
 * @method warn
 */
export const warn = (...args: [any?, ...any[]]): void => (
  runInDebug(() => WARN.apply(null, args))
);

/**
 * throw error on falsy test case
 *
 * @method assert
 * @param {boolean} test
 * @param {string} message
 */
export const assert = (test: boolean, message: string): void => (
  runInDebug(() => {
    if (!test) {
      throw new Error(message);
    }
  })
);

/**
 * Show warnings when a deprecated function is used
 *
 * @method deprecate
 * @param {string} message
 * @param {string} until
 */
export const deprecate = (message: string, until: string = ''): void => (
  runInDebug(() => {
    assert(isValidVersion(until), 'deprecate requires a semver "until" as the second param');

    const comp = compareVersion(config.VERSION, until);
    if (comp === -1) {
      WARN(`DEPRECATED: ${message} - Supported until version ${until}`);
    } else {
      ERROR(`DEPRECATED: ${message} - Not supported since version ${until}`);
    }
  })
);

