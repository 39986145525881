import React from "react";
import styles from "./styles.module.scss";
import ModalLayout from "../ModalLayout/ModalLayout";
import IndexModel from "./indexModel";
import Button from "app/components/Button/ButtonVariant";
import UploadMedia from "app/components/inputs/UploadMedia";
import UploadsPicker from "../UploadsPicker";

interface Props {
  onCloseFunc?: () => void;
  isOpen?: boolean;
  disabled?: boolean;
}

const MediaPickerModal = ({ onCloseFunc, isOpen, disabled }: Props) => {
  const { selectedIndex, handleSelect } = IndexModel();
  return (
    <ModalLayout onClose={onCloseFunc} isOpen={!disabled ? isOpen : null}>
      <div className={styles.container}>
        <div className={styles.modal_title}>Select Media</div>
        <div className={styles.modal_content}>
          <ul className={styles.modal_content_header}>
            <li
              onClick={() => handleSelect(1)}
              className={selectedIndex === 1 && styles.selectedHeader}
            >
              <i className="tab-icon ico ico-add"></i> <p>Upload</p>
            </li>
            <li
              onClick={() => handleSelect(2)}
              className={selectedIndex === 2 && styles.selectedHeader}
            >
              <i className="tab-icon ico ico-library-o"></i>
              <p>Library</p>
            </li>
          </ul>
          <div className={styles.tab_content}>
            {selectedIndex === 1 ? <UploadMedia /> : <UploadsPicker />}
          </div>
        </div>
        <div className={styles.button_bottom_wrapper}>
          <Button
            title="Cancel"
            buttonType="secondary"
            onClickFunc={onCloseFunc}
          />
        </div>
      </div>
    </ModalLayout>
  );
};

export default MediaPickerModal;
