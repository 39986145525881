import { useDispatch, useSelector } from "react-redux";
import { RootState } from "typedefs";
import { filterData } from "../../actions/filter";
import { useCallback, useEffect, useRef, useState } from "react";
import UserModel from "app/models/UserModel";
import { useActions } from "app/utils/hooks";
import {
  fetchMedia,
  clearMediaState,
  mediaLibraryData,
} from "app/actions/media-library-data";
import { clearFilter } from "app/actions/filter";
import useFilters from "../helpers/useFilters";

export const FilterDropdownModel = () => {
  const filterState = useSelector((state: RootState) => state.filter);
  const dispatch = useDispatch();
  const mediaActions = useActions({ fetchMedia, clearMediaState });
  const filterActions = useActions({ clearFilter });
  const { filters } = useFilters();
  const [filterButtonClicked, setFilterButtonClicked] = useState(false);
  const libraryData = useSelector((state: RootState) => state.mediaLibraryData);
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const mediaTypeOptions = [
    { value: "image", label: "Images" },
    { value: "video", label: "Videos" },
    { value: "audio", label: "Audio" },
    { value: "datafile", label: "Documents" },
  ];

  const sortByOptions = [
    { value: "created_at", label: "Created at" },
    { value: "captured_at", label: "Captured at" },
  ];

  const toggleMediaType = useCallback(
    (dataType: "image" | "video" | "audio" | "datafile") => {
      if (filterState.data) {
        dispatch(
          filterData({
            ...filterState.data,
            type: {
              ...filterState.data.type,
              [dataType]: !filterState.data.type[dataType],
            },
          }),
        );
      }
    },
    [filterState.data],
  );

  const authorOptions = useSelector(
    (state: RootState) => state.connection.data,
  );
  const userSelectRef = useRef(null);

  const authorsSelectHandler = useCallback(
    (selectedData: UserModel | UserModel[]) => {
      if (selectedData) {
        const newIds = selectedData.map((user: UserModel) => user.id);
        dispatch(
          filterData({
            ...filterState.data,
            userIds: newIds,
          }),
        );
      }
    },
    [filterState.data],
  );

  function formatWord(word: string) {
    if (!word) {
      return null;
    }
    const parts = word.split("_");

    const formattedWord = parts
      .map((part) => {
        if (part.length > 0) {
          return part[0].toUpperCase() + part.slice(1);
        }
        return part;
      })
      .join(" ");

    return formattedWord;
  }

  const onSortBySelect = (orderby: "created_at" | "captured_at") => {
    dispatch(filterData({ ...filterState.data, orderby }));
  };

  let sortByValue =
    formatWord(filterState.data.orderby) || sortByOptions[0].label;

  useEffect(() => {
    sortByValue =
      formatWord(filterState.data.orderby) || sortByOptions[0].label;
  }, [filterState.data.orderby]);

  const onTagsChange = (tags: any) => {
    const tagNames = tags.map((item: { name: string }) => item.name);
    dispatch(filterData({ ...filterState.data, tags: tagNames }));
  };

  const onFilterClick = () => {
    mediaActions.clearMediaState();
    mediaActions
      .fetchMedia(1, 20, filters)
      .then((res) => {
        dispatch(mediaLibraryData(res));
        setFilterButtonClicked(true);
        setDropdownOpen(true);
      })
      .finally(() => setDropdownOpen(false));
    // dispatch(page(currentPage + 1));
  };

  const clearFiltersHandler = () => {
    mediaActions.clearMediaState();
    filterActions.clearFilter();
    mediaActions
      .fetchMedia(1, 20)
      .then((res) => {
        dispatch(mediaLibraryData(res));
        setFilterButtonClicked(true);
        setDropdownOpen(true);
      })
      .finally(() => setDropdownOpen(false));
    // dispatch(page(currentPage + 1));
  };

  return {
    filterState,
    mediaTypeOptions,
    toggleMediaType,
    sortByOptions,
    userSelectRef,
    authorsSelectHandler,
    authorOptions,
    onSortBySelect,
    sortByValue,
    onTagsChange,
    onFilterClick,
    clearFiltersHandler,
    filterButtonClicked,
    libraryData,
    isDropdownOpen,
  };
};
