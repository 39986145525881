/**
 * @module Utils.Hooks
 *
 */
import { useEffect, useRef } from 'react';

/**
 *
 * @public
 * @method useDOMState
 */
export default function useDOMState(): DOMState {
  const state = useRef(new DOMState());

  useEffect(() => {
    state.current._render();
    return () => {
      state.current._destroy();
    };
  });
  return state.current;
}

export type DOMStateTypes = 'destroyed' | 'rendered';

class DOMState {
  private __state: DOMStateTypes = 'rendered';

  public get isRendered(): boolean {
    return this.__state === 'rendered';
  }

  public get isDestroyed(): boolean {
    return this.__state === 'destroyed';
  }

  public get state(): DOMStateTypes {
    return this.__state;
  }

  public _render(): void {
    this.__state = 'rendered';
  }

  public _destroy(): void {
    this.__state = 'destroyed';
  }
}
