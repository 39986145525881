/**
 * @module Actions
 *
 */
import { Promise } from 'rsvp';
import { Dispatch } from 'react';
import { RequestError } from 'typedefs';
import { ActionType, action } from 'typesafe-actions';
import { fetchGet } from 'app/utils/request';

export const asyncSportsGet = (): Promise<string[]> => {
  const promise = fetchGet('sports', {}, { version: 1 });
  return (promise as Promise<string[]>);
};

const pending = () => action('@sports.fetch.pending');
const error = (err: RequestError) => action('@sports.fetch.error', err);
const success = (model: string[]) => action('@sports.fetch.success', model);

const thisActions = { pending, error, success };

export type SportsAction = ActionType<typeof thisActions>;

export function fetchSports() {
  return (dispatch: Dispatch<SportsAction>) => {
    dispatch(pending());

    return asyncSportsGet()
      .then(model => dispatch(success(model)))
      .catch(err => dispatch(error(err)));
  };
}

export const actions = {
  fetchSports
};

