/** @module utils/hooks */
import { useEffect, useState, useRef } from 'react';
import getCalendly from 'app/library/calendly-widget';

/**
 * Gets the Calendly class instance
 *
 * @method useCalendly
 * @return {Class} Calendly
 */
export default function useCalendly() {
  const ref = useRef('destroyed');
  const [ calendly, setCalendly ] = useState<ReturnType<typeof getCalendly>>(null);

  useEffect(() => {
    ref.current = 'rendered';
    return () => {
      ref.current = 'destroyed';
    };
  }, []);

  useEffect(() => {
    const _calendly = getCalendly();
    if (calendly == null) {
      _calendly.registerOnReady(() => {
        if (ref.current === 'rendered') {
          setCalendly(_calendly);
        }
      });
    }
  }, []);

  return calendly;
}
