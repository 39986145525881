import { CopyAction } from "app/actions/copy";
import { RequestError } from "typedefs";

export type CopyState = {
  data: any | null;
  error: RequestError | null;
  pending: boolean;

  pendingCopy: boolean;
  errorCopy: RequestError | null;
  dataCopy: any | null;
  copyLocation: string | string[] | null;
};

const initialState: CopyState = {
  data: null,
  error: null,
  pending: false,

  dataCopy: null,
  errorCopy: null,
  pendingCopy: false,
  copyLocation: null,
};

export default function copyReducer(state = initialState, action: CopyAction) {
  switch (action.type) {
    case "@copy.fetch.pending":
      return { ...state, pending: true, data: null };
    case "@copy.fetch.error":
      return { ...state, pending: false, data: null, error: action.payload };
    case "@copy.fetch.success":
      return { ...state, pending: false, data: action.payload, error: null };
    case "@copy.create.pending":
      return { ...state, dataCopy: null, pendingCopy: true };
    case "@copy.create.error":
      return {
        ...state,
        dataCopy: null,
        pendingCopy: false,
        errorCopy: action.payload,
      };
    case "@copy.create.success":
      return { ...state, dataCopy: action.payload, pendingCopy: false };
    case "@copy.fetch.copyLocation":
      return { ...state, copyLocation: action.payload, pending: false };
    case "@copy.clear":
      return {
        ...state,
        data: null,
        dataCopy: null,
        errorCopy: null,
        copyLocation: null,
        pendingCopy: null,
      };
    default:
      return state;
  }
}
