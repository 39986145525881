/**
 * @module Routes
 *
 */
import React from 'react';
import { NavLink } from 'react-router-dom';
import { defineMessages } from 'react-intl';
import { cls } from 'app/utils';
import { Loc } from 'app/components/helpers';
import upgrade_trial_jpg from 'app/images/upgrade-trial.jpg';
import Styles from './styles.module.scss';

/**
 * @class ExpiredTrial
 *
 */
export default function ExpiredTrial() {
  return (
    <div className={Styles.successMain}>
      <div className={cls(Styles.panel, Styles.panelOne)}>
        <div className={Styles.successImage} style={{ backgroundImage: `url(${upgrade_trial_jpg})` }} />
      </div>

      <div className={cls(Styles.panel, Styles.panelTwo)}>
        <div className={Styles.panelContainer}>
          <div className={Styles.panelHeader}>
            <Loc value={locales.heading} />
          </div>

          <div className={Styles.panelBody}>
            <p><Loc value={locales.information} /></p>
            <p className={Styles.highlight}><Loc value={locales.summary} /></p>
            <p><Loc value={locales.extra} /></p>
            <div className="clear-float"></div>
          </div>

          <div className={Styles.panelFooter}>
            <NavLink className={cls('btn', 'btn-outline-secondary', Styles.rowButton)} to="/">
              <Loc value={locales.enter_site} />
            </NavLink>
            <NavLink className={cls('btn', 'btn-primary', Styles.rowButton)} to="/upgrade">
              <Loc value={locales.upgrade_now} />
            </NavLink>
          </div>

          <div className="clear-float"></div>
        </div>
      </div>
    </div>
  );
}

const locales = defineMessages({
  title: { id: 'trial.expired-trial.title', defaultMessage: 'Your CoachNow+ trial expired' },
  heading: { id: 'trial.expired-trial.heading', defaultMessage: 'Your 7-day Trial Has Expired' },
  information: { id: 'trial.expired-trial.information', defaultMessage: 'Thank you for trying out CoachNow+' },
  summary: { id: 'trial.expired-trial.summary', defaultMessage: 'Liked what you saw?' },
  extra: {
    id: 'trial.expired-trial.extra',
    defaultMessage: 'Upgrade now and keep access to unlimited Spaces and Teams, media posting and video/image analysis in Teams, 150GB of storage, Library, Segements, and Templates Tools.'
  },
  enter_site: { id: 'trial.expired-trial.enter-site', defaultMessage: 'Back' },
  upgrade_now: { id: 'trial.expired-trial.upgrade-now', defaultMessage: 'Upgrade' },
});
