/**
 * @module Utils
 *
 */
import { AnalyticsService } from "app/services";
import { isEmpty } from "app/utils";
import { assert } from "app/utils/debug";

export const track = (
  eventName: string,
  props: { [key: string]: any },
  callback?: () => void
) => {
  assert(
    !isEmpty(eventName) && typeof eventName === "string",
    `track requires a string eventName, you passed ${typeof eventName}`
  );
  AnalyticsService.track(eventName, props, callback);
};

export const createdAccount = (source: string, callback?: () => void) => {
  assert(!isEmpty(source), "source is required for createAccount event");
  trackSource("Created Account", source, callback);
  if (callback !== null) {
    callback();
  }
};

export const signedIn = (source: string, callback?: () => void) => {
  trackSource("Signed In", source, callback);
  if (callback !== null) {
    callback();
  }
};

export const accountCreatedSurvey = (callback?: () => void) => {
  AnalyticsService.track("Onboarding About You Survey Completed", {}, callback);
  if(callback !== null) {
    callback();
  }
};

const trackSource = (
  eventName: string,
  Source: string,
  callback?: () => void
) => {
  AnalyticsService.track(eventName, { Source }, callback);
  if (callback !== null) {
    callback();
  }
};
