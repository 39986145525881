/**
 * @module Components.Wrappers
 *
 */
import React, { PropsWithChildren } from 'react';
import { Redirect } from 'react-router-dom';
import { AuthService } from 'app/services';
import { encodeQuery } from 'app/utils/object';
import Styles from './styles.module.scss';

const Auth: { isAuthenticated: boolean } = (AuthService as any);

type PropsDef = PropsWithChildren<{
  params?: {[key: string]: any};
}>;

/**
 * @class Unauthenticated
 *
 */
export default function Unauthenticated(props: PropsDef) {
  if (Auth.isAuthenticated) {
    let path = '/';
    if (props.params != null) {
      path = `/?${encodeQuery(props.params)}`;
    }
    return (<Redirect to={path} />);
  }

  return (
    <div className={Styles.authWrapper}>{props.children}</div>
  );
}
