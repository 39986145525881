/**
 * @module Actions.SubscriptionPlan
 *
 */
import { Dispatch } from 'react';
import { action, ActionType } from 'typesafe-actions';
import { RequestError } from 'typedefs';
import { SubscriptionModel } from 'app/models';
import { fetchPost } from 'app/utils/request';

const pending = () => action('@subscription.fetch.pending');
const error = (error: RequestError) => action('@subscription.fetch.error', error);
const success = (data: SubscriptionModel) => action('@subscription.fetch.success', data);

const actionHandlers = { pending, error, success };

export type SubscriptionAction = ActionType<typeof actionHandlers>;

export const startTrial = () => {
  return (dispatch: Dispatch<SubscriptionAction>) => {
    dispatch(pending());

    return fetchPost('subscriptions/trial', {}, { version: 1 })
      .then((data: { subscriptions: SubscriptionModel[] }) => dispatch(success(data.subscriptions[0])))
      .catch(err => dispatch(error(err.errors[0])));
  };
};

export const actions = {
  startTrial
};
