import React, { memo } from "react";
import styles from "./styles.module.scss";
import Dropdown from "app/components/Dropdown";
import CheckBox from "app/components/inputs/CheckBox";
import SelectBox, { TagSelect } from "app/components/inputs/SelectBox";
import Userselect from "./UserSelect";
import { cls } from "app/utils";
import { FilterDropdownModel } from "./indexModel";
import Button from "app/components/Button/ButtonVariant";
import DatePicker from "./DatePicker";
import { initialFilterState } from "app/reducers/filter";

export type filterStateDataType = "image" | "video" | "audio" | "datafile";

const FilterDropdown = () => {
  const {
    filterState,
    mediaTypeOptions,
    sortByOptions,
    sortByValue,
    authorsSelectHandler,
    toggleMediaType,
    onSortBySelect,
    onTagsChange,
    clearFiltersHandler,
    onFilterClick,
    libraryData,
    isDropdownOpen,
  } = FilterDropdownModel();
  const FilterBtn = () => (
    <span className={styles.filter_btn_wrapper}>
      <i className="ico ico-funnel" />
      <p>Filter</p>
    </span>
  );

  return (
    <Dropdown
      closeModal={isDropdownOpen}
      btnContent={<FilterBtn />}
      dropdownClassname={styles.dropdown_btn}
    >
      <div className={styles.dropdown_list_wrapper}>
        <div className={styles.dropdown_list}>
          <label>Media Type</label>
          {mediaTypeOptions.map((item, index) => (
            <div
              key={index}
              className={styles.dropdown_item}
              onClick={() => toggleMediaType(item.value as filterStateDataType)}
            >
              <CheckBox
                key={index}
                name={item.value}
                className={styles.checkbox_input}
                checked={
                  filterState.data &&
                  filterState.data.type &&
                  filterState.data.type[item.value as filterStateDataType]
                }
              />
              <div className={styles.name}>{item.label}</div>
            </div>
          ))}
        </div>
        <div className={styles.dropdown_list}>
          <label htmlFor="author">Author</label>
          <Userselect onChange={authorsSelectHandler} />
        </div>
        <div className={styles.dropdown_list}>
          <label>Sort by</label>
          <SelectBox
            className={styles.select_box}
            placeholder={sortByValue}
            isClearable={false}
            isCreatable={false}
            isMulti={false}
            options={sortByOptions}
            onChange={(sortValue: { value: "created_at" | "captured_at" }) =>
              onSortBySelect(sortValue.value)
            }
          />
        </div>
        <div className={styles.dropdown_list}>
          <label>Tags</label>
          <TagSelect
            className={styles.select_box}
            placeholder={"Add some tags."}
            isClearable
            isCreatable={false}
            isMulti={true}
            onChange={onTagsChange}
          />
        </div>
        <DatePicker />
        <div className={cls(styles.dropdown_buttons)}>
          <Button
            disabled={(filterState.error as any) || libraryData.pending}
            title="Filter"
            buttonType="primary"
            onClickFunc={onFilterClick}
          />
          {JSON.stringify(filterState.data) !==
            JSON.stringify(initialFilterState.data) && (
            <Button
              disabled={libraryData.pending}
              title="Show All"
              buttonType="secondary-outline"
              onClickFunc={clearFiltersHandler}
            />
          )}
        </div>
      </div>
    </Dropdown>
  );
};

export default memo(FilterDropdown);
