/**
  @module services
*/
import { Service, createService } from 'app/utils/service';
import { fetchGet } from 'app/utils/request';

/**
 * @class ScheduledPostsService
 *
 */
class ScheduledPostsService extends Service {
  static _name = "ScheduledPostsService";

  /**
   * Request scheduled posts counts grouped by date.
   *
   * @returns {Promise}
   */
  groupedByDate(timezoneOffset) {
    return fetchGet('posts/scheduled_by_date', { timezoneOffset }, { version: 1 });
  }
}

export default createService(ScheduledPostsService);
