/**
 * @module Utils.File
 *
 */
import getExtension from './getExtension';
import getContentType from './getContentType';

/**
 * Determines if a file is a type of type where type can be an array of types or comma seperated list.
 *
 * @example
 *  isTypeOf(file, '.jpg,.img');
 *  isTypeOf(file, ['.jpg', '.img']);
 *  isTypeOf(file, ['image/*', '.docx']); // where images is an extension group
 *
 * @param file {(string|file)}
 * @param types {string[]}
 * @param {boolean}
 */
export default function isTypeOf(file: File | string, types: string[]): boolean {
  if (file == null) { return false; }

  const ext = getExtension(file);
  if (ext == null) { return false; }

  const filetype = getContentType(ext);
  const typeFound = getSupportedTypes(types).find(t => {
    const c = getContentType(t);
    return filetype === c || (new RegExp(t)).test(ext);
  });
  return typeFound != null;
}

function getSupportedTypes(types: string[]): string[] {
  types = (Array.isArray(types) ? types : (typeof types === 'string' ? [ types ] : []));

  return mergeList(types.map(t => {
    if (t === '*') {
      return mergeList(Object.keys(_supported).map(k => _supported[k]));
    } else if (_supported[t] != null) {
      return _supported[t];
    } else {
      return [t];
    }
  }));
}

function mergeList(list: any[][]): any[] {
  return list.reduce((a, b) => [...a, ...b], []);
}

const _supported: { [key: string]: string[] } = {
  // video
  "video/*": [
    '.mov', '.avi', '.mp4', '.m4v', '.mkv',
    '.wmv', '.mpg', '.mpeg', '.webm', '.ogv',
    '.3gp', '.3gp2', '.3g2', '.3gpp', '.3gpp2',
  ],

  // images
  "image/*": [
    '.gif', '.jpeg', '.jpg', '.png', '.webp', '.heic'
  ],

  // documents
  "document/*": [
    '.doc', '.docx', '.pdf', '.xls',
    '.xlsx', '.ppt', '.pptx',
  ],

  // audio
  "audio/*": [
    '.m4a', '.mp3', '.wav',
    '.wma', '.aif', '.ogg',
  ],
};
