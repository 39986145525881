/**
 * @module Utils.File
 *
 */
import isFile from './isFile';

/**
 * Gets the extensions of a file.
 *
 * @param {File|string}) file
 * @returns {string}
 */
export default function getExtension(file: File | string): string {
  const name: string = isFile(file) ? (file as File).name : (file as string);
  if (name == null) { return ''; }

  const idx = name.lastIndexOf('.');
  const ext = name.slice(idx);
  return idx !== -1 && ext.length > 0 ? ext : name;
}
