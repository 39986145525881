/**
 * @module Utils
 *
 */
import { AnalyticsService } from 'app/services';

 class SpaceAnalytics {
   origin: string = '';
   type: string = '';
   count: number = 0;
   coachCount: number = 0;
   fanCount: number = 0;
 }

 export function spaceCreated(track: string, space: SpaceAnalytics): void {
  const properties: any = {};
  space = space || new SpaceAnalytics();
  if (space.origin) { properties['Origin'] = space.origin; }
  if (space.type) { properties['Type'] = space.type; }
  if (space.count) { properties['Count'] = space.count; }

  AnalyticsService.track('TS Creation ' + track, properties);
}

export function spaceEdited(track: string, space: SpaceAnalytics): void {
  const properties: any = {};
  if (space.coachCount) { properties['Coach Count'] = space.coachCount; }
  if (space.fanCount) { properties['Fan Count'] = space.fanCount; }

  AnalyticsService.track('TS ' + track, properties);
}
