import { useActions, useSelector } from "app/utils/hooks";
import {
  allowMultipleSelect,
  clearDestinations,
  fetchDestinations,
} from "app/actions/destinations";
import { useEffect, useState } from "react";
import { RootState } from "typedefs";
import { route } from "app/utils/router";
import pdf_ico from "../../../public/images/ico_pdf.png";
import xlxx_ico from "../../../public/images/ico_xls.png";
import doc_ico from "../../../public/images/ico_doc.png";
import ppt_ico from "../../../public/images/ico_ppt.png";
import audio_ico from "../../../public/images/ico_audio.png";
import { CopyPostModel } from "app/models/CopyPostModel";
import TagModel from "app/models/TagModel";
import { MovePostDestination, MovePostModel } from "app/models/MovePostModel";
import {
  clearCopy,
  copyLocation,
  createCopyPost,
  fetchPost,
} from "app/actions/copy";
import {
  clearMove,
  moveLocation,
  moveObjectLocation,
  movePost,
} from "app/actions/move";
import { clearSegments, fetchSegments } from "app/actions/segments";
import { useDispatch } from "react-redux";

const useCopyMovePostModel = () => {
  const icons: { [key: string]: any } = {
    xlxx: xlxx_ico,
    xlsx: xlxx_ico,
    doc: doc_ico,
    docx: doc_ico,
    ppt: ppt_ico,
    pptx: ppt_ico,
    pdf: pdf_ico,
    audio: audio_ico,
  };

  const actions = useActions({ fetchDestinations, clearDestinations });
  const postActions = useActions({ fetchPost, createCopyPost, clearCopy });
  const movePostActions = useActions({ movePost, clearMove });
  const segmentActions = useActions({ fetchSegments, clearSegments });

  const {
    data: post,
    pending: postPending,
    dataCopy,
  } = useSelector((state: RootState) => state.copy);
  const dispatch = useDispatch();
  const moveLocationSelector = useSelector(
    (state: RootState) => state.move.moveLocation
  );

  const copyLocationSelector = useSelector(
    (state: RootState) => state.copy.copyLocation
  );
  const copyPending = useSelector((state: RootState) => state.copy.pendingCopy);

  const { dataMove } = useSelector((state: RootState) => state.move);

  const { selectedDestinations } = useSelector(
    (state: RootState) => state.destinations
  );

  const [postDetails, setPostDetails] = useState("");
  const [postTags, setPostTags] = useState<TagModel[]>([]);

  // modals
  const [openMoveModal, setOpenMoveModal] = useState(false);
  const [openCopyModal, setOpenCopyModal] = useState(false);
  const [movePostReplies, setMovePostReplies] = useState(false);
  const [sendNotification, setSendNotification] = useState(false);

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [btnLabel, setBtnLabel] = useState<string>("Copy");
  const [isPostMove, setIsPostMove] = useState(false);
  const [moveLocationUrl, setMoveLocationUrl] = useState("spaces");
  const currentUrl = window.location.pathname;
  const [copyObjects, setCopyObjects] = useState({
    smart_list_ids: [],
    space_id: [],
    team_ids: [],
    segment_ids: [],
    team_group_ids: [],
  });

  useEffect(() => {
    const id = window.location.pathname.split("/")[2];
    postActions.fetchPost(id);

    segmentActions.fetchSegments();
  }, []);

  useEffect(() => {
    dispatch(allowMultipleSelect(!currentUrl.includes("move")));
    currentUrl.includes("move") ? setBtnLabel("Move") : setBtnLabel("Copy");
    if (currentUrl.includes("move")) {
      setIsPostMove(true);
    } else {
      setIsPostMove(false);
    }

    return () => {
      dispatch(allowMultipleSelect(true));
    };
  }, []);

  const handleMoveClick = () => {
    if (!isPostMove) {
      return;
    }

    if (!selectedDestinations || selectedDestinations.length < 1) {
      setErrorMessage("Please select location");
    } else {
      setErrorMessage(null);
      setOpenMoveModal(true);
    }
  };

  useEffect(() => {
    if (post && !postPending) {
      setPostDetails(post.details);
    }
  }, [postPending, post]);

  const copyRedirect = () => {
    if (copyPending) {
      return;
    }
    route("/");
    postActions.clearCopy();
  };

  useEffect(() => {
    if (selectedDestinations && selectedDestinations.length > 0) {
      switch (selectedDestinations[0].object) {
        case "smart_list":
          dispatch(moveObjectLocation("smart_list"));
          setMoveLocationUrl("smartList");
          break;
        case "athlete_space":
          dispatch(moveObjectLocation("athlete_space"));
          setMoveLocationUrl("spaces");
          break;
        case "team":
          dispatch(moveObjectLocation("team"));
          setMoveLocationUrl("groups");
          break;
        case "team_group":
          dispatch(moveObjectLocation("team_group"));
          setMoveLocationUrl("channels");
          break;
        default:
          return;
      }
      dispatch(moveLocation(selectedDestinations[0].id));
    }
  }, [selectedDestinations]);

  useEffect(() => {
    if (dataMove && moveLocationSelector) {
      if (moveLocationUrl === "channels") {
        route(
          `/groups/${selectedDestinations[0].team.id}/channels/${moveLocationSelector}`
        );
      }
      if (moveLocationUrl !== "channels") {
        route(`/${moveLocationUrl}/${moveLocationSelector}`);
      } else {
        route(`/`);
      }
      movePostActions.clearMove();
    }
  }, [dataMove]);

  function handleCancel() {
    route("/");
  }

  useEffect(() => {
    actions.fetchDestinations();
  }, []);

  const dataFileIconHandler = (datafile: string): any => {
    if (datafile === undefined) {
      return;
    }

    return icons[datafile] || undefined;
  };

  useEffect(() => {
    if (!isPostMove && selectedDestinations) {
      const pinIds = selectedDestinations.map((pin: any) => pin.id);
      dispatch(copyLocation(pinIds));
    }

    if (selectedDestinations) {
      selectedDestinations.forEach((obj: { id: string; object: string }) => {
        const idx =
          obj.object === "smart_list"
            ? "smart_list_ids"
            : obj.object === "athlete_space"
            ? "space_id"
            : obj.object === "team"
            ? "team_ids"
            : obj.object === "segment"
            ? "segment_ids"
            : "team_group_ids";

        setCopyObjects((prevState) => ({
          ...prevState,
          [idx]: [...prevState[idx], obj.id],
        }));
      });
    }

    return () => {
      setCopyObjects({
        smart_list_ids: [],
        space_id: [],
        team_ids: [],
        segment_ids: [],
        team_group_ids: [],
      });
    };
  }, [selectedDestinations]);

  const handleCopyClick = () => {
    if (
      !isPostMove &&
      (!copyLocationSelector || copyLocationSelector.length < 1)
    ) {
      setErrorMessage("Please select at least one location");
    } else {
      setErrorMessage(null);
    }

    if (copyPending) {
      return;
    }

    const postModel: CopyPostModel = {
      details: postDetails,
      ...copyObjects,
      tags: postTags.length > 0 ? postTags.map((tag) => tag.name) : post.tags,
    };

    if (post.type !== "note") {
      postModel.resource_id = post[post.type].id;
      postModel.resource_type = post.type;
    }

    postActions.createCopyPost(postModel);
  };

  useEffect(() => {
    if (dataCopy) {
      setOpenCopyModal(true);
    }
  }, [dataCopy]);

  const createMovePost = () => {
    const postModel: MovePostModel = {
      post: {
        details: postDetails,
        tags: postTags.length > 0 ? postTags.map((tag) => tag.name) : post.tags,
      },
      destination: getSingleDestination(selectedDestinations),
      include_replies: movePostReplies,
      skip_post_notifications: sendNotification,
    };

    movePostActions.movePost(post.id, postModel);
  };

  function getSingleDestination(locations: any): MovePostDestination {
    const location = locations[0];

    if (location.object === "smart_list") {
      return {
        id: location.id,
        type: "smart_list",
      };
    }

    if (location.object === "athlete_space") {
      return {
        id: location.id,
        type: "space",
      };
    }

    if (location.object === "team_group") {
      return {
        id: location.id,
        type: "team_group",
      };
    }

    if (location.object === "team") {
      return {
        id: location.id,
        type: "team",
      };
    }

    throw new Error("Invalid location object");
  }

  return {
    handleCancel,
    post,
    postPending,
    dataFileIconHandler,
    setPostDetails,
    postDetails,
    createMovePost,
    setPostTags,
    openMoveModal,
    setOpenMoveModal,
    movePostReplies,
    sendNotification,
    setMovePostReplies,
    setSendNotification,
    errorMessage,
    handleMoveClick,
    openCopyModal,
    setOpenCopyModal,
    copyRedirect,
    btnLabel,
    selectedDestinations,
    isPostMove,
    handleCopyClick,
  };
};

export default useCopyMovePostModel;
