/**
 * @module Actions.Coupon
 *
 */
import { Dispatch } from 'react';
import { MessageDescriptor, defineMessages } from 'react-intl';
import { ActionType, action } from 'typesafe-actions';
import { RequestError } from 'typedefs';
import { fetchPost } from 'app/utils/request';
import { CouponModel } from 'app/models';

interface ApplyCouponParams {
  couponCode: string;
  productKind: string;
  planCode: string;
}

const pending = () => action('@coupon.fetch.pending');
const error = (error: RequestError) => action('@coupon.fetch.error', null, null, error);
const success = (coupon: CouponModel) => action('@coupon.fetch.success', coupon, null, null);
export const clearCoupon = () => action('@coupon.clear');

const actionHandlers = { pending, error, success, clearCoupon };

export type CouponAction = ActionType<typeof actionHandlers>;

export const applyCoupon = (params: ApplyCouponParams) => {
  return (dispatch: Dispatch<CouponAction>) => {
    dispatch(pending());

    const promise = fetchPost('stripe/coupon', params, { version: 1 });
          promise.then(data => dispatch(success(data)));
          promise.catch(err => dispatch(error(mapErrorMessage(err))));

    return promise;
  };
};

export const actions = {
  clearCoupon,
  applyCoupon,
};

const LOCALE = defineMessages({
  CARD_INVALID: { id: 'error.invalid.api.card', defaultMessage: 'The information provided cannot be verfified. Please check your information and try again.' },
  COUPON_CODE_INVALID_ERROR: { id: 'error.invalid.api.coupon-code', defaultMessage: "The coupon entered is not valid." },
  COUPON_CODE_PRODUCT_MISMATCH_ERROR: { id: 'error.api.mismatch.coupon-code', defaultMessage: "The coupon entered is not valid for this product." },
  COUPON_CODE_EXPIRED_ERROR: { id: 'error.expired.api.coupon-code', defaultMessage: "This coupon has expired." },
  UNKNOWN_ERROR: { id: 'error.unknown.api', defaultMessage: 'An unknown error occurred. Please try again.' },
  SEVERE_UNKNOWN_ERROR: { id: 'error.unknown.api.severe', defaultMessage: 'An unknown error occurred. Please contact customer service.' },
});

const ERROR_MAP: { [key: string]: MessageDescriptor } = {
  card_invalid: LOCALE.CARD_INVALID,
  coupon_code_unsupported: LOCALE.COUPON_CODE_PRODUCT_MISMATCH_ERROR,
  coupon_code_invalid: LOCALE.COUPON_CODE_INVALID_ERROR,
  coupon_code_expired: LOCALE.COUPON_CODE_EXPIRED_ERROR,
  unknown_error: LOCALE.UNKNOWN_ERROR,

  // show message for customer to contact
  // customer service if these errors happen
  // on production server.
  term_kind_required: LOCALE.SEVERE_UNKNOWN_ERROR,
  product_kind_required: LOCALE.SEVERE_UNKNOWN_ERROR,
  token_required: LOCALE.SEVERE_UNKNOWN_ERROR,
};

function mapErrorMessage(err: any): MessageDescriptor {
  let error = ERROR_MAP.unknown_error;
  if (err.errors && err.errors.length && ERROR_MAP[err.errors[0]] != null) {
    error = ERROR_MAP[err.errors[0]];
  }
  return error;
}
