/**
 * @module Services
 *
 */
import { Promise } from 'rsvp';
import { Service, createService } from 'app/utils/service';
import config from 'config/environment';

const FB_AUTH_SCOPE = { scope: 'public_profile,email' };
const FB_CREATE_SCOPE = { fields: 'first_name,last_name,picture,id,email' };

function injectFacebookSDK(d, s, id) {
  let js, fjs = d.getElementsByTagName(s)[0];

  if (d.getElementById(id)) {
    return;
  }

  js = d.createElement(s); js.id = id;
  js.src = "https://connect.facebook.net/en_US/sdk.js";
  // js.src = "https://connect.facebook.net/en_US/sdk/debug.js";
  fjs.parentNode.insertBefore(js, fjs);
}

class FacebookService extends Service {
  static _name = "FacebookService";

  init() {
    this.isInit = false;
  }

  initialize() {
    return new Promise((resolve) => {
      window.fbAsyncInit = () => {
        window.FB.init({
          appId: config.FACEBOOK_APP_ID,
          autoLogAppEvents: true,
          xfbml: true,
          version: 'v3.2'
        });
        window.setTimeout(() => {
          resolve(window.FB);
        }, 100);
      };

      injectFacebookSDK(document, 'script', 'facebook-jssdk');
    });
  }

  fbPromise() {
    return new Promise(resolve => {
      if (!this.isInit) {
        this.initialize().then((fb) => {
          this.isInit = true;
          resolve(fb);
        });
      } else {
        resolve(window.FB);
      }
    });
  }

  getLoginStatus() {
    return new Promise(resolve => {
      this.fbPromise().then(fb =>
        fb.getLoginStatus(data =>
          resolve(data)
        )
      );
    });
  }

  authenticate() {
    return new Promise(resolve => {
      this.fbPromise().then(fb => {
        fb.getLoginStatus(status => {
          if (status.status === 'unknown' || status.status === 'not_authorized') {
            fb.login(data => resolve(data), FB_AUTH_SCOPE);
          } else {
            resolve(status);
          }
        });
      });
    });
  }

  invalidate() {
    return new Promise((resolve, reject) => {
      this.fbPromise().then(fb => {
        fb.getLoginStatus(status => {
          if (status.status === 'connected') {
            try {
              fb.logout();
              resolve();
            } catch (e) {
              reject(e);
            }
          } else {
            resolve();
          }
        });
      });
    });
  }

  getAccountInfo() {
    return this.authenticate().then(data => {
      if (data.status === 'connected') {
        return this.api('/me', FB_CREATE_SCOPE).then(profile => ({ ...data, profile }));
      } else {
        return data;
      }
    });
  }

  api(path, method, params) {
    return new Promise((resolve) => {
      this.fbPromise().then(fb => {
        fb.api(path, method, params, data => resolve(data));
      });
    });
  }
}

export default createService(FacebookService);
