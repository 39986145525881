/**
 * @module Reducers.PaymentInfo
 *
 */
import { MessageDescriptor } from 'react-intl';
import { PaymentInfoModel } from 'app/models';
import { PaymentInfoAction } from 'app/actions/payment-info';

export type PaymentInfoState = {
  data: PaymentInfoModel | null,
  error: MessageDescriptor | string | null,
  pending: boolean,
  update: boolean,
};

const initialState: PaymentInfoState = {
  data: null,
  error: null,
  pending: false,
  update: false,
};

export default function paymentInfoReducer(state = initialState, action: PaymentInfoAction): PaymentInfoState {
  switch (action.type) {
    case '@payment-info.request.pending':
      return { ...state, pending: true, error: null, update: false };
    case '@payment-info.fetch.success':
      return { ...state, pending: false, data: action.payload };
    case '@payment-info.put.success':
      return { ...state, pending: false, data: action.payload, update: true };
    case '@payment-info.request.error':
      return { ...state, pending: false, error: action.payload };

    default:
      return state;
  }
}
