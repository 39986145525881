import React from "react";
import styles from "./styles.module.scss";
import ModalLayout from "../ModalLayout/ModalLayout";
import checkmark from "../../../../public/images/checkmark-green.png";
import errorIcon from "../../../../public/images/error-x.svg";
import { cls } from "app/utils";
import { useSelector } from "react-redux";
import { RootState } from "typedefs";
import { ApplyTemplateToProps } from "app/components/Button/ApplyTemplateDropdown/index.types";

const ApplyTemplateSuccessModal = ({
  isSuccessModalOpen,
  onSuccessModalClose,
  templateName,
  appliedLocation,
  applied,
}: ApplyTemplateToProps) => {
  const applyTemplateError = useSelector(
    (state: RootState) => state.applyTemplate.errorApplyTemplate
  );

  return (
    <ModalLayout isOpen={isSuccessModalOpen} onClose={onSuccessModalClose}>
      <div className={styles.container}>
        <img
          src={applyTemplateError ? errorIcon : checkmark}
          alt="success"
          className={styles.checkMark}
        />
        <h1 className={styles.title}>
          {applyTemplateError ? "Something went wrong" : "Template applied"}
        </h1>
        <p className={styles.info}>
          {templateName ? `"${templateName}"` : "Template"}{" "}
          {applyTemplateError ? "wasn't" : "was successfully"} applied to{" "}
          <b>{applied}</b> {appliedLocation}
          {applyTemplateError && ", please try again"}
        </p>
        <button
          className={cls(styles.done_button, "btn btn-primary")}
          onClick={onSuccessModalClose}
        >
          Ok
        </button>
      </div>
    </ModalLayout>
  );
};

export default ApplyTemplateSuccessModal;
