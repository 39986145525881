/**
 * @module Reducers.Tag
 *
 */
import { RequestError } from "typedefs";
import { TagAction } from "app/actions/tag";
import { TagModel } from "app/models";

export interface TagState {
  data: TagModel[] | null;
  error: RequestError | null;
  pending: boolean;
}

const initState: TagState = {
  data: null,
  error: null,
  pending: false,
};

export default function tagReducer(
  state = initState,
  action: TagAction
): TagState {
  switch (action.type) {
    case "@tag.fetch.pending":
      return { ...state, pending: true };
    case "@tag.fetch.success":
      return { ...state, pending: false, data: action.payload };
    case "@tag.fetch.error":
      return { ...state, pending: false, error: action.payload };
    case "@tag.clear":
      return { ...initState };
    default:
      return state;
  }
}
