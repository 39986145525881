import React from "react";
import { ApplyTemplateToProps } from "../ApplyTemplateDropdown/index.types";
import { cls } from "app/utils";

const ApplyTemplateToChannel = ({
  className,
  channelSelectModalHanlder,
}: ApplyTemplateToProps) => {
  return (
    <>
      <div className={cls(className)} onClick={channelSelectModalHanlder}>
        <i className="ico ico-team" />
        <p>Apply to Channel</p>
      </div>
    </>
  );
};

export default ApplyTemplateToChannel;
