/**
 * @module Provider
 *
 */
import React, { PropsWithChildren } from 'react';
import { Provider } from 'react-redux';
import { IntlProvider } from 'react-intl';
import { ErrorBoundary } from 'app/library/bugsnag';
import { store } from 'app/Store';
import { translations } from 'app/translations';

// setup locale and preloaded translations
const { locale, messages } = translations();

type PropsDef = PropsWithChildren<{}>;

/**
 * @class ProvideWrapper
 *
 */
const ProvideWrapper = (props: PropsDef) => {
  return (
    <ErrorBoundary>
      <Provider store={store}>
        <IntlProvider locale={locale} messages={messages}>
          {props.children}
        </IntlProvider>
      </Provider>
    </ErrorBoundary>
  );
};


export default ProvideWrapper;
