/**
 * @module Utils
 *
 */
export { default as isFile } from './isFile';
export { default as isTypeOf } from './isTypeOf';
export { default as getType } from './getType';
export { default as getExtension } from './getExtension';
export { default as getContentType } from './getContentType';
export { default as readAsDataUrl } from './readAsDataUrl';
export { default as readAsArrayBuffer } from './readAsArrayBuffer';
export { default as readFilesFromInput } from './readFilesFromInput';
export { default as dataUrlToBlob } from './dataUrlToBlob';

export interface PreviewFile extends File {
  url?: string;
  originalUrl?: string;
  blob?: Blob;
}

