/**
 * @module Utils
 *
 */
import { Bugsnag } from '@bugsnag/browser';
import { bugsnag } from 'app/library/bugsnag';
import { print, warn, error } from './debug';

type OptsType = Bugsnag.INotifyOpts;

export default function errorLogger(message: string, options: OptsType) {
  options = options != null ? options : { severity: 'info' };

  const e = new Error(message);
  if (options.severity === 'error') {
    error(e.stack, options.metaData || '');
  } else if (options.severity === 'warning') {
    warn(e.stack, options.metaData || '');
  } else {
    print(e.stack, options.metaData || '');
  }

  if (bugsnag != null && bugsnag.notify != null) {
    bugsnag.notify(e, options);
  }
}
