/**
 * @module Utils
 *
 */
type AnyObject = {[key: string]: any};

export function isObject(value: any): boolean {
  if (value != null && typeof value === 'object' && !Array.isArray(value)) {
    return true;
  }
  return false;
}

export function get<T extends AnyObject>(context: T, key: string): any {
  const [ index, remaining ] = splitKey(key);
  const next = context[index];
  if (!remaining.length) {
    return next;
  } else if (next != null && typeof next === 'object') {
    return get(next, remaining);
  } else {
    return null;
  }
}

export function set<T extends AnyObject>(context: T, key: string, value: any) {
  if (!(context != null && typeof context === 'object')) {
    throw new Error('set() tried to index a non object');
  }

  const [ index, remaining ] = splitKey(key);
  if (!remaining.length) {
    context[(index as keyof T)] = value;
    return;
  }
  set(context[index], remaining, value);
}

export function encodeQuery(params: {[key: string]: any}) {
  if (params == null) {
    return '';
  }

  const keys = Object.keys(params);
  if (!keys.length) {
    return '';
  }

  return keys.map(key => {
    const value = params[key];
    if (Array.isArray(value)) {
      return value.map(val => `${key}[]=${val}`).join('&');
    } else if (typeof value === 'object') {
      return Object.keys(value).map(k => `${key}[${k}]=${value[k]}`).join('&');
    } else {
      return `${key}=${params[key]}`;
    }
  }).join('&');
}

export function decodeQuery(params: string): {[key: string]: any} {
  if (params == null || params.length === 0) {
    return {};
  }

  if (params.charAt(0) === '?') {
    params = params.slice(1);
  }

  const resut = Object.assign({}, ...params.split('&').map((item: string) => {
    const [ key, val ] = item.split('=');

    let value: any = decodeURIComponent(val);
    if (/^\d*$/.test(value)) {
      value = parseInt(value, 10);
    } else if (/^\d*\.\d*$/.test(value)) {
      value = parseFloat(value);
    } else if (/^true$/.test(value)) {
      value = true;
    } else if (/^false$/.test(value)) {
      value = false;
    }
    return { [key]: value };
  }));
  return resut;
}

const splitKey = (key: string): [ string, string ] => {
  const index = key.indexOf('.');
  if (index === -1) {
    return [ key, '' ];
  }
  const first = key.slice(0, index);
  const last = key.slice(index+1);
  return [ first, last ];
};
