/**
 * @module Utils
 *
 */
import { v4 } from 'uuid';
import { V4Options } from 'uuid/interfaces';

export type UUIDOpts = V4Options;

/**
 * Generates a uuid string
 *
 * @method uuid
 * @param {UUIDOpts} options?
 * @return {string}
 */
export function uuid(options?: UUIDOpts): string {
  return v4(options);
}

/**
 * Check to see if uuid string is a valid uuid
 *
 * @method isUUID
 * @param {string} value
 * @return {boolean}
 */
export function isUUID(value: string): boolean {
  const reg = new RegExp(/^[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/, 'i');
  return reg.test(value);
}
